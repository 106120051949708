import { StyleSheet } from "react-native";
import Colors from "../../constants/Colors"; 

export const Style=StyleSheet.create({
    mainContent:{
        flex:1,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 10,
        elevation: 3,
    },
    errorText:{
        display:'none',
        color:'red',
        marginTop:15,
        margin:10,
        alignSelf:'flex-start',
    },
    container:{
        flexDirection:'column',
    },
    contentHolder:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-evenly'
    },
    mobileContentHolder:{
        flexDirection:'column',
    },
    footer:{
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 1,
        marginBottom:0,
        position:'relative',
        flexDirection:'row',
        padding:2,
        zIndex:-1
    },
    divView:{
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 1,
        marginBottom:0,
        position:'relative',
        flexDirection:'column',
        padding:15
    },
    groupContainer:{
       padding:10,
    },
    header:{
        flex:1,
        height:100,
        flexDirection:'row',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.05,
        shadowRadius: 0,
        elevation: 1,
    },
    headerLeft:{
        flex:1,
        flexDirection:'row',
        alignSelf:'flex-start',
    },
    headerRight:{
        flex:1,
        alignSelf:'flex-end',
    },
    headerTitle:{
        fontSize:15,
        fontWeight:'500',
        margin:25
    },
    headerRightIocns:{
        height:30,
        width:30,
        margin:15,
        marginRight:25,
        alignSelf:'flex-end'
    },
    headerLeftIocns:{
        height:30,
        width:30,
        margin:20,
        marginRight:2,
        alignSelf:'flex-end'
    },
    categoryView:{
        flex:1,
        flexDirection:'row'
    },
    itemContainer: {
        flex:1,
        flexGrow:1,
        height: 200,
    },
    item: {
        flex: 1,
        margin: 5,
        backgroundColor: 'white',
        borderRadius:5,
        borderStyle:'solid',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.5,
        shadowRadius: 2,
        elevation: 5,
        justifyContent:'flex-end'
    },
    stockQty:{
        fontSize:13,
        marginLeft:5,
        marginBottom:5
    },
    rackId:{
        fontSize:15,
        margin:10,
        marginBottom:5
    },
    stockName:{
        fontSize:20,
        fontWeight:'500',
        margin:5,
        marginTop:5,
        maxWidth:400
    },
    stockViewControl:{
        paddingLeft:20,
        flexDirection:'row'
    },
    stockViewBtn:{
        height:40,
        width:100,
        justifyContent:'center',
        backgroundColor:'#dce0dd'
    },
    activeStockViewBtn:{
        justifyContent:'center',
        backgroundColor:'#248af0'
    },
    stockBtnLabel:{
        color:'black',
        alignSelf:'center'
    },
    activeStockBtnLabel:{
        color:'white',
        alignSelf:'center'
    },
    stockCard:{
        margin:15,
        padding:15,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.2,
        shadowRadius: 2,
        elevation: 1,
    },
    usageStocks:{
        margin:2.5,
        color:'white',
        fontWeight:'500',
        fontSize:12,
        marginLeft:10,
    },
    stockRequired:{
        margin:2.5,
        color:'black',
        fontWeight:'500',
        fontSize:12,
        marginLeft:10,
    },
    categryNameHeader:{
        width:'100%',
        backgroundColor:'#dce0dd'
    },
    categoryTitle:{
        margin:15,
        fontSize:12,
        fontWeight:'500'
    },
    catStockiew:{
        margin:20,
    },
    catStockCard:{
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.2,
        shadowRadius: 2,
        elevation: 1,
    },
    stockCatWiiseHolder:{
        padding:10,
    },
    metricTitle:{
        marginLeft:5,
        fontSize:15,
    },
});