import { StyleSheet } from "react-native";
import Colors from "../../constants/Colors"; 
const Style=StyleSheet.create({
    container:{
        height:'100%',
        maxWidth:120,
        justifyContent:'flex-start',
        padding:10,
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.2,
        shadowRadius: 2,
        flexDirection:'column',
    },
    contentHolder:{
        height:60,
        width:80,
        padding:5,
        display:'flex',
        flexDirection:'column',
        marginBottom:'soild',
        marginBottommWidth:20,
 
    },
    sidemenuTitle:{
        marginTop:10,
        fontWeight:'500',
        fontSize:10,
        alignSelf:'center'
    },
    sideMenuIcon:{
        height:20,
        width:20,
        alignSelf:'center'
    },
    activeSideMenu:{
        padding:5,
        height:60,
        width:80,
        borderRadius:3,
        justifyContent:'center',
        borderLeftStyle:'soild',
        borderLeftWidth:2.5,
        borderLeftColor:'#1286c9',
        backgroundColor:'#f2f6f7'
    },
    actveSidemenuTitle:{
        color:'#0a94f7'
    }
});

export default Style;