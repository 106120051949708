import React,{FC, useEffect, useState} from "react";
import { View, Text, TouchableOpacity, Image, FlatList, Picker, ScrollView } from "react-native";
import {generateRandomNumber, localTimeConversion} from "../../utility/index" 
import { useSelector, useDispatch } from 'react-redux';
import CustomAlert from "../CustomAlert";
import CustomTextInput from "../CustomTextInput";
import CustomLabel from "../CustomLabel";
import CustomButton from "../CustomButton";
import DatePicker from "../DatePicker";
import { DataTable } from 'react-native-paper';
import Style from "./style";
import CheckBoxButton from "../CheckBox";
import {getCategoryList, getTaskList, getOrderDetails, getAgendaList, getOPUList, createAGENDA} from '../../Server_Repo/action';
import ResponsiblePerson from "../ResponsiblePerson";

interface Props{
    isLargeScreen:number;
    data:any;
    orderID:any;
    buyerID:any;
    currentRoute:any;
    successCallBack:(data:any)=>void;
    internalComponentChanges:()=>void;
}
const EventAgenda:FC<Props>=(props)=>{
    const [screenType,setScreenType]=useState(Style);
    const [formStatus,setFormStatus]= useState<any>(0);
    const [formError,setFormError]=useState<any>(null);
    const [alertTitle,setAlertTitle]= useState<any>();
    const [alertSubTitle,setAlertSubTitle]= useState<any>();
    const [addProduction,setAddProduction]= useState<any>(false);
    const [agendaName,setAgendaName] = useState<any>();
    const [agenda,setAgenda]=useState<any>([]);
    const [categoryData,setCategoryData] = useState<any>([]);
    const [taskData,setTaskData] = useState<any>([]);
    const [orderGroupInfo,setOrderGroupInfoDetails]=useState<any>();
    const [addEvent,setEvent]= useState<any>(false);
    const [eventName,setEventName]= useState<any>('');
    const [eventPlanned,setEventPlanned]= useState<any>('');
    const [eventAccoumplised,serEventAccoumplised]= useState<any>('');
    const [eventResPerson,setEventResPerson]= useState<any>([]);
    const [editedIndex,setEditedIndex]= useState<any>(null);
    const [currentAgenda,setCurrentAgenda]= useState<any>('');

    const dispatch:any = useDispatch();
    const {
        getCategoryListResponse,
        getTaskListResponse,
        orderDetails,
        createAgendaResponse,
        getAgendaListResponse
    } = useSelector((state:any) => state.userReducer);

    const [agendaView,setAgendaView] = useState<any>([]);
    const [editAgendaNameList,setAgendaNameList] =  useState<any>([]);
    const [deletedAgenda,setDeletedAgenda]=useState<any>({
        mainUnit:[],
        subUnit:[]
    });


    useEffect(()=>{
        dispatch(
            getCategoryList(
              {
                buyerID: props.buyerID,
                orderID: props.orderID,
                userMail : "parixbe@gmail.com",
              }
            ))
        dispatch(
            getTaskList({
                buyerID: props.buyerID,
                orderID: props.orderID,
                userMail : "parixbe@gmail.com",
            }))
        dispatch(getOrderDetails({
            buyerID: props.buyerID,
            orderID: props.orderID,
            userMail : "parixbe@gmail.com",
            fetchType:"users",
            limit: 100,
            skip: 0,
        }));
    },[]);


    useEffect(()=>{
        if(getCategoryListResponse?.status==1){
            setCategoryData(getCategoryListResponse?.data);
        }
        if(getTaskListResponse?.status==1){
            setTaskData(getTaskListResponse?.data);
        }
        if(orderDetails?.status==1){
            setOrderGroupInfoDetails([...orderDetails.data]);
        }
    },[
        getCategoryListResponse!=undefined,
        getTaskListResponse!=undefined,
        orderDetails!=undefined]);

    useEffect(()=>{
        if(createAgendaResponse?.status==1){
           setFormStatus(1);
           setAlertTitle("Success");
           setAlertSubTitle("Agenda saved successfully")
        }else{
            setFormStatus(
                createAgendaResponse?.error
            )
        }
    },[createAgendaResponse!=undefined]);

    useEffect(()=>{
        dispatch(
            getAgendaList(
              {
                buyerID: props.buyerID,
                orderID: props.orderID,
                userMail : "parixbe@gmail.com",
              }
            ))
    },[formStatus==0]);

    useEffect(()=>{
        if(getAgendaListResponse?.status==1){
           setAgenda(getAgendaListResponse?.data);
        }
    },[getAgendaListResponse!=undefined]);

    const addNewAgenda=()=>{
        if(agendaName==null){
            setFormError(["All fields are mandatory"])
        }else{
            console.log("agenda",agenda);
            var checkName = agenda.filter((item:any)=>{return item.agendaName==agendaName});
            if(checkName.length>0){
                setFormError(["Agenda name already exists"])
            }else{
                var newProUnitLine:any = agenda ? agenda : [];
                newProUnitLine.push({
                    agendaName:agendaName,
                    event:[]
                })
                console.log("newProUnitLine",newProUnitLine);
                setAgenda([...newProUnitLine]);
                setAgendaName(null);
                setAddProduction(!addProduction);
            }
        }
    }
    const addNewEvent=(agendaName:any,editIndex:any)=>{
        var newProUnitLine:any = agenda ? agenda : [];
       

        var index = newProUnitLine.findIndex(function(obj:any){return obj.agendaName == agendaName})

        if(editIndex==null){
            newProUnitLine[index].event.push(
                {
                    subAgendaName:eventName,
                    plannedAt:eventPlanned,
                    accoumplisedAt:eventAccoumplised,
                    resPersons:eventResPerson
                }
            )
        }else{
            
            let extractOrder = newProUnitLine[editIndex[0]]["event"][editIndex[1]] ? 
            {...newProUnitLine[editIndex[0]]["event"][editIndex[1]]} : {}

                newProUnitLine[editIndex[0]]["event"][editIndex[1]]= {
                ...extractOrder,
                ...{
                    subAgendaName:eventName,
                    plannedAt:eventPlanned,
                    accoumplisedAt:eventAccoumplised,
                    resPersons:eventResPerson
                }
            }
        }
        setEvent(false)
        setAgenda([...newProUnitLine]);
    }

    const saveOrderAgendaEvent=()=>{
        dispatch(
            createAGENDA({
                buyerID: props.buyerID,
                orderID: props.orderID,
                deleteAenda:deletedAgenda,
                agendaData: agenda
            })
        )
    }

    const reSetAddEvent=()=>{
        setEvent("");
        setEventName("");
        setEventResPerson([]);
        setEventPlanned("");
        serEventAccoumplised("")
        setEditedIndex(null)
    }

    const viewProUnit=(unitID:any)=>{
        //If it's from server check in with _id or else checkig with index
        let tempUnit = agendaView;
        let unitIndex = tempUnit.indexOf(unitID);
        if(unitIndex==-1){
            tempUnit.push(unitID)
        }else{
            tempUnit = tempUnit.filter((unit:any)=>{
                return unit!=unitID
            })
        }
        setAgendaView([...tempUnit])
    }

    const editUnitName=(unitID:any)=>{
        let tempEditAgendaNameList = editAgendaNameList ? editAgendaNameList :[];
        if(tempEditAgendaNameList.indexOf(unitID)==-1){
            tempEditAgendaNameList.push(unitID)
        }else{
            tempEditAgendaNameList = tempEditAgendaNameList.filter((unitIDs:any)=>{
                return unitIDs != unitID
            })
        }
        console.log(
            "tempEditUnitNameList",tempEditAgendaNameList
        )
        setAgendaNameList([...tempEditAgendaNameList]);
    }

    const deleteAgenda = (unitID:any,unitType:any)=>{
        console.log(
            "deleteMainSubUnit",
            unitID
        )
        if(unitType=="main"){
            let mainUnit = deletedAgenda.mainUnit;
            if( String(unitID).length > 5){
                let mainUitIndex = mainUnit?.indexOf(unitID);
                if(mainUitIndex!=-1){
                    mainUnit = mainUnit?.filter((deletedIndex:any)=>{
                        return deletedIndex != unitID
                    });
                }else{
                    mainUnit.push(unitID)
                }

                console.log(
                    "deleteMainSubUnit",
                    "mainUit",
                    mainUnit
                )
                if(mainUnit!=undefined){
                    setDeletedAgenda(
                        {
                            mainUnit:[...mainUnit],
                            subUnit:deletedAgenda.subUnit
                        }
                    )
                }
            }else{
                let  tempProductionLine = agenda;
                delete tempProductionLine[unitID];
                tempProductionLine = tempProductionLine.filter((el:any) =>{
                    return el != null;
                });
                setAgenda([...tempProductionLine])
            }
        }else{
            let subUnit = deletedAgenda.subUnit;
            if( String(unitID).length > 5){
                let mainUitIndex = subUnit?.indexOf(unitID);
                subUnit = subUnit.filter((deletedIndex:any)=>{
                    return deletedIndex != unitID
                });
                if(mainUitIndex==-1){
                    subUnit.push(unitID);
                }
                console.log(
                    "tempProductionLine",subUnit
                )
                setDeletedAgenda(
                    {
                        mainUnit:deletedAgenda.mainUnit,
                        subUnit:subUnit
                    }
                )
            }else{
                let tempProductionLine = agenda;
                let tempSubLine = tempProductionLine[unitType]["event"];
                tempSubLine = tempSubLine.filter((unitIDs:any,index:any)=>{
                    return index!=unitID
                });
                tempProductionLine[unitType]["event"] = tempSubLine;
               
                setAgenda([...tempProductionLine])
            }
        }  
       
    }

    const checkIsDeleted=(unitID:any,type:any)=>{
        if(type=="main")
            return deletedAgenda.mainUnit.indexOf(unitID) == -1 ? 'delete' : 'undo'
        else 
            return deletedAgenda.subUnit.indexOf(unitID) == -1 ? 'delete' : 'undo'
    }

    const onValueChanged=(mainIndex:any,subIndex:any,field:any,val:any)=>{
        var newAgenda:any = agenda ? agenda : [];
        if(subIndex==null){
            newAgenda[mainIndex]["agendaName"] = val;
            console.log("newAgenda",newAgenda);
            setAgenda([...newAgenda]);
        }
    }


    return (
        <View style={Style.mainContent}>
            <View>
                <View style={Style.container}>
            { formStatus==1 ?
                <View>
                  <CustomAlert title={alertTitle} subTitle={alertSubTitle} onPress={()=>{setFormStatus(0)}} />
                </View>
            :    <View style={screenType.container}>
                    {
                    !addEvent ? 
                        <View style={Style.header}>
                            <View style={Style.headerLeft}>
                                <Text style={Style.headerTitle}>{"Event Agenda"}</Text>
                            </View>
                            <View style={Style.headerRight}>
                                <CustomTextInput type="text" id="categoryID" value={""}
                                    placeHolder="Search agendas" onInputChange={(ID,val)=>{console.log("sa")}}/>
                                <TouchableOpacity onPress={()=>{
                                    setAddProduction(!addProduction)
                                    setFormError(null)
                                }}>
                                    <Image style={Style.headerRightIocns}
                                        source={require("../../assets/images/"+(addProduction? "close" : "plus" )+".png")
                                    }/>
                                </TouchableOpacity>
                            </View>
                        </View>
                    :null
                    }
                    {
                        addProduction ? 
                            <View>
                                <View style={Style.toolBar}>
                                    <Text style={Style.headerTitle}>{"Add Agenda"}</Text>
                                </View>
                                <View>
                                    <CustomTextInput type="text" id="unitName"
                                        value={""}
                                        placeHolder="Agenda name"
                                        onInputChange={(ID,val)=>{setAgendaName(val)}} />
                                </View>
                                <View style={Style.footer}>
                                    {
                                        formError!=null ?
                                        <View style={{justifyContent:'center',flex:1}}>
                                                <CustomLabel type="formErrorMsg" label={""} errorMsg={formError}
                                                formRawData={null} successMsg={formError}/>
                                        </View>
                                        : null
                                    }
                                    <CustomButton buttonID={"Submit"} type="common"
                                    title="Create" onSubmit={()=>{addNewAgenda()}}/>
                                </View>
                            </View>
                        :  addEvent ? 
                                <View>
                                    <View style={Style.header}>
                                        <View style={Style.headerLeft}>
                                            <Text style={Style.headerTitle}>{"Add event"}</Text>
                                        </View>
                                        <View style={Style.headerRight}>
                                            <TouchableOpacity onPress={()=>{
                                                reSetAddEvent()
                                                setEvent(!addEvent)}}>
                                                <Image style={Style.headerRightIocns}
                                                    source={require("../../assets/images/"+(addEvent? "close" : "plus" )+".png")
                                                }/>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                    <View>
                                        <CustomTextInput type="text" id="unitName"
                                            value={eventName}
                                            placeHolder="Event Name"
                                            onInputChange={(ID,val)=>{setEventName(val)}} />
                                        <View>
                                            <ResponsiblePerson 
                                                title={"Add responsible persons"}
                                                groupList={orderGroupInfo}
                                                orderID={props.orderID}
                                                subRoute = {"insideOrder"}
                                                accessID={props.currentRoute}
                                                currentRoute={props.currentRoute}
                                                resPerson={eventResPerson}
                                                id={"eventAgenda"}
                                                visible={false}
                                                userListUpdated={(ueserList)=>{
                                                    setEventResPerson([...ueserList]);
                                                }}/>
                                        </View>
                                        <View style={{flexDirection:'row',zIndex:4,marginBottom:20}}>
                                            <DatePicker label={"Planned"} placeholder={"Production Start Date"}
                                                date={eventPlanned} layerIndex={2} onDateSelected={(date)=>{setEventPlanned(date+"")}}/>
                                            <DatePicker label={"Accompulished"} placeholder={"Production Start Date"}
                                                date={eventAccoumplised} layerIndex={2} onDateSelected={(date)=>{serEventAccoumplised(date+"")}}/>
                                        </View>
                                    </View>
                                    
                                    <View style={[Style.footer,{marginTop:25}]}>
                                        <View style={{justifyContent:'center',flex:1}}>
                                            <CustomLabel type="formErrorMsg" label={""} errorMsg={""}
                                            formRawData={null} successMsg={null}/>
                                        </View>
                                        <CustomButton buttonID={"Submit"} type="common"
                                        title="Create Event" onSubmit={()=>{addNewEvent(currentAgenda,editedIndex)}}/>
                                    </View>
                                </View>
                            :
                             agenda ?
                                <View>
                                    <FlatList
                                        data={agenda}
                                        horizontal={true}
                                        renderItem={({item,index}) => (
                                        <View style={Style.unitBreadCurms}>
                                             <CheckBoxButton 
                                                id={"check - "+item.unitName}
                                                state={agendaView.indexOf(item._id ? item._id : index)==-1 ? false : true }
                                                isChecked={()=>{viewProUnit(item._id ? item._id : index)}}/>
                                            <Text style={Style.unitBCTitle}>{item.agendaName}</Text>
                                        </View>)
                                    }/>       
                                     <View>
                                        <FlatList
                                            data={agenda}
                                            renderItem={({item,index}) => (
                                            <View>
                                                {
                                                    agendaView.length==0 || agendaView.indexOf(item._id ? item._id : index)!=-1 ?
                                                        <View style={Style.itemContainer}>
                                                            <View style={Style.item}>
                                                                <View style={Style.header}>
                                                                    <View style={Style.headerLeft}>
                                                                        {
                                                                             (item?._id ? editAgendaNameList.indexOf(item._id) :
                                                                             editAgendaNameList.indexOf(index)) == -1 ?
                                                                                 <Text style={Style.headerTitle}>{item.agendaName}</Text>
                                                                             :
                                                                             <CustomTextInput type="others" id="unitName"
                                                                             value={item?.agendaName}
                                                                             placeHolder="Sub Line Name"
                                                                             onInputChange={(ID,val)=>{onValueChanged(index,null,"unitName",val)}} />
                                                                         }
                                                                        <TouchableOpacity style={{flexDirection:'row'}} onPress={()=>{
                                                                            setCurrentAgenda(item.agendaName)
                                                                            reSetAddEvent()
                                                                            setEvent(!addEvent)}}>
                                                                            <Image style={Style.addSubLineImg}
                                                                                source={require("../../assets/images/"+(addProduction? "close" : "plus" )+".png")
                                                                            }/>
                                                                            <Text style={{marginTop:25}}>Add agenda events</Text>
                                                                        </TouchableOpacity>
                                                                    </View>
                                                                    <View style={Style.headerRight}>
                                                                        <TouchableOpacity onPress={()=>{
                                                                            editUnitName(item._id ? item._id : index)
                                                                        }}>
                                                                            <Image style={Style.deleteCurmIcons}
                                                                            source={require('../../assets/images/pencil.png')}/>
                                                                        </TouchableOpacity>
                                                                        <TouchableOpacity onPress={()=>{
                                                                            deleteAgenda(item._id ? item._id : index,"main")
                                                                        }}>
                                                                            <Image style={Style.deleteCurmIcons}
                                                                            source={require('../../assets/images/'+
                                                                            checkIsDeleted(item._id ? item._id : index,"main")
                                                                            +'.png')}/>
                                                                        </TouchableOpacity>
                                                                    </View>
                                                                </View>
                                                                <DataTable>
                                                                    <DataTable.Header>
                                                                        <DataTable.Title>
                                                                            <Text>Event Name</Text>
                                                                        </DataTable.Title>
                                                                        <DataTable.Title>Planed</DataTable.Title>
                                                                        <DataTable.Title>Accoumplised</DataTable.Title>
                                                                        <DataTable.Title>Action</DataTable.Title>
                                                                    </DataTable.Header>
                                                                    <ScrollView>
                                                                    {
                                                                        item?.event?.map((element:any,subIndex:any) => {
                                                                        return  <DataTable.Row onPress={()=>{
                                                                                setEventName(element.subAgendaName)
                                                                                setEventPlanned(element.plannedAt)
                                                                                serEventAccoumplised(element.accoumplisedAt)
                                                                                setEventResPerson(element.resPersons)
                                                                                setEvent(!addEvent)
                                                                                setEditedIndex([index,subIndex])
                                                                            }}>
                                                                                    <DataTable.Cell>
                                                                                        <Text>
                                                                                            {element.subAgendaName}
                                                                                        </Text>
                                                                                    </DataTable.Cell>
                                                                                    <DataTable.Cell>
                                                                                        <Text>
                                                                                            {
                                                                                                element.plannedAt? 
                                                                                                localTimeConversion(element?.plannedAt) 
                                                                                                :"Not Yet" 
                                                                                            }
                                                                                        </Text>
                                                                                    </DataTable.Cell>
                                                                                    <DataTable.Cell>
                                                                                        <Text>
                                                                                            {
                                                                                                element.accoumplisedAt ? 
                                                                                                localTimeConversion(element.accoumplisedAt) 
                                                                                                : "Not Yet" 
                                                                                            }
                                                                                        </Text>
                                                                                    </DataTable.Cell>
                                                                                    <DataTable.Cell>
                                                                                        <TouchableOpacity onPress={()=>{
                                                                                            deleteAgenda(element._id ? element._id : subIndex,index)
                                                                                        }}>
                                                                                            <Image style={Style.deleteCurmIcons}
                                                                                            source={require('../../assets/images/'+
                                                                                            checkIsDeleted(element._id ? element._id : subIndex,index)
                                                                                            +'.png')}/>
                                                                                        </TouchableOpacity>
                                                                                    </DataTable.Cell>
                                                                            </DataTable.Row>
                                                                        })
                                                                    }
                                                                    </ScrollView>
                                                                </DataTable>
                                                            </View>
                                                        </View>
                                                    :   null
                                                }
                                                
                                            </View>
                                        )}
                                            keyExtractor={item => item.unitName}/>
                                    </View>
                                    {
                                        agenda.length>0 ?
                                         <View style={Style.footer}>
                                            <View style={{justifyContent:'center',flex:1}}>
                                                <CustomLabel type="formErrorMsg" label={""} errorMsg={""}
                                                formRawData={null} successMsg={null}/>
                                            </View>
                                            <CustomButton buttonID={"Submit"} type="common"
                                            title="Save" onSubmit={()=>{saveOrderAgendaEvent()}}/>
                                        </View> 
                                        : null
                                    }
                                </View>
                            : <View>Add your new production unit/line</View> 
                    }
                </View>
            }
                </View>
            </View>
        </View>
    )
}

export default EventAgenda;