import React,{FC, useEffect, useState} from "react";
import {View, Text, Picker, Image, TouchableOpacity} from "react-native";
import {Style} from './style';
import { getOrderList, clearRepo } from '../../Server_Repo/action';
import CustomTextInput from "../CustomTextInput";
import CustomLabel from "../CustomLabel";
import DatePicker from '../DatePicker';
import CustomButton from "../../components/CustomButton";
import FileHandler from "../FilesHandler";
import { useSelector, useDispatch } from 'react-redux';
import CustomAlert from "../CustomAlert";
import SubTitleHeader from "../SubTitleHeader";
import { DataTable } from 'react-native-paper';
import {getAccessToken} from "../../utility/index";

interface Props{
  pagenation:(skip:any,limit:any)=>void;
}

const PagenationFooter:FC<Props>=(props)=>{

    const [pageLevels, setPageLevels] = useState(5);
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(5);
    const [filter, setFilter] = useState(10);
    const {
      userDetails,orderListRepo
    } = useSelector((state:any) => state.userReducer);

    return (
      <View style={Style.mainContent}>
        <View style={Style.pagenation}>
            <View>
              <TouchableOpacity onPress={()=>{setSkip(skip-Number(limit)<0 ? 0 : skip-Number(limit))}}>
                <Image style={Style.pagenationArrow}
                  source={require('../../assets/images/leftArrow.png')}/>
              </TouchableOpacity>
            </View>
              <View>
                <Picker
                  selectedValue={limit}
                  style={Style.pagenationDropdown}
                  onValueChange={(itemValue:any, itemIndex:any) =>{
                      setLimit(itemValue)
                      props.pagenation(skip,limit)
                    }
                  }>
                  <Picker.Item label="5" value={5} />
                  <Picker.Item label="10" value={10} />
                  <Picker.Item label="50" value={50} />
                  <Picker.Item label="100" value={100} />
                </Picker>
              </View>
            <View>
              <TouchableOpacity onPress={()=>{
                  setSkip(skip+Number(limit))
                  props.pagenation(skip,limit)
                }}>
                <Image style={Style.pagenationArrow}
                source={require('../../assets/images/rightArrow.png')}/>
              </TouchableOpacity>
            </View>
        </View>
      </View>
    )
}

export default PagenationFooter;