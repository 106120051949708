import React,{FC,useState,useEffect} from "react";
import {View,Text,Picker,Dimensions, TouchableOpacity, Image} from "react-native";
import Style from "./style";
import Ionicons from '@expo/vector-icons/Ionicons';
import CustomButton from "../CustomButton";
import DatePicker from "../DatePicker";
import { Button } from "react-native-paper";
import {DataTable} from "react-native-paper";
import CustomLabel from "../CustomLabel";
import { getReportFilterDetails, getActualReportDetails, clearRepo } from '../../Server_Repo/action';
import { useSelector, useDispatch } from 'react-redux';


  interface Props{
    goBack:()=>void;
    orderID:any;
    isLargeScreen:any;
  }


const OrderStockReport:FC<Props>=(props)=>{
  const [formError,setFormError]=useState<any>(null);
  const [internalViewType,setInternalViewType]=React.useState("init");
  const [filterStock,setFilterStock] = React.useState<any>();

  //Filter 
  const [filterData,setFilterData] = useState<any>({init:true});
  const [searchData,setSearchData] = useState<any>({});
  const dispatch:any = useDispatch();

  //Rendering data
  const [data,setData] = useState<any>();

  const {
    getReportFilterReponse,
    getActualReportDetailsReponse
  } = useSelector((state:any) => state.userReducer);

  useEffect(()=>{
    dispatch(
      clearRepo()
    )
    dispatch(getReportFilterDetails({
      orderID:props.orderID,
      filterFor:"stockInfo",
    }))
    dispatch(getActualReportDetails({
      orderID:props.orderID,
      routeFor:"stockReport",
      query:{
        ...filterData
      } 
    }))
  },[])

  useEffect(()=>{
    if(getReportFilterReponse&&getReportFilterReponse.status=="1"){
      setSearchData(getReportFilterReponse.data[0]);
    }
    if(getActualReportDetailsReponse&&getActualReportDetailsReponse.status==1){
      setData(getActualReportDetailsReponse.data[0]);
    
    }
    setFilterStock(false)
    console.log("Rendering",'Render',filterStock);
 
  },[getReportFilterReponse!=null,getActualReportDetailsReponse!=null])
  

  const onFilterChange=(filedID:any,val:any)=>{

    let temp=filterData;
    temp.init=false;
    if(filedID=="fromDate"||filedID=="toDate"){
      temp[filedID]=new Date(val)
    }else{
      if(temp[filedID]!=undefined ){
        temp[filedID].push(val);
      }else{
        temp[filedID]=[];
        temp[filedID].push(
          val
        )
      }
    }
    setFilterData(temp)
    console.log(
      "onFilterChange",
      temp
    )
  }

  const filterProInputs=()=>{
    dispatch(
      clearRepo()
    )
    
    dispatch(
      getActualReportDetails({
        "orderID":props.orderID,
        routeFor:"stockReport",
        "query":{
            ...filterData
         }
      })
    )
  }

  const getCategoryTaskName=(id:any,status:any)=>{
    let name:any=null;
    if(status==true){
      name =  searchData?.taskCol?.filter((ele:any)=>{
        return ele._id == id
      }); 
      if(name?.length>0){
        name =  searchData?.category?.filter((ele:any)=>{
          return ele._id == name[0].categoryID
        }); 
        if(name?.length>0){
          return name[0].categoryName
        }
      }
      
    }else{
      name =  searchData?.taskCol?.filter((ele:any)=>{
        return ele._id == id
      }); 
      if(name?.length>0){
        return name[0].taskName
      }
    }
  }


    return (
        <View>
            <View style={Style.container}>
                <View style={Style.containerLeft}>
                <TouchableOpacity onPress={()=>{props.goBack()}}>
                  <Ionicons name="arrow-back"  style={Style.menuIcon}/>
                </TouchableOpacity>
                    <Text style={Style.activityTitle}>Stock Report</Text>
                </View>
            </View>

            <View style={Style.filterView}>
              <Text>Filter Stock Report</Text>
              <TouchableOpacity onPress={()=>{setFilterStock(!filterStock)}}>
                  <Image style={{height:20,width:20,marginLeft:10,}}
                    source={require("../../assets/images/"+(filterStock? "close" : "filter" )+".png")
                  }/>
              </TouchableOpacity>
            </View>

            { filterStock == true ?            
                <View>
                  <View style={Style.filterOptions}>
                    <Picker
                        style={Style.dropDown}
                        onValueChange={(val:any) =>{onFilterChange("status",val)}}>
                        <Picker.Item label="Select Status" value={0} />
                        <Picker.Item label="Completed" value={1} />
                        <Picker.Item label="Defect" value={2} />
                        <Picker.Item label="Not QC" value={3} />
                    </Picker>
                    {
                      searchData.category ?
                      <Picker
                        style={Style.dropDown}
                        onValueChange={(val:any) =>{onFilterChange("category",val)}}>
                          <Picker.Item label="Select Category" value={0} />
                          { 
                            searchData?.category?.map((rel:any)=>{
                                return <Picker.Item label={rel.categoryName} value={rel._id} />
                            })
                          }
                        </Picker>
                      : null
                    }
                    {
                      searchData.taskCol ?
                      <Picker
                        style={Style.dropDown}
                        onValueChange={(val:any) =>{onFilterChange("taskIDs",val)}}>
                          <Picker.Item label="Select Task" value={0} />
                          { 
                            searchData?.taskCol?.map((rel:any)=>{
                                return <Picker.Item label={rel.taskName} value={rel._id} />
                            })
                          }
                        </Picker>
                      : null
                    }

                </View>
                <View style={Style.filterOptions}>
                  {
                      searchData.stockList ?
                      <Picker
                        style={Style.dropDown}
                        onValueChange={(val:any) =>{onFilterChange("stockIDs",val)}}>
                          <Picker.Item label="Select Stock" value={0} />
                          { 
                            searchData?.stockList?.map((rel:any)=>{
                                return <Picker.Item label={rel.stockName} value={rel.id} />
                            })
                          }
                        </Picker>
                      : null
                    }
                    <DatePicker label={"Start Date"} placeholder={"Start Date"}
                        date={""} layerIndex={4} onDateSelected={(date)=>{onFilterChange("fromDate",date)}}/>
                    <DatePicker label={"End Date"} placeholder={"End Date"}
                        date={""} layerIndex={4} onDateSelected={(date)=>{onFilterChange("toDate",date)}}/>
                </View>
                <View style={Style.footer}>
                  <View style={{justifyContent:'center',flex:1}}>
                    <CustomLabel type="formErrorMsg" label={""} errorMsg={formError}
                      formRawData={null} successMsg={null}/>
                  </View>
                  <CustomButton buttonID={"Submit"} type="common"
                  title="Apply" onSubmit={()=>{filterProInputs()}}/>
                </View>
                </View>
              : <View>
                  <View>
                    <DataTable>
                      <DataTable.Header>
                        <DataTable.Title>Stock Name</DataTable.Title>
                        <DataTable.Title>Category Name</DataTable.Title>
                        <DataTable.Title>Task Name</DataTable.Title>
                        <DataTable.Title>Usage</DataTable.Title>
                      </DataTable.Header>
                      {
                        data?.stockUsage?.map((ele:any)=>{
                          return ele.taskID==null ?      
                              <DataTable.Row>
                                  <DataTable.Cell>{ele.stockInfo[0].stockName}</DataTable.Cell>
                                  <DataTable.Cell>{getCategoryTaskName(ele._id.taskIDs,true)}</DataTable.Cell>
                                  <DataTable.Cell>{getCategoryTaskName(ele._id.taskIDs,false)}</DataTable.Cell>
                                  <DataTable.Cell>{ele.usageQty}</DataTable.Cell>
                                </DataTable.Row>
                            : null
                          }) 
                      }
                    </DataTable>
                  </View>
                </View>
              }
        </View>
    )
}

export default OrderStockReport;