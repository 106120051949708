import React,{FC, useEffect, useState} from "react";
import {View,Text,Picker,Dimensions, TouchableOpacity, Image} from "react-native";
import Style from "./style";
import Ionicons from '@expo/vector-icons/Ionicons';
import CustomButton from "../CustomButton";
import DatePicker from "../DatePicker";
import CustomLabel from "../CustomLabel";
import { Button } from "react-native-paper";
import { DataTable } from 'react-native-paper';
import FileHandler from "../FilesHandler";
import { getReportFilterDetails, getActualReportDetails, clearRepo } from '../../Server_Repo/action';
import { useSelector, useDispatch } from 'react-redux';
// import {
//     LineChart,
//     BarChart,
//     PieChart,
//     ProgressChart,
//     ContributionGraph,
//     StackedBarChart,
//   } from 'react-native-chart-kit';

  interface Props{
    goBack:()=>void;
    orderID:any,
    isLargeScreen:any
  }

  const MyLineChart = () => {
    return (
      <>
        <LineChart
          data={{
            labels: [1,1000],
            datasets: [
              {
                data: [20, 45, 28, 80, 99, 43,1,5,10,10,20, 45, 28, 80, 199, 43,1,5,10,10,20, 45, 28, 80, 99, 43,1,5,10,10,20, 45, 28, 80, 99, 43,1,5,10,10],
                strokeWidth: 2,
              },
            ],
          }}
          width={Dimensions.get('window').width - 370}
          height={400}
          chartConfig={{
            backgroundColor: '#1cc910',
            backgroundGradientFrom: '#eff3ff',
            backgroundGradientTo: '#efefef',
            decimalPlaces: 2,
            color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
            style: {
              borderRadius: 16,
            },
          }}
          style={{
            marginVertical: 8,
            borderRadius: 16,
          }}
        />
      </>
    );
  };


const OrderProductionReport:FC<Props>=(props)=>{
    const [formError,setFormError]=useState<any>(null);
    const [filterMenu,setFilterMenu] = useState<any>(false);


    //Component views
    const [categoryDetailView,setCategoryDetialView] = useState<any>(false);
    const [taskDetailView,setTaskDetailView] = useState<any>(false);
    const [orderfileView,setOrderFileView]= useState<any>(false);
    const [categoryFileView,setCategoryFileiew]= useState<any>(false);
    const [defectDetailsView,setDefectDetailsView]= useState<any>(false);
    const [taskDetailsView,setTaskDetailsView]= useState<any>(false);

    //Component Datas
    const [selectedDefects,setSelectedDefects]= useState<any>({});

    //File access states
    const [defectFileAccessDetails,setDefectFileAccessDetails]= useState<any>([]);


    //Filter 
    const [filterData,setFilterData] = useState<any>({init:true});
    const [searchData,setSearchData] = useState<any>({});
    const dispatch:any = useDispatch();

    //Rendering data
    const [data,setData] = useState<any>();
    const [selectedCategoryData,setSelectedCategryData] = useState<any>();
    const [selectedTaskData,setSelectedTaskData] = useState<any>();

    const {
      getReportFilterReponse,
      getActualReportDetailsReponse
    } = useSelector((state:any) => state.userReducer);

    useEffect(()=>{
      dispatch(
        clearRepo()
      )
      dispatch(getReportFilterDetails({
        orderID:props.orderID,
        filterFor:"defectInfo",
      }))
      dispatch(getActualReportDetails({
        orderID:props.orderID,
        routeFor:"defectReport",
        query:{
          ...filterData
        } 
      }))
    },[])

    useEffect(()=>{

     
      if(getReportFilterReponse&&getReportFilterReponse.status=="1"){
        setSearchData(getReportFilterReponse.data[0]);
      }
      if(getActualReportDetailsReponse&&getActualReportDetailsReponse.status==1){
        setData(getActualReportDetailsReponse.data[0]);
      
      }
      setFilterMenu(false)
      console.log("Rendering",'Render',filterMenu);
   
    },[getReportFilterReponse!=null,getActualReportDetailsReponse!=null])
    

    const onFilterChange=(filedID:any,val:any)=>{

      let temp=filterData;
      temp.init=false;
      if(filedID=="fromDate"||filedID=="toDate"){
        temp[filedID]=new Date(val)
      }else{
        if(temp[filedID]!=undefined ){
          temp[filedID].push(val);
        }else{
          temp[filedID]=[];
          temp[filedID].push(
            val
          )
        }
      }
      setFilterData(temp)
      console.log(
        "onFilterChange",
        temp
      )
    }


    const getCategoryTaskName=(id:any,status:any)=>{
      let name=null;
      if(status==true){
        name =  searchData?.category?.filter((ele:any)=>{
          return ele._id == id
        }); 
        if(name?.length>0){
          return name[0].categoryName
        }
      }else{
        name =  searchData?.task?.filter((ele:any)=>{
          return ele._id == id
        }); 
        if(name?.length>0){
          return name[0].taskName
        }
      }
    }

    const createdBy=(id:any)=>{
      let name=null;
      name =  searchData?.userInOrder?.filter((ele:any)=>{
        return ele._id == id
      }); 
      if(name?.length>0){
        return name[0].userName
      }else{
        return "Admin"
      }
    }

    const timeTrimer=(time:any)=>{
      return String(time).substring(0,10)
    }

    const taskInputed=(catID:any,mappingID:any,req:any)=>{
      let requredCount = searchData?.category?.filter((ele:any)=>{
        return ele._id == catID
      }); 
      if(requredCount?.length>0){
        requredCount=requredCount[0].noOfTask;
      }

      let inputedCount = data?.orderProInput?.filter((ele:any)=>{
        return ele.categoryID == catID && ele.taskID!=null && ele.mappingID == mappingID
      }); 

      if(req==true)
      {
        return requredCount
      }else{
        return inputedCount?.length
      }
    }

    const defectCountInputed=(orderProDefect:any,catID:any,mappingID:any,req:any)=>{

      console.log("orderProDefect",orderProDefect)
      if(req==true){
        let requredCount = orderProDefect?.filter((ele:any)=>{
          return ele.categoryID == catID && ele.taskID == null && ele.mappingID==mappingID
        }); 
        if(requredCount?.length>0){
          return requredCount.length
        }
      }

    }

    const filterProInputs=()=>{
      dispatch(
        clearRepo()
      )
      
      dispatch(
        getActualReportDetails({
          "orderID":props.orderID,
          "query":{
              ...filterData
           }
        })
      )
    }

    return (
        <View>
            <View style={Style.container}>
                <View style={Style.containerLeft}>
                <TouchableOpacity onPress={()=>{props.goBack()}}>
                  <Ionicons name="arrow-back"  style={Style.menuIcon}/>
                </TouchableOpacity>
                    <Text style={Style.activityTitle}>Actual Report</Text>
                </View>
            </View>
            {
              categoryDetailView==false&&taskDetailView==false ?
                <View style={Style.filterView}>
                  <Text>Filter Actual Report</Text>
                  <TouchableOpacity onPress={()=>{
                    setFilterData({init:true})
                    setFilterMenu(!filterMenu)
                  }}>
                      <Image style={{height:20,width:20,marginLeft:10,}}
                        source={require("../../assets/images/"+(filterMenu? "close" : "filter" )+".png")
                      }/>
                  </TouchableOpacity>
                </View>
              : null
            }
            
            { filterMenu == true ?            
              <View>
                <View style={Style.filterOptions}>
                    <Picker
                        style={Style.dropDown}
                        onValueChange={(val:any) =>{onFilterChange("status",val)}}>
                        <Picker.Item label="Select Status" value={0} />
                        <Picker.Item label="Completed" value={1} />
                        <Picker.Item label="Defect" value={2} />
                        <Picker.Item label="Not QC" value={3} />
                    </Picker>
                    {
                      searchData.category ?
                      <Picker
                        style={Style.dropDown}
                        onValueChange={(val:any) =>{onFilterChange("category",val)}}>
                          <Picker.Item label="Select Category" value={0} />
                          { 
                            searchData?.category?.map((rel:any)=>{
                                return <Picker.Item label={rel.categoryName} value={rel._id} />
                            })
                          }
                        </Picker>
                      : null
                    }
                    {
                      searchData.task ?
                      <Picker
                        style={Style.dropDown}
                        onValueChange={(val:any) =>{onFilterChange("task",val)}}>
                          <Picker.Item label="Select Task" value={0} />
                          { 
                            searchData?.task?.map((rel:any)=>{
                                return <Picker.Item label={rel.taskName} value={rel._id} />
                            })
                          }
                        </Picker>
                      : null
                    }

                </View>
                <View style={Style.filterOptions}>
                    <DatePicker label={"Start Date"} placeholder={"Start Date"}
                        date={""} layerIndex={4} onDateSelected={(date)=>{onFilterChange("fromDate",date)}}/>
                    <DatePicker label={"End Date"} placeholder={"End Date"}
                        date={""} layerIndex={4} onDateSelected={(date)=>{onFilterChange("toDate",date)}}/>
                </View>
                <View style={Style.footer}>
                  <View style={{justifyContent:'center',flex:1}}>
                    <CustomLabel type="formErrorMsg" label={""} errorMsg={formError}
                      formRawData={null} successMsg={null}/>
                  </View>
                  <CustomButton buttonID={"Submit"} type="common"
                  title="Apply" onSubmit={()=>{filterProInputs()}}/>
                </View>
              </View>
            :   <View>
              { categoryDetailView == false && taskDetailView == false ?
                  <View style={{margin:10,zIndex:-1}}>
                    {/* <View style={Style.rowWise}>
                      <View style={Style.breadCrumbs}>
                        <Text style={Style.breadCrumbsLable}>Total Category - 10</Text>
                      </View>
                      <View style={Style.breadCrumbs}>
                        <Text style={Style.breadCrumbsLable}>Completed Category - 20</Text>
                      </View>
                    </View>
                    <View style={Style.rowWise}>
                      <View style={Style.breadCrumbs}>
                        <Text style={Style.breadCrumbsLable}>Total Task - 10</Text>
                      </View> 
                      <View style={Style.breadCrumbs}>
                        <Text style={Style.breadCrumbsLable}>Task Completed - 30</Text>
                      </View>
                    </View> */}
                    {/* <MyLineChart /> */}
                    { categoryDetailView == false&&taskDetailView==false && data ?
                        <DataTable style={Style.tableCard}>
                            <View style={Style.tableHeaderCard}>
                              <DataTable.Header>
                                <DataTable.Title>Category Name</DataTable.Title>
                                <DataTable.Title>Qty</DataTable.Title>
                                <DataTable.Title>Task Qty</DataTable.Title>
                                <DataTable.Title>Custom ID</DataTable.Title>
                                <DataTable.Title>Status</DataTable.Title>
                                <DataTable.Title>Inputed At</DataTable.Title>
                                <DataTable.Title>Inputed By</DataTable.Title>
                              </DataTable.Header>
                            </View>
                            {
                              data?.orderProInput?.map((ele:any)=>{
                                return ele.taskID==null ?      
                                    <DataTable.Row onPress={()=>{
                                      setSelectedCategryData(ele)
                                      setTaskDetailView(false)
                                      setCategoryDetialView(true)}}>
                                        <DataTable.Cell>{"("+ele.mappingID+") "  +getCategoryTaskName(ele.categoryID,true)}</DataTable.Cell>
                                        <DataTable.Cell>{ele.qty}</DataTable.Cell>
                                        <DataTable.Cell>{
                                            taskInputed(ele.categoryID,ele.mappingID,false)+"/"+
                                            taskInputed(ele.categoryID,ele.mappingID,true)
                                        }</DataTable.Cell>
                                        <DataTable.Cell>{ele.customID}</DataTable.Cell>
                                        <DataTable.Cell>{ele.defectStatus}</DataTable.Cell>
                                        <DataTable.Cell>{timeTrimer(ele.inputedAt)}</DataTable.Cell>
                                        <DataTable.Cell>{createdBy(ele.createdBy)}</DataTable.Cell>
                                      </DataTable.Row>
                                  : null
                              }) 
                            }
                        </DataTable>
                      : null
                    }
                     { categoryDetailView == false&&taskDetailView==false&&data  ?
                        <DataTable  style={Style.tableCard}>
                              <View style={Style.tableHeaderCard}>
                                  <DataTable.Header>
                                    <DataTable.Title>Task Name</DataTable.Title>
                                    <DataTable.Title>Task Qt</DataTable.Title>
                                    <DataTable.Title>Status</DataTable.Title>
                                    <DataTable.Title>Custom ID</DataTable.Title>
                                    <DataTable.Title>Mapping Id</DataTable.Title>
                                    <DataTable.Title>Inputed At</DataTable.Title>
                                    <DataTable.Title>Inputed By</DataTable.Title>
                                </DataTable.Header>
                              </View>
                              {
                              data?.orderProInput?.map((ele:any)=>{
                                return ele.taskID!=null ?      
                                    <DataTable.Row onPress={()=>{
                                      setSelectedTaskData({...ele})
                                      setTaskDetailView(true)
                                      setCategoryDetialView(false)}}>
                                        <DataTable.Cell>{getCategoryTaskName(ele.taskID,false)}</DataTable.Cell>
                                        <DataTable.Cell>{ele.qty}</DataTable.Cell>
                                        <DataTable.Cell>{ele.defectStatus}</DataTable.Cell>
                                        <DataTable.Cell>{ele.customID}</DataTable.Cell>
                                        <DataTable.Cell>{ele.mappingID}</DataTable.Cell>
                                        <DataTable.Cell>{timeTrimer(ele.inputedAt)}</DataTable.Cell>
                                        <DataTable.Cell>{createdBy(ele.createdBy)}</DataTable.Cell>
                                      </DataTable.Row>
                                  : null
                                }) 
                            }
                        </DataTable>
                      : null
                    }
                  </View>
                : <View>
                    {
                      categoryDetailView == true && taskDetailView == false ? 
                        <View>
                          <View style={Style.filterView}>
                            <TouchableOpacity onPress={()=>{
                              setSelectedCategryData(null)
                              setCategoryDetialView(!categoryDetailView)}}>
                                <Image style={{height:25,width:25,marginTop:5,marginLeft:10,}}
                                  source={require("../../assets/images/back.png")
                                }/>
                            </TouchableOpacity>
                            <Text style={Style.viewMainTitle}>Category Details</Text>
                          </View>
                          <View>
                            <View>
                              <View style={Style.fileViewTabls}>
                                <TouchableOpacity style={[Style.fileViewBtn,categoryFileView==false ? Style.activeFileViewBtn : null]}
                                onPress={()=>{setCategoryFileiew(!categoryFileView)}}>
                                    <Text style={[
                                      Style.fileBtnLabel,
                                      categoryFileView==false ? Style.activeFileBtnLabel : null
                                    ]}>Category Inputed Details</Text>
                                </TouchableOpacity>
                              </View>
                              <View style={Style.statusContainer}>
                                  {
                                    categoryFileView == false ?
                                      <View style={Style.orderInfo}>
                                        <View>
                                            <View style={Style.rowWise}>
                                              <View style={Style.rowWiseSpliter}>
                                                <Text  style={Style.subTitle}>Category Name</Text>
                                                <Text style={Style.subValues}>{getCategoryTaskName(selectedCategoryData.categoryID,true)}</Text>
                                              </View>
                                              <View style={Style.rowWiseSpliter}>
                                                <Text  style={Style.subTitle}>Task Required</Text>
                                                <Text style={Style.subValues}>{ taskInputed(selectedCategoryData.categoryID,selectedCategoryData.mappingID,true)}</Text>
                                              </View>
                                              <View style={Style.rowWiseSpliter}>
                                                <Text  style={Style.subTitle}>Task Completed</Text>
                                                <Text style={Style.subValues}>{ taskInputed(selectedCategoryData.categoryID,selectedCategoryData.mappingID,false)}</Text>
                                              </View>
                                              <View style={Style.rowWiseSpliter}>
                                                <Text style={Style.subTitle}>No of defect</Text>
                                                <Text style={Style.subValues}>{defectCountInputed(
                                                  selectedCategoryData.orderProDefect,
                                                  selectedCategoryData.categoryID,
                                                  selectedCategoryData.mappingID,
                                                  true
                                                )}</Text>
                                              </View>
                                              <View style={Style.rowWiseSpliter}>
                                                <Text  style={Style.subTitle}>Inputed At</Text>
                                                <Text style={Style.subValues}>{timeTrimer(selectedCategoryData.inputedAt)}</Text>
                                              </View>
                                              <View style={Style.rowWiseSpliter}>
                                                <Text style={Style.subTitle}>Inputed By</Text>
                                                <View style={Style.rowWise}>
                                                  <Text style={Style.subValues}>{createdBy(selectedCategoryData.createdBy)}</Text>
                                                </View>
                                              </View>
                                            </View>
                                            <View style={Style.rowWiseSpliter}>
                                              <Text style={Style.subTitle}>Order Status</Text>
                                              <Text style={Style.subValues}>In Progress</Text>
                                            </View>
                                        </View>
                                      </View> 
                                    :  <View style={Style.orderInfo}>
                                        <Text>Category File Access</Text>
                                      </View>
                                  }
                              </View>

                              <View style={Style.fileViewTabls}>
                                <TouchableOpacity style={[Style.fileViewBtn,Style.activeFileViewBtn]}>
                                    <Text style={[
                                      Style.fileBtnLabel,
                                      Style.activeFileBtnLabel 
                                    ]}>Category Defect Details</Text>
                                </TouchableOpacity>
                              </View>
                              <View style={Style.statusContainer}>
                                  {
                                    defectDetailsView == false ?
                                      <View style={Style.orderInfo}>
                                        <View>
                                            <DataTable.Header>
                                                <DataTable.Title>Defect Type</DataTable.Title>
                                                <DataTable.Title>Defect Level</DataTable.Title>
                                                <DataTable.Title>Defect Descriptin</DataTable.Title>
                                                <DataTable.Title>Status</DataTable.Title>
                                                <DataTable.Title>Marked By</DataTable.Title>
                                                <DataTable.Title>Marked At</DataTable.Title>
                                            </DataTable.Header>
                                            {
                                              selectedCategoryData.orderProDefect.map((res:any)=>{
                                                return res.taskID==null ? <DataTable.Row onPress={()=>{setDefectDetailsView(!defectDetailsView)}}>
                                                    <DataTable.Cell>{""}</DataTable.Cell>
                                                    <DataTable.Cell>{res.level}</DataTable.Cell>
                                                    <DataTable.Cell>{res.desc}</DataTable.Cell>
                                                    <DataTable.Cell>{res.defectStatus}</DataTable.Cell>
                                                    <DataTable.Cell>{createdBy(res.createdBy)}</DataTable.Cell>
                                                    <DataTable.Cell>{timeTrimer(res.inputedAt)}</DataTable.Cell>
                                                  </DataTable.Row> : null
                                              })
                                            }
                                        </View>
                                      </View> 
                                    :  <View style={Style.orderInfo}>
                                            <View>
                                                <Ionicons name="arrow-back"  style={Style.backIcon} 
                                                onPress={()=>{setDefectDetailsView(!defectDetailsView)}}/> 
                                                  <View style={{margin:10}}>
                                                      <Text style={{margin:5}}>
                                                            Level : {
                                                                selectedDefects?.level==1 ? 'High' 
                                                                    :  selectedDefects?.level==2 ? 'Medium'
                                                                        :  selectedDefects?.level==3 ? 'Low'
                                                                            :  selectedDefects?.level==4 ? 'Informable'
                                                                            : null
                                                                }
                                                            </Text>
                                                            <Text style={{margin:5}}>
                                                            Defect Type :  {
                                                                selectedDefects.defectTypeName
                                                                }
                                                            </Text>
                                                            {
                                                                selectedDefects.categoryID ? 
                                                                    <Text style={{margin:5}}>
                                                                        Category : 
                                                                    </Text>
                                                                : null
                                                            }
                                                            {
                                                                selectedDefects.taskID ? 
                                                                    <Text style={{margin:5}}>
                                                                        Task :  
                                                                    </Text>
                                                                : null
                                                            }
                                                           <Text style={{margin:5}}>
                                                                MappingID :  {selectedDefects?.mappingID}
                                                            </Text>

                                                            <Text style={{margin:5}}>
                                                                Defect Status :  {selectedDefects?.defectStatus}
                                                            </Text>                                                        
                                                  </View>
                                                        
                                                        <FileHandler isLargeScreen={props.isLargeScreen} 
                                                            fileUploadAccess={false}
                                                            documentList={selectedDefects?.fileList}
                                                            orderID={props.orderID}
                                                            fileAccess={defectFileAccessDetails}
                                                            id={selectedDefects?.uuID+"files"}
                                                            updateUserAccess = {(fileAccess)=>{(null)}}
                                                            deletedFileList = {(fileAccess)=>{(null)}}
                                                            updatedFileList={(updatedList)=>{(null)}}/>
                                                    
                                            </View>
                                      </View>
                                  }
                              </View>
                            </View>
                            <View style={Style.fileViewTabls}>
                              <TouchableOpacity style={[Style.fileViewBtn,taskDetailsView==false ? Style.activeFileViewBtn : {}]}
                              onPress={()=>{setTaskDetailsView(!taskDetailsView)}}>
                                  <Text style={[
                                    Style.fileBtnLabel,
                                    taskDetailsView==false ? Style.activeFileBtnLabel : null
                                  ]}>Tasks Inputed</Text>
                              </TouchableOpacity>
                            </View>
                            <View style={Style.orderInfo}>
                                  <View>
                                      <DataTable.Header>
                                          <DataTable.Title>Task Name</DataTable.Title>
                                          <DataTable.Title>Mapping ID</DataTable.Title>
                                          <DataTable.Title>Status</DataTable.Title>
                                          <DataTable.Title>Defects</DataTable.Title>
                                          <DataTable.Title>Inputed By</DataTable.Title>
                                          <DataTable.Title>Inputed At</DataTable.Title>
                                      </DataTable.Header>
                                      {
                                        data.orderProInput.map((res:any)=>{
                                          return res.categoryID==selectedCategoryData.categoryID&&
                                            res.taskID!=null&&
                                            res.mappingID==selectedCategoryData.mappingID
                                           ? <DataTable.Row onPress={()=>{
                                                setTaskDetailView(true)
                                                setSelectedTaskData({...res})
                                                setCategoryDetialView(false)
                                              }}>
                                              <DataTable.Cell>{getCategoryTaskName(res.taskID,false)}</DataTable.Cell>
                                              <DataTable.Cell>{res.mappingID}</DataTable.Cell>
                                              <DataTable.Cell>{res.defectStatus}</DataTable.Cell>
                                              <DataTable.Cell>{res.defectStatus}</DataTable.Cell>
                                              <DataTable.Cell>{createdBy(res.createdBy)}</DataTable.Cell>
                                              <DataTable.Cell>{timeTrimer(res.inputedAt)}</DataTable.Cell>
                                            </DataTable.Row> : null
                                        })
                                      }
                                  </View>
                            </View>
                          </View>
                        </View>
                      : categoryDetailView == false && taskDetailView == true ? 
                        <View>
                          <View  style={Style.filterView}>
                            <TouchableOpacity onPress={()=>{
                              setSelectedTaskData(null)
                              if(selectedCategoryData==null){
                                setTaskDetailView(false)
                                setCategoryDetialView(false)

                              }else{
                                setTaskDetailView(false)
                                setCategoryDetialView(true)
                              }
                             
                            }}>
                              <Image style={{height:25,width:25,marginTop:5,marginLeft:10,}}
                                    source={require("../../assets/images/back.png")
                                }/>
                            </TouchableOpacity>
                            <Text style={Style.viewMainTitle}>Task Details</Text>
                          </View>
                          <View style={Style.fileViewTabls}>
                              <TouchableOpacity style={[Style.fileViewBtn,taskDetailsView==false ? Style.activeFileViewBtn : {}]}
                              onPress={()=>{setTaskDetailsView(!taskDetailsView)}}>
                                  <Text style={[
                                    Style.fileBtnLabel,
                                    taskDetailsView==false ? Style.activeFileBtnLabel : null
                                  ]}>Tasks Inputed</Text>
                              </TouchableOpacity>
                          </View>
                          <View style={Style.orderInfo}>
                            <View>
                                <View style={Style.rowWise}>
                                  <View style={Style.rowWiseSpliter}>
                                    <Text  style={Style.subTitle}>Task Name</Text>
                                    <Text style={Style.subValues}>{getCategoryTaskName(selectedTaskData.taskID,false)}</Text>
                                  </View>
                                  <View style={Style.rowWiseSpliter}>
                                    <Text style={Style.subTitle}>No of defect</Text>
                                    <Text style={Style.subValues}>{defectCountInputed(
                                      selectedTaskData .orderProDefect,
                                      selectedTaskData.categoryID,
                                      selectedTaskData.mappingID,
                                      false
                                    )}</Text>
                                  </View>
                                  <View style={Style.rowWiseSpliter}>
                                    <Text  style={Style.subTitle}>Inputed At</Text>
                                    <Text style={Style.subValues}>{timeTrimer(selectedTaskData.inputedAt)}</Text>
                                  </View>
                                  <View style={Style.rowWiseSpliter}>
                                    <Text style={Style.subTitle}>Inputed By</Text>
                                    <View style={Style.rowWise}>
                                      <Text style={Style.subValues}>{createdBy(selectedTaskData.createdBy)}</Text>
                                    </View>
                                  </View>
                                </View>
                                <View style={Style.rowWiseSpliter}>
                                  <Text style={Style.subTitle}>Order Status</Text>
                                  <Text style={Style.subValues}>In Progress</Text>
                                </View>
                            </View>
                          </View> 
                          <View style={Style.fileViewTabls}>
                                <TouchableOpacity style={[Style.fileViewBtn,Style.activeFileViewBtn]}>
                                    <Text style={[
                                      Style.fileBtnLabel,
                                      Style.activeFileBtnLabel 
                                    ]}>Task Defect Details</Text>
                                </TouchableOpacity>
                          </View>
                          <View style={Style.statusContainer}>
                              {
                                defectDetailsView == false ?
                                  <View style={Style.orderInfo}>
                                    <View>
                                       <View>
                                            <DataTable.Header>
                                                <DataTable.Title>Defect Type</DataTable.Title>
                                                <DataTable.Title>Defect Level</DataTable.Title>
                                                <DataTable.Title>Defect Descriptin</DataTable.Title>
                                                <DataTable.Title>Status</DataTable.Title>
                                                <DataTable.Title>Marked By</DataTable.Title>
                                                <DataTable.Title>Marked At</DataTable.Title>
                                            </DataTable.Header>
                                            {
                                              selectedTaskData.orderProDefect.map((res:any)=>{
                                                return res.taskID == selectedTaskData.taskID &&res.mappingID == selectedTaskData.mappingID ? <DataTable.Row onPress={()=>{setDefectDetailsView(!defectDetailsView)}}>
                                                    <DataTable.Cell>{""}</DataTable.Cell>
                                                    <DataTable.Cell>{res.level}</DataTable.Cell>
                                                    <DataTable.Cell>{res.desc}</DataTable.Cell>
                                                    <DataTable.Cell>{res.defectStatus}</DataTable.Cell>
                                                    <DataTable.Cell>{createdBy(res.createdBy)}</DataTable.Cell>
                                                    <DataTable.Cell>{timeTrimer(res.inputedAt)}</DataTable.Cell>
                                                  </DataTable.Row> : null
                                              })
                                            }
                                        </View>
                                    </View>
                                  </View> 
                                :  <View style={Style.orderInfo}>
                                        <View>
                                            <Ionicons name="arrow-back"  style={Style.backIcon} 
                                            onPress={()=>{setDefectDetailsView(!defectDetailsView)}}/> 
                                              <View style={{margin:10}}>
                                                  <Text style={{margin:5}}>
                                                        Level : {
                                                            selectedDefects?.level==1 ? 'High' 
                                                                :  selectedDefects?.level==2 ? 'Medium'
                                                                    :  selectedDefects?.level==3 ? 'Low'
                                                                        :  selectedDefects?.level==4 ? 'Informable'
                                                                        : null
                                                            }
                                                        </Text>
                                                        <Text style={{margin:5}}>
                                                        Defect Type :  {
                                                            selectedDefects.defectTypeName
                                                            }
                                                        </Text>
                                                        {
                                                            selectedDefects.categoryID ? 
                                                                <Text style={{margin:5}}>
                                                                    Category : 
                                                                </Text>
                                                            : null
                                                        }
                                                        {
                                                            selectedDefects.taskID ? 
                                                                <Text style={{margin:5}}>
                                                                    Task :  
                                                                </Text>
                                                            : null
                                                        }
                                                        <Text style={{margin:5}}>
                                                            MappingID :  {selectedDefects?.mappingID}
                                                        </Text>

                                                        <Text style={{margin:5}}>
                                                            Defect Status :  {selectedDefects?.defectStatus}
                                                        </Text>                                                        
                                                      </View>
                                                    
                                                    <FileHandler isLargeScreen={props.isLargeScreen} 
                                                        fileUploadAccess={false}
                                                        documentList={selectedDefects?.fileList}
                                                        orderID={props.orderID}
                                                        fileAccess={defectFileAccessDetails}
                                                        id={selectedDefects?.uuID+"files"}
                                                        updateUserAccess = {(fileAccess)=>{(null)}}
                                                        deletedFileList = {(fileAccess)=>{(null)}}
                                                        updatedFileList={(updatedList)=>{(null)}}/>
                                                
                                        </View>
                                  </View>
                              }
                          </View>
                          <View style={Style.fileViewTabls}>
                              <TouchableOpacity style={[Style.fileViewBtn,Style.activeFileViewBtn]}>
                                  <Text style={[
                                    Style.fileBtnLabel, Style.activeFileBtnLabel 
                                  ]}>Stock Usage</Text>
                              </TouchableOpacity>
                          </View>
                          <View style={Style.orderInfo}>
                                <View>
                                    <DataTable.Header>
                                        <DataTable.Title>Stock Name</DataTable.Title>
                                        <DataTable.Title>Stock Usage</DataTable.Title>
                                    </DataTable.Header>
                                    {
                                        selectedTaskData.stockUsage.map((res:any)=>{
                                          return res.mappingID&&
                                            res.mappingID==selectedTaskData.mappingId &&
                                            res.taskID == selectedTaskData.taskID ?
                                            <DataTable.Row>
                                              <DataTable.Cell>{res.mappingID} s</DataTable.Cell>
                                              <DataTable.Cell>{selectedTaskData.mappingID }</DataTable.Cell>
                                              <DataTable.Cell>{res.taskID }</DataTable.Cell>
                                              <DataTable.Cell>{selectedTaskData.taskID }</DataTable.Cell>
                                            </DataTable.Row> 
                                          : null
                                        })
                                      }
                                </View>
                          </View>
                        </View>
                      : null
                    }
                </View>
              }
              </View>
          }
        </View>
    )
}

export default OrderProductionReport;