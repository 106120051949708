import { StyleSheet } from "react-native";
const style=StyleSheet.create({
    container:{
        height:180,
        borderRadius:5,
        alignItems:'center',
        padding:30,
        shadowOffset: {width: -2, height: 4},  
        shadowColor: '#171717',  
        shadowOpacity: 0.2,  
        shadowRadius: 3,  
    },
    mainTitle:{
        fontSize:38,
        fontWeight:'600',
        alignSelf:'center'
    },
    subTitle:{
        fontSize:20,
        fontWeight:'500',
        alignSelf:'center',
        margin:5
    },
    alertBtn:{
        margin:5,
        alignSelf:'flex-end',
        justifyContent:'center',
        height:35,
        width:50,
        borderRadius:5,
        backgroundColor:'#4399f0'
    },
    alertBtnText:{
        alignSelf:'center',
        fontWeight:'600',
        color:'white'
    }
})

export default style;