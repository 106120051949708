import React,{FC, useEffect, useState} from "react";
import {View, Text, Image, TouchableOpacity} from "react-native";
import {Style} from './style';
import { fileAccess, clearRepo, createCategory, getOrderDetails, getCategoryList } from '../../Server_Repo/action';
import CustomTextInput from "../CustomTextInput";
import CustomLabel from "../CustomLabel";
import DatePicker from '../DatePicker';
import CustomButton from "../CustomButton";
import FileHandler from "../FilesHandler";
import { useSelector, useDispatch } from 'react-redux';
import CustomAlert from "../CustomAlert";
import SubTitleHeader from "../SubTitleHeader";
import PagenationFooter from "../PagenationFooter";
import {getAccessToken} from "../../utility/index"
import { DataTable } from 'react-native-paper';
import CreateGroup from "../CreateGroup";
import CreateUser from "../CreateUser";
import ResponsiblePerson from "../ResponsiblePerson";

interface Props{
  isLargeScreen:number;
  data:any;
  orderID:any;
  currentRoute:any;
  buyerID:any;
  successCallBack:(data:any)=>void;
  internalComponentChanges:()=>void;
}
const OrderUser:FC<Props>=(props)=>{

    const dispatch:any = useDispatch();
    const [screenType,setScreenType]=useState(Style);
    const [categoryData,setCategoryData] = useState<any>([]);
    const [currentCategoryData,setCurrentCategoryData] = useState<any>({});
    const [groupList,setGroupList]= useState<any>();
    const [formError,setFormError]=useState<any>(null);
    const [alertTitle,setAlertTitle]= useState<any>();
    const [alertSubTitle,setAlertSubTitle]= useState<any>();
    const [formStatus,setFormStatus]= useState<any>(0);
    const [addCategoryView,setAddCategoryView] = useState<any>(false);
    const [fileAccessDetails,setFileAccessDetails] = useState<any>();

    const {
      createCategoryResponse,
      getCategoryListResponse,
      getFileAccessListResponse,
      orderDetails
  } = useSelector((state:any) => state.userReducer);

  useEffect(()=>{
    if(orderDetails?.status==1&&orderDetails?.fetchType=="basicInfo"){
      getOrderList("users");
    }
    if(orderDetails?.status==1&&orderDetails?.fetchType=="users"){
      setGroupList(orderDetails?.data)
      getCategoryDetails();
    }
  },[orderDetails]);

  useEffect(()=>{
    if(getCategoryListResponse?.status==1){
      setCategoryData(getCategoryListResponse?.data);
    }
  },[getCategoryListResponse!=undefined]);

  useEffect(()=>{
    if(currentCategoryData?.fileList){
      getFileAccessDetails(currentCategoryData?.fileList);
    }
  },[currentCategoryData!=undefined]);


  useEffect(()=>{
    console.log("getFileAccessListResponse",getFileAccessListResponse);
   if(getFileAccessListResponse&&getFileAccessListResponse?.status==1){
     let fileAccess:any = [];
     getFileAccessListResponse.fileIds.forEach((data:any)=>{
       fileAccess.push(
         {
           id:data,
           fileAccess:getFileAccessListResponse?.data?.filter((access:any)=>{
             return access.fileID==data
           })
         }
       )
     });
     setFileAccessDetails([...fileAccess])
     console.log(
       "getFileAccessListResponse",
       fileAccess
     )
   }
},[getFileAccessListResponse!=undefined]);

  useEffect(()=>{
    getOrderList("basicInfo");
  },[props.orderID!=undefined]);

  useEffect(()=>{
   if(createCategoryResponse?.status==1){

    setAlertTitle("Success");
    setAlertSubTitle("Category created successfully")
    setFormStatus(1)
    setAddCategoryView(!addCategoryView)
    getCategoryDetails()
   }else{
      setFormError(createCategoryResponse?.error)
   }
  },[createCategoryResponse!=undefined]);


  const getOrderList=(fetchType:any)=>{
    dispatch(
      getOrderDetails(
        {
          buyerID: props.buyerID,
          fetchType: fetchType,
          limit: 0,
          orderID: props.orderID,
          skip: 0,
        }
      )
    )
  }

  const getCategoryDetails =()=>{
    dispatch(
      getCategoryList(
        {
          buyerID: props.buyerID,
          orderID: props.orderID
        }
      ))
  }

  const getFileAccessDetails=(element:any)=>{
    let fileId:any=[];
    element?.fileList?.forEach((element:any) => {
      fileId.push(element._id);
    });
    console.log("getFileAccessDetails",element,fileId);
    dispatch(
      fileAccess(
        {
          fileIds:fileId,
          id:"orderCtegory"
        }
      )
    )
  }

  const onInputChange = (field:any,val:any) =>{
    var tempCategoryData:any = currentCategoryData ? currentCategoryData : {};
    tempCategoryData[field] = field=="categoryQty"||field=="noOfTask" ? Number(val) : val;
    setCurrentCategoryData({...currentCategoryData ,...tempCategoryData});
    console.log("currentCategoryData",currentCategoryData);
  }

  const createCategoryEvent = () =>{
   
    dispatch(clearRepo());

    dispatch(
      createCategory({
        orderID:props.orderID,
        categoryData:currentCategoryData,
      })
    )
  }
    console.log("categoryXWE",currentCategoryData);
    return (
      <View style={Style.mainContent}>
        <View>
            { formStatus==1 ?
                <View>
                  <CustomAlert title={alertTitle} subTitle={alertSubTitle} onPress={()=>{
                    getCategoryDetails()
                    setCurrentCategoryData(null)
                    setFormStatus(0);
                  }} />
                </View>
            :    <View style={screenType.container}>
              <View style={Style.header}>
                <View style={Style.headerLeft}>
                  <Text style={Style.headerTitle}>{ addCategoryView ? "Add Category" : "Order Category" }</Text>
                </View>
                {
                  !addCategoryView ?
                    <View style={Style.headerRight}>
                      <CustomTextInput type="text" id="categoryID" value={""}
                      placeHolder="Search category" onInputChange={(ID,val)=>{console.log("sa")}}/>
                    </View>
                  : null
                }
                {
                  currentCategoryData?._id ?
                      <TouchableOpacity style={{alignSelf:'flex-end'}} onPress={()=>{
                        onInputChange("isDeleted",currentCategoryData.isDeleted == 'active' ? 'inactive' : 'active')
                      }}>
                        <Image style={Style.headerRightIocns}
                        source={require('../../assets/images/'+
                         ( currentCategoryData?.isDeleted == 'active' ? 'delete' : 'undo')
                        +'.png')}/>
                    </TouchableOpacity>
                    : null
                }
                
                <TouchableOpacity style={{alignSelf:'flex-end'}} onPress={()=>{
                    setCurrentCategoryData([])
                    setAddCategoryView(!addCategoryView)}}>
                  <Image style={Style.headerRightIocns}
                    source={require("../../assets/images/"+(addCategoryView? "close" : "plus" )+".png")
                  }/>
                </TouchableOpacity>
              </View>
              <View style={Style.divView}>
              {
                categoryData&&addCategoryView == false ? 
                  <DataTable style={screenType.table}>
                    <DataTable.Header>
                      <DataTable.Title>Category Name</DataTable.Title>
                      <DataTable.Title>Category Qty</DataTable.Title>
                      <DataTable.Title>No of Task</DataTable.Title>
                      <DataTable.Title>Production Qty</DataTable.Title>
                      <DataTable.Title>Defect Qty</DataTable.Title>
                      <DataTable.Title>Status</DataTable.Title>
                      <DataTable.Title>Action</DataTable.Title>
                    </DataTable.Header>
                    {
                      categoryData.map((element:any) => {
                          return<DataTable.Row>
                            <DataTable.Cell>{element.categoryName}</DataTable.Cell>
                            <DataTable.Cell>{element.categoryQty}</DataTable.Cell>
                            <DataTable.Cell>{element.noOfTask}</DataTable.Cell>
                            <DataTable.Cell>0</DataTable.Cell>
                            <DataTable.Cell>0</DataTable.Cell>
                            <DataTable.Cell>Completed</DataTable.Cell>
                            <DataTable.Cell>
                              <TouchableOpacity onPress={()=>{
                                 setAddCategoryView(!addCategoryView)
                                 setFileAccessDetails(null)
                                 setCurrentCategoryData(element)
                                 getFileAccessDetails(element)}
                              }>
                                <Text>Edit</Text>
                              </TouchableOpacity>
                            </DataTable.Cell>
                          </DataTable.Row>
                      })
                    }
                  </DataTable> : 
                   <View>
                    <View style={Style.divView}>
                      <View style={Style.categoryView}>
                        <CustomTextInput type="text" id="categoryID" 
                            value={ currentCategoryData?.categoryName}
                            placeHolder="Category Name" 
                            onInputChange={(ID,val)=>{
                              onInputChange(
                              "categoryName",
                              val)}} />
                        <CustomTextInput type="text" id="categoryDesc"
                              value={currentCategoryData?.categoryDesc}
                              placeHolder="Category Description"
                              onInputChange={(ID,val)=>{
                                onInputChange(
                                "categoryDesc",
                                val)}} />
                        <CustomTextInput type="number" id="categoryQty"
                            value={ currentCategoryData.categoryQty ?
                               Number(currentCategoryData?.categoryQty ) : null}
                            placeHolder="Category Quantity"
                            onInputChange={(ID,val)=>{
                              onInputChange(
                              "categoryQty",
                              Number(val))}} />
                      </View>
                      <View style={Style.categoryView}>
                        <CustomTextInput type="text" id="noOfTask" value={currentCategoryData.noOfTask}
                         placeHolder="No fo  Taks" onInputChange={(ID,val)=>{ onInputChange( "noOfTask", val)}} />

                        <DatePicker label={"Production End Date"} placeholder={"Production End Date"}
                        date={ currentCategoryData?.categoryStartDate}
                        layerIndex={2} onDateSelected={(date)=>{onInputChange("categoryStartDate",date)}}/>

                        <DatePicker label={"Production Start Date"} placeholder={"Production Start Date"}
                          date={currentCategoryData.categoryEndDate} layerIndex={3} 
                          onDateSelected={(date)=>{
                            console.log("aaaaa",date)
                            onInputChange(    
                              "categoryEndDate",
                              date+"")
                          }}/>
                      </View>
                      <View>
                      <ResponsiblePerson groupList={groupList}
                          id='orderCateory-res'
                          title={'Add responsible person'}
                          currentRoute={props.currentRoute}
                          subRoute = {"insideOrder"}
                          orderID={props.orderID}
                          accessID={props.currentRoute}
                          resPerson = {currentCategoryData?.resPersons}
                          userListUpdated = {(userList)=>{
                            onInputChange(
                              "resPersons",
                              userList)
                          }}
                          visible={true}/>
                          <FileHandler isLargeScreen={props.isLargeScreen} 
                            documentList={currentCategoryData.fileList}
                            fileAccess={fileAccessDetails}
                            id="orderCtegory-file"
                            fileUploadAccess={true}
                            orderID={props.orderID}
                            updateUserAccess = {(fileAccess)=>{onInputChange("fileAccess",fileAccess.value)}}
                            deletedFileList = {(fileAccess)=>{onInputChange("fileDeleted",fileAccess.value)}}
                            updatedFileList={(updatedList)=>{onInputChange("fileList",updatedList.value)}}/>
                      </View>
                    </View>
                    <View style={Style.footer}>
                      <View style={{justifyContent:'center',flex:1}}>
                        <CustomLabel type="formErrorMsg" label={""} errorMsg={formError}
                        formRawData={null} successMsg={null}/>
                      </View>
                      <CustomButton buttonID={"Submit"} type="common"
                      title="Save" onSubmit={()=>{createCategoryEvent()}}/>
                    </View>
                 </View>
                }
              </View>
            </View>
            }
        </View>
      </View>
    )
}

export default OrderUser;