import React,{FC, useEffect, useState} from "react";
import {View, Text, Image, TouchableOpacity, Dimensions,StyleSheet, FlatList} from "react-native";
import {Style} from './style';
import { getOrderStockUsage, getCategoryList, getTaskList  } from '../../Server_Repo/action';
import { useSelector, useDispatch } from 'react-redux';

interface Props{
  isLargeScreen:number;
  data:any;
  orderID:any;
  buyerID:any;
  successCallBack:(data:any)=>void;
  internalComponentChanges:()=>void;
}
const OrderStocksUsage:FC<Props>=(props)=>{

    const dispatch:any = useDispatch();
    const [screenType,setScreenType]=useState(Style);
    const [addStocks,setAddStocks] = useState<any>();
    const [stockViewType,setStockViewType] = useState<any>(false);
    const [taskList,setTaskList] = useState<any>([]);
    const [categoryList,setCategoryList] = useState<any>([]);
    const [stockUsage,setStockUsage] =  useState<any>([]);
    const [stockWiseUsage,setStockWiseUsage] = useState<any>([]);
    const [taskWiseUsage,setTaskWiseUsage] = useState<any>([]);

    const {
      orderStockUsageResponse,
      getCategoryListResponse,
      getTaskListResponse,
    } = useSelector((state:any) => state.userReducer);

  useEffect(()=>{
    dispatch( getCategoryList(
    {
      orderID: props.orderID,
    }))
    dispatch( getTaskList(
      {
        orderID: props.orderID,
      }))
    dispatch(
      getOrderStockUsage({
        orderID:props.orderID
      })
    )
  },[addStocks==false]);

  useEffect(()=>{
      if(orderStockUsageResponse?.status == 1){
        let tempData:any=[];
        let stockWiseData:any = [];
        setStockUsage(orderStockUsageResponse.data)
        //stockWise
        orderStockUsageResponse.data.forEach((element:any) => {
            if(tempData.indexOf(element._id.stockID)==-1){
                tempData.push(element._id.stockID)
                let stockUsage = 0;
                orderStockUsageResponse.data.filter((ele:any)=>{
                  if(ele._id.stockID==element._id.stockID){
                    stockUsage = stockUsage + element.updatedQty
                  }
                })

                let overAlltaskRequired = 0;
               
                taskList.filter((ele:any)=>{
                    let catData = categoryList.filter((elc:any)=>{
                        return elc._id == ele.categoryID;
                    });
                    if(catData.length>0){
                      if(ele.stockID == element._id.stockID){
                        overAlltaskRequired = overAlltaskRequired+ele.reqQty*catData[0].categoryQty;
                        console.log("sssssss",overAlltaskRequired,ele,catData[0].categoryQty)
                      }
                    }
                });
               
                stockWiseData.push({
                  stockID:element._id,
                  usageQty:stockUsage,
                  stockDetails:element.stockList[0],
                  overAllRequiredQty:overAlltaskRequired
                })
            }
        });
        console.log("stockWiseData",stockWiseData)
        setStockWiseUsage([...stockWiseData])
      }
      if(getCategoryListResponse?.status == 1){
        setCategoryList(getCategoryListResponse.data)
      }
      
      if(getTaskListResponse?.status == 1){
     
       let tempTaskList:any = [];
       for(let ele of getTaskListResponse.data ){
          ele.stockList.forEach((element:any) => {
            tempTaskList.push(
              {
                taskName:ele.taskName,
                categoryID:ele.categoryID,
                taskID:String(ele._id),
                stockID:element.id,
                reqQty:element.qty
              }
            )
          });
        }
        setTaskList([...tempTaskList])
      }
  },[orderStockUsageResponse!=undefined,
    getCategoryListResponse!=undefined,
    getTaskListResponse!=undefined])

    const loadTaskWiseStockUsage=(categoryID:any)=>{
     
      let catData = categoryList.filter((ele:any)=>{
        return ele._id == categoryID;
      });
    
      let taskLists = taskList.filter((ele:any)=>{
        return ele.categoryID == categoryID;
      });

     let taskWiseData:any = [];
   
     if(catData.length>0&&taskLists.length>0){
        taskLists.forEach((element:any) => {

          let stockDetails;
        
          let usage = stockUsage.filter((ele:any)=>{
            return ele._id.stockID == element.stockID && ele._id.taskID == element.taskID;
          });
        
          if(usage.length>0){
            stockDetails = usage[0].stockList[0];
            usage = usage[0].updatedQty;
          }else{
            usage = 0;
          }

         
          taskWiseData.push({
            taskName:element.taskName,
            used:usage,
            requiredQty:catData[0].categoryQty*element.reqQty - usage,
            taskID:element.taskID,
            stockDetails:stockDetails
          })

        });
        console.log("stockUsage",taskWiseData)
        return <FlatList data={taskWiseData}
        renderItem={(item)=> 
        <View>
            <View>
              <Text style={Style.stockName}>Task - {item.item.taskName}</Text>
              <Text style={Style.metricTitle}>{"availability ("+item.item.stockDetails.stockQty+")"}</Text>
              <Text style={Style.metricTitle}>required {item.item.requiredQty}</Text>
              <View style={{marginTop:5,flexDirection:'row',height:22.5,width:'100%',backgroundColor:'#f2f5f7'}}>
                <View  style={{height:22.5,width:'90%',backgroundColor:'#248af0'}}>
                  <Text style={Style.usageStocks}>{item.item.stockDetails.stockName + "("+item.item.used+")"}</Text>
                </View>
              </View>
            </View>
          </View>}
        />
        console.log("taskWiseData",taskWiseData)
      
     }
    }

    const asdf=(items:any)=>{
      console.log("asdsadasd",items)
    }

    return (
      <View style={Style.mainContent}>
        <View>
          <View style={screenType.container}>
            <View style={Style.header}>
                  <View style={Style.headerLeft}>
                    <Text style={Style.headerTitle}>{"Stocks Usage"}</Text>
                  </View>
                  <View style={Style.headerRight}>
                  </View>
            </View>
            <View style={Style.stockViewControl}>
              <TouchableOpacity style={[Style.stockViewBtn,stockViewType==false ? Style.activeStockViewBtn : null]}
              onPress={()=>{setStockViewType(!stockViewType)}}>
                  <Text style={[
                    Style.stockBtnLabel,
                    stockViewType==false ? Style.activeStockBtnLabel : null
                   ]}>Stock wise</Text>
              </TouchableOpacity>
              <TouchableOpacity  style={[Style.stockViewBtn,stockViewType==true ? Style.activeStockViewBtn : null]}
              onPress={()=>{setStockViewType(!stockViewType)}}>
                  <Text style={[
                    Style.stockBtnLabel,
                    stockViewType==true ? Style.activeStockBtnLabel : null
                   ]}>Task wise</Text>
              </TouchableOpacity>
            </View>
          </View>
          {
            stockViewType == false ?
               <View>
                  <FlatList data={stockWiseUsage}
                  keyExtractor={item => item.stockDetails.stockID}
                  renderItem={(item:any)=><View style={Style.stockCard}>
                      <Text style={Style.stockName}>{item.item?.stockDetails.stockName}</Text>
                      <Text style={Style.stockQty}>{"Current Stock ("+item.item?.stockDetails?.stockQty+")"}</Text>
                      <Text style={Style.stockQty}>{"Over all required ("+item.item?.overAllRequiredQty+")"}</Text>
                      <View style={{flexDirection:'row',height:22.5,width:'100%',backgroundColor:'#f2f5f7'}}>
                        <View  style={{height:22.5,width:'90%',backgroundColor:'#248af0'}}>
                          <Text style={Style.usageStocks}>Used {item.item?.usageQty}</Text>
                        </View>
                      </View>
                    </View>
                  }/>
               </View>
            : <View>
                   <View style={Style.catStockiew}>
                    {
                      categoryList.map((ele:any,index:any)=>{
                        return <View key={index+"catStock"} style={Style.catStockCard}>
                              <View style={Style.categryNameHeader}>
                                <Text style={Style.categoryTitle}>Category - {ele.categoryName}</Text>
                              </View>
                              <View style={Style.stockCatWiiseHolder}>
                                {
                                  loadTaskWiseStockUsage(ele._id)
                                }
                              </View>
                        </View>
                      })
                    }
                  </View>
              </View>
          }
        </View>
      </View>
    )
}

export default OrderStocksUsage;
