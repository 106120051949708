import { StyleSheet } from "react-native";
import * as Colors from '../../constants/Colors';
const Style=StyleSheet.create({
    container:{
        flex:1,
        justifyContent:'center',
        backgroundColor:'white'
    },
    splashLogo:{
        height:150,
        width:150,
        borderRadius:20,
        alignSelf:'center'
    },
    appTitle:{
        padding:20,
        alignSelf:'center',
        fontWeight:'600',
    }
});

export default Style;