import React,{FC,useEffect, useState} from "react";
import {View,Image,Text,Dimensions} from "react-native";
import {Style,tabletView,mobileView} from './style';
import SignupForm from "../../components/SingupForm";
import OTPForm from '../../components/OTPForm';
import ForgetPinForm from "../../components/ForgetPinForm";
import LoginForm from "../../components/LoginForm";
import BillingAddressForm from "../../components/BillingAddressForm";
import PlanForm from "../../components/PlansForm";
import {ComponentSwitch} from "../../constants/ComponentSwitch";

const window = Dimensions.get("window");
interface Props{
  rouetData:any;
  routeName:any
  navigation:(dataSets:any,natTo:any)=>void;
}
const SignupActivity:FC<Props>=(props)=>{

    const {data}=props.rouetData;
    console.log(
      "SignupActivity",
      data
    )
    const [dimensions, setDimensions] = useState( window.width);
    const [screenType,setScreenType]=useState(Style);
    const [formData,setFormData]=useState<any>(null);
    const [formType,setFormType]=useState<String>( data?.initScreen ? data.initScreen : "signup");
    const [routePath,setRoutePath]=useState<any>(["signup"]);

    useEffect(() => {
    
      var tempMediaQuery=Style;
      Dimensions.addEventListener("change",({ window}) => {
          console.log("windowasdasdsa",window);
            setDimensions(window.width);
      });

      if(dimensions<=425&&dimensions>=320)
           tempMediaQuery={...Style,...mobileView};
    
      if(dimensions<=730&&dimensions>=420)
        tempMediaQuery={...Style,...tabletView};
      
      setScreenType(tempMediaQuery);
      
    },[dimensions]);

    useEffect(()=>{
      console.log("data",data);
        if(data!=null){

          var tempFormData={...formData,...data};
          setFormData(tempFormData);
          if(data.status=="selectPlan"){
            setFormType("planSelector");
          }
        }
    },[data]);


    const loginSuccess=()=>{
      console.log("loginSuccess","loginSuccess")
      props.navigation("splashActivity",{});
    }

    const planSelected=(planIndex:Number)=>{
      var tempFormData={...formData,...data};
      tempFormData["planIndex"]=planIndex;
      tempFormData["planType"]="mainPlan";
      console.log("planIndex",tempFormData);
      switchForm("billingAddress",tempFormData);
    }

    const switchForm=(type:String,formData:any)=>{
      console.log("routePath",routePath);
      var tempFormData={...formData,...data};
      console.log("formData",tempFormData,type);
      setFormData(tempFormData);
      setRoutePath([formType,type]);
      setFormType(type);
    }

    const successCallBack=(formData:any)=>{
        props.navigation("splashActivity",{});
    }
  
      return (
        <View style={screenType.container}>
          <View style={screenType.columLeft}>              
              <Image style={screenType.splashLogo}
              source={require('../../assets/images/icon.png')}/>
          </View> 
          <View style={screenType.columRight}>
              {
                  formType=="signup" ?  
                  <SignupForm formData={formData}
                  switchFormType={(type,formData)=>switchForm(type,formData)}/> 
                : formType=="otp" ? 
                  <OTPForm formData={formData}
                  loginSuccess={()=>{loginSuccess()}}
                  tocreen={ComponentSwitch.indexOf(routePath[0])}
                  switchFormType={(type,formData)=>switchForm(type,formData)}/>  
                : formType=="login" ? 
                  <LoginForm formData={formData}
                  loginSuccess={()=>{loginSuccess()}}
                  switchFormType={(type)=>switchForm(type,formData)}/> 
                : formType=="forgetPin" ?  
                  <ForgetPinForm formData={formData}
                  toscreen={ComponentSwitch.indexOf(routePath[0],formData)}
                  switchFormType={(type,formData)=>switchForm(type,formData)}/> 
                : formType=="planSelector" ?  <PlanForm formData={formData} 
                  selectedPlan={(planIndex)=>{
                    planSelected(planIndex);
                  }} /> 
                : formType=="billingAddress" ?  <BillingAddressForm
                  formData={formData} 
                  successCallBack={(formData)=>{successCallBack(formData)}}
                  switchFormType={(type,formData)=>switchForm(type,formData)}/> 
                : null
             }
          </View>
        </View>
     )    
}

export default SignupActivity;