import React,{FC, useEffect, useState} from "react";
import {View, Text, TouchableOpacity, StyleSheet, Picker} from "react-native";
import {Style} from './style';
import { createStaff } from '../../Server_Repo/action';
import CheckBoxButton from "../CheckBox";
import CustomButton from "../../components/CustomButton";
import { useSelector, useDispatch } from 'react-redux';
import CustomAlert from "../CustomAlert";
import SubTitleHeader from "../SubTitleHeader";
import sideMenuQuery from "../../constants/FormQueries/sidemenu";
import CustomTextInput from "../CustomTextInput";
import CustomLabel from "../CustomLabel";

interface Props{
  isLargeScreen:number;
  userDetials:any;
  groupDetials:any;
  type:any;
  successCallBack:(data:any)=>void;
  switchComponent:(data:any)=>void;
}
const CreateUser:FC<Props>=(props)=>{

    const dispatch:any = useDispatch();
    const [screenType,setScreenType]=useState(Style);
    const [alertTitle,setAlertTitle]= useState<any>();
    const [alertSubTitle,setAlertSubTitle]= useState<any>();
    const [formStatus,setFormStatus]= useState<any>(0);
    const [userName,setUserName]= useState<any>(props?.userDetials?.userName);
    const [userID,setUserID]= useState<any>(props?.userDetials?.password);
    const [notifyMail,setNotifyMail]= useState<any>(props?.userDetials?.notifyMail);
    const [password,setUserPassword]= useState<any>(props?.userDetials?.password);
    const [userStatus,setUserStatus]= useState<any>(props?.userDetials?.userStatus == "true" ? true : false);
    const [userRole,setUserRole]= useState<any>(props?.userDetials?.userRole);
    const [userAccess,setUserAccess]= useState<any>(props?.userDetials?.userAccess);
    const [formError,setFormError] = useState<any>();

    const {
      userDetails,createStaffResponse
    }  = useSelector((state:any) => state.userReducer);
    const [alignContent, setAlignContent] = useState("flex-start");

   useEffect(()=>{
    console.log("createStaffResponse",createStaffResponse);
    if(createStaffResponse){
      if(createStaffResponse.status==0){
        setFormError(createStaffResponse.error);
      }else{
        if(createStaffResponse.route=="createStaff"){
          setAlertTitle("SUCCESS");
          setAlertSubTitle("User created successfully");
        }
        setFormStatus(1)
      }
    }
   },[userDetails!=undefined,createStaffResponse]);
  
    const submitBasicInfo=async()=>{
      setFormError("");
   
      var formData={
        buyerID:props.groupDetials.buyerID,
        groupID:props.groupDetials._id,
        userName:userName,
        userID:userID,
        notifyMail:notifyMail,
        access:userAccess,
        userRole:userRole,
        password:password,
        status:userStatus
      };
      dispatch(createStaff({
        ...props?.userDetials,
        ...formData
      }));
    }

    const alertClickEvent=()=>{
      setFormStatus(0);
      props.switchComponent("userList");
    }

    
    const selectedSideMenu=(element:any)=>{
      let mainAccess = userAccess ? userAccess : [];
      let elementIndex = mainAccess.findIndex((data:any)=>{
        return element.id == data.id
      });

      if(elementIndex == -1){
        mainAccess.push({
          id:element.id,
          access:0
        });
      }else{
        mainAccess.splice(elementIndex, 1);
      }

      setUserAccess([...mainAccess]);
    }

    const selectAccess=(access:any,elementID:any)=>{
     
      var tempSelectedOption = userAccess ? userAccess : [] ;   
      let elementIndex = -1;
      let checkIndex = tempSelectedOption.findIndex((items:any)=>{
          return items.id == elementID
      });
      if(checkIndex == -1){
          tempSelectedOption.push(
              {
                  id:elementID,
                  access:access
              }
          )
      }else{
          if(elementIndex==-1){
              tempSelectedOption[checkIndex].access=access;
         }
      }


      setUserAccess([...tempSelectedOption]);
      
    }
  
    const checkIsExists = (elementID:any)=>{

      var tempSelectedOption:any = userAccess ? userAccess : [] ;   
      let elementIndex = tempSelectedOption.findIndex((items:any)=>{
          return items.id == elementID
      });
       console.log("checkIsExists",elementIndex)
      if(elementIndex == -1){
          return -1
      }else{
          console.log( "checkIsExists",typeof tempSelectedOption)
          return tempSelectedOption[elementIndex]?.access;
      }
    }

    var propsUserDetails:any=props.userDetials;

    return (
      <View style={Style.mainContent}>
        <View>
          <View style={screenType.container}>
            { formStatus==1 ?
                <View>
                  <CustomAlert title={alertTitle} subTitle={alertSubTitle} onPress={()=>alertClickEvent()} />
                </View>
            :  
                <View style={Style.divView}>
                  <CustomTextInput type="text" id="userRole" value={propsUserDetails?.userRole}
                    placeHolder="User Role" onInputChange={(ID,val)=>{setUserRole(val)}} />
                  <CustomTextInput type="text" id="userID" value={propsUserDetails?.userID}
                    placeHolder="User ID" onInputChange={(ID,val)=>{setUserID(val)}} />
                  <CustomTextInput type="text" id="userName" value={propsUserDetails?.userName}
                    placeHolder="User Name" onInputChange={(ID,val)=>{setUserName(val)}} />
                  <CustomTextInput type="text" id="notifyMail" value={propsUserDetails?.notifyMail}
                    placeHolder="User Mail" onInputChange={(ID,val)=>{setNotifyMail(val)}} />
                  <CustomTextInput type="text" id="userPassword" value={propsUserDetails?.password}
                    placeHolder="password" onInputChange={(ID,val)=>{setUserPassword(val)}} />
                  <View style={[Style.userStatus]}>
                    <View>
                      <CheckBoxButton 
                      state={userStatus}
                      id={"userStaus"}
                      isChecked={(check)=>{setUserStatus(!userStatus)}}/>
                    </View>
                    <Text style={Style.userStatusLabel}>{"User Status"}</Text>
                  </View>
                  <SubTitleHeader title={"Enable screen to access user"}/>
                  <View style={{flexDirection:'row',flexWrap:'wrap'}}>
                    <View style={{maxHeight:500,flex:1,overflow:'scroll'}}>
                      <View style={{ padding:15, flex: 1 }}>
                        <View style={styleBox.row}>
                          {
                            sideMenuQuery.map((element:any)=>{
                            return  <View>
                                  <TouchableOpacity onPress={()=>{selectedSideMenu(element)}}>
                                    <View style={[styleBox.box]}>
                                      <CheckBoxButton 
                                      state={checkIsExists(element.id) != -1 ? true : false}
                                      id={null}
                                      isChecked={()=>{}}/>
                                      <Text style={Style.accessListText}>{element.title}</Text>
                                    </View>
                                  </TouchableOpacity>
                                  <Picker style={Style.accessDropdown} selectedValue={checkIsExists(element.id)}
                                    onValueChange={(itemValue:any) =>
                                      selectAccess(itemValue,element.id)
                                    }>
                                    <Picker.Item label="Normal User" value={0} />
                                    <Picker.Item label="Super User" value={1} />
                                  </Picker>
                              </View>   
                            })
                          }
                        </View>
                      </View>
                    </View>
                  </View>
                  <CustomLabel type="formErrorMsg" label={""} errorMsg={formError}
                    formRawData={null} successMsg={null}/>
                  <CustomButton buttonID={"Submit"} type="common"
                    title="Create" onSubmit={()=>{submitBasicInfo()}}/>
                </View>
            }
          </View>
        </View>
      </View>
    )
}



export default CreateUser;


const styleBox = StyleSheet.create({
  box: {
    flex:1,
    width: 120,
    height: 120,
    margin:10,
    padding:10,
    justifyContent:'flex-start',
    borderRadius:5,
    backgroundColor:'#f7f7f7'
  },
  row: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
});