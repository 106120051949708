import { StyleSheet } from "react-native";
import Colors from "../../constants/Colors"; 

export const Style=StyleSheet.create({
    mainContent:{
        flex:1,
    },
    errorText:{
        display:'none',
        color:'red',
        marginTop:15,
        margin:10,
        alignSelf:'flex-start',
    },
    container:{
        flexDirection:'column',
    },
    divView:{
        marginBottom:0,
        position:'relative',
        flexDirection:'column',
        padding:15
    },
    groupContainer:{
       padding:10,
    },
});