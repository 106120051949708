import React,{FC, useEffect, useState} from "react";
import {View, Text, Picker, Image, TouchableOpacity} from "react-native";
import {Style} from './style';
import { getOrderList, clearRepo } from '../../Server_Repo/action';
import CustomTextInput from "../CustomTextInput";
import CustomLabel from "../CustomLabel";
import DatePicker from '../DatePicker';
import CustomButton from "../../components/CustomButton";
import FileHandler from "../FilesHandler";
import { useSelector, useDispatch } from 'react-redux';
import CustomAlert from "../CustomAlert";
import SubTitleHeader from "../SubTitleHeader";
import { DataTable } from 'react-native-paper';
import {getAccessToken,localTimeConversion} from "../../utility/index";
interface Props{
  isLargeScreen:number;
  successCallBack:(data:any)=>void;
  onSelectedOrder:(data:any)=>void;
}
const MainOrderList:FC<Props>=(props)=>{

    const dispatch:any = useDispatch();
    const [screenType,setScreenType]=useState(Style);
    const [formError,setFormError]=useState<any>(null);
    const [orderList,setOrderList]= useState<any>();
    const [userMail,setUserMail]= useState<any>();
    const [pageLevels, setPageLevels] = useState(5);
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(5);
    const [filter, setFilter] = useState(10);


    const {
      userDetails,orderListRepo
    } = useSelector((state:any) => state.userReducer);

   useEffect(()=>{
    if(userDetails&&userDetails.userMail){
      setUserMail(userDetails.userMail[0]);
    }
    if(orderListRepo){
      if(orderListRepo.status!="success"){
        setFormError(orderListRepo.error);
      }else{
        console.log("orderListRepo",orderListRepo.data);
        setOrderList(orderListRepo.data);
      }
    }
   },[userDetails,orderListRepo]);

   useEffect(()=>{
    getOrderData();
   },[skip,limit,userMail]);

   const getOrderData=()=>{
    getAccessToken().then((userDetails)=>{
      dispatch(getOrderList(
        {
          userMail:userDetails.accessToken,
          skip:skip,
          limit:limit
        }
      ));
    });
   }
    return (
      <View style={Style.mainContent}>
        <View>
          <View style={screenType.orderListTopBar}>
              <View style={screenType.topBarRight}>
                <View style={screenType.topBarLeft}>
                  <CustomLabel type="elementLabel" label={"Production Type"} errorMsg={formError}
                  formRawData={null} successMsg={null}/>
                  <Picker
                      selectedValue={pageLevels}
                      style={Style.dropDown}
                      onValueChange={(itemValue:any, itemIndex:any) =>
                        setPageLevels(itemValue)
                      }>
                      <Picker.Item label="All" value={0} />
                      <Picker.Item label="Completed" value={1} />
                      <Picker.Item label="In production" value={2} />
                      <Picker.Item label="In production" value={3} />
                    </Picker>
                </View>
                <CustomTextInput type="text" id="orderName" placeHolder={'Search'}
                 onInputChange = {()=>{}}
                 value={""}/>
              </View>
          </View>
          <View>
            {
              orderList ? 
              <DataTable style={screenType.table}>
                <DataTable.Header>
                  <DataTable.Title>Order ID</DataTable.Title>
                  <DataTable.Title>Order Name</DataTable.Title>
                  <DataTable.Title>Start Date</DataTable.Title>
                  <DataTable.Title>End Date</DataTable.Title>
                  <DataTable.Title>No Category</DataTable.Title>
                  <DataTable.Title>Production Qty</DataTable.Title>
                  <DataTable.Title>Status</DataTable.Title>
                </DataTable.Header>
                {
                  orderList.map((element:any,index:any) => {
                      return<DataTable.Row onPress={()=>{props.onSelectedOrder(element._id)}} key={index+"main-order-list"}>
                        <DataTable.Cell>{element.orderID}</DataTable.Cell>
                        <DataTable.Cell>{element.orderName}</DataTable.Cell>
                        <DataTable.Cell>{localTimeConversion(element.proStartDate)}</DataTable.Cell>
                        <DataTable.Cell>{localTimeConversion(element.proEndDate)}</DataTable.Cell>
                        <DataTable.Cell>{element.noOfCategory}</DataTable.Cell>
                        <DataTable.Cell>{"0 /"+element.proQty}</DataTable.Cell>
                        <DataTable.Cell>Completed</DataTable.Cell>
                      </DataTable.Row>
                  })
                }
              </DataTable> : <Text>Data not found</Text>
            }
          </View>
        </View>
        <View style={screenType.pagenation}>
            <View>
              <TouchableOpacity onPress={()=>{setSkip(skip-Number(limit)<0 ? 0 : skip-Number(limit))}}>
                <Image style={screenType.pagenationArrow}
                  source={require('../../assets/images/leftArrow.png')}/>
              </TouchableOpacity>
            </View>
              <View>
                <Picker
                  selectedValue={limit}
                  style={Style.pagenationDropdown}
                  onValueChange={(itemValue:any, itemIndex:any) =>
                    setLimit(itemValue)
                  }>
                  <Picker.Item label="5" value={5} />
                  <Picker.Item label="10" value={10} />
                  <Picker.Item label="50" value={50} />
                  <Picker.Item label="100" value={100} />
                </Picker>
              </View>
            <View>
              <TouchableOpacity onPress={()=>{setSkip(skip+Number(limit))}}>
                <Image style={screenType.pagenationArrow}
                source={require('../../assets/images/rightArrow.png')}/>
              </TouchableOpacity>
            </View>
        </View>
      </View>
    )
}

export default MainOrderList;