import {
    API_CALL_FAIL, 
    USER_SIGNUP_REQUEST_SUCCESS,
    CLEAR_SERVER_REPO,
    VERIFY_OTP_SUCCESS,
    RESEND_OTP_SUCCESS,
    UPDATE_PLAN_DETAILS_SUCCESS,
    GET_USER_DETAILS_SUCCESS,
    LOGIN_USER_SUCCESS,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_OTP_SUCCESS,
    SAVE_FILE_REQUEST_SUCCESS,
    CREATE_ORDER_RESPONSE,
    GET_ORDER_LIST_RESPONSE,
    IS_ACTIVE_DATEPICKER,
    GET_ORDER_DETAIL_RESPONSE,
    CREATE_GROUP_RESPONSE,
    CREATE_STAFF_RESPONSE,
    GET_STAFF_LIST_RESPONSE,
    GET_STAFF_DETAILS_RESPONSE,
    GET_STAFF_LIST_BY_ID_RESPONSE,
    CREATE_STOCKS_RESPONSE,
    GET_STOCKS_LIST_RESPONSE,
    CREATE_CATEGORY_RESPONSE,
    GET_CATEGORY_LIST_RESPONSE,
    CREATE_TASK_RESPONSE,
    GET_TASK_LIST_RESPONSE,
    CREATE_OPU_RESPONSE,
    GET_OPU_LIST_RESPONSE,
    CREATE_AGENDA_RESPONSE,
    GET_AGENDA_LIST_RESPONSE,
    CREATE_CALENDAR_EVENT_RESPONSE,
    GET_CALENDAR_EVENT_LIST_RESPONSE,
    PRODUCTION_INPUT_RESPONSE,
    GET_INPUT_LIST_RESPONSE,
    CREATE_DEFECT_TYPE_RESPONSE,
    GET_DEFECT_TYPE_LIST_RESPONSE,
    INPUT_DEFECT_RESPONSE,
    INPUT_DEFECT_LIST_RESPONSE,
    GET_GROUP_LIST_RESPONSE,
    GET_FILE_ACCESS_LIST_RESPONSE,
    GET_FILE_ACCESS_LIST,
    UPDATE_ORDER_USER_LIST_RESPONSE,
    GET_ORDER_USER_LIST_RESPONSE,
    GET_ORDER_STOCK_USAGE_LIST_RESPONSE,
    GET_FILTER_DETAILS_RESPONSE,
    GET_OVERALL_REPORT_LIST_RESPONSE,
    GET_ACTUAL_REPORT_DETAILS_RESPONSE
} from './constant';

const initialState = {
    signupResponse: null,
    otpResponse: null,
    resendOtpResponse: null,
    updatePlanDetailsResponse:null,
    apiResponse:null,
    userDetails:null,
    loginResponse:null,
    forgetPinOtpResponse:null,
    forgetPinResponse:null,
    saveDocumentResponse:null,
    orderBasicInfo:null,
    orderDetails:null,
    orderListRepo:null,
    createGroupResponse:null,
    createStaffResponse:null,
    getStaffListResponse:null,
    getStaffDetailsResponse:null,
    getStaffDetailsByIdResponse:null,
    createStockResponse:null,
    stockListResponse:null,
    createCategoryResponse:null,
    getCategoryListResponse:null,
    createTaskResponse:null,
    getTaskListResponse:null,
    createOPUResponse:null,
    getOPUListResponse:null,
    createAgendaResponse:null,
    createCalendarEventResponse:null,
    getAgendaListResponse:null,
    getCalendarEventListResponse:null,
    productionInputResponse:null,
    getInputListResponse:null,
    createDefectTypeResponse:null,
    getDefectTypeResponse:null,
    createDefectInputResponse:null,
    getDefectInputListResponse:null,
    getGroupListResponse:null,
    getFileAccessListResponse:null,
    updateUserListResponse:null,
    getOrderUserListResponse:null,
    orderStockUsageResponse:null,
    getOverAllReportReponse:null,
    getReportFilterReponse:null,
    getActualReportDetailsReponse:null,
    isActiveDatePicker:null
}

function userReducer(state = initialState, action) {
    switch (action.type) {
        case GET_USER_DETAILS_SUCCESS:
            {
                if(action.response.data!=undefined){
                    return {
                        userDetails:action.response.data
                    }
                }
               
            }
        case USER_SIGNUP_REQUEST_SUCCESS:
            {
                return {
                    signupResponse:action.response
                }
            }
        case VERIFY_OTP_SUCCESS:
            {
                return {
                    otpResponse:action.response
                }
            }
        case RESEND_OTP_SUCCESS:
            {
                return {
                    resendOtpResponse:action.response,
                    otpResponse:undefined
                }
            }
        case UPDATE_PLAN_DETAILS_SUCCESS:
            {
                return {
                    updatePlanDetailsResponse:action.response
                }
            }
        case LOGIN_USER_SUCCESS:
            {

                console.log("action.response",action.response);
                return {
                    loginResponse:action.response
                }
            }
        case FORGET_PASSWORD_SUCCESS:
            {
                console.log("action.response",action.response);
                return {
                    forgetPinResponse:action.response
                }
            }
        case FORGET_PASSWORD_OTP_SUCCESS:
            {
                console.log("FORGET_PASSWORD_OTP_SUCCESS",action.response);
                return {
                    forgetPinOtpResponse:action.response
                }
            }
        case SAVE_FILE_REQUEST_SUCCESS:
            {
                console.log("action.saveDocumentResponse",action.response);
                return {
                    saveDocumentResponse:action.response
                }
            }
        case CREATE_ORDER_RESPONSE:
            {
                console.log("action.saveDocumentResponse",action.response);
                return {
                    orderBasicInfo:action.response
                }
            }
        case GET_ORDER_LIST_RESPONSE:
            {
                console.log("action.saveDocumentResponse",action.response);
                return {
                    orderListRepo:action.response
                }
            }
        case GET_ORDER_DETAIL_RESPONSE:
            {
                console.log("GET_ORDER_DETAIL_RESPONSE",action);
                return {
                    orderDetails:action.response
                }
            }
        case CREATE_GROUP_RESPONSE:
            {
                console.log("action.createGroupResponse",action.response);
                return {
                    createGroupResponse:action.response
                }
            }
        case CREATE_STAFF_RESPONSE:
            {
                console.log("action.createGroupResponse",action.response);
                return {
                    createStaffResponse:action.response
                }
            }
        case GET_STAFF_LIST_RESPONSE:
            {
                console.log("action.getStaffListResponse",action);
                return {
                    getStaffListResponse:action.response
                }
            }
        case GET_STAFF_DETAILS_RESPONSE:
            {
                console.log("action.getStaffDetailsResponse",action.response);
                return {
                    getStaffDetailsResponse:action.response
                }
            }
        case GET_STAFF_DETAILS_RESPONSE:
            {
                console.log("action.getStaffDetailsResponse",action.response);
                return {
                    getStaffDetailsResponse:action.response
                }
            }
        case GET_STAFF_LIST_BY_ID_RESPONSE:
            {
                console.log("action.getStaffDetailsByIdResponse",action.response);
                return {
                    getStaffDetailsByIdResponse:action.response
                }
            }
        case CREATE_STOCKS_RESPONSE:
            {
                console.log("action.getStaffDetailsByIdResponse",action.response);
                return {
                    createStockResponse:action.response
                }
            }
        case GET_STOCKS_LIST_RESPONSE:
            {
                console.log("action.stockListResponse",action.response);
                return {
                    stockListResponse:action.response
                }
            }
        case CREATE_CATEGORY_RESPONSE:
            {
                console.log("action.createCategoryResponse",action.response);
                return {
                    createCategoryResponse:action.response
                }
            }
        case CREATE_TASK_RESPONSE:
            {
                console.log("action.createTaskResponse",action.response);
                return {
                    createTaskResponse:action.response
                }
            }
            
        case GET_CATEGORY_LIST_RESPONSE:
            {
                console.log("action.getCategoryListResponse",action.response);
                return {
                    getCategoryListResponse:action.response
                }
            }
        case GET_TASK_LIST_RESPONSE:
            {
                console.log("action.getCategoryListResponse",action.response);
                return {
                    getTaskListResponse:action.response
                }
            }
        case CREATE_OPU_RESPONSE:
            {
                console.log("action.createOPUResponse",action.response);
                return {
                    createOPUResponse:action.response
                }
            }
        case GET_OPU_LIST_RESPONSE:
            {
                console.log("action.getOPUListResponse",action.response);
                return {
                    getOPUListResponse:action.response
                }
            }
        case CREATE_AGENDA_RESPONSE:
            {
                console.log("action.createOPUResponse",action.response);
                return {
                    createAgendaResponse:action.response
                }
            }
        case GET_AGENDA_LIST_RESPONSE:
            {
                console.log("action.getAgendaListResponse",action.response);
                return {
                    getAgendaListResponse:action.response
                }
            }
        case CREATE_CALENDAR_EVENT_RESPONSE:
            {
                console.log("action.createCalendarEventResponse",action.response);
                return {
                    createCalendarEventResponse:action.response
                }
            }
        case GET_CALENDAR_EVENT_LIST_RESPONSE:
            {
                console.log("action.getCalendarEventListResponse:",action.response);
                return {
                    getCalendarEventListResponse:action.response
                }
            }
        case PRODUCTION_INPUT_RESPONSE:
            {
                console.log("action.productionInputResponse",action.response);
                return {
                    productionInputResponse:action.response
                }
            }
        case GET_INPUT_LIST_RESPONSE:
            {
                console.log("action.getInputListResponse",action.response);
                return {
                    getInputListResponse:action.response
                }
            }
        case CREATE_DEFECT_TYPE_RESPONSE:
            {
                console.log("action.createDefectTypeResponse",action.response);
                return {
                    createDefectTypeResponse:action.response
                }
            }
        case GET_DEFECT_TYPE_LIST_RESPONSE:
            {
                console.log("action.getDefectTypeResponse",action.response);
                return {
                    getDefectTypeResponse:action.response
                }
            }
        case INPUT_DEFECT_RESPONSE:
            {
                console.log("action.createDefectInputResponse",action.response);
                return {
                    createDefectInputResponse:action.response
                }
            }
        case INPUT_DEFECT_LIST_RESPONSE:
            {
                console.log("action.getDefectInputListResponse",action.response);
                return {
                    getDefectInputListResponse:action.response
                }
            }
        case GET_GROUP_LIST_RESPONSE:
            {
                console.log("action.getGroupListResponse",action.response);
                return {
                    getGroupListResponse:action.response
                }
            }
        case GET_FILE_ACCESS_LIST_RESPONSE:
            {
                console.log("action.getFileAccessListResponse",action.response);
                return {
                    getFileAccessListResponse:action.response
                }
            }
        case UPDATE_ORDER_USER_LIST_RESPONSE:
            {
                console.log("action.updateUserListResponse",action.response);
                return {
                    updateUserListResponse:action.response
                }
            }
        case GET_ORDER_USER_LIST_RESPONSE:
            {
                console.log("action.updateUserListResponse",action.response);
                return {
                    getOrderUserListResponse:action.response
                }
            }
        case GET_ORDER_STOCK_USAGE_LIST_RESPONSE:
            {
                console.log("action.getOrderStockUsageResponse",action.response);
                return {
                    orderStockUsageResponse:action.response
                }
            }
        case GET_OVERALL_REPORT_LIST_RESPONSE:
            {
                console.log("action.getOrderStockUsageResponse",action.response);
                return {
                    getOverAllReportReponse:action.response
                }
            }
        case GET_FILTER_DETAILS_RESPONSE:
            {
                console.log("action.getReportFilterReponse",action.response);
                return {
                    getReportFilterReponse:action.response
                }
            }
        case GET_ACTUAL_REPORT_DETAILS_RESPONSE:
            {
                console.log("action.getActualReportDetailsReponse",action.response);
                return {
                    getActualReportDetailsReponse:{...action.response}
                }
            }
        case IS_ACTIVE_DATEPICKER:
            {
                console.log("formData",action.payload);
                return {
                    isActiveDatePicker:action.payload
                }
            }
        case CLEAR_SERVER_REPO:
            return {
                signupResponse: null,
                otpResponse: null,
                resendOtpResponse: null,
                updatePlanDetailsResponse:null,
                apiResponse:null,
                userDetails:null,
                loginResponse:null,
                forgetPinOtpResponse:null,
                forgetPinResponse:null,
                saveDocumentResponse:null,
                orderBasicInfo:null,
                orderDetails:null,
                orderListRepo:null,
                createGroupResponse:null,
                createStaffResponse:null,
                createStockResponse:null,
                createTaskResponse:null,
                getTaskListResponse:null,
                getOPUListResponse:null,
                getAgendaListResponse:null,
                createAgendaResponse:null,
                createCalendarEventResponse:null,
                getCalendarEventListResponse:null,
                productionInputResponse:null,
                getInputListResponse:null,
                createDefectTypeResponse:null,
                getDefectTypeResponse:null,
                createDefectInputResponse:null,
                getDefectInputListResponse:null,
                getGroupListResponse:null,
                getFileAccessListResponse:null,
                updateUserListResponse:null,
                getOrderUserListResponse:null,
                orderStockUsageResponse:null,
                getOverAllReportReponse:null,
                getReportFilterReponse:null,
                getActualReportDetailsReponse:null,
                isActiveDatePicker:null
            }
        case API_CALL_FAIL:
                return {
                    apiResponse:{
                        status:"Fail",
                        msg:"API Call Fail"
                    }
                }
        default:
            return state;
    }
}

export default userReducer;