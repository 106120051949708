import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import userReducer from './reducer';
import createSagaMiddleware from "redux-saga"
import apiSaga from "./saga";
const sagaMiddleware = createSagaMiddleware()
const rootReducer = combineReducers({ userReducer });
const middlewares = [sagaMiddleware,thunk];
export const Store = createStore(rootReducer, applyMiddleware(...middlewares));

sagaMiddleware.run(apiSaga)
