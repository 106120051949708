 const loginFormQuery:any=[
     {
        id:"loginLabel",
        type:"formTitle",
        elementType:"text",
        mandatory:true,
        placeHolder:"Name",
        value:"Login",
        validation:false,
        readonly:false,
        options:[]
     },
     {
        id:"subLogLabel",
        type:"subFormTitle",
        mandatory:true,
        elementType:"text",
        placeHolder:"Name",
        value:"Enter your user ID / Mail and password to login",
        validation:false,
        readonly:false,
        errorMsg:"User name atleast 4 character",
        options:[]
     },
    {
        id:"userID",
        type:"text",
        mandatory:true,
        elementType:"inputText",
        placeHolder:"Email / User ID",
        value:"",
        defaultVallue:"",
        validation:false,
        readonly:false,
        errorMsg:"User ID atleast 4 character",
        options:[]
    },
    {
        id:"password",
        type:"password",
        mandatory:true,
        elementType:"inputText",
        placeHolder:"Password",
        value:"",
        defaultVallue:"",
        validation:false,
        readonly:false,
        errorMsg:"Password must be atleast 10 character",
        options:[]
    },
    {
        id:"errorMsg",
        type:"formErrorMsg",
        mandatory:true,
        value:"",
        validation:false,
        readonly:false,
        errorMsg:"",
        options:[]
     },
    {
        id:"loginBtn",
        type:"submitBtn",
        title:"Login",
        mandatory:true,
        placeHolder:"Login",
        value:"",
        validation:true,
        readonly:false,
        errorMsg:"",
        options:[]
    }
]

export default loginFormQuery;