import React,{FC, useEffect, useState} from "react";
import { View, Text, TouchableOpacity, Image, FlatList, Picker, ScrollView } from "react-native";
import { useSelector, useDispatch } from 'react-redux';
import CustomAlert from "../CustomAlert";
import CustomTextInput from "../CustomTextInput";
import CustomLabel from "../CustomLabel";
import CustomButton from "../CustomButton";
import DatePicker from "../DatePicker";
import { DataTable } from 'react-native-paper';
import Style from "./style";
import CheckBoxButton from "../CheckBox";
import {
     getCategoryList,
     productionInput,
     getTaskList, 
     getOrderDetails, 
     productionInputList,
     getDefectTypeLists,
     inputDefectList,
     fileAccess,
     inputDefect
} from '../../Server_Repo/action';
import ResponsiblePerson from "../ResponsiblePerson";
import FileHandler from "../FilesHandler";
import { localTimeConversion } from "../../utility";
import Ionicons from '@expo/vector-icons/Ionicons';

interface Props{
    isLargeScreen:number;
    data:any;
    orderID:any;
    buyerID:any;
    successCallBack:(data:any)=>void;
    internalComponentChanges:()=>void;
}
const ProductionInput:FC<Props>=(props)=>{
    const [screenType,setScreenType]=useState(Style);
    const [formStatus,setFormStatus]= useState<any>(0);
    const [formError,setFormError]=useState<any>(null);
    const [extraFormError,setExtraFormError]=useState<any>();
    const [alertTitle,setAlertTitle]= useState<any>();
    const [alertSubTitle,setAlertSubTitle]= useState<any>();

    const [addEvent,setEvent]= useState<any>(false);


   
    const [currentYear,setCurrentYear]= useState<any>('');
    const [currentMonth,setCurrentMonth]= useState<any>('');
    const [dateList,setDateList]= useState<any>('');
    const [eventList,setEventList]= useState<any>();
    const [filteredEventList,setFilteredEventList]= useState<any>([]);
    const [categoryList,setCategoryList]= useState<any>();
    const [taskList,setTaskList]= useState<any>();
    const [inputType,setInputType]= useState<any>(2);

    const [selectedCategory,setSelectedCategory]= useState<any>();
    const [selectedTask,setSelectedTask]= useState<any>();
    const [selectedDate,setSelectedDate]= useState<any>();
    const [netQuantity,setNetQuantity]= useState<any>();
    const [fileSets,setFileSets]= useState<any>([]);
    const [currentInputSelected,setCurrentInputSelected]= useState<any>({});
    // Current category helps to filter over data to view
    const [currentCategory,setCurrentCategory]= useState<any>(0);
    const [fileAccessDetails,setFileAccessDetails] = useState<any>();
    const [addMoreInputs,setAddMoreInputs]= useState<any>();
    const [moreInputsView,setMoreInputsView]= useState<any>(false);
    const [netQtData,setNetQtyData] = useState<any>([]);

    //defect List
    const [defectList,setDefectList]= useState<any>([]);
    const [defectViewList,setViewDefectList]= useState<any>(false);
    const [defectViewDetails,setDefectViewDetails]= useState<any>(false);
    const [defectTypeList,setDefectTypeLList]= useState<any>([]);
    const [currentDefectInput,seCurrentDefectInput]= useState<any>();
    const [defectStatus,setDefectStatus]= useState<any>(0);
    const [selectedDefect,setSelectedDefect]= useState<any>();

    const dispatch:any = useDispatch();
    const {
        orderDetails,
        productionInputResponse,
        getCategoryListResponse,
        getTaskListResponse,
        getInputListResponse,
        getFileAccessListResponse,
        getDefectInputListResponse,
        getDefectTypeResponse
    } = useSelector((state:any) => state.userReducer);

    const defectLevelList=["High","Medium","Low","Imformable"];
    const defectStatusList=["QC","No Defect","Resolved","Review","QC Review","Defect"];

    useEffect(()=>{
        getCategoryDetails();
        dispatch(getTaskList({
            buyerID: props.buyerID,
            orderID: props.orderID,
            userMail : "parixbe@gmail.com",
        }))
        dispatch(getOrderDetails({
            buyerID: props.buyerID,
            orderID: props.orderID,
            userMail : "parixbe@gmail.com",
            fetchType:"users",
            limit: 100,
            skip: 0,
        }));
        let currentDate = new Date();
        setCurrentMonth(currentDate.getMonth());
        setCurrentYear(currentDate.getFullYear());
        getDefectTypeList();
        getDefectInputList();
    },[]);

    useEffect(()=>{
        if(getCategoryListResponse?.status==1){
            setCategoryList(getCategoryListResponse.data);
        }
        if(getTaskListResponse?.status==1){
            setTaskList(getTaskListResponse.data);
        }
        if(getDefectInputListResponse?.status==1){
            setDefectList(getDefectInputListResponse.data);
        }
        if(getDefectTypeResponse?.status==1){
            setDefectTypeLList(getDefectTypeResponse.data);
        }
    },[
        orderDetails!=undefined,
        getCategoryListResponse!=undefined,
        getTaskListResponse!=undefined,
        getDefectInputListResponse!=undefined,
        getDefectTypeResponse!=undefined
    ]);

    useEffect(()=>{
        if(productionInputResponse?.status==1){
           setFormStatus(1);
           setAlertTitle("Success");
           setAlertSubTitle("Production inputed successfully")
           setSelectedCategory("0")
           setSelectedTask("0")
           resetInputView()
        }else{
            let errorMsg = productionInputResponse?.error[0]; 
            if(  typeof errorMsg != "string" ){
               
                let composeErrorMs = [ productionInputResponse?.error[0].msg];
                taskList?.forEach((element:any) => {
                    if(productionInputResponse?.error[0].extras.indexOf(element._id) !=-1){
                        composeErrorMs.push(
                            "\n -> Task:  "+element.taskName
                        )
                    }
                });
                setFormError(
                    composeErrorMs
                )
            }else{
                setFormError(
                    productionInputResponse?.error
                )
            }
            
        }
    },[productionInputResponse!=undefined]);

    useEffect(()=>{
        dispatch(
            productionInputList(
              {
                buyerID: props.buyerID,
                orderID: props.orderID,
                userMail : "parixbe@gmail.com",
              }
            ))
    },[formStatus==0]);

    useEffect(()=>{
        console.log("getInputListResponse",getInputListResponse);
        if(getInputListResponse?.status==1){
            setEventList(getInputListResponse?.data);
            setFilteredEventList(getInputListResponse?.data);
            setDateList([...dateList])
        }
    },[getInputListResponse!=undefined]);

    useEffect(()=>{
        dateRange();
    },[currentMonth,currentYear])

    useEffect(()=>{
        console.log("getFileAccessListResponse",getFileAccessListResponse);
       if(getFileAccessListResponse&&getFileAccessListResponse?.status==1){
         let fileAccess:any = [];
         getFileAccessListResponse.fileIds.forEach((data:any)=>{
           fileAccess.push(
             {
               id:data,
               fileAccess:getFileAccessListResponse?.data?.filter((access:any)=>{
                 return access.fileID==data
               })
             }
           )
         });
         setFileAccessDetails([...fileAccess])
         console.log(
           "getFileAccessListResponse",
           fileAccess
         )
       }
    },[getFileAccessListResponse!=undefined]);

    const  getDefectTypeList = ()=>{
        dispatch(getDefectTypeLists(
            {
                orderID: props.orderID
            }
        ))
    }

    const  getDefectInputList = ()=>{
        dispatch(inputDefectList(
            {
                orderID: props.orderID,
            }
        ))
    }

    const weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
    const monthList:any = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sept","Octr","Nov","Dec"];
    function dateRange(steps = 1) {
        const weekWiseDate=[];
        var dateArray = [];
        var weekCount=1;

        let currentDate = new Date(currentYear,currentMonth,1,1);
        console.log("currentDate",currentDate);
        console.log("currentDate",new Date(currentYear,currentMonth+1,1,1));
        while (currentDate <= new Date(currentYear,currentMonth+1,1,1)) {
          var  temDate:any=  new Date(currentDate)
          var day = temDate.getDate();
          currentDate.setUTCDate(currentDate.getUTCDate() + steps);

          // calculating starting index
          if(weekCount==1){
            var startWeekIndex=weekday.indexOf(weekday[temDate.getDay()]);
            for(var i=0;i<startWeekIndex+1;i++){
                dateArray.push({day:0,index:0});
            }
             dateArray.push({day:day,index:weekday[temDate.getDay()]});
          }else{
             dateArray.push({day:day,index:weekday[temDate.getDay()]});      
          }
          weekCount+=1;
        }
  
        var rowCount=0;
        var rowWiseDate:any=[];
        var tempDateList:any=[];
        dateArray.forEach((date,index)=>{
            if(index>0){
                if(dateArray.length-1!=index)
                    tempDateList.push(date);
                if(index!=0&&index%7==0){
                    rowCount+=1;
                    rowWiseDate[rowCount]=tempDateList;
                    tempDateList=[];
                }
                if(dateArray.length-1==index){
                    rowCount+=1;
                    rowWiseDate[rowCount]=tempDateList;
                    tempDateList=[];
                }
            }
        })
        

        console.log("currentDate",currentMonth,currentYear)

        rowWiseDate= rowWiseDate.filter((item:any)=>{
            return item!=null
        })

        setDateList(rowWiseDate);
        console.log("dateArray",dateList)
       
    }

    const yearRender=()=>{
        var mapData=[];
        for(var i=2023;i>=1900;i--){
            mapData.push(i);
        }
        return <Picker style={Style.dropDown}
            value={currentYear}
            onValueChange={(itemValue:any, itemIndex:any) =>
            setCurrentYear(itemValue)}>
            <Picker.Item label="Select Year" value={0} />
            {
                mapData.map((i)=>{
                    return <Picker.Item label={i} value={i} />
                })
            }
        </Picker>
       
    }

    const onDateSelected=(day:any)=>{
        var tempEventList = eventList ? eventList : [];
        tempEventList = tempEventList.filter((item:any)=>{
        var stDate = new Date(item.inputedAt)
        var inputedDay= stDate.getDate(), inputedMonth = stDate.getMonth(), inputedYear  = stDate.getFullYear();
            return currentCategory!=0 ? 
                inputedDay == day && 
                inputedMonth == currentMonth && 
                inputedYear == currentYear && 
                currentCategory==item.categoryID
            :   inputedDay == day && 
                inputedMonth == currentMonth && 
                inputedYear == currentYear 
        })
        setFilteredEventList([...tempEventList])
    }

    const getEventCount=(day:any)=>{
        var tempEventList = eventList ? eventList : [];
        tempEventList = tempEventList.filter((item:any)=>{
            var stDate = new Date(item.inputedAt)
            var inputedDay= stDate.getDate(), inputedMonth = stDate.getMonth(), inputedYear  = stDate.getFullYear();
            return currentCategory!=0 ? 
                inputedDay == day && 
                inputedMonth == currentMonth && 
                inputedYear == currentYear && 
                currentCategory==item.categoryID
            :   inputedDay == day && 
                inputedMonth == currentMonth && 
                inputedYear == currentYear 
        });

        return tempEventList.length;
    }

    const getCategoryDetails =()=>{
        dispatch(
          getCategoryList(
          {
            buyerID: props.buyerID,
            orderID: props.orderID,
            userMail : "parixbe@gmail.com",
          }
        ))
      }

    const loadCategoryTaskName=(id:any,type:any,qty:any)=>{
        var tempList = type == "category"  ? categoryList : taskList;
        tempList = tempList?.filter((item:any)=>{
            return  item._id ==id
        })
        console.log(
            "loadCategoryTaskName",taskList
        )
        if(tempList?.length>0){
            if(qty==true){
                return type == "category" ? tempList[0]?.['categoryQty'] :  tempList[0]?.['taskQty']
            }else{
                return type == "category" ? tempList[0]?.['categoryName'] :  tempList[0]?.['taskName']
            }
           
        }else{
            return ""
        }
    }

    const onInputChange = (index:any,field:any,val:any) =>{

        console.log(
            "zsdccs",field,val
        )
        if(index!=null){
            var tempInputData:any = currentInputSelected.inputList ? currentInputSelected.inputList : [];
            tempInputData[index][field] = field=="qty" ? Number(val) : val;
           
            setCurrentInputSelected(
               {
                 ...currentInputSelected,
                 ...{
                        inputList : tempInputData
                    }
                }
            );
            console.log(
                "xxxxx",currentInputSelected
            )
        }else{
            var tempInputData:any = currentInputSelected ? currentInputSelected : {};
            tempInputData[field] = field=="qty" ? Number(val) : val;
            setCurrentInputSelected(
                {
                  ...currentInputSelected,
                  ...tempInputData
                }
             );
        }
       
        console.log("setCurrentInputSelected",currentInputSelected);
    }

    const compseBreadCrums=()=>{
        return loadCategoryTaskName(currentInputSelected?.categoryID,"category",false)
        + (currentInputSelected.taskID == undefined  ? '' : " / " +loadCategoryTaskName(currentInputSelected?.taskID,"task",false))
        +" / "+localTimeConversion( currentInputSelected?.inputedAt)
    }

    const loadInputsView=()=>{
        console.log(
            "netQuantity",
            currentInputSelected.netQuantity
        )

        let sampleSets:any=[];

        let qty = currentInputSelected.taskID == undefined ? 
            loadCategoryTaskName(currentInputSelected?.categoryID,"category",true)
            : loadCategoryTaskName(currentInputSelected?.taskID,"task",true)

        for(let i=0;i<Number(currentInputSelected.netQuantity);i++){
            console.log(i);
            sampleSets.push(
               {
                 id:i,
                 uuID: Math.random().toString(36).substring(2,7),
                 customID: null,
                 qty:qty,
                 mappingID:currentInputSelected?.mappingID,
                 categoryID:currentInputSelected?.categoryID,
                 taskID:currentInputSelected?.taskID,
                 fileList:[],
                 fileAccess:[],
               }
            )
        }
      
       

        setCurrentInputSelected({
            ...currentInputSelected,
            ...{inputList: [...sampleSets]}
        });

       

        setNetQtyData([...sampleSets])
    }

    const submitInputs=()=>{
        setFormError(null)
        dispatch(
            productionInput({
                ...{orderID:props.orderID},
                ...currentInputSelected
            })
        )
    }

    const loadDefectTypeName=(id:any)=>{
       
        var tempList = defectTypeList  ? defectTypeList : [];
      
        tempList = tempList?.filter((item:any)=>{
            console.log("tempList", item._id,id);
            return  item._id ==id
        })

        if(tempList.length>0)
           return  tempList[0]['defectTypeName']
        else 
           return "";
    }


    const renderTasksWithMappingID=(mappingID:any,forDefect:any)=>{
        if(forDefect==true){
            let eventListTemp =  eventList ? eventList : []
            eventListTemp = eventListTemp.filter((data:any)=>{
                return data.mappingID==mappingID&&data.taskID!=null
            });
            console.log(
                "eventListTemp",
                eventListTemp
            )
            return <View>
                {
                    eventListTemp.map((element:any) => {
                        return <DataTable.Row onPress={()=>{}}>
                            <DataTable.Cell>{loadCategoryTaskName(element.taskID,'task',false)}</DataTable.Cell>
                            <DataTable.Cell>{element.mappingID}</DataTable.Cell>
                            <DataTable.Cell>
                            <View style={Style.defectStausHolder}>
                                <View style={[Style.pointerView,Style.noDefectPoint]}>
                                    <Text style={Style.pointerTxt}>
                                        {
                                            getDefectCount(element.taskID,element.mappingID,true,0)
                                        }
                                    </Text>
                                </View>
                                <View style={[Style.pointerView,Style.reviewDefect]}>
                                    <Text style={Style.pointerTxt}>
                                        {
                                            getDefectCount(element.taskID,element.mappingID,true,1)
                                        }
                                    </Text>
                                </View>
                                <View style={[Style.pointerView,Style.defectPointer]}>
                                    <Text style={Style.pointerTxt}>
                                        {
                                            getDefectCount(element.taskID,element.mappingID,true,2)
                                        }
                                    </Text>
                                </View>
                            </View>
                        </DataTable.Cell>
                        </DataTable.Row>
                    })
                }
            </View>
        }else{
            let eventListTemp =  defectList ? defectList : []
            eventListTemp = eventListTemp.filter((data:any)=>{
                if(!currentInputSelected.taskID){
                    return data.mappingID==mappingID&&data.categoryID==currentInputSelected.categoryID
                }else{
                    return data.mappingID==mappingID&&data.taskID==currentInputSelected.taskID
                }
            });
            console.log(
                "eventListTemp",selectedCategory,selectedTask,
                eventListTemp
            )
            return <View>
                {
                    eventListTemp.map((element:any) => {
                        return <DataTable.Row onPress={()=>{
                            console.log("currentDefectInput",element);
                            getFileAccessDetails(element.fileList);
                            setSelectedDefect(element);
                            setDefectViewDetails(true);
                        }}>
                            <DataTable.Cell>{loadDefectTypeName(element.defectTypeId)}</DataTable.Cell>
                            <DataTable.Cell>{defectLevelList[element.level-1]}</DataTable.Cell>
                            <DataTable.Cell>{defectStatusList[element.defectStatus]}</DataTable.Cell>
                        </DataTable.Row>
                    })
                }
            </View>
        }
    }

    const resetInputView =()=>{
        setCurrentInputSelected({})
        setFormError(null)
        setMoreInputsView(false)
        setEvent(false)
    }

    const getDefectCount=(id:any,mappingID:any,task:any,statusType:any)=>{

        let defects=defectList.filter((data:any)=>{
            if(task==true){
                if(statusType==0){
                    return data.taskID == id
                    && data.mappingID == mappingID 
                    && data.defectStatus  <=2
                }else{
                    if(statusType==1){
                        return data.taskID == id
                        && data.mappingID == mappingID 
                        && data.defectStatus  == 3
                    }else{
                        return data.taskID == id
                        && data.mappingID == mappingID 
                        && data.defectStatus  == 5
                    }
                }
            }else{
                if(statusType==0){
                    return data.categoryID == id
                    && data.mappingID == mappingID 
                    && data.defectStatus  <=2
                }else{
                    if(statusType==1){
                        return data.categoryID == id
                        && data.mappingID == mappingID 
                        && data.defectStatus  == 3
                    }else{
                        return data.categoryID == id
                        && data.mappingID == mappingID 
                        && data.defectStatus  == 5
                    }
                }
            }
        });

        console.log(
            "qsxsq",
            defects?.length
        )
        return defects?.length;

    }

    const onDefectUpdate = (field:any,val:any) =>{

        var tempInputData:any = currentDefectInput ? currentDefectInput : {};
        tempInputData[field] = val;
        seCurrentDefectInput({
                ...currentDefectInput,
                ...tempInputData,
                ...{
                    categoryID:selectedCategory,
                    taskID:selectedTask,

                }
        });
        console.log("setCurrentInputSelected",currentDefectInput);
    }

    const getFileAccessDetails=(fileList:any)=>{
        let fileId:any=[];
        fileList.forEach((element:any) => {
          fileId.push(element._id);
        });
        console.log("getFileAccessDetails",fileId);
        dispatch(
          fileAccess(
            {
              fileIds:fileId,
              id:"prInputs"
            }
          )
        )
    }

    const saveDefectInput=()=>{
        console.log("selectedTask",selectedTask)
   
        var formData={
            ...selectedDefect,
            ...{
                defectStatus:defectStatus
            }
        }
        
        dispatch(
            inputDefect(formData)
        )
    
    }

    return (
        <View style={Style.mainContent}>
            { 
                formStatus==1 ?
                <View>
                    <CustomAlert title={alertTitle} subTitle={alertSubTitle} onPress={()=>{
                        setFormStatus(0)
                        setFormError(null)
                        setEvent(0)
                    }} />
                </View>
                : <View style={{flexDirection:'row'}}>
                    {
                        addEvent==false ?
                            <View style={{flex:6}}>
                                <View style={{flexDirection:'row',justifyContent:'center',padding:5}}>
                                    <Text style={{flexDirection:'row',alignSelf:'center',padding:5}}>Calendar</Text>
                                    {
                                        yearRender()
                                    }
                                    <Picker style={Style.dropDown}
                                    value={currentMonth}
                                    onValueChange={(itemValue:any, itemIndex:any) =>
                                        setCurrentMonth(itemIndex-1)}>
                                        <Picker.Item label="Select Month" value={0}/>
                                        {
                                            monthList.map((month:any,index:any)=>{
                                                return <Picker.Item label={month} value={index} />
                                            })
                                        }
                                    </Picker>
                                    <Picker style={Style.dropDown}
                                        value={currentCategory}
                                        onValueChange={(itemValue:any, itemIndex:any) =>{setCurrentCategory(itemValue)}}>
                                        <Picker.Item label="Select Category" value={0}/>
                                        {
                                            categoryList?.map((data:any,index:any)=>{
                                                return <Picker.Item label={data.categoryName} value={data._id} />
                                            })
                                        }
                                    </Picker>
                                </View>
                                {
                                    currentMonth!=null&&currentYear ?
                                        <View>
                                            <View style={{borderLeftWidth:3.5,borderLeftColor:'#2f95dc',padding:5}}>
                                                <View style={Style.dateRow}>
                                                    {
                                                        weekday.map((day)=>{
                                                            return <View style={Style.weekNameHolder}>
                                                                <Text style={{ fontWeight:'500', fontSize:20}}>{day}</Text>
                                                            </View>
                                                        })
                                                    }
                                                </View>
                                                <View>
                                                    {   dateList.length!=0 ? 
                                                        dateList.map((weekList:any)=>{
                                                            return <View style={Style.dateRow}>
                                                                {
                                                                    weekList.map((weekList:any)=>{
                                                                        return <TouchableOpacity onPress={()=>{
                                                                                    onDateSelected(weekList.day);
                                                                                }}>
                                                                                    <View style={Style.weekNameHolder}>

                                                                                        {
                                                                                            getEventCount(weekList.day)!=0&&weekList.day !=0 ?
                                                                                                <View style={{alignSelf:'flex-start',flexDirection:'row',alignContent:'space-around'}}>
                                                                                                    <View style={{margin:5,marginBottom:10, height:12,width:12,borderRadius:50,backgroundColor:'#2f95dc'}}>
                                                                                                    </View>
                                                                                                    <Text style={{fontWeight:'500',fontSize:12,marginLeft:5,marginTop:4}}>P - {getEventCount(weekList.day)}</Text>
                                                                                                </View>
                                                                                            : null
                                                                                        }
                                                                                    
                                                                                        <Text style={{
                                                                                            fontWeight:'800',
                                                                                            justifyContent:'center',
                                                                                            alignSelf:'baseline',
                                                                                            fontSize:15}}>{weekList.day !=0 ? weekList.day : null}</Text>
                                                                                    </View>
                                                                            </TouchableOpacity>
                                                                    })
                                                                }
                                                            </View>
                                                        }) : null
                                                    }   
                                                </View> 
                                            </View>
                                        </View>
                                    : null
                                }
                            </View>
                        : null
                    }
                    <View style={{flex: 4,padding:2.5,margin:5,overflow:'scroll'}}>
                        <View style={Style.header}>
                            <View style={Style.headerLeft}>
                                <Text style={Style.headerTitle}>{ 
                                    !addEvent ? 
                                        "Production Input" 
                                        : moreInputsView==false ? 
                                            "Input Production" : compseBreadCrums()
                                    }
                                </Text>
                                <TouchableOpacity style={{flexDirection:'row'}} onPress={()=>{
                                    setInputType(2)
                                    setCurrentInputSelected({})
                                    setFormError(null)
                                    resetInputView()
                                    setAddMoreInputs(false)
                                    setDefectViewDetails(false)
                                    setEvent(!addEvent)}}>
                                    <Image style={Style.addSubLineImg}
                                        source={require("../../assets/images/"+(addEvent? "close" : "plus" )+".png")
                                    }/>
                                    {
                                        !addEvent ?
                                            <Text style={{marginTop:25}}>
                                                Input Production
                                                
                                            </Text>
                                        : null
                                    }
                                </TouchableOpacity>
                            </View>
                            <View style={Style.headerRight}>
                                { addEvent ?
                                      null
                                    : null
                                }
                            </View>
                        </View>
                        {
                            moreInputsView == false ?
                                <View>
                                    {
                                        defectViewDetails == false ?
                                            <View>
                                            { !addEvent ?
                                                <View style={{flex:4,padding:2.5,margin:5}}>
                                                    <View style={{padding:5,paddingTop:0}}>
                                                        <CustomTextInput type="text" id="categoryID" value={""}
                                                            placeHolder="Search category" onInputChange={(ID,val)=>{console.log("sa")}}/>
                                                            <TouchableOpacity onPress={()=>{ setFilteredEventList([...eventList]) }}>
                                                                {
                                                                    eventList?.length!=filteredEventList?.length ?
                                                                        <Text style={{marginLeft:10,margin:10}}>{"Show all"}</Text>
                                                                        : null
                                                                }
                                                            </TouchableOpacity>
                                                        <DataTable>
                                                            <DataTable.Header>
                                                                <DataTable.Title>Category</DataTable.Title>
                                                                <DataTable.Title>Mapping ID</DataTable.Title>
                                                                <DataTable.Title>Defect</DataTable.Title>
                                                            </DataTable.Header>
                                                                {
                                                                    eventList&&filteredEventList.map((data:any)=>{
                                                                        console.log("filteredEventList",currentCategory,data.categoryID)
                                                                    return  currentCategory!=0&&data.categoryID==currentCategory
                                                                                &&!data.taskID ?
                                                                                <DataTable.Row onPress={()=>{
                                                                                    setCurrentInputSelected(data)
                                                                                    setSelectedCategory(data.categoryID)
                                                                                    setInputType(0)
                                                                                    setEvent(!addEvent)}}>
                                                                                    <DataTable.Cell>{loadCategoryTaskName(data.categoryID,"category",false)}</DataTable.Cell>
                                                                                    <DataTable.Cell>{data.mappingID}</DataTable.Cell>
                                                                                    <DataTable.Cell>
                                                                                        <View style={Style.defectStausHolder}>
                                                                                            <View style={[Style.pointerView,Style.noDefectPoint]}>
                                                                                                <Text style={Style.pointerTxt}>
                                                                                                    {
                                                                                                        getDefectCount(data.categoryID,data.mappingID,false,0)
                                                                                                    }
                                                                                                </Text>
                                                                                            </View>
                                                                                            <View style={[Style.pointerView,Style.reviewDefect]}>
                                                                                                <Text style={Style.pointerTxt}>
                                                                                                    {
                                                                                                        getDefectCount(data.categoryID,data.mappingID,false,1)
                                                                                                    }
                                                                                                </Text>
                                                                                            </View>
                                                                                            <View style={[Style.pointerView,Style.defectPointer]}>
                                                                                                <Text style={Style.pointerTxt}>
                                                                                                    {
                                                                                                        getDefectCount(data.categoryID,data.mappingID,false,2)
                                                                                                    }
                                                                                                </Text>
                                                                                            </View>
                                                                                        </View>
                                                                                    </DataTable.Cell>
                                                                                </DataTable.Row>
                                                                            : currentCategory==0&&!data.taskID  ?
                                                                                <DataTable.Row onPress={()=>{
                                                                                    setCurrentInputSelected(data)
                                                                                    setSelectedCategory(data.categoryID)
                                                                                    setInputType(0)
                                                                                    setEvent(!addEvent)}}>
                                                                                    <DataTable.Cell>{loadCategoryTaskName(data.categoryID,"category",false)}</DataTable.Cell>
                                                                                    <DataTable.Cell>{data.mappingID}</DataTable.Cell>
                                                                                    <DataTable.Cell>
                                                                                        <View style={Style.defectStausHolder}>
                                                                                            <View style={[Style.pointerView,Style.noDefectPoint]}>
                                                                                                <Text style={Style.pointerTxt}>
                                                                                                    {
                                                                                                        getDefectCount(data.categoryID,data.mappingID,false,0)
                                                                                                    }
                                                                                                </Text>
                                                                                            </View>
                                                                                            <View style={[Style.pointerView,Style.reviewDefect]}>
                                                                                                <Text style={Style.pointerTxt}>
                                                                                                    {
                                                                                                        getDefectCount(data.categoryID,data.mappingID,false,1)
                                                                                                    }
                                                                                                </Text>
                                                                                            </View>
                                                                                            <View style={[Style.pointerView,Style.defectPointer]}>
                                                                                                <Text style={Style.pointerTxt}>
                                                                                                    {
                                                                                                        getDefectCount(data.categoryID,data.mappingID,false,2)
                                                                                                    }
                                                                                                </Text>
                                                                                            </View>
                                                                                        </View>
                                                                                    </DataTable.Cell>
                                                                                </DataTable.Row>
                                                                            : null
                                                                    })
                                                                }
                                                        </DataTable>
                                                        <DataTable>
                                                            <DataTable.Header>
                                                                <DataTable.Title>Task</DataTable.Title>
                                                                <DataTable.Title>Mapping ID</DataTable.Title>
                                                                <DataTable.Title>Defect</DataTable.Title>
                                                            </DataTable.Header>
                                                                {
                                                                    eventList&&filteredEventList.map((data:any)=>{
                                                                        return currentCategory!=0&&
                                                                            data.categoryID==currentCategory&&data.taskID  ? 
                                                                                    <DataTable.Row onPress={()=>{
                                                                                        setCurrentInputSelected(data)
                                                                                        setSelectedCategory(data.categoryID)
                                                                                        setSelectedTask(data.taskID)
                                                                                        setInputType(1)
                                                                                        setEvent(!addEvent)}}>
                                                                                        <DataTable.Cell>{loadCategoryTaskName(data.taskID,"task",false)}</DataTable.Cell>
                                                                                        <DataTable.Cell>{data.mappingID}</DataTable.Cell>
                                                                                        <DataTable.Cell>
                                                                                        <View style={Style.defectStausHolder}>
                                                                                            <View style={[Style.pointerView,Style.noDefectPoint]}>
                                                                                                <Text style={Style.pointerTxt}>
                                                                                                    {
                                                                                                        getDefectCount(data.taskID,data.mappingID,true,0)
                                                                                                    }
                                                                                                </Text>
                                                                                            </View>
                                                                                            <View style={[Style.pointerView,Style.reviewDefect]}>
                                                                                                <Text style={Style.pointerTxt}>
                                                                                                    {
                                                                                                        getDefectCount(data.taskID,data.mappingID,true,1)
                                                                                                    }
                                                                                                </Text>
                                                                                            </View>
                                                                                            <View style={[Style.pointerView,Style.defectPointer]}>
                                                                                                <Text style={Style.pointerTxt}>
                                                                                                    {
                                                                                                        getDefectCount(data.taskID,data.mappingID,true,2)
                                                                                                    }
                                                                                                </Text>
                                                                                            </View>
                                                                                        </View>
                                                                                    </DataTable.Cell>
                                                                                    </DataTable.Row>
                                                                                : currentCategory==0&&
                                                                                    data.categoryID&&
                                                                                    data.taskID ? 
                                                                                        <DataTable.Row onPress={()=>{
                                                                                        setCurrentInputSelected(data)
                                                                                        setSelectedCategory(data.categoryID)
                                                                                        setSelectedTask(data.taskID)
                                                                                        setInputType(1)
                                                                                        setEvent(!addEvent)}}>
                                                                                        <DataTable.Cell>{loadCategoryTaskName(data.taskID,"task",false)}</DataTable.Cell>
                                                                                        <DataTable.Cell>{data.mappingID}</DataTable.Cell>
                                                                                        <DataTable.Cell>
                                                                                            <View style={Style.defectStausHolder}>
                                                                                                <View style={[Style.pointerView,Style.noDefectPoint]}>
                                                                                                    <Text style={Style.pointerTxt}>
                                                                                                        {
                                                                                                            getDefectCount(data.taskID,data.mappingID,true,0)
                                                                                                        }
                                                                                                    </Text>
                                                                                                </View>
                                                                                                <View style={[Style.pointerView,Style.reviewDefect]}>
                                                                                                    <Text style={Style.pointerTxt}>
                                                                                                        {
                                                                                                            getDefectCount(data.taskID,data.mappingID,true,1)
                                                                                                        }
                                                                                                    </Text>
                                                                                                </View>
                                                                                                <View style={[Style.pointerView,Style.defectPointer]}>
                                                                                                    <Text style={Style.pointerTxt}>
                                                                                                        {
                                                                                                            getDefectCount(data.taskID,data.mappingID,true,2)
                                                                                                        }
                                                                                                    </Text>
                                                                                                </View>
                                                                                            </View>
                                                                                        </DataTable.Cell>
                                                                                        </DataTable.Row>
                                                                                    : null
                                                                    })
                                                                }
                                                        </DataTable>
                                                    </View>
                                                </View>
                                                : <View style={{flex:1}}>
                                                    <View style={{zIndex:1}}>
                                                        {
                                                            inputType != 2 ?
                                                                <View>
                                                                    {
                                                                        inputType==0 ?
                                                                            <View>
                                                                                <Picker style={Style.categoryDropDown}
                                                                                    value={currentInputSelected?.categoryID}
                                                                                    onValueChange={(itemValue:any, itemIndex:any) =>{
                                                                                        setSelectedCategory(itemValue)
                                                                                        onInputChange(null,'categoryID',itemValue)}}>
                                                                                    <Picker.Item label="Select Category" value={0}/>
                                                                                    {
                                                                                        categoryList?.map((category:any,index:any)=>{
                                                                                            return <Picker.Item label={category.categoryName} value={category._id} />
                                                                                        })
                                                                                    }
                                                                                </Picker>
                                                                                {
                                                                                    currentInputSelected.categoryID ?
                                                                                        <DatePicker label={"Scheduled On"} placeholder={"Production Start Date"}
                                                                                        date={currentInputSelected?.inputedAt} layerIndex={4} 
                                                                                        onDateSelected={(date)=>{onInputChange(null,'inputedAt',date)}}/>
                                                                                    : null
                                                                                }
                                                                                { 
                                                                                    currentInputSelected?.inputedAt && !currentInputSelected._id?
                                                                                        <TouchableOpacity style={{flexDirection:'row'}} onPress={()=>{
                                                                                            setAddMoreInputs(!addMoreInputs)
                                                                                        }}>
                                                                                            <Image style={Style.addSubLineImg}
                                                                                                source={require("../../assets/images/"+(addMoreInputs==true ? "close" : "plus" )+".png")
                                                                                            }/>
                                                                                            <Text style={Style.addSubLineTile}>Add production input for selected Date</Text>
                                                                                        </TouchableOpacity>
                                                                                    :   <View style={Style.defectView}>
                                                                                        <TouchableOpacity style={{flexDirection:'row'}} onPress={()=>{
                                                                                            setViewDefectList(!defectViewList)}}>
                                                                                            <Image style={{margin:10,height:35,width:35}}
                                                                                            source={require("../../assets/images/defect.png")}/>
                                                                                            {
                                                                                                <Text style={{marginTop:25}}>View Defect Status</Text>
                                                                                            }
                                                                                        </TouchableOpacity>
                                                                                    </View>
                                                                                }
                                                                                {
                                                                                    addMoreInputs != true && currentInputSelected._id!=undefined && defectViewList==false?
                                                                                        <View>
                                                                                            <DataTable.Row>
                                                                                                <DataTable.Cell>Task Name</DataTable.Cell>
                                                                                                <DataTable.Cell>Mapping ID</DataTable.Cell>
                                                                                                <DataTable.Cell>Defect status</DataTable.Cell>
                                                                                            </DataTable.Row>
                                                                                            {
                                                                                                renderTasksWithMappingID(currentInputSelected.mappingID,true)
                                                                                            }
                                                                                        </View>
                                                                                    :  defectViewList==true ? 
                                                                                        <View>
                                                                                            <DataTable.Row>
                                                                                                <DataTable.Cell>Defect Category</DataTable.Cell>
                                                                                                <DataTable.Cell>Defect Level</DataTable.Cell>
                                                                                                <DataTable.Cell>Status</DataTable.Cell>
                                                                                            </DataTable.Row>
                                                                                            {
                                                                                                renderTasksWithMappingID(currentInputSelected.mappingID,false)
                                                                                            }
                                                                                        </View>
                                                                                    : <View>
                                                                                        {
                                                                                        addMoreInputs == true && currentInputSelected._id == undefined ?
                                                                                                <View>
                                                                                                    <CustomTextInput type="text" id="categoryQuantity" 
                                                                                                    value={  currentInputSelected?.netQuantity }
                                                                                                    placeHolder="Category Qty"
                                                                                                    onInputChange={(ID,val)=>{onInputChange(null,'netQuantity',val)}} />
                                                                                                    <CustomButton buttonID={"Submit"} type="common"
                                                                                                    title="Create" onSubmit={()=>{
                                                                                                        loadInputsView()
                                                                                                        setMoreInputsView(true)
                                                                                                    }}/>
                                                                                                </View>
                                                                                            : null
                                                                                        }
                                                                                    
                                                                                    </View>
                                                                                }
                                                                            </View>
                                                                            :  inputType==1 ?
                                                                                    <View>
                                                                                        <Picker style={Style.categoryDropDown}
                                                                                            value={currentInputSelected?.categoryID}
                                                                                            onValueChange={(itemValue:any, itemIndex:any) =>{
                                                                                            setSelectedCategory(itemValue)
                                                                                            onInputChange(null,'categoryID',itemValue)}
                                                                                            }>
                                                                                            <Picker.Item label="Select Category" value={0}/>
                                                                                            {
                                                                                                categoryList?.map((category:any,index:any)=>{
                                                                                                    return <Picker.Item label={category.categoryName} value={category._id} />
                                                                                                })
                                                                                            }
                                                                                        </Picker>
                                                                                        {
                                                                                            currentInputSelected?.categoryID != undefined ?
                                                                                                <Picker style={Style.categoryDropDown}
                                                                                                    value={currentInputSelected?.taskID}
                                                                                                    onValueChange={(itemValue:any, itemIndex:any) =>{
                                                                                                        setSelectedTask(itemValue)
                                                                                                        onInputChange(null,'taskID',itemValue)}
                                                                                                    }>
                                                                                                    <Picker.Item label="Select Task" value={0}/>
                                                                                                    {
                                                                                                        taskList?.map((task:any,index:any)=>{
                                                                                                            return task.categoryID == currentInputSelected.categoryID ?
                                                                                                                    <Picker.Item label={task.taskName} value={task._id} />
                                                                                                                : null
                                                                                                        })
                                                                                                    }
                                                                                                </Picker>
                                                                                            : null
                                                                                        }
                                                                                        {
                                                                                            currentInputSelected.categoryID&&
                                                                                            currentInputSelected.taskID ?
                                                                                                <DatePicker label={"Scheduled On"} placeholder={"Production Start Date"}
                                                                                                date={currentInputSelected?.inputedAt} layerIndex={4} 
                                                                                                onDateSelected={(date)=>{onInputChange(null,'inputedAt',date)}}/>
                                                                                            : null
                                                                                        }
                                                                                    { currentInputSelected?.inputedAt&&currentInputSelected._id==undefined?
                                                                                        <TouchableOpacity style={{flexDirection:'row'}} onPress={()=>{
                                                                                            setAddMoreInputs(!addMoreInputs)
                                                                                        }}>
                                                                                            <Image style={Style.addSubLineImg}
                                                                                                source={require("../../assets/images/"+(addMoreInputs==true ? "close" : "plus" )+".png")
                                                                                            }/>
                                                                                            <Text style={Style.addSubLineTile}>Add production input for selected Date</Text>
                                                                                        </TouchableOpacity>
                                                                                    : null

                                                                                }
                                                                                <View>
                                                                                    <DataTable.Row>
                                                                                        <DataTable.Cell>Defect Category</DataTable.Cell>
                                                                                        <DataTable.Cell>Defect Level</DataTable.Cell>
                                                                                        <DataTable.Cell>Status</DataTable.Cell>
                                                                                    </DataTable.Row>
                                                                                    {
                                                                                        renderTasksWithMappingID(currentInputSelected.mappingID,false)
                                                                                    }
                                                                                </View>
                                                                                {
                                                                                addMoreInputs != true ?
                                                                                    null
                                                                                :   <View>
                                                                                        
                                                                                        <CustomTextInput type="text" id="categoryQuantity" 
                                                                                        value={  currentInputSelected?.netQuantity }
                                                                                        placeHolder="Task Qty" 
                                                                                        onInputChange={(ID,val)=>{onInputChange(null,'netQuantity',val)}} />
                                                                                        <CustomButton buttonID={"Submit"} type="common"
                                                                                        title="Create" onSubmit={()=>{
                                                                                            loadInputsView()
                                                                                            setMoreInputsView(true)
                                                                                        }}/>
                                                                                    </View>
                                                                                }
                                                                                    </View>
                                                                            : null
                                                                    }
                                                                </View>
                                                            : <View style={{margin:2,padding:5,}}>
                                                                <TouchableOpacity onPress={()=>{setInputType(0)}}>
                                                                    <View style={{margin:2,backgroundColor:'#f7f9fa',minWidth:320,height:150,justifyContent:'center'}}>
                                                                            <Text style={{alignSelf:'center',fontSize:18}}>Category Input</Text>
                                                                    </View>
                                                                </TouchableOpacity>
                                                                <TouchableOpacity onPress={()=>{setInputType(1)}}>
                                                                    <View style={{margin:2,backgroundColor:'#f7f9fa',minWidth:320,height:150,justifyContent:'center'}}>
                                                                            <Text style={{alignSelf:'center',fontSize:18}}>Task Input</Text>
                                                                    </View>
                                                                </TouchableOpacity>
                                                            </View>
                                                        }
                                                    </View>
                                                </View>}
                                            </View>
                                        :   <View>
                                                <Ionicons name="arrow-back"  style={Style.backIcon} onPress={()=>{
                                        
                                                    setDefectViewDetails(false)
                                                    setEvent(true)
                                                }}/> 
                                                <View style={{margin:10}}>
                                                    <Text style={{margin:5}}>
                                                    Level : {
                                                         selectedDefect.level==1 ? 'High' 
                                                            : selectedDefect.level==2 ? 'Medium'
                                                                : selectedDefect.level==3 ? 'Low'
                                                                    : selectedDefect.level==4 ? 'Informable'
                                                                    : null
                                                        }
                                                    </Text>
                                                    <Text style={{margin:5}}>
                                                    Defect Type :  {
                                                        selectedDefect.defectTypeName
                                                    }
                                                    </Text>
                                                    {
                                                        currentInputSelected.categoryID ? 
                                                            <Text style={{margin:5}}>
                                                                Category :  {loadCategoryTaskName( currentInputSelected.categoryID,'category',false)}
                                                            </Text>
                                                        : null
                                                    }
                                                    {
                                                         currentInputSelected.taskID ? 
                                                            <Text style={{margin:5}}>
                                                                Task :  {loadCategoryTaskName( currentInputSelected.taskID,'task',false)}
                                                            </Text>
                                                        : null
                                                    }
                                                    {
                                                        selectedDefect.desc ? 
                                                            <Text style={{margin:5}}>
                                                                Description :  {selectedDefect.desc}
                                                            </Text>
                                                        : null
                                                    }
                                                </View>
                                                {
                                                    selectedDefect.defectStatus == 3 ?
                                                        <View style={Style.productionView}>
                                                            <View style={Style.defectMarker}>
                                                                <CheckBoxButton  id={null}
                                                                isChecked={()=>{setDefectStatus(4)}}
                                                                state={defectStatus==4}/>
                                                                <Text style={Style.defectMarkerLabel}>QC Review</Text>
                                                            </View>
                                                        </View>
                                                    : null
                                                }
                                                
                                                <FileHandler isLargeScreen={props.isLargeScreen} 
                                                    documentList={selectedDefect.fileList}
                                                    orderID={props.orderID}
                                                    fileUploadAccess={true}
                                                    fileAccess={fileAccessDetails}
                                                    id={"ProINFiles"}
                                                    updateUserAccess = {(fileAccess)=>{onDefectUpdate("fileAccess",fileAccess.value)}}
                                                    deletedFileList = {(fileAccess)=>{onDefectUpdate("fileDeleted",fileAccess.value)}}
                                                updatedFileList={(updatedList)=>{onDefectUpdate("fileList",updatedList.value)}}/>
                                                {
                                                    selectedDefect.defectStatus == 3 ?
                                                        <View style={Style.footer}>
                                                            <View style={{justifyContent:'center',flex:1}}>
                                                                {   formError!=null ?
                                                                        <CustomLabel type="formErrorMsg" label={""} errorMsg={formError}
                                                                        formRawData={null} successMsg={null}/>
                                                                    : null
                                                                }
                                                                <CustomButton buttonID={"Submit"} type="common"
                                                                title="Submit updates" onSubmit={()=>{
                                                                    saveDefectInput()
                                                                    setMoreInputsView(true)
                                                                }}/>
                                                            </View>
                                                        </View>
                                                    : null
                                                }
                                            </View>       
                                    }
                                </View>
                            :  <View>
                                {
                                    currentInputSelected.inputList?.map((ele:any,index:any)=>{
                                        return <View style={Style.inputView}>
                                        <View><Text style={Style.uuidLabel}>{ele.uuID}</Text></View>
                                        <View style={Style.inputTxtHolder}>

                                            <CustomTextInput type="number" id={"id"} 
                                            value={ele.customID}
                                            placeHolder="Custom ID" onInputChange={(ID,val)=>{onInputChange(index,"customID",val)}} />
                                            <CustomTextInput type="number" id={"qty"} 
                                            value={ele.qty}
                                            placeHolder="Qty" onInputChange={(ID,val)=>{onInputChange(index,ID,val)}} /> 

                                        </View>
                                        <View>
                                        <FileHandler isLargeScreen={props.isLargeScreen} 
                                              documentList={ele.fileList}
                                              orderID={props.orderID}
                                              fileUploadAccess={true}
                                              fileAccess={ele.fileAccess}
                                              id={ele.uuID+"pro-input-files"}
                                              updateUserAccess = {(fileAccess)=>{onInputChange(index,"fileAccess",fileAccess.value)}}
                                              deletedFileList = {(fileAccess)=>{onInputChange(index,"fileDeleted",fileAccess.value)}}
                                              updatedFileList={(updatedList)=>{onInputChange(index,"fileList",updatedList.value)}}/>
                                        </View>
                                    </View>
                                    })
                                }
                                 <View style={Style.footer}>
                                    <View style={{justifyContent:'center',flex:1}}>
                                        {   formError!=null ?
                                                <CustomLabel type="formErrorMsg" label={""} errorMsg={formError}
                                                formRawData={null} successMsg={null}/>
                                            : null
                                        }
                                        <CustomButton buttonID={"Submit"} type="common"
                                        title="Create" onSubmit={()=>{
                                            submitInputs()
                                            setMoreInputsView(true)
                                        }}/>
                                    </View>
                                </View>
                               </View>
                        }
                    </View>
                </View>
            }
        </View>
    )
}

export default ProductionInput;