import { StyleSheet } from "react-native";
import Colors from "../../constants/Colors"; 

export const Style=StyleSheet.create({
    mainContent:{
        flex:1,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 10,
        elevation: 3,
    },
    errorText:{
        display:'none',
        color:'red',
        marginTop:15,
        margin:10,
        alignSelf:'flex-start',
    },
    container:{
        flexDirection:'column',
    },
    contentHolder:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-evenly'
    },
    mobileContentHolder:{
        flexDirection:'column',
    },
    footer:{
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 1,
        marginBottom:0,
        position:'relative',
        flexDirection:'row',
        padding:2,
        zIndex:-1
    },
    divView:{
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 1,
        marginBottom:0,
        position:'relative',
        flexDirection:'column',
        overflow:'scroll'
    },
    groupContainer:{
       padding:10,
    },
    header:{
        flex:1,
        height:100,
        flexDirection:'row',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.05,
        shadowRadius: 0,
        elevation: 1,
    },
    headerLeft:{
        flex:5,
        flexDirection:'column',
    },
    headerRight:{
        flex:5,
        flexDirection:'column',
    },
    headerTitle:{
        fontSize:15,
        fontWeight:'500',
        margin:25
    },
    headerRightIocns:{
        height:30,
        width:30,
        margin:15,
        marginRight:25,
        alignSelf:'flex-end'
    },
    headerLeftIocns:{
        height:30,
        width:30,
        margin:20,
        marginRight:2,
        alignSelf:'flex-end'
    },
    categoryView:{
        flex:1,
        flexDirection:'row',
        zIndex:2
    },
    toolBar:{
        flex:1,
        padding:10,
        backgroundColor:'#f7f9fa'
    },
    toolBarImg:{
        height:30,
        width:30,
        alignSelf:'flex-end'
    },
    topBarRight:{
        flex:1,
        alignSelf:'flex-start',
        flexDirection:'row'
    },
    topBarLeft:{
        flex:1,
        alignSelf:'flex-end'
    },
    dropDown:{
        height:45,
        width:250,
        borderRadius:5,
    },
    table:{
        padding:10,
    },
    pagenation:{
        bottom:10,
        height:60,
        flexGrow:1,
        padding:15,
        alignSelf:'center',
        position:'absolute',
        justifyContent:'center',
        flexDirection:'row'
    },
    pagenationDropdown:{
        height:35,
        width:60,
        borderRadius:4,
    },
    pagenationArrow:{
        margin:8,
        padding:5,
        height:15,
        width:15,
    },
    deleteCurmIcons:{
        marginBottom:5,
        marginRight:15,
        width:25,
        height:25,
    },
});