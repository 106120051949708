import { StyleSheet } from "react-native";


const Style=StyleSheet.create({
    container:{
        flex:1,
        margin:5,
        padding:10,
    },
    fileSelectContent:{
        flexGrow:1,
        height:100,
        backgroundColor:'gray',
        justifyContent:'center',
        alignItems:'center'
    },
    uploadText:{
        color:'white',
        fontSize:15,
    },
    fileListHolder:{
        flex:1,
        flexWrap:'wrap',
        overflow:'scroll',
        flexDirection:'column'
    },
    fileCurms:{
        margin:5,
        padding:10,
        height:40,
        alignSelf:'baseline',
        flexDirection:'row',
        borderColor:'#7ea8e0',
        borderBottomWidth:.7,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.2,
        shadowRadius: 10,
        elevation: 7,
    },
    fileTitle:{
        maxWidth:150,
        overflow:'scroll'
    },
    accessCurmIcons:{
        marginLeft:5,
        width:25,
        height:25,
    },
    downloadCurmIcons:{
        marginLeft:5,
        marginTop:2,
        width:20,
        height:20,
    },
    deleteCurmIcons:{
        marginLeft:5,
        marginTop:2,
        width:15,
        height:15,
    },
});

export default Style;