import { StyleSheet } from "react-native";

const Style=StyleSheet.create({
    container:{
        flexDirection:'row',
        padding:5,
        backgroundColor:"#228FF3",
        maxHeight:50,
    },
    containerLeft:{
        flexDirection:'row',
        alignSelf:'flex-start',
        padding:5,
    },
    containerRight:{
        flexDirection:'row',
        alignSelf:'flex-start',
        padding:20,
    },
    activityTitle:{
        margin:5,
        fontSize:18,
        color:'white',
        fontWeight:'700',
    },
    menuIcon:{
        marginTop:2,
        color:'white',
        fontSize:25
    },
    createProducts:{
        alignSelf:'flex-end',
        with:200,
        height:45,
        borderColor:'white',
    },
    filterOptions:{
        flexDirection:'row',
        padding:5,
        paddingTop:5
    },
    dropDown:{
        margin:10,
        marginRight:15,
        height:48,
        width:'30%',
        borderRadius:2,
    },
    filterView:{
     marginTop:15,
     marginLeft:12,
     flexDirection: 'row',
    },
    wrapView:{
        flexDirection: 'row',
        flexWrap: 'wrap',
        backgroundColor:'green'
    },
    footer:{
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 1,
        marginBottom:0,
        position:'relative',
        flexDirection:'row',
        padding:2,
        zIndex:-1
    },
    defectLevelHolders:{
        margin:10,
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'stretch'
    },
    defectLevelCrums:{
        margin:10,
        height:100,
        width:200,
        justifyContent:'center',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 1,
        marginBottom:0,
        position:'relative',
        flexDirection:'column',
        alignSelf:'center',
        borderBottomWidth:5,
    },
    defectHighIndicator:{
        borderBottomColor:'red'
    },
    defectLevelValue:{
        fontWeight:'600',
        fontSize:14,
        alignSelf:'center'
    },
    defectLevelLable:{
        fontWeight:'600',
        fontSize:14,
        alignSelf:'center'
    },
    fileViewTabls:{
        marginTop:10,
        flexDirection:'row'
    },
    fileViewBtn:{
        height:40,
        width:180,
        justifyContent:'center',
        backgroundColor:'#dce0dd'
    },
    activeFileViewBtn:{
        justifyContent:'center',
        backgroundColor:'#248af0'
    },
        orderInfo:{
        padding:10,
        width:'100%',
        marginBttom:10,
        borderLeftStyle:'soild',
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.2,
        shadowRadius: 2,
        justifyContent:'center'
    },
    fileBtnLabel:{
        color:'black',
        alignSelf:'center'
    },
    activeFileBtnLabel:{
        color:'white',
        alignSelf:'center'
    },
    viewMainTitle:{
        margin:10,
        fontWeight:'500',
    },
    orderAgendaList:{
        flex:1,
        flexWrap:'wrap',
        flexDirection:'row'
    },
    equalSapce:{
        flexGrow:1,
    },
    agendaBreadCrums:{
        margin:5,
        height:50,
        flexDirection:'row',
        borderLeftStyle:'soild',
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.2,
        shadowRadius: 2,
    },
    defectMarker:{
        alignSelf:'center',
        margin:10,
        padding:10
    },
    defectMarkerLabel:{
        alignSelf:'center'
    },
    resPersonDrop:{
        margin:10,
        borderRadius:5,
        height:55,
    }
});

export default Style;