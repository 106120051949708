
import AsyncStorage from '@react-native-async-storage/async-storage';

export const getAccessToken =async()=>{
    try {
        const value:String|null = await AsyncStorage.getItem('@storage_Key')
        if(value !== null) {
        // value previously stored
          return {
            status:"1",
            accessToken:value?.replaceAll("\"","")
          }
        }else{
          return {
            status:"0",
            accessToken:null
          }
        }
    } catch(e) {
        // error reading value
        return {
          status:"0",
          accessToken:null
        }
    }
}

export const storeAccessToken = async (value:any) => {
    try {
      const jsonValue = JSON.stringify(value)
      await AsyncStorage.setItem('@storage_Key', jsonValue).then(()=>{
        return {
          status:"1",
          accessToken:null
        }
      })
    } catch (e) {
      // saving error
      return {
        status:"0",
        accessToken:null
      }
    }
}


export const getUserDetails =async()=>{
  try {
      const value:String|null = await AsyncStorage.getItem('@suser_details')
      if(value !== null) {
      // value previously stored
        return {
          status:"1",
          userDetails:value
        }
      }else{
        return {
          status:"0",
          accessToken:null
        }
      }
  } catch(e) {
      // error reading value
      return {
        status:"0",
        accessToken:null
      }
  }
}

export const storeUserDetails= async (value:any) => {
  try {
    const jsonValue = JSON.stringify(value)
    await AsyncStorage.setItem('@suser_details', jsonValue).then(()=>{
      return {
        status:"1",
        userDetails:null
      }
    })
  } catch (e) {
    // saving error
    return {
      status:"0",
      accessToken:null
    }
  }
}

export const logoutUser = async () => {
  try {
    await storeAccessToken("").then(()=>{
      return true;
    })
  } catch (e) {
    // saving error
    return false;
  }
}

export const generateColor = () => {
  const randomColor = Math.floor(Math.random() * 16999219)
    .toString(16)
    .padStart(6, '0');
  return `#${randomColor}`;
};

export const generateRandomNumber=(withn:any)=>
{
 return  Math.floor(Math.random() * withn) + 1 ;
}

export const localTimeConversion=(date:any)=>{
  var localDate = new Date(date);
  const monthList:any = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sept","Octr","Nov","Dec"];
  return monthList[localDate.getMonth()]+" "+localDate.getDay()+" "+localDate.getFullYear()
}