import React,{FC,useState,useEffect} from "react";
import {View,Text} from "react-native";
import { useSelector, useDispatch } from 'react-redux';
import CustomTextInput from "../CustomTextInput";
import CustomLabel from "../CustomLabel";
import CustomButton from "../CustomButton";
import { customTextInputList,customTextList,customBtnList } from "../../constants/FormCollection/index";
import fromValidation from "../../validation";
interface Props{
    formElementData:Array<any>;
    formData:any;
    erroMsg:Array<any>;
    submitForm:(formData:any)=>void;
    successCallBack:(formData:any)=>void;
    formID:any;
}
const FormMaker:FC<Props>=(props)=>{
 
    const dispatch = useDispatch();
    const [formData,setFormData] = useState<any>();
    const [erroMsg,setErroMsg] = useState<any>(props.erroMsg);
    const {
        signupResponse,
        otpResponse,
        loginResponse,
        forgetPinOtpResponse,
        forgetPinResponse,
        apiResponse
    } = useSelector((state:any) => state.userReducer);
    useEffect(()=>{
        console.log("signupResponse",forgetPinResponse,forgetPinOtpResponse,signupResponse,
            otpResponse,
            loginResponse,
            apiResponse)
        try { 
            if(signupResponse&&signupResponse.status=="fail"){
                setErroMsg(signupResponse.error)
            }else{
                if(signupResponse!=undefined&&props.formID=="signUp")
                    props.successCallBack(signupResponse);
            }
            if(otpResponse&&otpResponse.status=="fail"){
                setErroMsg(otpResponse.error)
            }else{
                console.log("otpResponse",otpResponse);
                if(otpResponse!=undefined&&props.formID=="otp")
                    props.successCallBack(otpResponse);
            }
            if(loginResponse&&loginResponse.status=="fail"){
                setErroMsg(loginResponse.error)
            }else{
                console.log("loginResponse",loginResponse);
                if(loginResponse!=undefined&&props.formID=="login")
                    props.successCallBack(loginResponse);
            }
            if(forgetPinOtpResponse&&forgetPinOtpResponse.status=="fail"){
                setErroMsg(forgetPinOtpResponse.error)
            }else{
                console.log("forgetPinOtpResponse",forgetPinOtpResponse);
                if(forgetPinOtpResponse!=undefined&&props.formID=="forgetPin")
                    props.successCallBack(forgetPinOtpResponse);
            }
           
            if(forgetPinResponse&&forgetPinResponse.status=="fail"){
                setErroMsg(forgetPinOtpResponse.error)
            }else{
                console.log("forgetPinResponse",forgetPinResponse,props.formID);
                if(forgetPinResponse!=undefined&&props.formID=="otp")
                    props.successCallBack(forgetPinResponse);
            }
           
            if(props.formData!=null&&props.formData!=undefined){
                setFormData(props.formData);
            }
            
        } catch (
            error
        ) {
            console.log("formError",error);
        }
       
    },[ apiResponse,
        signupResponse,
        otpResponse,
        loginResponse,
        forgetPinOtpResponse,
        forgetPinResponse,
        props.formData
    ])

    const onInputChange=(id:string,val:any)=>{
         var tempObj=formData!=null ? formData : {} ;
         tempObj[id]=val;
         setErroMsg(null);
         setFormData(tempObj);
    }

    const onSubmit=(btnID:String)=>{
        setErroMsg(null);
        let btnProps=props.formElementData.filter((data)=>data.id==btnID);
        if(btnProps[0].validation==true){
           var validation= fromValidation(props.formElementData,formData);
           if(validation.status==0)
                props.submitForm(formData);
            else
                setErroMsg(validation.errorMsg[0]["msg"]);
               
        }else{
            props.submitForm(formData);
        } 
    }
    return(
        <View>
            {
                props.formElementData.map((formData)=>{
                    if(customTextList.indexOf(formData.type)!=-1) 
                        return <CustomLabel  formRawData={formData} type={formData.type}
                        label={formData.value} errorMsg={erroMsg ? erroMsg :null}/>
                    if(customTextInputList.indexOf(formData.type)!=-1) 
                        return <CustomTextInput id={formData.id} type={formData.type}   
                        placeHolder={formData.placeHolder}
                        value={formData.value}
                        onInputChange={(id,value)=>{onInputChange(id,value)}}/>
                    if(customBtnList.indexOf(formData.type)!=-1) 
                        return <CustomButton buttonID={formData.id} type={formData.type}   
                        title={formData.title}
                        onSubmit={(buttonID)=>{onSubmit(buttonID)}}/>
                })
            }
        </View>
    )
}

export default FormMaker;