import {
    USER_SIGNUP_REQUEST,
    CLEAR_SERVER_REPO,
    VERIFY_OTP,
    RESEND_OTP,
    UPDATE_PLAN_DETAILS,
    GET_USER_DETAILS,
    LOGIN_USER,
    FORGET_PASSWORD,
    FORGET_PASSWORD_OTP,
    SAVE_FILE_REQUEST,
    IS_ACTIVE_DATEPICKER,
    CREATE_ORDER_REQUEST,
    GET_ORDER_LIST_REQUEST,
    GET_ORDER_DETAILS_REQUEST,
    CREATE_GROUP,
    CREATE_STAFF,
    GET_STAFF_LIST,
    GET_STAFF_DETAILS,
    GET_STAFF_LIST_BY_ID,
    CREATE_STOCKS,
    GET_STOCKS_LIST,
    CREATE_CATEGORY,
    GET_CATEGORY_LIST,
    CREATE_TASK,
    GET_TASK_LIST,
    CREATE_OPU,
    GET_OPU_LIST,
    CREATE_AGENDA,
    GET_AGENDA_LIST,
    CREATE_CALENDAR_EVENT,
    GET_CALENDAR_EVENT_LIST,
    PRODUCTION_INPUT,
    GET_INPUT_LIST,
    CREATE_DEFECT_TYPE,
    GET_DEFECT_TYPE_LIST,
    INPUT_DEFECT,
    INPUT_DEFECT_LIST,
    GET_GROUP_LIST,
    GET_FILE_ACCESS_LIST,
    UPDATE_ORDER_USER_LIST,
    GET_ORDER_USER_LIST,
    GET_ORDER_STOCK_USAGE_LIST,
    GET_OVERALL_REPORT,
    GET_FILTER_DETAILS,
    GET_ACTUAL_REPORT_DETAILS
} from "./constant"


export const setUserMail = (formData:any) => (dispatch:any) => {
    dispatch({
        type: GET_USER_DETAILS,
        payload: formData,
    });
};

export const getUserDetails = (formData:any) => (dispatch:any) => {
    console.log("dispatchWorkning",formData);
    dispatch({
        type: GET_USER_DETAILS,
        payload: formData,
    });
};

export const userSignup = (formData:any) => (dispatch:any) => {
    dispatch({
        type: USER_SIGNUP_REQUEST,
        payload: formData,
    });
};

export const verifyOTP = (formData:any) => (dispatch:any) => {
    console.log("resendOtpCall","");
    dispatch({
        type: VERIFY_OTP,
        payload: formData,
    });
};

export const resendOTP = (formData:any) => (dispatch:any) => {
    console.log("resendOtpCall","");
    dispatch({
        type: RESEND_OTP,
        payload: formData,
    });
};


export const updatePlanDetails = (formData:any) => (dispatch:any) => {
    dispatch({
        type: UPDATE_PLAN_DETAILS,
        payload: formData,
    });
};

export const loginUser = (formData:any) => (dispatch:any) => {
    dispatch({
        type: LOGIN_USER,
        payload: formData,
    });
};

export const forgetOTP = (formData:any) => (dispatch:any) => {
    console.log("resendOtpCall","");
    dispatch({
        type: FORGET_PASSWORD_OTP,
        payload: formData,
    });
};

export const forgetPassword = (formData:any) => (dispatch:any) => {
    dispatch({
        type: FORGET_PASSWORD,
        payload: formData,
    });
};

export const saveDocuments = (formData:any) => (dispatch:any) => {
    dispatch({
        type: SAVE_FILE_REQUEST,
        payload: formData,
    });
};

export const saveOrderBasicInfo = (formData:any) => (dispatch:any) => {
    dispatch({
        type: CREATE_ORDER_REQUEST,
        payload: formData,
    });
};


export const getOrderList = (formData:any) => (dispatch:any) => {
    dispatch({
        type: GET_ORDER_LIST_REQUEST,
        payload: formData,
    });
};

export const getOrderDetails = (formData:any) => (dispatch:any) => {
    dispatch({
        type: GET_ORDER_DETAILS_REQUEST,
        payload: formData,
    });
};

export const createGroup = (formData:any) => (dispatch:any) => {
    dispatch({
        type: CREATE_GROUP,
        payload: formData,
    });
};

export const createStaff = (formData:any) => (dispatch:any) => {
    dispatch({
        type: CREATE_STAFF,
        payload: formData,
    });
};

export const getStaffList = (formData:any) => (dispatch:any) => {
    dispatch({
        type: GET_STAFF_LIST,
        payload: formData,
    });
};

export const getStaffDetailsById = (formData:any) => (dispatch:any) => {
    dispatch({
        type: GET_STAFF_LIST_BY_ID,
        payload: formData,
    });
};

export const getStaffDetails = (formData:any) => (dispatch:any) => {
    dispatch({
        type: GET_STAFF_LIST,
        payload: formData,
    });
};

export const clearRepo = () => (dispatch:any) => {
    dispatch({
        type: GET_STAFF_DETAILS,
        payload: null,
    });
};

export const createStock = (formData:any) => (dispatch:any) => {
    dispatch({
        type:CREATE_STOCKS ,
        payload: formData,
    });
};

export const getStockList = (formData:any) => (dispatch:any) => {
    dispatch({
        type:GET_STOCKS_LIST ,
        payload: formData,
    });
};

export const createCategory = (formData:any) => (dispatch:any) => {
    dispatch({
        type:CREATE_CATEGORY ,
        payload: formData,
    });
};

export const getCategoryList = (formData:any) => (dispatch:any) => {
    dispatch({
        type:GET_CATEGORY_LIST ,
        payload: formData,
    });
};

export const createTask = (formData:any) => (dispatch:any) => {
    dispatch({
        type:CREATE_TASK ,
        payload: formData,
    });
};

export const getTaskList = (formData:any) => (dispatch:any) => {
    dispatch({
        type:GET_TASK_LIST ,
        payload: formData,
    });
};


export const createOPU = (formData:any) => (dispatch:any) => {
    dispatch({
        type:CREATE_OPU ,
        payload: formData,
    });
};

export const getOPUList = (formData:any) => (dispatch:any) => {
    dispatch({
        type:GET_OPU_LIST ,
        payload: formData,
    });
};
/// utility
export const createAGENDA = (formData:any) => (dispatch:any) => {
    dispatch({
        type:CREATE_AGENDA ,
        payload: formData,
    });
};
export const getAgendaList = (formData:any) => (dispatch:any) => {
    dispatch({
        type:GET_AGENDA_LIST ,
        payload: formData,
    });
};
export const createCalendarEvent = (formData:any) => (dispatch:any) => {
    dispatch({
        type:CREATE_CALENDAR_EVENT ,
        payload: formData,
    });
};
export const getCalendarEventLisEvent = (formData:any) => (dispatch:any) => {
    dispatch({
        type:GET_CALENDAR_EVENT_LIST,
        payload: formData,
    });
};

export const productionInput = (formData:any) => (dispatch:any) => {
    dispatch({
        type:PRODUCTION_INPUT ,
        payload: formData,
    });
};

export const productionInputList = (formData:any) => (dispatch:any) => {
    dispatch({
        type:GET_INPUT_LIST ,
        payload: formData,
    });
};

export const createDefectType = (formData:any) => (dispatch:any) => {
    dispatch({
        type:CREATE_DEFECT_TYPE ,
        payload: formData,
    });
};

export const getDefectTypeLists = (formData:any) => (dispatch:any) => {
    dispatch({
        type:GET_DEFECT_TYPE_LIST ,
        payload: formData,
    });
};

export const inputDefect = (formData:any) => (dispatch:any) => {
    dispatch({
        type:INPUT_DEFECT ,
        payload: formData,
    });
};

export const inputDefectList = (formData:any) => (dispatch:any) => {
    dispatch({
        type:INPUT_DEFECT_LIST ,
        payload: formData,
    });
};

export const getGroupList = (formData:any) => (dispatch:any) => {
    dispatch({
        type: GET_GROUP_LIST,
        payload: formData,
    });
};

export const updateOrderUserList = (formData:any) => (dispatch:any) => {
    dispatch({
        type: UPDATE_ORDER_USER_LIST,
        payload: formData,
    });
};

export const getOrderUserList = (formData:any) => (dispatch:any) => {
    dispatch({
        type: GET_ORDER_USER_LIST,
        payload: formData,
    });
};

export const fileAccess = (formData:any) => (dispatch:any) => {
    dispatch({
        type: GET_FILE_ACCESS_LIST,
        payload: formData,
    });
};

export const isActiveDatePick = (formData:any) => (dispatch:any) => {
    dispatch({
        type: IS_ACTIVE_DATEPICKER,
        payload: formData,
    });
};

export const getOrderStockUsage = (formData:any) => (dispatch:any) => {
    dispatch({
        type: GET_ORDER_STOCK_USAGE_LIST,
        payload: formData,
    });
};

export const getOverAllReport = (formData:any) => (dispatch:any) => {
    dispatch({
        type: GET_OVERALL_REPORT,
        payload: formData,
    });
};

export const getReportFilterDetails = (formData:any) => (dispatch:any) => {
    dispatch({
        type: GET_FILTER_DETAILS,
        payload: formData,
    });
};

export const getActualReportDetails = (formData:any) => (dispatch:any) => {
    dispatch({
        type: GET_ACTUAL_REPORT_DETAILS,
        payload: formData,
    });
};