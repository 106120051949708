import React, { FC, useState, useEffect } from "react";
import { View,Text, Dimensions, TouchableOpacity, Image } from "react-native";
import Style from "./style";
import {localTimeConversion, logoutUser} from "../../utility";
import ToolBarTop from "../../components/ToolBarTop";
import SideMenuBar from "../../components/SideMenuBarTop";
import { RootStackScreenProps } from '../../types';
import { DataTable } from 'react-native-paper';
const window = Dimensions.get("window");
import { useSelector, useDispatch } from 'react-redux';
import {clearRepo, getOrderDetails, getStaffList} from "../../Server_Repo/action";
import CustomTextInput from "../../components/CustomTextInput";
import PagenationFooter from "../../components/PagenationFooter";
import CreateGroup from "../../components/CreateGroup";
import CreateUser from "../../components/CreateUser";
interface Props{
    rouetData:any;
    routeName:any
    navigation:(dataSets:any,natTo:any)=>void;
}
const  UserManagementActivity:FC<Props>=(props)=>
{
    const dispatch:any = useDispatch();
    const [sideMenu,setSideMenu]=useState(true);
    const [isLargeScreen,setIsLargeScreen]=useState(window.width);
    const [orderGroupInfo,setOrderGroupInfoDetails]=useState<any>();
    const [viewType,setViewType]=useState("users");
    const [internalViewType,setInternalViewType]= useState<any>("groupList");
    const [orderName,setOrderName]= useState<string>();
    const [userDetials,setUserDetails]= useState<any>();
    const [groupDetails,setGroupDetails]= useState<any>();
    const [formError,setFormError]=useState<any>(null);
    const [userList,setUserList]= useState<any>();
    const [userAccessDetails,setUserAccessDetails] = useState<any>();
    const [selectedGroup,setSelectedGroup] = useState<any>();
    var userGroupDetails:any=null;

    const {
        orderDetails,
        getStaffListResponse
    } = useSelector((state:any) => state.userReducer);

    useEffect(()=>{
        getGroupList();
    },[]);

    useEffect(()=>{
        if(orderDetails&&orderDetails.status){
            if(orderDetails.status==1&&viewType=="users"){
                setInternalViewType("groupList");
                setOrderGroupInfoDetails(orderDetails.data);
            }
        }
    },[orderDetails]);

    useEffect(()=>{
        if(getStaffListResponse){
            console.log("getStaffListResponse",getStaffListResponse);
            if(getStaffListResponse.status==0){
            setFormError(getStaffListResponse.error);
            }else{
            setUserList(getStaffListResponse.data);
            }
        }
    },[getStaffListResponse]);

    Dimensions.addEventListener("change",({ window}) => {
        setIsLargeScreen(window.width);
    });
    
    useEffect(()=>{
        if(isLargeScreen>=730)
            setSideMenu(true)
        else
            setSideMenu(true)
    },[isLargeScreen])

    const navigateTo=(route:any)=>{
        props.navigation(route,{});
    }

    const groupSelected=(groupData:any)=>{
      console.log("groupSelected",groupData);
      setGroupDetails(groupData);
      userGroupDetails = groupData;
      switchComponent("userList");
      console.log("groupData",groupData);
    }

    const internalComponentControl=()=>{
        switch (internalViewType) {
            case "groupList":{
                setUserDetails([]);
                setInternalViewType("createGroup");
            }
            break;
            case "createGroup":
                setInternalViewType("groupList");
            break;
            case "userList":{
                userGroupDetails = groupDetails;
                setInternalViewType("createUser");
            }
            break;
            case "createUser":
            {
                setUserDetails(null);
                setInternalViewType("userList");
            }
            break;
            default:
            break;
        }
    }

    const switchComponent=(component:any)=>{
        setInternalViewType(component);
        if(component=="userList"){
            getStaffListAPI();
        }
        if(component=="groupList"){
            getGroupList();
        }
    }

    const userSelected=(userData:any)=>{
        setUserDetails(userData);
        console.log("userData",userData);
        switchComponent("createUser")
    }

    const getGroupList = ()=>{
        dispatch(getOrderDetails({
            skip:0,
            limit:10,
            fetchType:viewType,
        }));
    }

    const getStaffListAPI=()=>{
        console.log("groupID",userGroupDetails,groupDetails)
        dispatch(getStaffList(
            {
                groupID:userGroupDetails!=null? userGroupDetails._id : groupDetails._id,
            }
        ))
    }

    return (
        <View style={Style.container}>
            <View style={Style.mainContect}>
                <View style={{flex:1}}>
                    <View style={Style.header}>
                        <View style={Style.headerLeft}>
                        {
                            internalViewType=="userList" ?
                            <TouchableOpacity onPress={()=>switchComponent("groupList")}>
                                <Image style={Style.headerLeftIocns}
                                source={
                                require("../../assets/images/back.png")
                                }/>
                            </TouchableOpacity>
                            : null
                        }
                        <Text style={Style.headerTitle}>{
                            internalViewType=="createGroup"||orderGroupInfo==null ? "Create Group" :
                            internalViewType=="groupList" ? "Group List" : 
                            internalViewType=="createUser" ? "Create User" : 
                            internalViewType=="userList" ? "User List" : null
                        }</Text>
                        </View>
                        <View style={Style.headerRight}>
                            <TouchableOpacity onPress={()=>{
                                internalComponentControl()
                                setSelectedGroup(null);
                            }}>
                            <Image style={Style.headerRightIocns}
                                source={ 
                                internalViewType=="createGroup"||internalViewType=="createUser" ? 
                                require("../../assets/images/close.png")
                                : require("../../assets/images/plus.png")
                            }/>
                            </TouchableOpacity>
                        </View>
                    </View>
                    {
                        internalViewType=="groupList"&&orderGroupInfo ?
                            <View style={Style.cardView}>
                                <CustomTextInput type="text" id="orderName" value=""
                                    placeHolder="Search Group"
                                    onInputChange={(ID,val)=>{setOrderName(val)}} />
                                    <View style={Style.groupContainer}>
                                        <DataTable>
                                            <DataTable.Header>
                                                <DataTable.Title>Group Name</DataTable.Title>
                                                <DataTable.Title>Staff Count</DataTable.Title>
                                                <DataTable.Title>Group Status</DataTable.Title>
                                                <DataTable.Title>Updated At </DataTable.Title>
                                                <DataTable.Title>Action</DataTable.Title>
                                            </DataTable.Header>
                                            {
                                            orderGroupInfo ? orderGroupInfo.map((element:any,index:any) => {
                                                return <DataTable.Row onPress={()=>groupSelected(element)} key={index+"groupList"}>
                                                    <DataTable.Cell>{element.groupName}</DataTable.Cell>
                                                    <DataTable.Cell>
                                                        <Text>
                                                            {
                                                                element.count.length > 0 ? element.count[0].staffCount : 0
                                                            }
                                                        </Text>
                                                    </DataTable.Cell>
                                                    <DataTable.Cell>{"Active"}</DataTable.Cell>
                                                    <DataTable.Cell>{localTimeConversion(element.updatedAt)}</DataTable.Cell>
                                                    <DataTable.Cell>
                                                        <TouchableOpacity onPress={()=>{
                                                            setSelectedGroup(element);
                                                            setInternalViewType("createGroup")}}>
                                                            <Text>Edit</Text>
                                                        </TouchableOpacity>
                                                    </DataTable.Cell>
                                                </DataTable.Row>
                                            }) : null 
                                        }
                                        </DataTable> 
                                    </View>
                                <PagenationFooter pagenation={(skip,limit)=>{}}/>
                            </View>
                        : internalViewType=="createGroup"?
                            <View style={Style.cardView}>
                                <CreateGroup isLargeScreen={isLargeScreen}
                                    orderID={null}
                                    selectedGroup={selectedGroup}
                                    switchComponent={(component)=>switchComponent(component)}
                                    successCallBack={()=>{}}/>
                            </View>
                        :  internalViewType=="createUser"?
                            <CreateUser isLargeScreen={isLargeScreen}
                                userDetials={userDetials}
                                groupDetials={groupDetails}
                                switchComponent={(component:any)=>switchComponent(component)}
                                type={"order"}
                                successCallBack={()=>{}}/> 
                                : groupDetails&&internalViewType=="userList" ?
                                    <View style={Style.groupContainer}>
                                    {
                                        userList&&userList.length==0 ?  
                                            <Text>User list not found for this group, Please add user to this group.</Text>
                                        :  <DataTable>
                                            <DataTable.Header>
                                                <DataTable.Title><Text>User ID</Text></DataTable.Title>
                                                <DataTable.Title><Text>User Name</Text></DataTable.Title>
                                                <DataTable.Title><Text>User Role</Text></DataTable.Title>
                                                <DataTable.Title><Text>Notify Name</Text></DataTable.Title>
                                                <DataTable.Title><Text>Action</Text></DataTable.Title>
                                            </DataTable.Header>
                                            {
                                                userList ? userList.map((element:any,index:any) => {
                                                return <DataTable.Row key={index+"-userList"}>
                                                        <DataTable.Cell>{element.userID}</DataTable.Cell>
                                                        <DataTable.Cell>{element.userName}</DataTable.Cell>
                                                        <DataTable.Cell>{element.userRole}</DataTable.Cell>
                                                        <DataTable.Cell>{element.notifyMail}</DataTable.Cell>
                                                        <DataTable.Cell>
                                                            <TouchableOpacity onPress={()=>userSelected(element)}>
                                                                <Text>Edit</Text>
                                                            </TouchableOpacity>
                                                        </DataTable.Cell>
                                                </DataTable.Row>
                                                }) : null 
                                            }
                                        </DataTable>
                                    }
                                    </View> 
                                    : null
                    }
                </View>
            </View>
        </View>
    )
}

export default UserManagementActivity;