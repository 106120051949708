import React,{FC} from "react";
import {View, Text, TouchableOpacity} from "react-native";
import style from "./style";
interface Props{
    title:string;
    subTitle:string;
    onPress:()=>void;
}
const CustomAlert:FC<Props>=(props)=>{
    return(
        <View style={style.container}>
            <Text style={style.mainTitle}>{props.title}</Text>
            <Text style={style.subTitle}>{props.subTitle}</Text>
            <TouchableOpacity style={style.alertBtn} onPress={()=>props.onPress()}>
                    <Text style={style.alertBtnText}>OK</Text>
            </TouchableOpacity>
        </View>
    )
}

export default CustomAlert;