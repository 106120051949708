import { StyleSheet } from "react-native";

const Style = StyleSheet.create({
    main:{
        margin:10,
        marginTop:15,
        marginBottom:25,
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.2,
        shadowRadius: 2,
    },
    container:{
       
        flexDirection:'row',
        justifyContent:'flex-start',
        padding:20,
    },
    containerLeft:{
        flexDirection:'row',
        alignSelf:'flex-end',
    },
    containerRight:{
        flexDirection:'row',
        alignSelf:'flex-start',
        justifyContent:'space-evenly'
    },
    divView:{

    },
    dropDown:{
        margin:10,
        padding:10,
        minwidth:345,
        marginTop:10,
        height:50,
        borderRadius:5,
    },
    userSelectView:{

    },
    accessListText:{
        fontSize:15,
        fontWeight:'500',
        color:'#6b6f70',
    },
    editAccessView:{
        flex:1,
        padding:10,
        justifyContent:'space-between'
    },
    editAccessLabels:{
        margin:5,
        marginBottom:0,
    },
    editAccessValue:{
        margin:5,
        fontWeight:'600',
        fontSize:18
    },
    accessControlView:{
        padding:10,
    },
    headerLeftIocns:{
        height: 80,
        width:70,
        margin:10,
        marginRight:2,
        alignSelf:'flex-end'
    },
    editAccessLabel:{
        fontSize:25,
    },
    rowWise:{
        marginTop:10,
        flexDirection:'row'
    },
    contentLabel:{
        margin:10,
        fontSize:20,
        color:'black'
    },
});

export default Style;