 const otpFormQuery:any=[
     {
        id:"sinupLabel",
        type:"formTitle",
        elementType:"text",
        mandatory:true,
        placeHolder:"Name",
        value:"OTP",
        validation:false,
        readonly:false,
        errorMsg:"User name atleast 4 character",
        options:[]
     },
     {
        id:"sinupLabel",
        type:"subFormTitle",
        mandatory:true,
        elementType:"text",
        placeHolder:"Name",
        value:"Enter your OTP to verify",
        validation:false,
        readonly:false,
        errorMsg:"User name atleast 4 character",
        options:[]
     },
    {
        id:"otp",
        type:"password",
        mandatory:true,
        elementType:"inputText",
        placeHolder:"OTP",
        value:"",
        defaultVallue:"",
        validation:true,
        readonly:false,
        errorMsg:"Invalid OTP",
        options:[]
    },
    {
        id:"errorMsg",
        type:"formErrorMsg",
        mandatory:true,
        value:"",
        validation:false,
        readonly:false,
        errorMsg:"",
        options:[]
     },
    {
        id:"verifyBtn",
        type:"submitBtn",
        title:"Verify",
        mandatory:true,
        placeHolder:"Confirm password",
        value:"",
        validation:false,
        readonly:false,
        errorMsg:"",
        options:[]
    },
    {
        id:"tocreen",
        type:"resendOtp",
        mandatory:true,
        elementType:"text",
        placeHolder:"Name",
        value:"Resend OTP",
        validation:false,
        readonly:false,
        errorMsg:"User name atleast 4 character",
        options:[]
     },
]

export default otpFormQuery;