import React,{FC, useEffect, useState} from "react";
import { View, Text, TouchableOpacity, Image, FlatList, Picker, ScrollView } from "react-native";
import { useSelector, useDispatch } from 'react-redux';
import CustomAlert from "../CustomAlert";
import CustomTextInput from "../CustomTextInput";
import CustomLabel from "../CustomLabel";
import CustomButton from "../CustomButton";
import DatePicker from "../DatePicker";
import { DataTable } from 'react-native-paper';
import Style from "./style";
import CheckBoxButton from "../CheckBox";
import {
    createCalendarEvent,
    getTaskList,
    getOrderDetails,
    getCalendarEventLisEvent,
    clearRepo,
    fileAccess
} from '../../Server_Repo/action';
import ResponsiblePerson from "../ResponsiblePerson";
import FileHandler from "../FilesHandler";
import {generateRandomNumber, localTimeConversion} from "../../utility/index" 

interface Props{
    isLargeScreen:number;
    data:any;
    orderID:any;
    buyerID:any;
    currentRoute:any;
    successCallBack:(data:any)=>void;
    internalComponentChanges:()=>void;
}
const CalendarEvent:FC<Props>=(props)=>{
    const [screenType,setScreenType]=useState(Style);
    const [formStatus,setFormStatus]= useState<any>(0);
    const [formError,setFormError]=useState<any>(null);
    const [alertTitle,setAlertTitle]= useState<any>();
    const [alertSubTitle,setAlertSubTitle]= useState<any>();
    const [orderGroupInfo,setOrderGroupInfoDetails]=useState<any>();
    const [addEvent,setEvent]= useState<any>(false);
    const [currentYear,setCurrentYear]= useState<any>('');
    const [currentMonth,setCurrentMonth]= useState<any>('');
    const [dateList,setDateList]= useState<any>('');
    const [eventList,setEventList]= useState<any>();
    const [filteredEventList,setFilteredEventList]= useState<any>([]);
    const [currentEvent,setCurrentEvent]= useState<any>([]);

    const dispatch:any = useDispatch();
    const {
        orderDetails,
        createCalendarEventResponse,
        getAgendaListResponse,
        getFileAccessListResponse,
        getCalendarEventListResponse
    } = useSelector((state:any) => state.userReducer);


    useEffect(()=>{
        dispatch(
            clearRepo()
        )
        dispatch(
            getTaskList({
                buyerID: props.buyerID,
                orderID: props.orderID,
                userMail : "parixbe@gmail.com",
            }))
        dispatch(getOrderDetails({
            buyerID: props.buyerID,
            orderID: props.orderID,
            userMail : "parixbe@gmail.com",
            fetchType:"users",
            limit: 100,
            skip: 0,
        }));
        let currentDate = new Date();
        setCurrentMonth(currentDate.getMonth());
        setCurrentYear(currentDate.getFullYear());
    },[]);

    useEffect(()=>{
      
       if(getFileAccessListResponse&&getFileAccessListResponse?.status==1){
         let fileAccess:any = [];
         getFileAccessListResponse.fileIds.forEach((data:any)=>{
          
           fileAccess.push(
             {
               id:data,
               fileAccess:getFileAccessListResponse?.data?.filter((access:any)=>{
                 return access.fileID==data
               })
             }
           )
         });
         onInputChange("fileAccess",fileAccess)
         console.log(
           "aaaaaaaa",
           fileAccess
         )
       }
    },[getFileAccessListResponse!=undefined]);

    useEffect(()=>{
        if(orderDetails?.status==1){
            setOrderGroupInfoDetails(orderDetails.data);
        }
    },[orderDetails!=undefined]);

    useEffect(()=>{
        if(createCalendarEventResponse?.status==1){
           setFormStatus(1);
           setAlertTitle("Success");
           setAlertSubTitle("Event successfully added")
        }else{
            setFormStatus(
                createCalendarEventResponse?.error
            )
        }
    },[createCalendarEventResponse!=undefined]);

    useEffect(()=>{
        getCalendarEve();
    },[formStatus==0]);

    useEffect(()=>{
        console.log("getCalendarEventListResponse",getCalendarEventListResponse);
        if(getCalendarEventListResponse?.status==1){
            setEventList(getCalendarEventListResponse?.data);
            setFilteredEventList(getCalendarEventListResponse?.data);
            setDateList([...dateList])
        }
    },[getCalendarEventListResponse!=undefined]);

    useEffect(()=>{
        dateRange();
    },[currentMonth,currentYear])


    const getCalendarEve=()=>{
        dispatch(
            getCalendarEventLisEvent({
                buyerID: props.buyerID,
                orderID: props.orderID,
                userMail : "parixbe@gmail.com",
            }
        ))
    }

    const weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
    const monthList:any = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sept","Octr","Nov","Dec"];
    function dateRange(steps = 1) {
        const weekWiseDate=[];
        var dateArray = [];
        var weekCount=1;

     
        let currentDate = new Date(currentYear,currentMonth,1,1);
        console.log("currentDate",currentDate);
        console.log("currentDate",new Date(currentYear,currentMonth+1,1,1));
        while (currentDate <= new Date(currentYear,currentMonth+1,1,1)) {
          var  temDate:any=  new Date(currentDate)
          var day = temDate.getDate();
          currentDate.setUTCDate(currentDate.getUTCDate() + steps);

          // calculating starting index
          if(weekCount==1){
            var startWeekIndex=weekday.indexOf(weekday[temDate.getDay()]);
            for(var i=0;i<startWeekIndex+1;i++){
                dateArray.push({day:0,index:0});
            }
            dateArray.push({day:day,index:weekday[temDate.getDay()]});
          }else{
             dateArray.push({day:day,index:weekday[temDate.getDay()]});      
          }
          weekCount+=1;
        }
  
        var rowCount=0;
        var rowWiseDate:any=[];
        var tempDateList:any=[];
        dateArray.forEach((date,index)=>{
            if(index>0){
                if(dateArray.length-1!=index)
                    tempDateList.push(date);
                if(index!=0&&index%7==0){
                    rowCount+=1;
                    rowWiseDate[rowCount]=tempDateList;
                    tempDateList=[];
                }
                if(dateArray.length-1==index){
                    rowCount+=1;
                    rowWiseDate[rowCount]=tempDateList;
                    tempDateList=[];
                }
            }
        })
        

        console.log("currentDate",currentMonth,currentYear)

        rowWiseDate= rowWiseDate.filter((item:any)=>{
            return item!=null
        })

        setDateList(rowWiseDate);
        console.log("dateArray",dateList)
       
    }

    const yearRender=()=>{
        var mapData=[];
        for(var i=2022;i>=1900;i--){
            mapData.push(i);
        }
        return <Picker style={Style.dropDown}
            value={currentYear}
            onValueChange={(itemValue:any, itemIndex:any) =>
            setCurrentYear(itemValue)}>
            <Picker.Item label="Select Year" value={0} />
            {
                mapData.map((i)=>{
                    return <Picker.Item label={i} value={i} />
                })
            }
        </Picker>
       
    }

    const onDateSelected=(day:any)=>{
        var tempEventList = eventList ? eventList : [];
        tempEventList = tempEventList.filter((item:any)=>{
           var sdTime = new Date(item.eventPlannedAt)
           var inputedDay= sdTime.getDate(), inputedMonth = sdTime.getMonth(), inputedYear  = sdTime.getFullYear();
           return inputedDay == day &&
                    inputedYear == currentYear &&
                        inputedMonth == currentMonth
        })
        setFilteredEventList([...tempEventList])
    }

    const getEventCount=(day:any)=>{
        var tempEventList = eventList ? eventList : [];
        tempEventList = tempEventList.filter((item:any)=>{
           var sdTime = new Date(item.eventPlannedAt)
           var inputedDay= sdTime.getDate(), inputedMonth = sdTime.getMonth(), inputedYear  = sdTime.getFullYear();
           return inputedDay == day &&
                    inputedYear == currentYear &&
                        inputedMonth == currentMonth
        })
        return tempEventList.length;
    }

    const addNewEvent=()=>{
        console.log(
            "addNewEvent",currentEvent
        )
        if(currentEvent.eventName==null||
            currentEvent.eventPlannedAt==null){
            setFormError(["Event name and schedule data is mandatory "])
        }else{
            let extraPayload = currentEvent._id ? {} : {isDeleted : "active"} ; 
            dispatch(
                createCalendarEvent({
                    buyerID: props.buyerID,
                    orderID: props.orderID,
                    eventData:{...currentEvent,...extraPayload}
                })
            )
        }
    }

    const onInputChange = (field:any,val:any) =>{
        var tempEveData:any = currentEvent ? currentEvent : {};
        tempEveData[field] =  val;
        setCurrentEvent({...currentEvent ,...tempEveData});
    }

    const getFileAccessDetails=(element:any)=>{
        let fileId:any=[];
        element?.fileList.forEach((element:any) => {
          fileId.push(element._id);
        });
        console.log("getFileAccessDetails",element,fileId);
        dispatch(
          fileAccess(
            {
              fileIds:fileId,
              id:"calendarEve"
            }
          )
        )
    }

    return (
        <View style={Style.mainContent}>
            { 
                formStatus==1 ?
                <View>
                    <CustomAlert title={alertTitle} subTitle={alertSubTitle} onPress={()=>{
                        setFormStatus(0)
                        setEvent(0)
                    }} />
                </View>
                : <View style={{flexDirection:'row'}}>
                    {
                        addEvent == false ? 
                            <View style={{flex:6}}>

                            <View style={{flexDirection:'row',justifyContent:'center',padding:5}}>
                                <Text style={{flexDirection:'row',alignSelf:'center',padding:5}}>Calendar</Text>
                                {
                                    yearRender()
                                }
                                <Picker style={Style.dropDown}
                                value={currentMonth}
                                onValueChange={(itemValue:any, itemIndex:any) =>
                                    setCurrentMonth(itemIndex-1)}>
                                    <Picker.Item label="Select Month" value={0}/>
                                    {
                                        monthList.map((month:any,index:any)=>{
                                            return <Picker.Item label={month} value={index} />
                                        })
                                    }
                                </Picker>
                            </View>
                            {
                                currentMonth!=null&&currentYear ?
                                    <View>
                                        <View style={{borderLeftWidth:3.5,borderLeftColor:'#2f95dc',padding:5}}>
                                            <View style={Style.dateRow}>
                                                {
                                                    weekday.map((day)=>{
                                                        return <View style={Style.weekNameHolder}>
                                                            <Text style={{ fontWeight:'500', fontSize:20}}>{day}</Text>
                                                        </View>
                                                    })
                                                }
                                            </View>
                                            <View>
                                                {   dateList.length!=0 ? 
                                                    dateList.map((weekList:any)=>{
                                                        return <View style={Style.dateRow}>
                                                            {
                                                                weekList.map((weekList:any)=>{
                                                                    return <TouchableOpacity onPress={()=>{
                                                                                onDateSelected(weekList.day);
                                                                            }}>
                                                                                <View style={Style.weekNameHolder}>

                                                                                    {
                                                                                        getEventCount(weekList.day)!=0&&weekList.day !=0 ?
                                                                                            <View style={{alignSelf:'flex-start',flexDirection:'row',alignContent:'space-around'}}>
                                                                                                <View style={{margin:5,marginBottom:10, height:12,width:12,borderRadius:50,backgroundColor:'#2f95dc'}}>
                                                                                                </View>
                                                                                                <Text style={{fontWeight:'500',fontSize:12,marginLeft:5,marginTop:4}}>E - {getEventCount(weekList.day)}</Text>
                                                                                            </View>
                                                                                        : null
                                                                                    }
                                                                                
                                                                                    <Text style={{
                                                                                        fontWeight:'800',
                                                                                        justifyContent:'center',
                                                                                        alignSelf:'baseline',
                                                                                        fontSize:15}}>{weekList.day !=0 ? weekList.day : null}</Text>
                                                                                </View>
                                                                        </TouchableOpacity>
                                                                })
                                                            }
                                                        </View>
                                                    }) : null
                                                }   
                                            </View> 
                                        </View>
                                    </View>
                                : null
                            }
                            </View>
                        : null
                    }
                   
                    <View style={{flex:4,overflow:'scroll'}}>
                        <View style={[Style.header]}>
                            <View style={Style.headerLeft}>
                                <Text style={Style.headerTitle}>{ !addEvent ? "Events" : "Add Events"}</Text>   
                            </View>
                            <View style={Style.headerRight}>
                                { 
                                    !addEvent ?
                                        <TouchableOpacity style={{flexDirection:'row'}} onPress={()=>{          
                                            setCurrentEvent([]);
                                            getCalendarEve()
                                            setEvent(!addEvent)}}>
                                            <Image style={Style.addSubLineImg}
                                                source={require("../../assets/images/"+(addEvent? "close" : "plus" )+".png")
                                            }/>
                                            {
                                                !addEvent ?
                                                    <Text style={{marginTop:25}}>Add agenda events</Text>
                                                : null
                                            }
                                        </TouchableOpacity>
                                    : null 
                                }
                                { 
                                    addEvent ?
                                        <View style={{alignSelf:'flex-end'}}>
                                            <View style={{flexDirection:'row',alignSelf:'flex-end'}} >
                                                {
                                                    currentEvent._id!=undefined ?
                                                        <TouchableOpacity onPress={()=>{
                                                            onInputChange("isDeleted",currentEvent.isDeleted == 'active' ? 'inactive' : 'active')
                                                        }}>
                                                            <Image style={Style.addSubLineImg}
                                                                source={require("../../assets/images/"+(currentEvent.isDeleted == 'active' ? "delete" : "undo" )+".png")
                                                            }/>
                                                        </TouchableOpacity>
                                                    : null
                                                }
                                                <TouchableOpacity  onPress={()=>{
                                                setCurrentEvent([]);
                                                setEvent(!addEvent)}}>
                                                    <Image style={Style.addSubLineImg}
                                                        source={require("../../assets/images/"+(addEvent? "close" : "plus" )+".png")
                                                    }/>
                                                </TouchableOpacity>
                                            </View>
                                        </View>
                                    : null 
                                }
                            </View>
                        </View>
                        {
                            !addEvent ?
                            <View style={{flex:4,padding:2.5,margin:5}}>
                                <View style={{padding:5,paddingTop:0}}>
                                        <CustomTextInput type="text" id="categoryID" value={""}
                                        placeHolder="Search category" onInputChange={(ID,val)=>{console.log("sa")}}/>
                                         <TouchableOpacity onPress={()=>{ setFilteredEventList([...eventList]) }}>
                                            {
                                                eventList?.length!=filteredEventList?.length ?
                                                    <Text style={{marginLeft:10,margin:10}}>{"Show all"}</Text>
                                                    : null
                                            }
                                           
                                        </TouchableOpacity>
                                    <DataTable>
                                        <DataTable.Header>
                                            <DataTable.Title>Title</DataTable.Title>
                                            <DataTable.Title>Planned</DataTable.Title>
                                        </DataTable.Header>
                                            {
                                                eventList&&filteredEventList.map((data:any)=>{
                                                    return  <DataTable.Row onPress={()=>{
                                                        dispatch(
                                                            clearRepo()
                                                        )
                                                        setCurrentEvent(data)
                                                        getFileAccessDetails(data)
                                                        setEvent(!addEvent)}}>
                                                        <DataTable.Cell>{data.eventName}</DataTable.Cell>
                                                        <DataTable.Cell>{localTimeConversion(data.eventPlannedAt)}</DataTable.Cell>
                                                    </DataTable.Row>
                                                })
                                            }
                                    </DataTable>
                                </View>
                            </View>
                            : <View>
                                <CustomTextInput type="text" id="categoryID" 
                                    value={  currentEvent?.eventName }
                                    placeHolder="Event Name" 
                                    onInputChange={(ID,val)=>{
                                        onInputChange("eventName",val)
                                }} />
                                 <View style={Style.rowWise}>
                                    <DatePicker label={"Scheduled On"} placeholder={"Production Start Date"}
                                    date={currentEvent?.eventPlannedAt} layerIndex={4} onDateSelected={(date)=>{
                                        onInputChange("eventPlannedAt",date)
                                    }}/>
                                </View>
                                {
                                    orderGroupInfo ?
                                        <ResponsiblePerson groupList={orderGroupInfo}
                                        id={ "addResponsible-calEvn"}
                                        resPerson = {currentEvent?.resPersons}
                                        currentRoute={props.currentRoute}
                                        subRoute = {"insideOrder"}
                                        title={"Involve staff"}
                                        orderID={props.orderID}
                                        accessID={props.currentRoute}
                                        visible={false}
                                        userListUpdated = {(userList)=>{onInputChange("resPersons",userList)}}/>
                                    : null
                                }
                                
                                <FileHandler isLargeScreen={props.isLargeScreen} 
                                documentList={currentEvent?.fileList}
                                fileAccess={currentEvent?.fileAccess}
                                orderID={props.orderID}
                                id="orderCalEve-file"
                                updateUserAccess = {(fileAccess)=>{onInputChange("fileAccess",fileAccess.value)}}
                                deletedFileList = {(fileAccess)=>{onInputChange("fileDeleted",fileAccess.value)}}
                                updatedFileList={(updatedList)=>{onInputChange("fileList",updatedList.value)}}/>
                            
                                <View style={Style.footer}>
                                    <View style={{justifyContent:'center',flex:1}}>
                                        <CustomLabel type="formErrorMsg" label={""} errorMsg={formError}
                                        formRawData={null} successMsg={null}/>
                                    </View>
                                    <CustomButton buttonID={"Submit"} type="common"
                                    title="Save" onSubmit={()=>{addNewEvent()}}/>
                                </View>
                            </View>
                        }
                    
                    </View>
                </View>
            }
        </View>
    )
}

export default CalendarEvent;