import React,{useState,useEffect,FC} from "react";
import {View,Dimensions} from "react-native";
import { Button } from "react-native-paper";
import Style from "./style";
interface Props{
    buttonID:String;
    type:String;
    title:String,
    onSubmit:(buttonID:String)=>void;
}
const CustomButton:FC<Props>=(props)=>{ 
    return(
        <View>
            <Button mode="contained" onPress={() => props.onSubmit(props.buttonID)}
            style={Style.customButton}>
                {props.title}
            </Button>
        </View>
    )
}

export default CustomButton;

