import React,{FC, useState} from "react";
import {View,Image,Text,Dimensions} from "react-native";
import CustomTextInput from "../../components/CustomTextInput";
import CustomButton from "../../components/CustomButton";
import {Style,tabletView,mobileView} from './style';
const window = Dimensions.get("window");
import Ionicons from '@expo/vector-icons/Ionicons';
import {ComponentSwitch} from "../../constants/ComponentSwitch";
import FormMaker from "../FormMaker";
import otpFormQuery from "../../constants/FormQueries/otpForm";
import { useDispatch,useSelector } from 'react-redux';
import { verifyOTP, clearRepo, forgetPassword } from '../../Server_Repo/action';
import {storeAccessToken} from "../../utility";
interface Props{
  formData:any;
  tocreen:number;
  switchFormType:(type:String,formData:any)=>void;
  loginSuccess:()=>void;
}
const  OTPForm:FC<Props>=(props)=>{

  const dispatch:any = useDispatch();
  const [screenType,setScreenType]=useState(Style);
  const [formQuery,setFormQuery]=useState(otpFormQuery);
  const [formData,setFormData]=useState<any>(props.formData);
  const [formError,setFormError]=useState<any>(null);

  React.useEffect(() => {
  
  }, [])
  
  var formTempData=formData;
  formTempData["tocreen"]=props.tocreen;
  otpFormQuery.forEach((element:any,index:number) => {
      if(formTempData&&formTempData[element.id]!=undefined){
          element.value=formTempData[element.id];
          element.options[0]=formData.userMail;
          otpFormQuery[index]= element;
      }else{
          if(element.elementType=="inputText")
          element.value='';
      }
  });

  const submitForm=(playload:any)=>{
    console.log("playload",playload);
    if(formData.otpType==1){
      console.log("verifyOTP",formData,playload);
      dispatch(forgetPassword(playload));
    }else{
      setFormData(playload);
      dispatch(verifyOTP(
        {
          userMail:playload.userMail,
          otp:playload.otp
        }
      ));
    }
  }

  const successCallBack=async (reponse:any)=>{
    console.log("formData",formData,reponse);
    if(formData&&formData.otpType==0){
      // assining init as access token 
      await storeAccessToken('init').then(()=>{
        props.switchFormType(ComponentSwitch[4],reponse.data);
      })
    }
    if(formData&&formData.otpType==1){
      await storeAccessToken('init').then(()=>{
        props.loginSuccess();
      })
    }
   
  }

    console.log("tocreen",props.tocreen)
    return (
      <View style={screenType.formWidth}>
          <Ionicons name="arrow-back"  style={Style.backIcon} onPress={()=>{
            props.switchFormType(ComponentSwitch[props.tocreen],props.formData)
          }}/> 
          <FormMaker formElementData={formQuery} 
          formID="otp"
          erroMsg={formError} formData={formData} 
          successCallBack={(formData)=>{successCallBack(formData)}}
          submitForm={(formData)=>submitForm(formData)}/>
      </View>
    )
}

export default OTPForm;