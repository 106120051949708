export const USER_SIGNUP_REQUEST = "USER_SIGNUP_REQUEST";
export const USER_SIGNUP_REQUEST_SUCCESS = "USER_SIGNUP_REQUEST_SUCCESS";
export const SET_USER_MAIL = "SET_USER_MAIL";
export const API_CALL_FAIL = "API_CALL_FAIL";
export const CLEAR_SERVER_REPO = "CLEAR_SERVER_REPO";
export const VERIFY_OTP = "VERIFY_OTP";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const RESEND_OTP = "RESEND_OTP";
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const UPDATE_PLAN_DETAILS = "UPDATE_PLAN_DETAILS";
export const UPDATE_PLAN_DETAILS_SUCCESS = "UPDATE_PLAN_DETAILS_SUCCESS";
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const FORGET_PASSWORD_OTP = "FORGET_PASSWORD_OTP";
export const FORGET_PASSWORD_OTP_SUCCESS = "FORGET_PASSWORD_OTP_SUCCESS";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const SAVE_FILE_REQUEST = "SAVE_FILE_REQUEST";
export const SAVE_FILE_REQUEST_SUCCESS = "SAVE_FILE_REQUEST_SUCCESS";
export const IS_ACTIVE_DATEPICKER = "IS_ACTIVE_DATEPICKER";
export const CREATE_ORDER_REQUEST = "CREATE_ORDER_REQUEST";
export const CREATE_ORDER_RESPONSE = "CREATE_ORDER_RESPONSE";
export const GET_ORDER_LIST_REQUEST = "GET_ORDER_LIST_REQUEST";
export const GET_ORDER_LIST_RESPONSE = "GET_ORDER_LIST_RESPONSE";
export const GET_ORDER_DETAILS_REQUEST = "GET_ORDER_DETAILS_REQUEST";
export const GET_ORDER_DETAIL_RESPONSE = "GET_ORDER_DETAIL_RESPONSE";
export const CREATE_GROUP = "CREATE_GROUP";
export const CREATE_GROUP_RESPONSE = "CREATE_GROUP_RESPONSE";
export const CREATE_STAFF = "CREATE_STAFF";
export const CREATE_STAFF_RESPONSE = "CREATE_STAFF_RESPONSE";
export const GET_STAFF_LIST_BY_ID = "GET_STAFF_LIST_BY_ID";
export const GET_STAFF_LIST_BY_ID_RESPONSE = "GET_STAFF_LIST_BY_ID_RESPONSE";
export const GET_STAFF_LIST = "GET_STAFF_LIST";
export const GET_STAFF_LIST_RESPONSE = "GET_STAFF_LIST_RESPONSE";
export const GET_STAFF_DETAILS = "GET_STAFF_DETAILS";
export const GET_STAFF_DETAILS_RESPONSE = "GET_STAFF_DETAILS_RESPONSE";
export const CREATE_STOCKS = "CREATE_STOCKS";
export const CREATE_STOCKS_RESPONSE = "CREATE_STOCKS_RESPONSE";
export const GET_STOCKS_LIST = "GET_STOCKS_LIST";
export const GET_STOCKS_LIST_RESPONSE = "GET_STOCKS_LIST_RESPONSE";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const CREATE_CATEGORY_RESPONSE = "CREATE_CATEGORY_RESPONSE";
export const GET_CATEGORY_LIST = "GET_CATEGORY_LIST";
export const GET_CATEGORY_LIST_RESPONSE = "GET_CATEGORY_LIST_RESPONSE";
export const CREATE_TASK = "CREATE_TASK";
export const CREATE_TASK_RESPONSE = "CREATE_TASK_RESPONSE";
export const GET_TASK_LIST = "GET_TASK_LIST";
export const GET_TASK_LIST_RESPONSE = "GET_TASK_LIST_RESPONSE";
export const CREATE_OPU = "CREATE_OPU";
export const CREATE_OPU_RESPONSE = "CREATE_OPU_RESPONSE";
export const GET_OPU_LIST = "GET_OPU_LIST";
export const GET_OPU_LIST_RESPONSE = "GET_OPU_LIST_RESPONSE";
export const CREATE_AGENDA = "CREATE_AGENDA";
export const CREATE_AGENDA_RESPONSE = "CREATE_AGENDA_RESPONSE";
export const GET_AGENDA_LIST = "GET_AGENDA_LIST";
export const GET_AGENDA_LIST_RESPONSE = "GET_AGENDA_LIST_RESPONSE";
export const CREATE_CALENDAR_EVENT = "CREATE_CALENDAR_EVENT";
export const CREATE_CALENDAR_EVENT_RESPONSE = "CREATE_CALENDAR_EVENT_RESPONSE";
export const GET_CALENDAR_EVENT_LIST = "GET_CALENDAR_EVENT_LIST";
export const GET_CALENDAR_EVENT_LIST_RESPONSE = "GET_CALENDAR_EVENT_LIST_RESPONSE";
export const PRODUCTION_INPUT = "PRODUCTION_INPUT";
export const PRODUCTION_INPUT_RESPONSE = "PRODUCTION_INPUT_RESPONSE";
export const GET_INPUT_LIST = "GET_INPUT_LIST";
export const GET_INPUT_LIST_RESPONSE = "GET_INPUT_LIST_RESPONSE";
export const CREATE_DEFECT_TYPE = "CREATE_DEFECT_TYPE";
export const CREATE_DEFECT_TYPE_RESPONSE = "CREATE_DEFECT_TYPE_RESPONSE";
export const GET_DEFECT_TYPE_LIST = "GET_DEFECT_TYPE_LIST";
export const GET_DEFECT_TYPE_LIST_RESPONSE = "GET_DEFECT_TYPE_LIST_RESPONSE";
export const INPUT_DEFECT = "INPUT_DEFECT";
export const INPUT_DEFECT_RESPONSE = "INPUT_DEFECT_RESPONSE";
export const INPUT_DEFECT_LIST = "INPUT_DEFECT_LIST";
export const INPUT_DEFECT_LIST_RESPONSE = "INPUT_DEFECT_LIST_RESPONSE";
export const GET_GROUP_LIST = "GET_GROUP_LIST";
export const GET_GROUP_LIST_RESPONSE = "GET_GROUP_LIST_RESPONSE";
export const GET_FILE_ACCESS_LIST = "GET_FILE_ACCESS_LIST";
export const GET_FILE_ACCESS_LIST_RESPONSE = "GET_FILE_ACCESS_LIST_RESPONSE";
export const UPDATE_ORDER_USER_LIST = "UPDATE_ORDER_USER_LIST";
export const UPDATE_ORDER_USER_LIST_RESPONSE = "UPDATE_ORDER_USER_LIST_RESPONSE";
export const GET_ORDER_USER_LIST = "GET_ORDER_USER_LIST";
export const GET_ORDER_USER_LIST_RESPONSE = "GET_ORDER_USER_LIST_RESPONSE";
export const GET_ORDER_STOCK_USAGE_LIST = "GET_ORDER_STOCK_USAGE_LIST";
export const GET_ORDER_STOCK_USAGE_LIST_RESPONSE = "GET_ORDER_STOCK_USAGE_LIST_RESPONSE";
export const GET_OVERALL_REPORT = "GET_OVERALL_REPORT";
export const GET_OVERALL_REPORT_LIST_RESPONSE = "GET_OVERALL_REPORT_LIST_RESPONSE";
export const GET_FILTER_DETAILS = "GET_FILTER_DETAILS";
export const GET_FILTER_DETAILS_RESPONSE = "GET_FILTER_DETAILS_RESPONSE";
export const GET_ACTUAL_REPORT_DETAILS = "GET_ACTUAL_REPORT_DETAILS";
export const GET_ACTUAL_REPORT_DETAILS_RESPONSE = "GET_ACTUAL_REPORT_DETAILS_RESPONSE";