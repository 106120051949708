export const customTextInputList=[
    "text","password","number"
]

export const customTextList=[
    "formTitle","subFormTitle","formErrorMsg","resendOtp"
]

export const customBtnList=[
    "submitBtn"
]