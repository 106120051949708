import React,{FC, useEffect, useState} from "react";
import {View, Text, TouchableOpacity} from "react-native";
import Style from "./style";
import CustomTextInput from "../CustomTextInput";
import { useSelector, useDispatch } from 'react-redux';
import { isActiveDatePick } from '../../Server_Repo/action';
interface Props{
    label:string;
    date:string;
    layerIndex:number;
    placeholder:string;
    onDateSelected:(date:any)=>void;
}
const DatePicker:FC<Props>=(props)=>{

    const [calendarView,setCalendarView]=useState(false);
    const [currentDay,setCurrentDay]=useState<any>();
    const [currentMonth,setCurrentMonth]=useState<any>();
    const [currentYear,setCurrentYear]=useState<any>();
    const [currentDate,setCurrentDate]=useState<any>();
    const [dateSelected,setSeletedDate]=useState<any>(props.date);
    const [monthCal,setMonthCal]=useState<any>(false);
    const [yearCal,setYearCal]=useState<any>(false);
    const [dateList,setDateList]=useState<any>([]);
    const [yearViewList,setYearViewList]=useState<any>([]);
    
    const monthList:any = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sept","Octr","Nov","Dec"];
    const monthViewList:any = [["Jan","Feb","Mar","Apr"],["May","Jun","Jul","Aug"],["Sept","Octr","Nov","Dec"]];
    const weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
    const dispatch:any = useDispatch();
    const {
        isActiveDatePicker
    } = useSelector((state:any) => state.userReducer);

    useEffect(()=>{
      
        try { 
            if(isActiveDatePicker&&isActiveDatePicker!=props.layerIndex)
            {
                setCalendarView(false);
            }else{
                if(calendarView){
                    dispatch(isActiveDatePick(props.layerIndex));
                }
            }
        } catch (
            error
        ) {
            console.log("formError",error);
        }
       
    },[ isActiveDatePicker,calendarView])

    const showCalendar=()=>{
        dispatch(isActiveDatePick(props.layerIndex));
        setCalendarView(!calendarView);
        var currentTime = new Date();
        if(dateSelected){
            var spreadTime=dateSelected.split("/");
            currentTime = new Date(spreadTime[2],spreadTime[1]-1,spreadTime[0],1,1);
            calendarLogics(currentTime);
        }else{
            calendarLogics(currentTime);
        }
    }

    const calendarLogics=(currenDate:any)=>{
        console.log("currenDate",currenDate);
        var month = currenDate.getMonth();
        var day = currenDate.getDate();
        var year = currenDate.getFullYear();
            setCurrentDay(day);
            setCurrentMonth(month);
            setCurrentYear(year);
            dateRange();
    }

    const changeMoth=(move:number)=>{
    
        if(yearCal==false){
            // setting month
            if(move==0){
                if(currentMonth==0){
                    setCurrentMonth(11);
                    setCurrentYear(currentYear-1);
                }else{
                    if(currentMonth==11){
                        setCurrentMonth(0);
                        setCurrentYear(currentYear-1);
                    }else{
                        setCurrentMonth(currentMonth-1);
                    }
                }
            }else{

                console.log("currentMonth",currentMonth);

                if(currentMonth==0){
                    setCurrentMonth(11);
                }
                if(currentMonth==11){
                    setCurrentMonth(0);
                    setCurrentYear(currentYear+1);
                }else{
                    setCurrentMonth(currentMonth+1);
                }
            }
        }else{
           // setting current year
           var tempCurrentYear;
           if(move==0){
                if(yearViewList.length!=0)
                    tempCurrentYear=yearViewList[2][0]-24;
           }else{
            if(yearViewList.length!=0)
                tempCurrentYear=yearViewList[0][2]+12;
           }
           setYearCalData(tempCurrentYear);
        }
    }

    function dateRange(steps = 1) {
        const weekWiseDate=[];
        var dateArray = [];
        var weekCount=1;

     
        let currentDate = new Date(currentYear,currentMonth,1,1);
      
        while (currentDate <= new Date(currentYear,currentMonth+1,1,1)) {
          var  temDate:any=  new Date(currentDate)
          var day = temDate.getDate();
          currentDate.setUTCDate(currentDate.getUTCDate() + steps);

          // calculating starting index
          if(weekCount==1){
            var startWeekIndex=weekday.indexOf(weekday[temDate.getDay()]);
            for(var i=0;i<startWeekIndex+1;i++){
                dateArray.push({day:0,index:0});
            }
            dateArray.push({day:day,index:weekday[temDate.getDay()]});
          }else{
             dateArray.push({day:day,index:weekday[temDate.getDay()]});      
          }
          weekCount+=1;
        }
  
        var rowCount=0;
        var rowWiseDate:any=[];
        var tempDateList:any=[];
        dateArray.forEach((date,index)=>{
            if(index>0){
                if(dateArray.length-1!=index)
                    tempDateList.push(date);
                if(index!=0&&index%7==0){
                    rowCount+=1;
                    rowWiseDate[rowCount]=tempDateList;
                    tempDateList=[];
                }
                if(dateArray.length-1==index){
                    rowCount+=1;
                    rowWiseDate[rowCount]=tempDateList;
                    tempDateList=[];
                }
            }
        })

        console.log("currentDate",currentMonth,currentYear,currentDate,"\n", new Date(currentYear,currentMonth+1,1))
        console.log("dateArray",dateArray)
        setDateList(rowWiseDate);
      }

    const setYearCalData=(tempCurrentYear:any)=>{
        var tempCalView=[],dataList=[];
        var tempCurYear= tempCurrentYear;
        for(var i=0;i<=3;i++){
            for(var j=0;j<=3;j++){
                tempCurYear+=1;
                dataList.push(tempCurYear);
            }
            tempCalView.push(dataList);
            dataList=[];
        }
        setYearCal(true);
        setYearViewList(tempCalView);
    }


    useEffect(()=>{
        var currentTime = new Date(currentYear, currentMonth, currentDay,1,1);
        var updateDate= currentDay+"/"+currentMonth+"/"+currentYear;
        calendarLogics(currentTime);
        setCurrentDate(updateDate);
    },[currentDay,currentMonth,currentYear]);

    const onDateSelected=(currenDate:any)=>{
        setCalendarView(false);

        var selectedDate=((currenDate) <9 ? ("0"+(currenDate)):(currenDate))
        +"/"+
        +((currentMonth+1) <9 ? ("0"+(currentMonth+1)):(currentMonth+1)) 
        +"/"+currentYear;
        setSeletedDate(
            selectedDate
        );
        props.onDateSelected(
          new Date(currentYear,currentMonth,currenDate,1,1)
        )
    }

    console.log(
        "dateSelected",
        dateSelected ? dateSelected :props.label 
    )
    return(
        <View style={[Style.container,{zIndex:props.layerIndex}]}>
            <TouchableOpacity onPress={showCalendar}>
                <CustomTextInput type="date" id="orderName" placeHolder={props.label}
                 onInputChange = {()=>{}}
                 value={ dateSelected ? dateSelected :props.label }/>
            </TouchableOpacity>
           
            {
                calendarView==true ?
                <View style={[Style.calendarView,{zIndex:props.layerIndex}]}>
                    <View style={Style.calendarHeader}>
                        <View style={Style.headerRow}>
                            <TouchableOpacity onPress={()=>{changeMoth(0)}}>
                                <Text>{"<="}</Text>
                            </TouchableOpacity>
                        </View>
                        {   monthCal==false&&yearCal==false ?
                            <View style={Style.headerRow}>
                                <TouchableOpacity onPress={()=>{setMonthCal(!monthCal)}}>
                                    <Text>{monthList[currentMonth]}</Text>
                                </TouchableOpacity>
                            </View> : null
                        }
                        <View style={Style.headerRow}>
                            <TouchableOpacity onPress={()=>{setYearCalData(currentYear)}}>
                                <Text>{currentYear}</Text>
                            </TouchableOpacity>
                        </View>
                        <View style={Style.headerRow}>
                            <TouchableOpacity onPress={()=>{changeMoth(1)}}>
                                <Text>{"=>"}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={Style.calendarBody}>
                        {
                            monthCal==false&&yearCal==false?
                            <View>
                                <View>
                                    <View style={Style.dateRow}>
                                        {
                                            weekday.map((day)=>{
                                                return <View style={Style.weekNameHolder}>
                                                    <Text>{day}</Text>
                                                </View>
                                            })
                                        }
                                    </View>
                                </View>
                                <View>
                                    
                                    {
                                        dateList.length!=0 ? 
                                        dateList.map((weekList:any)=>{
                                            return <View style={Style.dateRow}>
                                                {
                                                    weekList.map((weekList:any)=>{
                                                        return  <View style={Style.weekNameHolder}>
                                                            <TouchableOpacity onPress={()=>{
                                                                onDateSelected(weekList.day);
                                                            }}>
                                                                <Text>{weekList.day !=0 ? weekList.day : null}</Text>
                                                            </TouchableOpacity>
                                                        </View>
                                                    })
                                                }
                                            </View>
                                        }) : null
                                    }
                                    
                                </View> 
                            </View>
                            : null
                        }
                        {
                            monthCal==true ?
                            <View style={Style.monthYearViewHolder}>
                                {
                                    monthViewList.length!=0 ? 
                                    monthViewList.map((monthViewData:any)=>{
                                        return <View style={Style.dateRow}>
                                               {
                                                    monthViewData.map((index:any)=>{
                                                    return  <View style={Style.monthYearHolder}>
                                                                <TouchableOpacity onPress={()=>{
                                                                    setCurrentMonth(monthList.indexOf(index))
                                                                    setMonthCal(false)
                                                                    setYearCal(false)
                                                                }}>
                                                                <Text>{index}</Text>
                                                            </TouchableOpacity>
                                                        </View>
                                                    })
                                                }
                                        </View>
                                    }) : null
                                }
                            </View>
                            : null
                        }
                        {
                            yearCal==true ?
                            <View style={Style.monthYearViewHolder}>
                                {
                                    yearViewList.length!=0 ? 
                                    yearViewList.map((yearViewData:any)=>{
                                        return <View style={Style.dateRow}>
                                               {
                                                    yearViewData.map((index:any)=>{
                                                    return  <View style={Style.monthYearHolder}>
                                                                <TouchableOpacity onPress={()=>{
                                                                    setYearCal(false);
                                                                    setMonthCal(true);
                                                                    setCurrentYear(index);
                                                                }}>
                                                                <Text>{index}</Text>
                                                            </TouchableOpacity>
                                                        </View>
                                                    })
                                                }
                                        </View>
                                    }) : null
                                }
                            </View>
                            : null
                        }
                    </View>
                </View>
                : null
            }
            
        </View>
    )
}

export default DatePicker;