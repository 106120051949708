import React,{FC, useState} from "react";
import {View,Text} from "react-native";
import CustomTextInput from "../../components/CustomTextInput";
import CustomButton from "../../components/CustomButton";
import {Style} from './style';
import {ComponentSwitch} from "../../constants/ComponentSwitch";
import FormMaker from "../FormMaker";
import loginFormQuery from "../../constants/FormQueries/loginForm";
import { useDispatch,useSelector } from 'react-redux';
import { loginUser, clearRepo } from '../../Server_Repo/action';
import {storeAccessToken} from "../../utility/index";
interface Props{
    formData:any;
    loginSuccess:()=>void;
    switchFormType:(type:String)=>void;
}

const LoginForm:FC<Props>=(props)=>{

    const dispatch:any = useDispatch();
    const [formQuery,setFormQuery]=useState(loginFormQuery);
    const [formData,setFormData]=useState<any>(props.formData);
    const [formError,setFormError]=useState<any>(null);
    const [screenType,setScreenType]=useState(Style);
  
    const successCallBack=(formData:any)=>{
        console.log("formData",formData.data.token)
        storeAccessToken(formData.data.token).then((status)=>{
            props.loginSuccess();
        });
    }
    const submitForm=(formData:any)=>{
        console.log("formData",formData);
        dispatch(loginUser(formData));
    }

    return (
        <View style={screenType.formWidth}>
           <FormMaker formElementData={formQuery} 
             formID="login"
            erroMsg={formError} formData={formData} 
            successCallBack={(formData)=>successCallBack(formData)}
            submitForm={(formData)=>submitForm(formData)}/>
            <View style={Style.formFooter}>
                <Text style={Style.loginText}
                onPress={()=>{
                    dispatch(clearRepo());
                    props.switchFormType(ComponentSwitch[0]);

                }}>SignUp</Text>
                <Text style={Style.forgetPin}
                onPress={()=>{
                    dispatch(clearRepo());
                    props.switchFormType(ComponentSwitch[3]);
                }}>Forgeting password</Text>
            </View>
        </View>
     )
}

export default LoginForm;