import React,{FC, useEffect, useState} from "react";
import {View,Text,Image, TouchableOpacity} from "react-native";
import Style from "./style";
import CustomTextInput from "../CustomTextInput";
import * as DocumentPicker from 'expo-document-picker';
import { useDispatch,useSelector } from 'react-redux';
import { saveDocuments,getGroupList} from '../../Server_Repo/action';
import {getAccessToken} from "../../utility";
import ResponsiblePerson from "../ResponsiblePerson";
import { getUserDetails } from "../../utility";

interface Props{
    isLargeScreen:number,
    documentList:any;
    fileAccess:any;
    orderID:any;
    updateUserAccess:(userAccess:any)=>void;
    updatedFileList:(updatedList:any)=>void;
    deletedFileList:(updatedList:any)=>void;
    id:any;
    fileUploadAccess:any;
}
const FileHandler:FC<Props>=(props)=>{
 
    const dispatch:any = useDispatch();
    const {
        saveDocumentResponse,
        apiResponse
    } = useSelector((state:any) => state.userReducer);
    const [files,setFiles]=useState( props.documentList ? props.documentList:[] );
    const [ doc, setDoc ] = useState<any>();
    const [isLoading,setLoadng] = useState<any>(false);
    const [erroMsg,setErroMsg] = useState<any>();
    const [moreInfo,setMoreInfo] = useState<any>();
    const [groupList,setGroupList]= useState<any>([]);
    const [selectedUser,setSelectedUser]= useState<any>([]);
    const [currentFile,setCurrentFile]= useState<any>([]);
    const [formError,setFormError]=useState<any>(null);
    const [deletedFiles,setDeletedFiles]=useState<any>([]);
    const [localUserDetails,setUserDetails] = useState<any>([]);
    const { getGroupListResponse } = useSelector((state:any) => state.userReducer);

    useEffect(()=>{
        getUserDetails().then((userDetails:any)=>{
            console.log(
                "FileHandler",
                userDetails.userDetails
            )
            setUserDetails(JSON.parse(userDetails?.userDetails))
        })
    },[]);

    useEffect(()=>{
        dispatch(
            getGroupList(
            {
                fetchType: "users",
                limit:0,
                skip:0
            })
        )
    },[])

    useEffect(()=>{
        console.log("FileHandler",getGroupListResponse)
        if(getGroupListResponse?.status==1){
            setGroupList(getGroupListResponse?.data);
        }
    },[getGroupListResponse!=undefined])

    useEffect(()=>{
        console.log("fileAccess",props.fileAccess)
        setSelectedUser(
            {
                access:props.fileAccess
            }
        )
    },[props.fileAccess!=undefined])
    
    useEffect(()=>{
        setLoadng(false);
        console.log("formError",saveDocumentResponse);
        try { 
            if(saveDocumentResponse&&saveDocumentResponse.status=="fail"){
                setErroMsg(saveDocumentResponse.error)
            }else{
                if(saveDocumentResponse?.fieldID == props.id){
                    var updatedFile=[...files,...saveDocumentResponse.data];
                    setDoc(null);
                    setFiles(updatedFile);
                    props.updatedFileList({
                        id:props.id,
                        value:updatedFile
                    });
                }
            }
        } catch (
            error
        ) {
            console.log("formError",error);
        }
    },[ apiResponse,
        saveDocumentResponse,
    ])

    const pickDocument = async (id:any) => {

      setErroMsg(null);

      let userMail=await getAccessToken().then((userData)=>{
            return userData.accessToken;
      })

      let result = await DocumentPicker.getDocumentAsync({ type: "*/*", multiple: true, copyToCacheDirectory: true })
      .then((response:any) => {
            const files: FileList = response.output;
            return Promise.all(
            Object.keys(files).map(
              (_key, i) =>
                new Promise((resolve) => {
                  const reader = new FileReader();
                  const file = files[i];
                  reader.onload = () => {
                    resolve({
                      fileData: reader.result as string,
                      fileName: file.name,
                      fileSize: file.size,
                      userMail:userMail
                    });
                  };
                  reader.readAsDataURL(file);
                })
            )
            );
        });
        if(id==props.id)
            setDoc(result)
    }

    useEffect(()=>{
        if(doc!=null){
            dispatch(saveDocuments({documents:doc,fieldID:props.id}));
            setLoadng(true);
        }  
    },[doc!=null])

    const updateUserList=(updateUserList:any)=>{
        
        let users = selectedUser ? selectedUser : [];
        console.log(
            "yyyyyyyy",updateUserList,users?.access
        )
        if(updateUserList.length>0){
            if(users.access==undefined){
                console.log("yyyyyyyy","IF")
                users.access=[{
                    id:currentFile._id,
                    fileAccess:updateUserList
                }]
            }else{
                console.log("cccccc","ELSE")
                let index= users.access.findIndex((accessDetails:any)=>{
                    return accessDetails.id == currentFile._id
                });
                if(index==-1){
                    users.access.push(
                        {
                             id:currentFile._id,
                             fileAccess:updateUserList
                        } 
                     )
                }else{
                    users.access[index] =  {
                        id:currentFile._id,
                        fileAccess:updateUserList
                   } 
                }
              
            }
        }
        props.updateUserAccess(
        {
            id:props.id,
            value: users.access
        });
        setSelectedUser(users);
    }

    const getSelectedUser=(docID:any)=>{
        let resPerson = null;
        resPerson = selectedUser?.access?.findIndex((accessDex:any)=>{
            return accessDex.id==docID
        })
        if(resPerson!=undefined&&resPerson!=-1){
            return selectedUser?.access[resPerson]?.fileAccess;
        }else{
            return null;
        }
       
    }

    const deleteFiles=(id:any)=>{
        let tempFiels = deletedFiles ? deletedFiles : [];
        let index = tempFiels.indexOf(id);
        if(index !=-1){
            tempFiels = tempFiels.filter((itemID:any)=>{
                return itemID!=id
            });
        }else{
            tempFiels.push(id)
        }
        setDeletedFiles([...tempFiels]);
        props.deletedFileList({
            id:props.id,
            value:[...tempFiels]
        })
    }

    const provideFileAccess = (data:any)=>{

        if(localUserDetails.userRole=="Admin"){
            return "A"
        }

        let tempAccess:any=[];
        if(selectedUser.access){
            tempAccess = selectedUser?.access?.filter((access:any)=>{
                return access.id==data._id;
            });
        }else{
           return 0
        }
     
        if(tempAccess.length>0){
            tempAccess= tempAccess[0]?.fileAccess?.filter((access:any)=>{
                return access.resPerson!=localUserDetails._id
            })
        }else{
            return 0
         }

        if(tempAccess.length>0){
            tempAccess= tempAccess[0]?.taskAccess?.filter((access:any)=>{
                return access.ey!="fileAccess"
            })
        }else{
            return 0
         }

        if(tempAccess.length>0){
            console.log(
                "provideFileAccess",
                tempAccess[0]
            )
            return tempAccess[0].val;
        }else{
            return 0
        }
    }

    const proovideUserFileUpload=()=>{
        console.log("checkFileUploadAccess",localUserDetails)
       if(localUserDetails.userRole=="Admin"){
            return true;
       }else{
         let checkFileUploadAccess = localUserDetails?.userAccess?.filter((element:any)=>{
            return element.id == "createOrder";
         })
         console.log("checkFileUploadAccess",checkFileUploadAccess)
         if(checkFileUploadAccess?.length>0){
            return Number(checkFileUploadAccess[0].access ) > 0
         }else{
            return false
         }
       } 
    }
   
    return(
        <View style={Style.container}>
            { isLoading==false ? 
                <View>
                    { 
                    moreInfo!=true ? 
                            <View>
                               {  
                                    proovideUserFileUpload()==true&&props.fileUploadAccess==true ?
                                        <TouchableOpacity onPress={()=>pickDocument(props.id)}>
                                            <View style={[Style.fileSelectContent]}>
                                                <Text style={Style.uploadText}>Upload documents</Text>
                                            </View>
                                        </TouchableOpacity>
                                    : null
                                }
                                <View style={Style.fileListHolder}>
                                    {
                                        files&&files.length==0&&props.fileUploadAccess==true ? 
                                            <View  style={Style.fileCurms}><Text>Files not yet uploaded</Text></View>
                                        :   files?.map((data:any)=>{
                                            return  provideFileAccess(data)!=0 ? 
                                                <View style={Style.fileCurms}>
                                                    <View style={Style.fileTitle}><Text>{data.fileName}</Text></View>
                                                    {
                                                        deletedFiles.indexOf(data._id) == -1 ?
                                                            <View style={{flexDirection:'row',justifyContent:'space-evenly'}}>
                                                                {
                                                                    provideFileAccess(data)=="A"||Number(provideFileAccess(data))>=2?
                                                                        <TouchableOpacity onPress={()=>{
                                                                            setMoreInfo(!moreInfo) 
                                                                            setCurrentFile(data)
                                                                        }}>
                                                                            <Image style={Style.accessCurmIcons}
                                                                            source={require('../../assets/images/access.png')}/>
                                                                    </TouchableOpacity>
                                                                    : null
                                                                }
                                                                {
                                                                    provideFileAccess(data)=="A"||Number(provideFileAccess(data))>=1?
                                                                        <TouchableOpacity onPress={()=>console.log("download",data)}>
                                                                            <Image style={Style.downloadCurmIcons}
                                                                            source={require('../../assets/images/download.png')}/>
                                                                        </TouchableOpacity>
                                                                    : null
                                                                } 
                                                                {
                                                                    provideFileAccess(data)=="A"||Number(provideFileAccess(data))==2?  
                                                                        <TouchableOpacity onPress={()=>{deleteFiles(data._id)}}>
                                                                            <Image style={Style.deleteCurmIcons}
                                                                            source={require('../../assets/images/delete.png')}/>
                                                                        </TouchableOpacity>
                                                                    : null
                                                                }
                                                            </View>
                                                        :   <TouchableOpacity onPress={()=>{deleteFiles(data._id)}}>
                                                                <Image style={Style.deleteCurmIcons}
                                                                source={require('../../assets/images/undo.png')}/>
                                                            </TouchableOpacity>
                                                        }
                                                        </View>
                                                        : null
                                                    })
                                                }
                                </View>
                            </View> 
                        :   
                        <View>
                            <View style={{flexDirection:'row'}}>
                                <TouchableOpacity onPress={()=>setMoreInfo(!moreInfo)}>
                                    <Image style={{height:25,width:30,marginRight:10,marginLeft:10,marginTop:2}}
                                    source={require("../../assets/images/back.png")}/>
                                </TouchableOpacity>
                                <Text style={{marginTop:5}}>Back to Files</Text>
                            </View>
                            <View style={{marginTop:15,flexDirection:'row',height:1,backgroundColor:'black'}}>
                            </View>    
                            <ResponsiblePerson groupList={groupList}
                                visible={true}
                                subRoute={"insideFileHandler"}
                                orderID={props.orderID}
                                title={"Allow file to access"}
                                id={"createOrder_file_"+props.id}
                                resPerson = {getSelectedUser(currentFile._id)}
                                currentRoute = {"createOrder"}
                                accessID={"fileHandler"}
                                userListUpdated = {(userList)=>{ 
                                    updateUserList(userList)}
                                }/>
                        </View>
                    }
                </View>
                : <View  style={Style.fileCurms}>
                    <Text>File is uploading</Text>
                </View>
            }
            
        </View>
    );
}

export default FileHandler;
