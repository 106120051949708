import { StyleSheet } from "react-native";
import Colors from "../../constants/Colors"; 

export const Style=StyleSheet.create({
    mainContent:{
        flex:1,
    },
    errorText:{
        display:'none',
        color:'red',
        marginTop:15,
        margin:10,
        alignSelf:'flex-start',
    },
    container:{
        flex:1,
        flexDirection:'column',
    },
    contentHolder:{
        flex:1,
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-evenly'
    },
    mobileContentHolder:{
        flexDirection:'column',
    },
    footer:{
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 1,
        marginBottom:0,
        position:'relative',
        flexDirection:'row',
        padding:2,
        zIndex:-1
    },

    divView:{
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 1,
        marginBottom:0,
        position:'relative',
        flexDirection:'column',
        padding:5
    }
});