import React,{FC, useEffect, useState} from "react";
import {View, Text, Image, TouchableOpacity, Dimensions,StyleSheet, FlatList} from "react-native";
import {Style} from './style';
import { useSelector, useDispatch } from 'react-redux';
import Ionicons from '@expo/vector-icons/Ionicons';

interface Props{
  goBack:()=>void;
}

const OrderFileReport:FC<Props>=(props)=>{

    const dispatch:any = useDispatch();
    const [screenType,setScreenType]=useState(Style);
    const [stockList,setStockList] = useState<any>([1,2,3,4,5,6]);


    return (
      <View style={Style.mainContent}>
        <View>
          <View style={Style.container}>
              <View style={Style.containerLeft}>
              <TouchableOpacity onPress={()=>{props.goBack()}}>
                <Ionicons name="arrow-back"  style={Style.menuIcon}/>
              </TouchableOpacity>
                  <Text style={Style.activityTitle}>Order calendar</Text>
              </View>
          </View>
          <View>
            <View style={Style.header}>
                <View style={Style.headerLeft}>
                  <Text style={Style.headerTitle}>{"Files"}</Text>
                </View>
            </View>
            <View>
              <View style={{padding:10,}}>
                <View style={{padding:10,}}>
                  <FlatList
                    data={stockList}
                    renderItem={({item}) => (
                      <TouchableOpacity style={Style.itemContainer} onPress={()=>{(item)}}>
                        <View style={Style.item}>
                            <Text>{item}</Text>
                        </View>
                      </TouchableOpacity>
                    )}
                    keyExtractor={item => item}
                    numColumns={5} />
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    )
}

export default OrderFileReport;
