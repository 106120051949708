import React, { FC, useEffect, useState } from "react";
import { View, TouchableOpacity, Image } from "react-native";
import Style from "./style";
import { DataTable } from 'react-native-paper';
import CustomTextInput from "../CustomTextInput";
import CheckBoxButton from "../CheckBox";
import { useSelector, useDispatch,  } from 'react-redux';
import { createStaff, clearRepo, getStockList, getStaffList, getStaffDetailsById} from '../../Server_Repo/action';

interface Props{
    groupList:any;
    buyerId:any;
    orderId:any;
    stockList:any;
    id:any;
    visible:any;
    stockListUpdated:(userList:any)=>void;
}
const PickStocks:FC<Props> = (props)=>{

    const [addUser,setAddUser] = useState<any>(false);
    const [stockList,setStockList] = useState<any>([]);
    const [selectedStock,setSelectedStock] = useState<any>(props.stockList);
    const [addCheckedAll,setAddCheckedAll] = useState<any>(false);
    const [stockDetailsList,setStockDetailsList] = useState<any>();
    const dispatch:any = useDispatch();

    const {
        stockListResponse
    }  = useSelector((state:any) => state.userReducer);

    useEffect(()=>{
        dispatch(
          getStockList(
            {
              userMail:"parixbe@gmail.com",
              orderID : props.orderId,
              buyerID : props.buyerId,
            }
          )
        )
    },[]);

    useEffect(()=>{
        console.log("PickerStock",stockListResponse);
        if(stockListResponse?.status == 1){
            setStockList(stockListResponse?.data)
        }
    },[stockListResponse!=undefined])

    const updateStockList=(staffData:any)=>{
        var tempStockList = selectedStock ? selectedStock : [];
        var stockDetailsListTemp = stockDetailsList ? stockDetailsList : [];
        if(staffData!=null){
            var indexID = tempStockList.findIndex(function(obj:any){return obj.id == staffData._id});
            if(indexID==-1){
                tempStockList.push({id:staffData._id,qty:0});
                stockDetailsListTemp.push(staffData);
            }else{
                tempStockList.splice(indexID, 1);
                stockDetailsListTemp = stockDetailsListTemp.filter((data:any)=>{
                    return data._id != staffData._id 
                })
            }
            setSelectedStock([...tempStockList]);
        }else{
            if(addCheckedAll==true){
                setSelectedStock([]);
                stockList?.forEach((element:any) => {
                    stockDetailsListTemp =  stockDetailsListTemp.filter((data:any)=>{
                        return data._id != element._id
                    });
                });
            }else{
                tempStockList = [];
                stockList?.forEach((element:any) => {
                    tempStockList.push({id:element._id,qty:0});
                    var alreadyExists =  stockDetailsListTemp.filter((data:any)=>{
                        return data._id == element._id
                    });
                    if(alreadyExists.length == 0)
                    stockDetailsListTemp.push(element);
                });
                setSelectedStock([...tempStockList]);
            }
            setAddCheckedAll(!addCheckedAll);
        }
        console.log("selectedStock",tempStockList);
        setStockDetailsList(stockDetailsListTemp);
        props.stockListUpdated(tempStockList);
    }

    const reqQtyChange = (stockID:any,val:any)=>{
        var tempStockList = selectedStock ? selectedStock : [];
        var index = tempStockList.findIndex(function(obj:any){return obj.id == stockID});
        tempStockList[index] = {
            id:stockID,
            qty:val
        }
        console.log("tempStockList",tempStockList)
        props.stockListUpdated(tempStockList);
        props.stockListUpdated(tempStockList);
    }

    const onVlaueChanged  = (val:any)=>{
        var list = selectedStock.filter((data:any)=>{
           return data.id  == val
        })
        if(list.length>0){
            return Number(list[0]["qty"])==0 ? null : Number(list[0]["qty"]);
        }else{
            return null
        }
       
    }

    return(
        <View>
             <View style={Style.container}>
                <View style={Style.containerLeft}>
                    <View style={{flex:1,flexDirection:'row'}}>
                        {(addUser ? "Add" : "" )+" Stocks"}
                        <TouchableOpacity onPress={()=>{setAddUser(!addUser)}}>
                            <Image style={{height:20,width:20,marginLeft:10,}}
                                source={require("../../assets/images/"+(addUser? "close" : "plus" )+".png")
                            }/>
                        </TouchableOpacity>
                    </View>
                </View>
             </View>
             {
                addUser ? 
                    <View  style={Style.divView}>
                        <View style={Style.userSelectView}>
                        { 
                              stockList?.length>10 ?
                                <CustomTextInput type="text" id="orderName"
                                value={""}
                                placeHolder="Search User Name/ID" onInputChange={(ID,val)=>{(val)}} />
                              : null
                         }
                        </View>
                        <View style={Style.userSelectView}>
                            { stockList ?
                                <DataTable>
                                    <DataTable.Header>
                                        <DataTable.Title>
                                            <CheckBoxButton  id={null}
                                            isChecked={()=>{updateStockList(null)}}
                                            state={addCheckedAll}/>
                                        </DataTable.Title>
                                        <DataTable.Title>Stock Name</DataTable.Title>
                                    </DataTable.Header>
                                    {
                                        stockList.map((userData:any)=>{
                                            return <DataTable.Row> <DataTable.Cell>
                                                <View style={{paddingTop:15,}}>
                                                    <CheckBoxButton  id={null}
                                                        isChecked={()=>{updateStockList(userData)}}
                                                        state={selectedStock&&selectedStock?.findIndex(function(obj:any){return obj.id == userData._id}) !=-1 ? true : false 
                                                    }/>
                                                </View>
                                            </DataTable.Cell>
                                            <DataTable.Cell>{userData?.stockName}</DataTable.Cell>
                                            </DataTable.Row>
                                        })
                                    }
                                </DataTable>
                            : null }
                        </View>
                    </View>
                :   selectedStock ? 
                    <View  style={Style.divView}>
                        { 
                            stockList?.length>10 ?
                                <CustomTextInput type="text" id="orderName"
                                value={""}
                                placeHolder="Search User name/ID" onInputChange={(ID,val)=>{(val)}} />
                            :   null 
                        }
                        <View style={Style.userSelectView}>
                            <DataTable>
                                <DataTable.Header>
                                    <DataTable.Title>
                                        <View style={{padding:1,}}>
                                            <CheckBoxButton  id={null}
                                              isChecked={()=>{updateStockList(null)}}
                                              state={addCheckedAll}/>
                                        </View>
                                    </DataTable.Title>
                                    <DataTable.Title>Stock Name</DataTable.Title>
                                    <DataTable.Title>Required Qty</DataTable.Title>
                                </DataTable.Header>
                                {
                                    stockList?.map((stockData:any)=>{
                                        return  selectedStock?.findIndex(function(obj:any){return obj.id == stockData._id })==-1 ? false : true ? 
                                        <DataTable.Row> <DataTable.Cell>
                                            <View style={{paddingTop:15,}}>
                                                <CheckBoxButton  id={null}
                                                    isChecked={()=>{updateStockList(stockData)}}
                                                    state={
                                                        selectedStock?.findIndex(function(obj:any){return obj.id == stockData._id }) ? false : true 
                                                }/>
                                            </View>
                                        </DataTable.Cell>
                                            <DataTable.Cell>{stockData?.stockName}</DataTable.Cell>
                                            <DataTable.Cell  style={{padding:5}}>
                                                <View>
                                                    <CustomTextInput id={stockData._id} type={"stockQty"}
                                                    value={onVlaueChanged(stockData._id)} 
                                                    placeHolder={"Required Qty"} 
                                                    onInputChange={(id,val)=>{reqQtyChange(stockData._id,Number(val))}}/>
                                                </View>
                                            </DataTable.Cell>
                                        </DataTable.Row> : null
                                    })
                                }
                            </DataTable>
                        </View>
                    </View> 
                : null
             }
        </View>
    )
}

export default PickStocks;