import React,{FC,useState} from "react";
import {Image,View,Text,Alert,TouchableOpacity} from "react-native";
import styles from "./style";
interface Props{
    state:boolean,
    isChecked:(id:any,states:boolean)=>void;
    id:any;
}
const CheckBoxButton:FC<Props>=(props)=>{
    return(
    <View style={styles.container}>
        <TouchableOpacity onPress={()=>{
          props.isChecked(props.id,!props.state)
          console.log("isChecked",props.id)
        }}>
          <View style={styles.checkboxContainer}>
            {props.state==true ? (
              <Image style={styles.checkImage}
              source={require('../../assets/images/check.png')} />
              ) : null
              }
          </View>
        </TouchableOpacity>
    </View>
    );
}

export default CheckBoxButton;