import React, { FC,useState, useEffect } from "react";
import { View,Text, Dimensions } from "react-native";
import Style from "./style";
import { RootStackScreenProps } from '../../types';
import {logoutUser} from "../../utility";
import ToolBarTop from "../../components/ToolBarTop";
import SideMenuBar from "../../components/SideMenuBarTop";
import {useRoute} from '@react-navigation/native';
import BreadCurmbs from "../../components/BreadCrumbs";
import SubTitleHeader from "../../components/SubTitleHeader";
import OrderBasicInfo from "../../components/OrderBasicInfo";
import CustomButton from "../../components/CustomButton";
import CustomTextInput from "../../components/CustomTextInput";
import MainOrderList from "../../components/MainOderList";
const window = Dimensions.get("window");
interface Props{
    rouetData:any;
    routeName:any
    navigation:(dataSets:any,natTo:any)=>void;
}
const OrderList:FC<Props>=(props)=>
{

    const [sideMenu,setSideMenu]=useState(true);
    const [isLargeScreen,setIsLargeScreen]=useState(window.width);
    const [viewType,setViewType]=useState("basicInfo");
    Dimensions.addEventListener("change",({ window}) => {
        setIsLargeScreen(window.width);
    });
    
    useEffect(()=>{
        if(isLargeScreen>=730)
            setSideMenu(true)
        else
            setSideMenu(true)
    },[isLargeScreen])

    const logout=()=>{
        logoutUser().then((status)=>{
            props.navigation("Root",{});
        })
    }
    const navigateTo=(route:any,orderID:any)=>{
        props.navigation(route,{data:{orderID:orderID}});
    }

    const switchComponent=(name:any)=>{
        console.log("switchComponent",name);
    }

    const successCallBack=(data:any)=>{
        if(data&&data.status==1){
            
        }
        console.log("successCallBack",data);
    }

    return (
        <View style={Style.container}>
            <View style={Style.mainContect}>
                {   isLargeScreen>768||sideMenu==false ?
                        <View style={[Style.overAllContent,isLargeScreen<768 ? {width:isLargeScreen}:null]}>
                            <SubTitleHeader title={"Order List"}/>
                            <MainOrderList isLargeScreen={isLargeScreen} successCallBack={()=>{}}
                             onSelectedOrder={(orderID)=>{navigateTo("createOrder",orderID)}}/>
                        </View>
                    : null
                }
            </View>
        </View>
    )
}

export default OrderList;