import { StyleSheet } from "react-native";

const Style = StyleSheet.create({
    container:{
        flexDirection:'row',
        justifyContent:'flex-start',
        padding:20,
    },
    containerLeft:{
        flex:1,
        flexDirection:'row',
        alignSelf:'flex-end',
    },
    containerRight:{
        flex:1,
        flexDirection:'row',
        alignSelf:'flex-start',
        justifyContent:'space-evenly'
    },
    divView:{

    },
    dropDown:{
        marginTop:10,
        height:50,
        borderRadius:5,
    },
    userSelectView:{
        flexDirection:'row',
    }
});

export default Style;