import React,{FC, useEffect, useState} from "react";
import {View, Text, TouchableOpacity, StyleSheet, Picker} from "react-native";
import {Style} from './style';
import { createStaff, clearRepo, getOrderDetails, createStock} from '../../Server_Repo/action';
import CheckBoxButton from "../CheckBox";
import CustomLabel from "../CustomLabel";
import DatePicker from '../DatePicker';
import CustomButton from "../../components/CustomButton";
import FileHandler from "../FilesHandler";
import { useSelector, useDispatch,  } from 'react-redux';
import CustomAlert from "../CustomAlert";
import SubTitleHeader from "../SubTitleHeader";
import PagenationFooter from "../PagenationFooter";
import {getAccessToken, generateColor} from "../../utility/index"
import { DataTable } from 'react-native-paper';
import sideMenuQuery from "../../constants/FormQueries/sidemenu";
import CustomTextInput from "../CustomTextInput";
import ResponsiblePerson from "../ResponsiblePerson";

interface Props{
  currentRoute:any;
  isLargeScreen:number;
  stockDetails:any;
  successCallBack:()=>void;
}
const CreateStocks:FC<Props>=(props)=>{
    console.log("stockDetails",props.stockDetails);
    const dispatch:any = useDispatch();
    const [screenType,setScreenType]=useState(Style);
    const [formError,setFormError]=useState<any>(null);
    const [alertTitle,setAlertTitle]= useState<any>();
    const [alertSubTitle,setAlertSubTitle]= useState<any>();
    const [formStatus,setFormStatus]= useState<any>(0);
    const [groupList,setGroupList]= useState<any>();
    const [stockName,setStockName] = useState<any>(props?.stockDetails?.stockName);
    const [rackID,setRackID] = useState<any>(props?.stockDetails?.rackID);
    const [stockQty,setStockQty] =  useState<any>(props?.stockDetails?.stockQty);
    const [stockLimit,setStockLimit] =  useState<any>(props?.stockDetails?.stockLimit);
    const [resPersons,setResPersons] = useState<any>([]);

    const {
      orderDetails,createStockResponse
    }  = useSelector((state:any) => state.userReducer);
    const [alignContent, setAlignContent] = useState("flex-start");

    useEffect(()=>{
      getOrderList();
      setFormError(0);
    },[]);

    useEffect(()=>{
      console.log("orderDetails",orderDetails);
      if(orderDetails?.status==1){
        setGroupList(orderDetails?.data)
      }
    },[orderDetails!=undefined]);

    useEffect(()=>{
      console.log(
        "createStockResponse",createStockResponse
      )
      if(createStockResponse?.status==1){
        setAlertTitle("Success")
        setAlertSubTitle("Stock created successfully");
        setFormStatus(1);
      }else{
        setFormError(createStockResponse?.error)
      }
    },[createStockResponse!=undefined]);



    const getOrderList=()=>{
      dispatch(
        getOrderDetails(
          {
            fetchType: "users",
            limit: 0,
            skip: 0,
            userMail : "parixbe@gmail.com",
          }
        )
      )
    }

    const createNewStock=()=>{
      dispatch(
        clearRepo()
      )
      setFormError([""]);
      var stockObj={
        "stockName":stockName,
        "rackID":rackID,
        "stockQty":stockQty,
        "stockLimit":stockLimit,
        "resPersons":resPersons
      }
      if(Number(stockQty)<=Number(stockLimit)){
        setFormError(["Stock quantity is less than stock alert limit"])
      }else{
        console.log(
          "sdfghjklllkjhgfd",
          {
            ...props.stockDetails,
            ...stockObj,
  
          }
        )
        dispatch(
          createStock({
            ...props.stockDetails,
            ...stockObj,
          })
        )
      }
    }

    const alertOk= () =>{
      setFormStatus(0)
      props.successCallBack();
    }


    console.log("xxxxxxxxxxx",props?.stockDetails);

    return (
      <View style={Style.mainContent}>
        <View>
          <View style={screenType.container}>
            { formStatus==1 ?
                <View>
                  <CustomAlert title={alertTitle} subTitle={alertSubTitle} onPress={()=>{alertOk()}} />
                </View>
            :  
                <View style={Style.divView}>
                  <CustomTextInput type="text" id="stockName" value={stockName}
                    placeHolder="Stock Name" onInputChange={(ID,val)=>{setStockName(val)}} />
                  <CustomTextInput type="text" id="stockQuty" value={stockQty}
                    placeHolder="Stock quanity" onInputChange={(ID,val)=>{setStockQty(Number(val))}} />
                  <CustomTextInput type="text" id="ractID" value={rackID}
                    placeHolder="Stock rack ID" onInputChange={(ID,val)=>{setRackID(val)}} />
                  <CustomTextInput type="text" id="stockAlertLimit" value={stockLimit}
                    placeHolder="Stock alert limit" onInputChange={(ID,val)=>{setStockLimit(Number(val))}} />
                    {
                      groupList?.length > 0? 
                          <ResponsiblePerson groupList={groupList}
                          id='stockManagement'
                          title={"Add stock incharge"}
                          subRoute={"stockMagement"}
                          orderID={null}
                          currentRoute={props.currentRoute}
                          accessID={props.currentRoute}
                          resPerson = {props?.stockDetails?.resPersons}
                          userListUpdated = {(userList)=>{setResPersons(userList)}}
                          visible={true}/>
                        : null
                    }
                   <CustomLabel type="formErrorMsg" label={""} errorMsg={formError}
                    formRawData={null} successMsg={null}/>
                  <CustomButton buttonID={"Submit"} type="common"
                    title="Create" onSubmit={()=>{createNewStock()}}/>
                </View>
            }
          </View>
        </View>
      </View>
    )
}



export default CreateStocks;


const styleBox = StyleSheet.create({
  box: {
    flex:1,
    width: 120,
    height: 120,
    margin:10,
    padding:10,
    justifyContent:'flex-start',
    borderRadius:5,
    backgroundColor:'#f7f7f7'
  },
  row: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
});