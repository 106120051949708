import React,{FC,useState} from "react";
import {View,Text,Picker,Dimensions, TouchableOpacity, Image} from "react-native";
import Style from "./style";
import Ionicons from '@expo/vector-icons/Ionicons';
import CustomButton from "../CustomButton";
import DatePicker from "../DatePicker";
import { Button } from "react-native-paper";
import {DataTable} from "react-native-paper";
import CustomLabel from "../CustomLabel";
import CheckBoxButton from "../CheckBox";
import {
    LineChart,
    BarChart,
    PieChart,
    ProgressChart,
    ContributionGraph,
    StackedBarChart,
  } from 'react-native-chart-kit';

  interface Props{
    goBack:()=>void;
  }

  const MyLineChart = () => {
    return (
      <>
        <LineChart
          data={{
            labels: ['January', 'February', 'March', 'April', 'May', 'June',1,,2,3,4,5,6,7,],
            datasets: [
              {
                data: [20, 45, 28, 80, 99, 43,1,5,10,10],
                strokeWidth: 2,
              },
            ],
          }}
          width={Dimensions.get('window').width - 370}
          height={400}
          chartConfig={{
            backgroundColor: '#1cc910',
            backgroundGradientFrom: '#eff3ff',
            backgroundGradientTo: '#efefef',
            decimalPlaces: 2,
            color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
            style: {
              borderRadius: 16,
            },
          }}
          style={{
            marginVertical: 8,
            borderRadius: 16,
          }}
        />
      </>
    );
  };

const OrderAdvanceReport:FC<Props>=(props)=>{
  const [formError,setFormError]=useState<any>(null);
  const [filterView,setFilteriewTrue] = useState(true)

    return (
      <View>
          <View style={Style.container}>
              <View style={Style.containerLeft}>
              <TouchableOpacity onPress={()=>{props.goBack()}}>
                <Ionicons name="arrow-back"  style={Style.menuIcon}/>
              </TouchableOpacity>
                  <Text style={Style.activityTitle}>Order Advance Report</Text>
              </View>
          </View>
          <View style={Style.orderAgendaList}>
              {
                filterView == true ?
                    <View style={Style.equalSapce}>
                      <DatePicker label={"Scheduled On"} placeholder={"Production Start Date"}
                        date={''} layerIndex={4} 
                        onDateSelected={(date)=>{""}}/>
                      <DatePicker label={"Scheduled On"} placeholder={"Production Start Date"}
                        date={''} layerIndex={4} 
                        onDateSelected={(date)=>{""}}/>
                      <View style={{padding:5,}}>
                        <View>
                            <View style={{marginTop:10,borderLeftWidth:5,borderLeftColor:'green'}}>
                              <View style={[Style.agendaBreadCrums]}>
                                  <View style={Style.defectMarker}>
                                      <CheckBoxButton  id={null}
                                      isChecked={()=>{(1)}}
                                      state={true}/>
                                  </View>
                                  <Text style={Style.defectMarkerLabel}>By Category</Text>
                              </View>
                              <View style={{marginLeft:'5%'}}>
                                  <DataTable>
                                    <DataTable.Header>
                                      <DataTable.Title>Categroy Name</DataTable.Title>
                                      <DataTable.Title>Categroy Required</DataTable.Title>
                                      <DataTable.Title>Categroy Completed</DataTable.Title>
                                      <DataTable.Title>Categroy defects</DataTable.Title>
                                    </DataTable.Header>
                                    <DataTable.Row>
                                        <DataTable.Cell> 
                                            <View style={{marginTop:10,marginRight:10, flexDirection:'row'}}>
                                              <CheckBoxButton  id={null} isChecked={()=>{(1)}} state={true}/>
                                              <Text style={{marginLeft:10}}>23</Text>
                                            </View>
                                        </DataTable.Cell>
                                        <DataTable.Cell>23</DataTable.Cell>
                                        <DataTable.Cell>23</DataTable.Cell>
                                        <DataTable.Cell>23</DataTable.Cell>
                                    </DataTable.Row>
                                  </DataTable>
                              </View>
                            </View>
                            <View style={[{marginTop:10,borderLeftWidth:5,borderLeftColor:'green'}]}>
                                <View style={Style.agendaBreadCrums}>
                                  <View style={Style.defectMarker}>
                                      <CheckBoxButton  id={null}
                                      isChecked={()=>{(1)}}
                                      state={true}/>
                                  </View>
                                  <Text style={Style.defectMarkerLabel}>By Task</Text>
                                </View>
                                <View style={{marginLeft:'5%'}}>
                                  <DataTable>
                                    <DataTable.Header>
                                      <DataTable.Title>Task Name</DataTable.Title>
                                      <DataTable.Title>Task Required</DataTable.Title>
                                      <DataTable.Title>Task Completed</DataTable.Title>
                                      <DataTable.Title>Task defects</DataTable.Title>
                                    </DataTable.Header>
                                    <DataTable.Row>
                                        <DataTable.Cell> 
                                            <View style={{marginTop:10,marginRight:10, flexDirection:'row'}}>
                                              <CheckBoxButton  id={null} isChecked={()=>{(1)}} state={true}/>
                                              <Text style={{marginLeft:10}}>23</Text>
                                            </View>
                                        </DataTable.Cell>
                                        <DataTable.Cell>23</DataTable.Cell>
                                        <DataTable.Cell>23</DataTable.Cell>
                                        <DataTable.Cell>23</DataTable.Cell>
                                    </DataTable.Row>
                                  </DataTable>
                                </View>
                            </View>
                            <View style={[{marginTop:10,borderLeftWidth:5,borderLeftColor:'green'}]}>
                                <View style={[Style.agendaBreadCrums]}>
                                  <View style={Style.defectMarker}>
                                      <CheckBoxButton  id={null}
                                      isChecked={()=>{(1)}}
                                      state={true}/>
                                  </View>
                                  <Text style={Style.defectMarkerLabel}>By Defect</Text>
                                </View>
                                <View  style={[{marginLeft:'5%'}]}>
                                  <DataTable >
                                    <DataTable.Header>
                                      <DataTable.Title>Defect Name</DataTable.Title>
                                      <DataTable.Title>Defect Stages</DataTable.Title>
                                    </DataTable.Header>
                                    <DataTable.Row>
                                        <DataTable.Cell>
                                          <View style={{marginTop:10,marginRight:10, flexDirection:'row'}}>
                                            <CheckBoxButton  id={null} isChecked={()=>{(1)}} state={true}/>
                                            <Text style={{marginLeft:10}}>23</Text>
                                          </View>
                                        </DataTable.Cell>
                                        <DataTable.Cell>23</DataTable.Cell>
                                    </DataTable.Row>
                                  </DataTable>
                                </View>
                            </View>
                            <View style={{marginTop:10,borderLeftWidth:5,borderLeftColor:'green'}}>
                                <View  style={[Style.agendaBreadCrums]}>
                                  <View style={Style.defectMarker}>
                                      <CheckBoxButton  id={null}
                                      isChecked={()=>{(1)}}
                                      state={true}/>
                                  </View>
                                  <Text style={Style.defectMarkerLabel}>By Stock</Text>
                                </View>
                                <View style={{marginLeft:'5%'}}>
                                  <DataTable>
                                    <DataTable.Header>
                                      <DataTable.Title>Stock Name</DataTable.Title>
                                      <DataTable.Title>Stock Current Qty</DataTable.Title>
                                      <DataTable.Title>Stock Required Qty</DataTable.Title>
                                      <DataTable.Title>Stock Used Qty</DataTable.Title>
                                    </DataTable.Header>
                                    <DataTable.Row>
                                        <DataTable.Cell>
                                          <View style={{marginTop:10,marginRight:10, flexDirection:'row'}}>
                                            <CheckBoxButton  id={null} isChecked={()=>{(1)}} state={true}/>
                                            <Text style={{marginLeft:10}}>23</Text>
                                          </View>
                                        </DataTable.Cell>
                                        <DataTable.Cell>23</DataTable.Cell>
                                        <DataTable.Cell>23</DataTable.Cell>
                                        <DataTable.Cell>23</DataTable.Cell>
                                    </DataTable.Row>
                                  </DataTable>
                                </View>
                            </View>
                            <View style={{marginTop:10,borderLeftWidth:5,borderLeftColor:'green'}}>
                              <View style={Style.agendaBreadCrums}>
                                <View style={Style.defectMarker}>
                                    <CheckBoxButton  id={null}
                                    isChecked={()=>{(1)}}
                                    state={true}/>
                                </View>
                                <Text style={Style.defectMarkerLabel}>By Person</Text>

                              </View>
                              <View style={[{marginLeft:'5%'}]}>
                                <DataTable>
                                  <DataTable.Header>
                                    <DataTable.Title>ID</DataTable.Title>
                                    <DataTable.Title>Person Name</DataTable.Title>
                                    <DataTable.Title>Person Group</DataTable.Title>
                                    <DataTable.Title>Role</DataTable.Title>
                                  </DataTable.Header>
                                  <DataTable.Row>
                                      <DataTable.Cell>
                                        <View style={{marginTop:10,marginRight:10, flexDirection:'row'}}>
                                          <CheckBoxButton  id={null} isChecked={()=>{(1)}} state={true}/>
                                          <Text style={{marginLeft:10}}>23</Text>
                                        </View>
                                      </DataTable.Cell>
                                      <DataTable.Cell>23</DataTable.Cell>
                                      <DataTable.Cell>23</DataTable.Cell>
                                      <DataTable.Cell>23</DataTable.Cell>
                                  </DataTable.Row>
                                </DataTable>
                              </View>
                            </View>
                        </View>
                        <CustomButton buttonID={"Submit"} type="common"
                        title="Create" onSubmit={()=>{setFilteriewTrue(false)}}/>
                      </View>
                    </View>
                : null
              }
              {
                 filterView == false ?
                    <View style={Style.equalSapce}>
                        <View  style={Style.filterView}>
                          <TouchableOpacity onPress={()=>{setFilteriewTrue(true)}}>
                            <Image style={{height:25,width:25,marginTop:5,marginLeft:10,}}
                                  source={require("../../assets/images/back.png")
                              }/>
                          </TouchableOpacity>
                          <Text style={Style.viewMainTitle}>Task Details</Text>
                        </View>
                      <DataTable>
                        <DataTable.Header>
                          <DataTable.Title>Task Name</DataTable.Title>
                          <DataTable.Title>Task Category</DataTable.Title>
                          <DataTable.Title>Task Incharge</DataTable.Title>
                          <DataTable.Title>Stocks</DataTable.Title>
                          <DataTable.Title>Staff involved</DataTable.Title>
                        </DataTable.Header>
                        <DataTable.Row>
                            <DataTable.Cell>23</DataTable.Cell>
                            <DataTable.Cell>23</DataTable.Cell>
                            <DataTable.Cell>23</DataTable.Cell>
                            <DataTable.Cell>23</DataTable.Cell>
                            <DataTable.Cell>Completed</DataTable.Cell>
                        </DataTable.Row>
                      </DataTable>
                    </View>
                 : null
              }
             
          </View>
      </View>
    )
}

export default OrderAdvanceReport;