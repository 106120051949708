import React,{FC, useEffect, useState} from "react";
import { View,Text,Button,Image } from "react-native";
import Style from "./Style";
interface Props{
    progressStatus:(progressStatus:any)=>void
}
const  ProgressBar:FC<Props>=(props)=>{
     const [progrss,setProgress] = useState<number>(0);
    
      async function loadProgress(){
        for(var i=0;i<=250;i++){
            setProgress(i+10);
            await delay(5);
        }
      };

      useEffect(()=>{
        if(progrss==0)
            loadProgress();
        if(progrss>=250)
            props.progressStatus(progrss);
      },[progrss])

 
      function delay(delayInms:any) {
        return new Promise(resolve => {
          setTimeout(() => {
            resolve(2);
          }, delayInms);
        });
      }

      useEffect(() => {
        return () => { 
          setProgress(350)
        }
      }, [])
      
    return (
        <View>
            <View style={Style.progressBarHolder}>
                <View  style={[Style.progressBar,{width:progrss}]}></View>
            </View>
        </View>
    )
}

export default ProgressBar;