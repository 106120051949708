import { StyleSheet } from "react-native";
import * as Colors from '../../constants/Colors';
const Style=StyleSheet.create({
    mainContainer:{
        flex:1,
        backgroundColor:'white',
        shadowColor: '#3199de',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.5,
        shadowRadius: 2,
        elevation: 1,
    },
    container:{
        flex:1,
        padding:20,
    },
    rowWise:{
        flexDirection:'row',
        margin:5,
    },
    breadCrads:{
        margin:5,
        width:240,
        height:130,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 1,
        padding:10,
        backgroundColor:'#ffffff80',
        opacity:.7
  
    },
    totalActiveCard:{

    },
    notification:{
        flex:1,
        margin:15,
        padding:15,
        // borderBottomWidth:5,
        // borderBottomColor:'#4eb0f2',
        height:'98%',
       
    },
    subCardTitle:{
        margin:10,
        fontSize:14,
        fontWeight:'600',
    },
    subCardVal:{
        margin:10,
        fontSize:30,
        fontWeight:'700',
        alignSelf:'stretch'
    },
    totalOrderHolder:{
        borderBottomColor:"#034473",
        borderBottomWidth:5,
    },
    completedOrderHolder:{
        borderBottomColor:"#436e8c",
        borderBottomWidth:5,
    },
    inprogressOrderHolder:{
        borderBottomColor:"#5eb5f2",
        borderBottomWidth:5,
    },
    categoryOrderHolder:{
        borderBottomColor:"#033599",
        borderBottomWidth:5,
    },
    taskOrderHolder:{
        borderBottomColor:"#3d5c9c",
        borderBottomWidth:5,
    },
    defectOrderHolder:{
        borderBottomColor:"#81a6f0",
        borderBottomWidth:5,
    },
    searchIcon:{
        marginLeft:15,
        color:'black',
        fontSize:50
    },
    pageTitleLable:{
        margin:10,
        fontSize:18,
        fontWeight:'600'
    },
    pageSubLabel:{
        margin:10,
        fontSize:18,
        fontWeight:'600'
    },
    countryLabel:{
        fontSize:18,
        fontWeight:'600'
    },
    planDetails:{
        padding:10,
    },
    planCost:{
        fontSize:18,
    },
    planFor:{
        fontSize:18,
        fontWeight:'600'
    },
    planRenewBox:{
        maxWidth:'62.5%'
    },
    equalSapce:{
        flexGrow:1,
    },
});

export default Style;