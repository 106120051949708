import React, { FC, useEffect, useState } from "react";
import {View,Text, Image, TouchableOpacity} from "react-native";
import Style from "./Style";
import sideMenuQuery from "../../constants/FormQueries/sidemenu"; 
import ToolBarTop from "../ToolBarTop";
import { getUserDetails } from "../../utility";
import { getOrderUserList } from '../../Server_Repo/action';
import { useSelector, useDispatch } from 'react-redux';

interface Props{
    routeName:string;
    activeCurm:String;
    isLargeScreen:number;
    orderID:any;
    switchComponent:(name:any)=>void;
}
const BreadCurmbs:FC<Props>=(props)=>{

    const dispatch:any = useDispatch();
    const [collections,setCollection]=useState([]);
    const infroStore:any=[];
    const [localUserDetails,setUserDetails] = useState<any>({});
    const {  getOrderUserListResponse } = useSelector((state:any) => state.userReducer);
    const [ userAccess, setUserAccess] = useState([]);

    useEffect(()=>{
        getUserDetails().then((userDetails:any)=>{
            console.log(
                "BreadCurmbs",
                userDetails.userDetails
            )

            if(userDetails.userDetails.userRole!="Admin"){
                dispatch(
                    getOrderUserList(
                    {
                        orderID: props.orderID,
                        id:"breadCrumbs-userList"
                    })
                )
            }
            
            setUserDetails(JSON.parse(userDetails.userDetails))
        })
    },[]);
    
    useEffect(()=>{
       
        if(getOrderUserListResponse?.status==1&&
            getOrderUserListResponse.id=="breadCrumbs-userList"){
            let access =  getOrderUserListResponse?.data?.filter((useCol:any)=>{
                return useCol.resPerson == localUserDetails._id
            });
            console.log( "BreadCurmbs",access)
            if(access.length>0)
                setUserAccess(access[0].taskAccess)
        }
    },[getOrderUserListResponse!=undefined]);

    useEffect(()=>{
        var tempCollections = sideMenuQuery.filter((data:any)=>
            data.route==props.routeName 
        )
        setCollection(
            tempCollections.length==0? [] : tempCollections[0]["options"]
        );
    },[]);
 
    return(
        <View style={[Style.container,props.isLargeScreen <=730 ? Style.flexDirectionRow : Style.flexDirectionColumn]}>
        {
            collections.length!=0 ?
            collections.map((element:any,index )=> {
                if(userAccess?.filter((access:any)=>{ return access.key == element.id}).length>0||
                    localUserDetails.userRole=="Admin"){
                    return <TouchableOpacity onPress={()=>{props.switchComponent(element.componentName)}} key={index+"key-breadcrumbs"}>
                        <View style={[ Style.contentHolder,
                         element.componentName==props.activeCurm? Style.activeSideMenu : null]}>
                            <Image style={Style.sideMenuIcon} source={
                            require("../../assets/images/"+
                            (infroStore.indexOf(element.id)!=-1? "checkActive.png" : "checkInactive.png" ))}/> 
                            <Text style={[ Style.sidemenuTitle,
                                props.activeCurm==element.route? Style.actveSidemenuTitle:null]}>
                                {element.title}
                            </Text>
                        </View>
                    </TouchableOpacity>
                }else{
                    return null;
                }
            })
            : null
        }
        </View>
    )
}

export default BreadCurmbs;