import React,{FC,useState} from "react";
import {View,Text,Picker,Dimensions, TouchableOpacity, Image} from "react-native";
import Style from "./style";
import Ionicons from '@expo/vector-icons/Ionicons';
import CustomButton from "../CustomButton";
import DatePicker from "../DatePicker";
import { Button } from "react-native-paper";
import {DataTable} from "react-native-paper";
import CustomLabel from "../CustomLabel";
import CheckBoxButton from "../CheckBox";
import {
    LineChart,
    BarChart,
    PieChart,
    ProgressChart,
    ContributionGraph,
    StackedBarChart,
  } from 'react-native-chart-kit';

  interface Props{
    goBack:()=>void;
  }

  const MyLineChart = () => {
    return (
      <>
        <LineChart
          data={{
            labels: ['January', 'February', 'March', 'April', 'May', 'June',1,,2,3,4,5,6,7,],
            datasets: [
              {
                data: [20, 45, 28, 80, 99, 43,1,5,10,10],
                strokeWidth: 2,
              },
            ],
          }}
          width={Dimensions.get('window').width - 370}
          height={400}
          chartConfig={{
            backgroundColor: '#1cc910',
            backgroundGradientFrom: '#eff3ff',
            backgroundGradientTo: '#efefef',
            decimalPlaces: 2,
            color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
            style: {
              borderRadius: 16,
            },
          }}
          style={{
            marginVertical: 8,
            borderRadius: 16,
          }}
        />
      </>
    );
  };

const OrderAgendaReport:FC<Props>=(props)=>{
  const [formError,setFormError]=useState<any>(null);
  const [internalViewType,setInternalViewType]=React.useState("init");
  const [filterStock,setFilterStock] = React.useState<any>();
  const [defectInputedListView,setDefectInputedList] = React.useState<any>(false);

    return (
      <View>
          <View style={Style.container}>
              <View style={Style.containerLeft}>
              <TouchableOpacity onPress={()=>{props.goBack()}}>
                <Ionicons name="arrow-back"  style={Style.menuIcon}/>
              </TouchableOpacity>
                  <Text style={Style.activityTitle}>Order calendar</Text>
              </View>
          </View>
          <View style={Style.orderAgendaList}>
              <View style={Style.equalSapce}>
                  <View>
                    <View>
                        <View style={Style.agendaBreadCrums}>
                            <Text style={Style.defectMarkerLabel}>Total agendas</Text>
                        </View>
                        <View style={Style.agendaBreadCrums}>
                            <View style={Style.defectMarker}>
                                <CheckBoxButton  id={null}
                                isChecked={()=>{(1)}}
                                state={true}/>
                            </View>
                            <Text style={Style.defectMarkerLabel}>No Defect</Text>
                        </View>
                        <View style={Style.agendaBreadCrums}>
                            <View style={Style.defectMarker}>
                                <CheckBoxButton  id={null}
                                isChecked={()=>{(1)}}
                                state={true}/>
                            </View>
                            <Text style={Style.defectMarkerLabel}>No Defect</Text>
                        </View>
                        <View style={Style.agendaBreadCrums}>
                            <View style={Style.defectMarker}>
                                <CheckBoxButton  id={null}
                                isChecked={()=>{(1)}}
                                state={true}/>
                            </View>
                            <Text style={Style.defectMarkerLabel}>No Defect</Text>
                        </View>
                        <View style={Style.agendaBreadCrums}>
                            <View style={Style.defectMarker}>
                                <CheckBoxButton  id={null}
                                isChecked={()=>{(1)}}
                                state={true}/>
                            </View>
                            <Text style={Style.defectMarkerLabel}>No Defect</Text>
                        </View>
                    </View>
                    <Picker 
                      style={Style.resPersonDrop}
                      onValueChange={(itemValue:any, itemIndex:any) =>{}}>
                        <Picker.Item label="By Responsible" value={0}/>
                        <Picker.Item label={"One two three"} value={1} />
                    </Picker>
                    <DatePicker label={"Scheduled On"} placeholder={"Production Start Date"}
                      date={''} layerIndex={4} 
                      onDateSelected={(date)=>{""}}/>
                     <DatePicker label={"Scheduled On"} placeholder={"Production Start Date"}
                      date={''} layerIndex={4} 
                      onDateSelected={(date)=>{""}}/>
                      <CustomButton buttonID={"Submit"} type="common"
                      title="Create" onSubmit={()=>{
                        
                      }}/>
                </View>
              </View>
              <View style={Style.equalSapce}>
                 <Text>
                    
                  </Text>
                  <DataTable>
                      <DataTable.Header>
                        <DataTable.Title>Task Name</DataTable.Title>
                        <DataTable.Title>Task Category</DataTable.Title>
                        <DataTable.Title>Task Incharge</DataTable.Title>
                        <DataTable.Title>Stocks</DataTable.Title>
                        <DataTable.Title>Staff involved</DataTable.Title>
                      </DataTable.Header>
                      <DataTable.Row>
                          <DataTable.Cell>23</DataTable.Cell>
                          <DataTable.Cell>23</DataTable.Cell>
                          <DataTable.Cell>23</DataTable.Cell>
                          <DataTable.Cell>23</DataTable.Cell>
                          <DataTable.Cell>Completed</DataTable.Cell>
                      </DataTable.Row>
                   </DataTable>
              </View>
          </View>
      </View>
    )
}

export default OrderAgendaReport;