import React,{FC, useEffect, useState} from "react";
import { View, Text, TouchableOpacity, Image, FlatList, Picker, ScrollView } from "react-native";
import { useSelector, useDispatch } from 'react-redux';
import CustomAlert from "../CustomAlert";
import CustomTextInput from "../CustomTextInput";
import CustomLabel from "../CustomLabel";
import CustomButton from "../CustomButton";
import { DataTable } from 'react-native-paper';
import Style from "./style";
import CheckBoxButton from "../CheckBox";
import {getCategoryList, getTaskList, getOrderDetails, createOPU, getOPUList} from '../../Server_Repo/action';

interface Props{
    isLargeScreen:number;
    data:any;
    orderID:any;
    buyerID:any;
    successCallBack:(data:any)=>void;
    internalComponentChanges:()=>void;
}
const OrderProductionLine:FC<Props>=(props)=>{
    const [screenType,setScreenType]=useState(Style);
    const [formStatus,setFormStatus]= useState<any>(0);
    const [formError,setFormError]=useState<any>(null);
    const [alertTitle,setAlertTitle]= useState<any>();
    const [alertSubTitle,setAlertSubTitle]= useState<any>();
    const [addProduction,setAddProduction]= useState<any>(false);
    const [lineName,setLineName] = useState<any>();
    const [productionLine,setProductionLine]=useState<any>([]);
    const [categoryData,setCategoryData] = useState<any>([]);
    const [taskData,setTaskData] = useState<any>([]);
    const [orderGroupInfo,setOrderGroupInfoDetails]=useState<any>();

    const [unitView,setUnitView] = useState<any>([]);
    const [editUnitNameList,setUnitNameList] =  useState<any>([]);
    const [deletedProdUnit,setDeletedProdUnit]=useState<any>({
        mainUnit:[],
        subUnit:[]
    });

    const dispatch:any = useDispatch();
    const {
        getCategoryListResponse,
        getTaskListResponse,
        orderDetails,
        createOPUResponse,
        getOPUListResponse
    } = useSelector((state:any) => state.userReducer);


    useEffect(()=>{
        dispatch(
            getCategoryList(
              {
                buyerID: props.buyerID,
                orderID: props.orderID,
                userMail : "parixbe@gmail.com",
              }
            ))
        dispatch(
            getTaskList({
                buyerID: props.buyerID,
                orderID: props.orderID
            }))
        dispatch(getOrderDetails({
            buyerID: props.buyerID,
            orderID: props.orderID,
            userMail : "parixbe@gmail.com",
            fetchType:"users",
            limit: 100,
            skip: 0,
        }));
    },[]);


    useEffect(()=>{
        if(getCategoryListResponse?.status==1){
            setCategoryData(getCategoryListResponse?.data);
        }
        if(getTaskListResponse?.status==1){
            setTaskData(getTaskListResponse?.data);
        }
        if(orderDetails?.status==1){
            setOrderGroupInfoDetails(orderDetails.data);
        }
    },[getCategoryListResponse!=undefined,getTaskListResponse!=undefined]);

    useEffect(()=>{
        if(createOPUResponse?.status==1){
           setFormStatus(1);
           setAlertTitle("Success");
           setAlertSubTitle("Production line saved successfully")
        }else{
            setFormStatus(
                createOPUResponse?.error
            )
        }
    },[createOPUResponse!=undefined]);

    useEffect(()=>{
        dispatch(
            getOPUList(
              {
                buyerID: props.buyerID,
                orderID: props.orderID,
                userMail : "parixbe@gmail.com",
              }
            ))
    },[formStatus==0]);

    useEffect(()=>{
        if(getOPUListResponse?.status==1){
           setProductionLine(getOPUListResponse?.data);
        }
    },[getOPUListResponse!=undefined]);

    const addProductionLine=()=>{
        if(lineName==null){
            setFormError(["All fields are mandatory"])
        }else{
            console.log("productionLine",productionLine);
            var checkName = productionLine.filter((item:any)=>{return item.unitName==lineName});
            if(checkName.length>0){
                setFormError(["Unit name already exists"])
            }else{
                var newProUnitLine:any = productionLine ? productionLine : [];
                newProUnitLine.push({
                    unitName:lineName,
                    subLine:[{
                        subLineName:"",
                        categoryID:0,
                        taskID:0,
                        team:0
                    }]
                })
                console.log("newProUnitLine",newProUnitLine);
                setProductionLine([...newProUnitLine]);
                setLineName(null);
                setAddProduction(!addProduction);
            }
        }
    }

    const addSubUnitLine=(unitName:any)=>{
        var newProUnitLine:any = productionLine ? productionLine : [];
        var index = newProUnitLine.findIndex(function(obj:any){return obj.unitName == unitName})

        if(newProUnitLine[index]["subLine"]==undefined){
            newProUnitLine[index]["subLine"] = [];
        }
        newProUnitLine[index].subLine.push(
            {
                subLineName:"",
                categoryID:0,
                taskID:0,
                team:0
            }
        )
        setProductionLine([...newProUnitLine]);
    }

    const onValueChanged=(mainIndex:any,subIndex:any,field:any,val:any)=>{
        var newProUnitLine:any = productionLine ? productionLine : [];
        if(subIndex==null){
            newProUnitLine[mainIndex]["unitName"] = val;
            console.log("newProUnitLine",newProUnitLine);
            setProductionLine([...newProUnitLine]);
        }else{
            newProUnitLine[mainIndex]["subLine"][subIndex][field]=val;
            console.log("newProUnitLine",newProUnitLine);
            setProductionLine([...newProUnitLine]);
        }
       
    }

    const loadTask=(categoryID:any,index:any,subIndex:any,defaultVal:any)=>{
        var tempTaskData = taskData;
        tempTaskData=tempTaskData?.filter((item:any)=>{
            console.log("tempTaskData", item,categoryID);
            return item.categoryID==categoryID
        });

    
    let val = productionLine[index]["subLine"][subIndex]["taskID"]
    
    console.log("loadTask",val)
      
    return <Picker  
        value={val}
        onValueChange={(itemValue:any, itemIndex:any) =>{
        onValueChanged(index,subIndex,"taskID",itemValue) }}>
        <Picker.Item label="Select Task" value={0} />
        { tempTaskData.map((element:any)=>{
        return  <Picker.Item label={element.taskName} value={element._id} />
        })}
        </Picker>;
        
        
    }

    const saveProductionLine=()=>{

        let validateUnitName:any = [];
        let errorMsg = null;

        productionLine.forEach((element:any) => {
            if(validateUnitName.indexOf(element.unitName)==-1){
                validateUnitName.push(element.unitName);
            }else{
                errorMsg = "Unit name should be unique"
            }
        });

        if(errorMsg==null){
            dispatch(
                createOPU({
                    buyerID: props.buyerID,
                    orderID: props.orderID,
                    userMail : "parixbe@gmail.com",
                    deleteUnits: deletedProdUnit,
                    opu: productionLine
                })
            )
        }else{
            setFormError(errorMsg)
        }
    }

    const viewProUnit=(unitID:any)=>{
        //If it's from server check in with _id or else checkig with index
        let tempUnit = unitView;
        let unitIndex = tempUnit.indexOf(unitID);
        if(unitIndex==-1){
            tempUnit.push(unitID)
        }else{
            tempUnit = tempUnit.filter((unit:any)=>{
                return unit!=unitID
            })
        }
        setUnitView([...tempUnit])
    }

    const editUnitName=(unitID:any)=>{
        let tempEditUnitNameList = editUnitNameList ? editUnitNameList :[];
        if(tempEditUnitNameList.indexOf(unitID)==-1){
            tempEditUnitNameList.push(unitID)
        }else{
            tempEditUnitNameList = tempEditUnitNameList.filter((unitIDs:any)=>{
                return unitIDs != unitID
            })
        }
        console.log(
            "tempEditUnitNameList",tempEditUnitNameList
        )
        setUnitNameList([...tempEditUnitNameList]);
    }

    const deleteMainSubUnit = (unitID:any,unitType:any)=>{
        if(unitType=="main"){
            let mainUnit = deletedProdUnit.mainUnit;
            if( String(unitID).length > 5){
                let mainUitIndex = mainUnit?.indexOf(unitID);
                if(mainUitIndex!=-1){
                    mainUnit = mainUnit?.filter((deletedIndex:any)=>{
                        return deletedIndex != unitID
                    });
                }else{
                    mainUnit.push(unitID)
                }

                console.log(
                    "deleteMainSubUnit",
                    "mainUit",
                    mainUnit
                )
                if(mainUnit!=undefined){
                    setDeletedProdUnit(
                        {
                            mainUnit:[...mainUnit],
                            subUnit:deletedProdUnit.subUnit
                        }
                    )
                }
            }else{
                let  tempProductionLine = productionLine;
                delete tempProductionLine[unitID];
                tempProductionLine = tempProductionLine.filter((el:any) =>{
                    return el != null;
                });
                setProductionLine([...tempProductionLine])
            }
        }else{
            let subUnit = deletedProdUnit.subUnit;
            if( String(unitID).length > 5){
                let mainUitIndex = subUnit?.indexOf(unitID);
                subUnit = subUnit.filter((deletedIndex:any)=>{
                    return deletedIndex != unitID
                });
                if(mainUitIndex==-1){
                    subUnit.push(unitID);
                }
                setDeletedProdUnit(
                    {
                        mainUnit:deletedProdUnit.mainUnit,
                        subUnit:subUnit
                    }
                )
            }else{
                let tempProductionLine = productionLine;
                let tempSubLine = tempProductionLine[unitType]["subLine"];
                tempSubLine = tempSubLine.filter((unitIDs:any,index:any)=>{
                    return index!=unitID
                });
                tempProductionLine[unitType]["subLine"] = tempSubLine;
                console.log(
                    "tempProductionLine",tempProductionLine
                )
                setProductionLine([...tempProductionLine])
            }
        }  
    }

    const checkIsDeleted=(unitID:any,type:any)=>{
        if(type=="main")
            return deletedProdUnit.mainUnit.indexOf(unitID) == -1 ? 'delete' : 'undo'
        else 
            return deletedProdUnit.subUnit.indexOf(unitID) == -1 ? 'delete' : 'undo'
    }

    return (
        <View style={Style.mainContent}>
            <View>
                <View style={Style.container}>
            { formStatus==1 ?
                <View>
                  <CustomAlert title={alertTitle} subTitle={alertSubTitle} onPress={()=>{setFormStatus(0)}} />
                </View>
            :    <View style={screenType.container}>
                    <View style={Style.header}>
                        <View style={Style.headerLeft}>
                            <Text style={Style.headerTitle}>{"Production line"}</Text>
                        </View>
                        <View style={Style.headerRight}>
                            <CustomTextInput type="text" id="categoryID" value={""}
                                placeHolder="Search category" onInputChange={(ID,val)=>{console.log("sa")}}/>
                            <TouchableOpacity onPress={()=>{
                                setFormError(null)
                                setAddProduction(!addProduction)}}>
                                <Image style={Style.headerRightIocns}
                                     source={require("../../assets/images/"+(addProduction? "close" : "plus" )+".png")
                                }/>
                            </TouchableOpacity>
                        </View>
                    </View>
                    {
                        addProduction ? 
                            <View>
                                <View style={Style.toolBar}>
                                    <Text style={Style.headerTitle}>{"Add production Line/Uint"}</Text>
                                </View>
                                <View>
                                    <CustomTextInput type="text" id="unitName"
                                        value={""}
                                        placeHolder="Line or Unit name"
                                        onInputChange={(ID,val)=>{setLineName(val)}} />
                                </View>
                                <View style={Style.footer}>
                                    {
                                        formError!=null ?
                                        <View style={{justifyContent:'center',flex:1}}>
                                                <CustomLabel type="formErrorMsg" label={""} errorMsg={formError}
                                                formRawData={null} successMsg={formError}/>
                                        </View>
                                        : null
                                    }
                                    <CustomButton buttonID={"Submit"} type="common"
                                    title="Create" onSubmit={()=>{addProductionLine()}}/>
                                </View>
                            </View>
                        :   productionLine ?
                                <View>
                                    <FlatList
                                        data={productionLine}
                                        horizontal={true}
                                        renderItem={({item,index}) => (
                                        <View style={Style.unitBreadCurms}>
                                            <CheckBoxButton 
                                             id={"check - "+item.unitName}
                                             state={unitView.indexOf(item._id ? item._id : index)==-1 ? false : true }
                                             isChecked={()=>{viewProUnit(item._id ? item._id : index)}}/>
                                            <Text style={Style.unitBCTitle}>{item.unitName}</Text>
                                        </View>)
                                    }/>       
                                     <View>
                                        {
                                            productionLine.map((item:any,index:any) => {
                                                return <View>
                                                { 
                                                     unitView.length==0 || unitView.indexOf(item._id ? item._id : index)!=-1 ?
                                                         <View style={Style.itemContainer}>
                                                             <View style={Style.item}>
                                                                 <View style={Style.header}>
                                                                     <View style={Style.headerLeft}>
                                                                         {
                                                                             (item?._id ? editUnitNameList.indexOf(item._id) :
                                                                             editUnitNameList.indexOf(index)) == -1 ?
                                                                                 <Text style={Style.headerTitle}>{item.unitName}</Text>
                                                                             :
                                                                             <CustomTextInput type="others" id="unitName"
                                                                             value={item?.unitName}
                                                                             placeHolder="Sub Line Name"
                                                                             onInputChange={(ID,val)=>{onValueChanged(index,null,"unitName",val)}} />
                                                                         }
                                                                        
                                                                         <TouchableOpacity style={{flexDirection:'row'}} onPress={()=>{addSubUnitLine(item.unitName)}}>
                                                                             <Image style={Style.addSubLineImg}
                                                                                 source={require("../../assets/images/"+(addProduction? "close" : "plus" )+".png")
                                                                             }/>
                                                                             <Text style={{marginTop:25}}>Add sub Line/Unit</Text>
                                                                         </TouchableOpacity>
                                                                     </View>
                                                                     <View style={Style.headerRight}>
                                                                         <TouchableOpacity onPress={()=>{
                                                                             editUnitName(item._id ? item._id : index)
                                                                         }}>
                                                                             <Image style={Style.deleteCurmIcons}
                                                                             source={require('../../assets/images/pencil.png')}/>
                                                                         </TouchableOpacity>
                                                                         <TouchableOpacity onPress={()=>{
                                                                             deleteMainSubUnit(item._id ? item._id : index,"main")
                                                                         }}>
                                                                             <Image style={Style.deleteCurmIcons}
                                                                             source={require('../../assets/images/'+
                                                                                checkIsDeleted(item._id ? item._id : index,"main")
                                                                             +'.png')}/>
                                                                         </TouchableOpacity>
                                                                     </View>
                                                                 </View>
                                                                 <DataTable>
                                                                     <DataTable.Header>
                                                                         <DataTable.Title>
                                                                             <Text style={{marginLeft:15, marginTop:-2.5,position:'absolute'}}>Sub Line/Unit name</Text>
                                                                         </DataTable.Title>
                                                                         <DataTable.Title>Assigned Category</DataTable.Title>
                                                                         <DataTable.Title>Action</DataTable.Title>
                                         
                                                                     </DataTable.Header>
                                                                     <ScrollView>
                                                                     {
                                                                         item?.subLine?.map((element:any,subIndex:any) => {
                                                                         return  <DataTable.Row>
                                                                                 <DataTable.Cell>
                                                                                     <CustomTextInput type="others" id="unitName"
                                                                                         value={element?.subLineName}
                                                                                         placeHolder="Sub Line Name"
                                                                                         onInputChange={(ID,val)=>{onValueChanged(index,subIndex,"subLineName",val)}} />
                                                                                 </DataTable.Cell>
                                                                                 <DataTable.Cell>
                                                                                     <View>
                                                                                         <Picker
                                                                                             value={element.categoryID}
                                                                                             onValueChange={(itemValue:any, itemIndex:any) =>{
                                                                                                 onValueChanged(index,subIndex,"categoryID",itemValue)
                                                                                             }}>
                                                                                             <Picker.Item label="Select Category" value={0} />
                                                                                             {
                                                                                                 categoryData?.map((element:any)=>{
                                                                                                 return <Picker.Item label={element.categoryName} value={element._id} />
                                                                                                 })
                                                                                             }
                                                                                         </Picker>
                                                                                     </View>
                                                                                     <View>
                                                                                         {
                                                                                             element.categoryID!=0 ? 
                                                                                               loadTask(element.categoryID,index,subIndex,element.taskID)
                                                                                             : null
                                                                                         }
                                                                                     </View>
                                                                                 </DataTable.Cell>
                                                                                 <DataTable.Cell>
                                                                                     <TouchableOpacity onPress={()=>{deleteMainSubUnit(element._id ? element._id : subIndex, index)}}>
                                                                                        <Image style={Style.deleteCurmIcons}
                                                                                        source={require('../../assets/images/'+
                                                                                            checkIsDeleted(element._id ? element._id : subIndex,"subLine")
                                                                                        +'.png')}/>
                                                                                     </TouchableOpacity>
                                                                                 </DataTable.Cell>
                                                                             </DataTable.Row>
                                                                         })
                                                                     }
                                                                     </ScrollView>
                                                                 </DataTable>
                                                             </View>
                                                         </View>
                                                     : null
                                                 }
                                             </View>
                                            })
                                        }
                                    </View>
                                   {
                                        productionLine.length > 0 ? 
                                            <View style={Style.footer}>
                                                 <View style={{justifyContent:'center',flex:1}}>
                                                    <CustomLabel type="formErrorMsg" label={""} errorMsg={formError}
                                                    formRawData={null} successMsg={formError}/>
                                                </View>
                                                <CustomButton buttonID={"Submit"} type="common"
                                                title="Save" onSubmit={()=>{saveProductionLine()}}/>
                                            </View>
                                        : null
                                   }
                                </View>
                            : <View>Add your new production unit/line</View> 
                    }
                </View>
            }
                </View>
            </View>
        </View>
    )
}

export default OrderProductionLine;