
import {routeURL} from "../constants/RouteParams";
import {getAccessToken} from "../utility/index";
export async function  Server_Handler(param:String,data:any,method="POST"){
    var  respone:any ;
    var token = await getAccessToken().then((result:any)=>{
        return result.accessToken;
        console.log("result",result.accessToken);
    });
    try {
        respone  =  (fetch(routeURL+param,{
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
                },
                body: JSON.stringify(data)
            })
            .then(response=>response.json())
            .then(response => {
                return response;
             }).catch(error=>{
                return data={
                    "status":400,
                    "data":[],
                    "error":error,
                    "message":"Some thing went wrong please try again later"
                }
            }));
    } catch (
        error
    ) {
        console.log("respone",error)
    }
    return respone;
}