import { StyleSheet } from "react-native";

const Style = StyleSheet.create({
    mainContent:{
        flex:1,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 10,
        elevation: 3,
        padding:5,
    },
    container:{

    },
    header:{
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.05,
        shadowRadius: 0,
        elevation: 1,
        justifyContent:'center',
        flexDirection:'row'
    },
    headerLeft:{
        flex:1,
        flexDirection:'row'
    },
    headerRight:{
        flex:1,
        flexDirection:'column'
    },
    headerTitle:{
        fontSize:15,
        fontWeight:'500',
        margin:25
    },
    headerRightIocns:{
        height:30,
        width:30,
        margin:15,
        marginRight:25,
        alignSelf:'flex-end'
    },
    headerLeftIocns:{
        height:30,
        width:30,
        margin:20,
        marginRight:2,
        alignSelf:'flex-end'
    },
    categoryView:{
        flexDirection:'row'
    },
    toolBar:{
        flex:1,
        padding:10,
        backgroundColor:'#f7f9fa'
    },
    toolBarImg:{
        height:30,
        width:30,
        alignSelf:'flex-end'
    },
    footer:{
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 1,
        marginBottom:0,
        position:'relative',
        justifyContent:'center',
        padding:2,
    },
    itemContainer: {
        flex:1,
        flexGrow:1,
    },
    item: {
        flex: 1,
        margin: 5,
        backgroundColor: 'white',
        borderStyle:'solid',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.5,
        shadowRadius: 2,
        elevation: 5,
        justifyContent:'flex-end'
    },
    unitName:{
        fontSize:20,
        margin:20,
    },
    unitBreadCurms:{
        height:40,
        margin:5,
        padding:10,
        justifyContent:'space-evenly'
    },
    unitBCTitle:{
        marginLeft:30
    },
    addSubLineImg:{
        margin:20,
        marginTop:25,
        height:20,
        width:20,
    },
    dateRow:{
        flexDirection:'row',
        flexGrow:1,
        flexShrink:1,
    },
    weekNameHolder:{
        height:70,
        width:90,
        justifyContent:'flex-end',
        alignItems:'flex-end',
        backgroundColor:'#f2f9ff',
        margin:1,
        padding:5,
    },
    dropDown:{
        height:40,
        width:140,
        padding:10,
        margin:10,
    },
    rowWise:{
        flexDirection:'row',
        zIndex:5
    },
    deleteIcon:{
        margin:25
    }
});

export default Style;