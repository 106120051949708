import { StyleSheet, Dimensions } from "react-native";
import * as Colors from '../../constants/Colors';
const Style=StyleSheet.create({
    container:{
        flex:1,
        backgroundColor:'white',
        shadowColor: '#3199de',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.5,
        shadowRadius: 2,
        elevation: 1,
    },
    mainContect:{
      flex:1,
      flexDirection:'row',
    },
    overAllContent:{
        flexGrow:1,
        margin:10,
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.2,
        shadowRadius: 2,
    },
    content:{
        flex:8,
        flexGrow:1,
        flexDirection:'row',
    },
    componentHolder:{
        flex:1,
        display:'flex',
        padding:20
    },
    flexDirectionRow:{
        flexDirection:'row',
        overflow:'scroll'
    },
    flexDirectionColumn:{
        flexDirection:'column',
        maxHeight:120,
    },
    mainContent:{
        flex:1,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 10,
        elevation: 3,
    },
    errorText:{
        display:'none',
        color:'red',
        marginTop:15,
        margin:10,
        alignSelf:'flex-start',
    },
    contentHolder:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-evenly'
    },
    mobileContentHolder:{
        flexDirection:'column',
    },
    footer:{
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 1,
        marginBottom:0,
        position:'relative',
        flexDirection:'row',
        padding:2,
        zIndex:-1
    },
    divView:{
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 1,
        marginBottom:0,
        position:'relative',
        flexDirection:'column',
        padding:15
    },
    groupContainer:{
       padding:10,
    },
    header:{
        height:55,
        flexDirection:'row',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.05,
        shadowRadius: 0,
        elevation: 1,
    },
    headerLeft:{
        flex:1,
        flexDirection:'row',
        alignSelf:'flex-start',
    },
    headerRight:{
        flex:1,
    },
    headerTitle:{
        fontSize:15,
        fontWeight:'500',
        margin:25
    },
    headerRightIocns:{
        height:30,
        width:30,
        margin:20,
        marginRight:25,
        alignSelf:'flex-end'
    },
    headerLeftIocns:{
        height:30,
        width:30,
        margin:20,
        marginRight:2,
        alignSelf:'flex-end'
    },
    cardView:{
        marginTop:15,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 10,
    },
    itemContainer: {
        flex:1,
        flexGrow:1,
        height: 200,
    },
    item: {
        flex: 1,
        margin: 5,
        backgroundColor: 'white',
        borderRadius:5,
        borderStyle:'solid',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.5,
        shadowRadius: 2,
        elevation: 5,
        justifyContent:'flex-end'
    },
    stockQty:{
        fontSize:15,
        margin:10,
        marginBottom:5
    },
    rackId:{
        fontSize:15,
        margin:10,
        marginBottom:5
    },
    stockName:{
        fontSize:20,
        fontWeight:'500',
        margin:10,
        marginTop:5,
        maxWidth:400
    }
});

export default Style;