import { StyleSheet } from "react-native";

const Style=StyleSheet.create({
    container:{
        minWidth:360,
        justifyContent:'center'
    },
    inputField:{
        margin:5,
        marginLeft:10,
        height:45,
        backgroundColor:'white',
        borderBottomColor:'red',
    }
});

export default Style;