const sideMenuQuery:any=[
    {
       id:"dashboard",
       title:"Dashboard",
       icon:"dashboard.png",
       active:false,
       route:"dashboard",
       options:[]
    },
    {
        id:"createOrder",
        title:"Production",
        icon:"createOrder.png",
        route:"createOrder",
        active:false,
         options:[{
            id:"basicInfo",
            title:"Basic Info",
            icon:"basicInfo.png",
            active:false,
            componentName:"basicInfo",
            options:[
               {title:"View Only",value:0},
               {title:"Edit",value:1}
            ]
         },{
            id:"users",
            title:"Users",
            icon:"users.png",
            active:false,
            componentName:"users",
            options:[
               {title:"View Only",value:0},
               {title:"Edit",value:1}
            ]
         },{
            id:"category",
            title:"Category",
            icon:"category.png",
            active:false,
            componentName:"category",
            options:[
               {title:"View Only",value:0},
               {title:"Edit",value:1}
            ]
         },{
            id:"task",
            title:"Task",
            icon:"task.png",
            active:false,
            componentName:"task",
            options:[
               {title:"View Only",value:0},
               {title:"Edit",value:1}
            ]
         },{
            id:"productionLine",
            title:"Production Line",
            icon:"productionLine.png",
            active:false,
            componentName:"opu",
            options:[
               {title:"View Only",value:0},
               {title:"Edit",value:1}
            ]
         },{
            id:"eventAgenda",
            title:"Event Agenda",
            icon:"eventAgenda.png",
            active:false,
            componentName:"eventAgenda",
            options:[
               {title:"View Only",value:0},
               {title:"Edit",value:1}
            ]
         },{
            id:"calendarEvent",
            title:"Event Calendar",
            icon:"calendarEvent.png",
            active:false,
            componentName:"calendarEvent",
            options:[
               {title:"View Only",value:0},
               {title:"Edit",value:1}
            ]
         },{
            id:"productionInput",
            title:"Production Input",
            icon:"productionInput.png",
            active:false,
            componentName:"productionInput",
            options:[
               {title:"View Only",value:0},
               {title:"Edit",value:1}
            ]
         },{
            id:"defectInput",
            title:"Defect Input",
            icon:"defectInput.png",
            active:false,
            componentName:"defectInput",
            options:[
               {title:"View Only",value:0},
               {title:"Edit",value:1}
            ]
         },
         ,{
            id:"stocks",
            title:"Stocks",
            icon:"stocks.png",
            active:false,
            componentName:"stocks",
            options:[
               {title:"View Only",value:0},
               {title:"Edit",value:1}
            ]
         },
         {
            id:"orderReport",
            title:"Report",
            icon:"report.png",
            active:false,
            componentName:"orderReport",
            options:[
               {title:"View Only",value:0},
               {title:"Download Report",value:1}
            ]
         }]
     },
     {
        id:"orderList",
        title:"Orders List",
        icon:"orderList.png",
        route:"orderList",
        active:false,
        options:[]
     },
     {
      id:"userManagement",
      title:"Users control",
      icon:"userManagement.png",
      route:"userManagement",
      active:false,
      options:[]
     },
     {
        id:"stockManagement",
        title:"Stocks",
        icon:"stockManagement.png",
        route:"stockManagement",
        active:false,
        options:[{
            id:"viewStock",
            title:"Stock",
            icon:null,
            active:false,
            componentName:"Stock",
            options:[
               {title:"View only",value:0},
               {title:"Edit",value:1}
            ]
         },{
            id:"createStock",
            title:"Create Stock",
            icon:null,
            active:false,
            componentName:"Create Stock",
            options:[
               {title:"Disable",value:0},
               {title:"Enable",value:1}
            ]
      }]
     },
   //   {
   //      id:"eventCalendar",
   //      title:"Events",
   //      icon:"eventCalendar.png",
   //      route:"eventCalendar",
   //      active:false,
   //      options:[]
   //   },
     {
        id:"assetStore",
        title:"Asset Store",
        icon:"assetStore.png",
        route:"assetStore",
        active:false,
        options:[]
     },
     {
        id:"setting",
        title:"Account",
        icon:"settings.png",
        route:"setting",
        active:false,
        options:[]
     },
     {
        id:"support",
        title:"Support",
        icon:"support.png",
        route:"support",
        active:false,
        options:[]
     },
     {
         id:"fileHandler",
         visible:false,
         title:"Events",
         icon:"eventCalendar.png",
         route:"fileHandler",
         active:false,
         options:[{
            id:"fileAccess",
            title:"File Access",
            icon:null,
            active:false,
            componentName:"Create Stock",
            options:[
               {title:"View",value:0},
               {title:"Download",value:1},
               {title:"Edit",value:2}
            ]
         }]
      },
]

export default sideMenuQuery;