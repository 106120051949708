import React,{FC, useEffect, useState} from "react";
import {View,Text,Picker,Dimensions, TouchableOpacity, Image} from "react-native";
import Style from "./style";
import Ionicons from '@expo/vector-icons/Ionicons';
import CustomButton from "../CustomButton";
import DatePicker from "../DatePicker";
import { Button } from "react-native-paper";
import {DataTable} from "react-native-paper";
import CustomLabel from "../CustomLabel";
import { getReportFilterDetails, getActualReportDetails, clearRepo } from '../../Server_Repo/action';
import { useSelector, useDispatch } from 'react-redux';
import {
    LineChart,
    BarChart,
    PieChart,
    ProgressChart,
    ContributionGraph,
    StackedBarChart,
  } from 'react-native-chart-kit';

  interface Props{
    goBack:()=>void;
    orderID:any;
    isLargeScreen:any;
  }

  const MyLineChart = () => {
    return (
      <>
        <LineChart
          data={{
            labels: ['January', 'February', 'March', 'April', 'May', 'June',1,,2,3,4,5,6,7,],
            datasets: [
              {
                data: [20, 45, 28, 80, 99, 43,1,5,10,10],
                strokeWidth: 2,
              },
            ],
          }}
          width={Dimensions.get('window').width - 370}
          height={400}
          chartConfig={{
            backgroundColor: '#1cc910',
            backgroundGradientFrom: '#eff3ff',
            backgroundGradientTo: '#efefef',
            decimalPlaces: 2,
            color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
            style: {
              borderRadius: 16,
            },
          }}
          style={{
            marginVertical: 8,
            borderRadius: 16,
          }}
        />
      </>
    );
  };

const OrderDefectReport:FC<Props>=(props)=>{
  const [formError,setFormError]=useState<any>(null);
  const [internalViewType,setInternalViewType]=React.useState("init");
  const [filterMenu,setFilterMenu] = React.useState<any>();
  const [defectInputedListView,setDefectInputedList] = React.useState<any>(false);
  const [levelFilter,setLevelFilter] = React.useState<any>(0);
  const dispatch:any = useDispatch();

  //Filter 
  const [filterData,setFilterData] = useState<any>({init:true});

    //Rendering data
    const [data,setData] = useState<any>();
    const [selectedCategoryData,setSelectedCategryData] = useState<any>();
    const [selectedTaskData,setSelectedTaskData] = useState<any>();
    const [searchData,setSearchData] = useState<any>({});
    
    const {
      getReportFilterReponse,
      getActualReportDetailsReponse
    } = useSelector((state:any) => state.userReducer);

    useEffect(()=>{
      dispatch(
        clearRepo()
      )
      dispatch(getReportFilterDetails({
        orderID:props.orderID,
        filterFor:"defectInfo",
      }))
      dispatch(getActualReportDetails({
        orderID:props.orderID,
        routeFor:"actualReport",
        query:{
          ...filterData
        } 
      }))
    },[])

    useEffect(()=>{

     
      if(getReportFilterReponse&&getReportFilterReponse.status=="1"){
        setSearchData(getReportFilterReponse.data[0]);
      }
      if(getActualReportDetailsReponse&&getActualReportDetailsReponse.status==1){
        setData(getActualReportDetailsReponse.data[0]);
      
      }
      setFilterMenu(false)
      console.log("Rendering",'Render',filterMenu);
   
    },[getReportFilterReponse!=null,getActualReportDetailsReponse!=null])
    


    return (
      <View>
          <View style={Style.container}>
              <View style={Style.containerLeft}>
              <TouchableOpacity onPress={()=>{props.goBack()}}>
                <Ionicons name="arrow-back"  style={Style.menuIcon}/>
              </TouchableOpacity>
                  <Text style={Style.activityTitle}>Defect Report</Text>
              </View>
          </View>

          {
            defectInputedListView == false ?
                <View>
                  <View style={Style.filterView}>
                    <Text>Filter Defect </Text>
                    <TouchableOpacity onPress={()=>{setFilterMenu(!filterMenu)}}>
                        <Image style={{height:20,width:20,marginLeft:10,}}
                          source={require("../../assets/images/"+(filterMenu? "close" : "filter" )+".png")
                        }/>
                    </TouchableOpacity>
                  </View>
                  <View style={Style.defectLevelHolders}>
                    <TouchableOpacity  onPress={()=>{setLevelFilter(0)}}
                    style={[Style.defectLevelCrums,levelFilter==0 ? Style.activeFilterlevel : null]}>
                      <Text style={Style.defectLevelLable}>All</Text>
                    </TouchableOpacity>
                    <TouchableOpacity 
                     onPress={()=>{setLevelFilter(1)}}
                     style={[Style.defectLevelCrums,levelFilter==1 ? Style.activeFilterlevel : null]}>
                      <Text style={Style.defectLevelLable}>High</Text>
                    </TouchableOpacity>
                    <TouchableOpacity  
                     onPress={()=>{setLevelFilter(2)}}
                    style={[Style.defectLevelCrums,levelFilter==2 ? Style.activeFilterlevel : null]}>
                      <Text style={Style.defectLevelLable}>Medium</Text>
                    </TouchableOpacity>
                    <TouchableOpacity 
                    onPress={()=>{setLevelFilter(3)}}
                    style={[Style.defectLevelCrums,levelFilter==3 ? Style.activeFilterlevel : null]}>
                      <Text style={Style.defectLevelLable}>Low</Text>
                    </TouchableOpacity>
                    <TouchableOpacity   onPress={()=>{setLevelFilter(4)}}
                     style={[Style.defectLevelCrums,levelFilter==4 ? Style.activeFilterlevel : null]}>
                      <Text style={Style.defectLevelLable}>Informable</Text>
                    </TouchableOpacity>
                  </View>
                </View>
            : null
          }
        
          { filterMenu == true ?
              <View>
                <View style={Style.filterOptions}>
                    <Picker
                        style={Style.dropDown}
                        onValueChange={() =>{console.log()}}>
                        <Picker.Item label="Select Status" value={0} />
                        <Picker.Item label="Completed" value={1} />
                        <Picker.Item label="In production" value={2} />
                        <Picker.Item label="In production" value={3} />
                    </Picker>
                    <Picker
                        style={Style.dropDown}
                        onValueChange={() =>{console.log()}}>
                        <Picker.Item label="Select Category" value={0} />
                        <Picker.Item label="Completed" value={1} />
                        <Picker.Item label="In production" value={2} />
                        <Picker.Item label="In production" value={3} />
                    </Picker>
                    <Picker
                        style={Style.dropDown}
                        onValueChange={() =>{console.log()}}>
                        <Picker.Item label="Select Task" value={0} />
                        <Picker.Item label="Completed" value={1} />
                        <Picker.Item label="In production" value={2} />
                        <Picker.Item label="In production" value={3} />
                    </Picker>
                </View>
                <View style={Style.filterOptions}>
                    <Picker
                        style={Style.dropDown}
                        onValueChange={() =>{console.log()}}>
                        <Picker.Item label="Select Defect Format" value={0} />
                        <Picker.Item label="Completed" value={1} />
                        <Picker.Item label="In production" value={2} />
                        <Picker.Item label="In production" value={3} />
                    </Picker>
                    <Picker
                        style={Style.dropDown}
                        onValueChange={() =>{console.log()}}>
                        <Picker.Item label="Select Defect Leeel" value={0} />
                        <Picker.Item label="Completed" value={1} />
                        <Picker.Item label="In production" value={2} />
                        <Picker.Item label="In production" value={3} />
                    </Picker>
                </View>
                <View style={Style.filterOptions}>
                    <DatePicker label={"Start Date"} placeholder={"Start Date"}
                        date={""} layerIndex={4} onDateSelected={(date)=>{(date)}}/>
                    <DatePicker label={"End Date"} placeholder={"End Date"}
                        date={""} layerIndex={4} onDateSelected={(date)=>{(date)}}/>
                </View>
                <View style={Style.footer}>
                  <View style={{justifyContent:'center',flex:1}}>
                    <CustomLabel type="formErrorMsg" label={""} errorMsg={formError}
                      formRawData={null} successMsg={null}/>
                  </View>
                  <CustomButton buttonID={"Submit"} type="common"
                  title="Apply" onSubmit={()=>{(null)}}/>
                </View>
              </View>
          : defectInputedListView == false ?
            <View>
                <View>
                  <DataTable>
                    <DataTable.Header>
                      <DataTable.Title>Defect Category</DataTable.Title>
                      <DataTable.Title>Defect Counts</DataTable.Title>
                      <DataTable.Title>Defect Level</DataTable.Title>
                    </DataTable.Header>
                    <DataTable.Row onPress={()=>{setDefectInputedList(true)}}>
                      <DataTable.Cell>123</DataTable.Cell>
                      <DataTable.Cell>123</DataTable.Cell>
                      <DataTable.Cell>23</DataTable.Cell>
                    </DataTable.Row>
                  </DataTable>
                </View>
            </View>
            : null
          }
        {
          defectInputedListView == true ?
            <View>
            <View  style={Style.filterView}>
              <TouchableOpacity onPress={()=>{setDefectInputedList(false)}}>
                <Image style={{height:25,width:25,marginTop:5,}}
                      source={require("../../assets/images/back.png")
                  }/>
              </TouchableOpacity>
              <Text style={Style.viewMainTitle}>Defect List</Text>
            </View>
            <View>
              <View style={Style.fileViewTabls}>
                  <TouchableOpacity style={[Style.fileViewBtn,Style.activeFileViewBtn]}>
                      <Text style={[
                        Style.fileBtnLabel, Style.activeFileBtnLabel 
                      ]}>Category Defects</Text>
                  </TouchableOpacity>
              </View>
              <View style={Style.orderInfo}>
                <View>
                    <DataTable.Header>
                        <DataTable.Title>Category Name</DataTable.Title>
                        <DataTable.Title>Mapping ID</DataTable.Title>
                        <DataTable.Title>Defect Level</DataTable.Title>
                        <DataTable.Title>Status</DataTable.Title>
                        <DataTable.Title>Inputed By</DataTable.Title>
                        <DataTable.Title>Marked By</DataTable.Title>
                    </DataTable.Header>
                </View>
              </View>
            </View>
            <View>
              <View style={Style.fileViewTabls}>
                  <TouchableOpacity style={[Style.fileViewBtn,Style.activeFileViewBtn]}>
                      <Text style={[
                        Style.fileBtnLabel, Style.activeFileBtnLabel 
                      ]}>Tasks Defects</Text>
                  </TouchableOpacity>
              </View>
              <View style={Style.orderInfo}>
                <View>
                    <DataTable.Header>
                        <DataTable.Title>Task Name</DataTable.Title>
                        <DataTable.Title>Mapping ID</DataTable.Title>
                        <DataTable.Title>Defect Level</DataTable.Title>
                        <DataTable.Title>Status</DataTable.Title>
                        <DataTable.Title>Inputed By</DataTable.Title>
                        <DataTable.Title>Marked By</DataTable.Title>
                    </DataTable.Header>
                </View>
              </View>
            </View>
            </View>
          : null
        }
      </View>
    )
}

export default OrderDefectReport;