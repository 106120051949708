import React,{FC, useEffect, useState} from "react";
import { View, Text, TouchableOpacity, Image, FlatList, Picker, ScrollView } from "react-native";
import { useSelector, useDispatch } from 'react-redux';
import CustomAlert from "../CustomAlert";
import CustomTextInput from "../CustomTextInput";
import CustomLabel from "../CustomLabel";
import CustomButton from "../CustomButton";
import DatePicker from "../DatePicker";
import { DataTable } from 'react-native-paper';
import Style from "./style";
import CheckBoxButton from "../CheckBox";
import {
     getCategoryList,
     productionInput,
     getTaskList, 
     getOrderDetails, 
     productionInputList,
     createDefectType,
     getDefectTypeLists,
     inputDefect,
     inputDefectList,
     fileAccess
} from '../../Server_Repo/action';
import ResponsiblePerson from "../ResponsiblePerson";
import FileHandler from "../FilesHandler";
import SearchUserDropDown from "../SearchUserDropDown";
import { createIconSetFromFontello } from "@expo/vector-icons";
import Ionicons from '@expo/vector-icons/Ionicons';

interface Props{
    isLargeScreen:number;
    data:any;
    orderID:any;
    buyerID:any;
    successCallBack:(data:any)=>void;
    internalComponentChanges:()=>void;
}
const ProductionDefectInput:FC<Props>=(props)=>{
    const [screenType,setScreenType]=useState(Style);
    const [formStatus,setFormStatus]= useState<any>(0);
    const [formError,setFormError]=useState<any>(null);
    const [alertTitle,setAlertTitle]= useState<any>();
    const [alertSubTitle,setAlertSubTitle]= useState<any>();
    const [addEvent,setEvent]= useState<any>(false);

    const [currentYear,setCurrentYear]= useState<any>('');
    const [currentMonth,setCurrentMonth]= useState<any>('');
    const [dateList,setDateList]= useState<any>('');

    const [filteredDefectList,setFilteredDefectList]= useState<any>([]);
    const [categoryList,setCategoryList]= useState<any>();
    const [taskList,setTaskList]= useState<any>();
    const [inputType,setInputType]= useState<any>(2);
    const [selectedCategory,setSelectedCategory]= useState<any>();
    const [selectedTask,setSelectedTask]= useState<any>();


    const [desc,setDesc]= useState<any>();
    const [currentDefectInput,seCurrentDefectInput]= useState<any>();
    // Current category helps to filter over data to view
    const [currentCategory,setCurrentCategory]= useState<any>(0);
    const [addDefectType,setAddDefectType]= useState<any>(false);
    const [defectTypeList,setDefectTypeLList]= useState<any>([]);
    const [selectedDefect,setSelectedDefect]= useState<any>();
    const [selectedLevelDefect,setSelectedLevelDefect]= useState<any>();
    const [defectList,setDefectList]= useState<any>([]);

    //Event list 
    const [eventList,setEventList]= useState<any>();
    const [filteredEventList,setFilteredEventList]= useState<any>([]);
    const [currentInputSelected,setCurrentInputSelected]= useState<any>({});
    const [defectStatus,setDefectStatus]= useState<any>(0);
    const [mappingID,setMappingID]= useState<any>(0);


    //Views contrller
    const [viewDefect,setViewDefect]= useState<any>(true);
    const [viewCurrentInputed,setCurrentInput]= useState<any>(false);
    const [inputDefectView,setInputDefectView]= useState<any>(false);
    const [defectViewList,setViewDefectList]= useState<any>(false);

    // file access 
    const [fileAccessDetails,setFileAccessDetails] = useState<any>();

    const [defectTypeName,setDefectTypeName]= useState<any>();
    const dispatch:any = useDispatch();
    const {
        orderDetails,
        productionInputResponse,
        getCategoryListResponse,
        getTaskListResponse,
        getInputListResponse,
        createDefectTypeResponse,
        getDefectTypeResponse,
        createDefectInputResponse,
        getDefectInputListResponse,
        getFileAccessListResponse
    } = useSelector((state:any) => state.userReducer);
    const defectLevelList=["High","Medium","Low","Imformable"];
    const defectStatusList=["QC","No Defect","Resolved","Review","QC Review","Defect"];

    useEffect(()=>{
        getCategoryDetails();
        dispatch(getTaskList({
            buyerID: props.buyerID,
            orderID: props.orderID,
            userMail : "parixbe@gmail.com",
        }))
        dispatch(getOrderDetails({
            buyerID: props.buyerID,
            orderID: props.orderID,
            userMail : "parixbe@gmail.com",
            fetchType:"users",
            limit: 100,
            skip: 0,
        }));
        getDefectTypeList();
        getDefectInputList();
        let currentDate = new Date();
        setCurrentMonth(currentDate.getMonth());
        setCurrentYear(currentDate.getFullYear());
    },[]);

    useEffect(()=>{
        if(getCategoryListResponse?.status==1){
            setCategoryList(getCategoryListResponse.data);
        }
        if(getTaskListResponse?.status==1){
            setTaskList(getTaskListResponse.data);
        }
        if(getDefectTypeResponse?.status==1){
            setDefectTypeLList(getDefectTypeResponse.data);
        }
        if(getDefectInputListResponse?.status==1){
            setDefectList(getDefectInputListResponse.data);
        }
    },[
        orderDetails!=undefined,
        getCategoryListResponse!=undefined,
        getTaskListResponse!=undefined,
        getDefectTypeResponse!=undefined,
        getDefectInputListResponse!=undefined]
    );

    useEffect(()=>{
        if(productionInputResponse?.status==1){
           setFormStatus(1);
           setAlertTitle("Success");
           setAlertSubTitle("Production inputed successfully")
           setSelectedCategory("0")
           setSelectedTask("0")
           setInputType(4)
           getDefectTypeList();
           setEvent(false)
           setInputDefectView(false)
           setCurrentInput(false)
        }else{
            setFormError(
                productionInputResponse?.error
            )
        }
    },[productionInputResponse!=undefined]);

    useEffect(()=>{
        if(productionInputResponse?.status==1){
           setFormStatus(1);
           setAlertTitle("Success");
           setAlertSubTitle("Production inputed successfully")
           setSelectedCategory("0")
           setSelectedTask("0")
           setInputType(4)
           getDefectTypeList();
        }else{
            setFormError(
                productionInputResponse?.error
            )
        }
    },[productionInputResponse!=undefined]);

    useEffect(()=>{
        console.log("createDefectInputResponse",createDefectInputResponse);
        if(createDefectInputResponse?.status==1){
           setFormStatus(1);
           setAlertTitle("Success");
           setAlertSubTitle("Defect Input saved successfullly")
           setSelectedCategory("0")
           setSelectedTask("0")
           getDefectInputList();
        }else{
            setFormError(
                createDefectInputResponse?.error
            )
        }
    },[createDefectInputResponse!=undefined]);

    useEffect(()=>{
        dispatch(
            productionInputList(
              {
                buyerID: props.buyerID,
                orderID: props.orderID,
                userMail : "parixbe@gmail.com",
              }
            ))
    },[formStatus==0]);

    useEffect(()=>{
        console.log("getInputListResponse",getDefectInputListResponse);
        if(getDefectInputListResponse?.status==1){
            setDefectList(getDefectInputListResponse?.data);
            setFilteredDefectList(getDefectInputListResponse?.data);
            setDateList([...dateList])
        }
    },[getDefectInputListResponse!=undefined]);

    useEffect(()=>{
        console.log("getInputListResponse",getInputListResponse);
        if(getInputListResponse?.status==1){
            setEventList(getInputListResponse?.data);
            setFilteredEventList(getInputListResponse?.data);
            setDateList([...dateList])
        }
    },[getInputListResponse!=undefined]);

    useEffect(()=>{
        dateRange();
    },[currentMonth,currentYear])

    useEffect(()=>{
        if(createDefectTypeResponse?.status == 1){
            setInputType(4)
            getDefectTypeList()
        }
    },[createDefectTypeResponse!=undefined])

    useEffect(()=>{
        console.log("getFileAccessListResponse",getFileAccessListResponse);
       if(getFileAccessListResponse?.status==1){
         let fileAccess:any = [];
         getFileAccessListResponse.fileIds.forEach((data:any)=>{
           fileAccess.push(
             {
               id:data,
               fileAccess:getFileAccessListResponse?.data?.filter((access:any)=>{
                 return access.fileID==data
               })
             }
           )
         });
         setFileAccessDetails([...fileAccess])
         console.log(
           "getFileAccessListResponse",
           fileAccess
         )
       }
    },[getFileAccessListResponse!=undefined]);

    const weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
    const monthList:any = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sept","Octr","Nov","Dec"];
    function dateRange(steps = 1) {
        const weekWiseDate=[];
        var dateArray = [];
        var weekCount=1;

        let currentDate = new Date(currentYear,currentMonth,1,1);
        console.log("currentDate",currentDate);
        console.log("currentDate",new Date(currentYear,currentMonth+1,1,1));
        while (currentDate <= new Date(currentYear,currentMonth+1,1,1)) {
          var  temDate:any=  new Date(currentDate)
          var day = temDate.getDate();
          currentDate.setUTCDate(currentDate.getUTCDate() + steps);

          // calculating starting index
          if(weekCount==1){
            var startWeekIndex=weekday.indexOf(weekday[temDate.getDay()]);
            for(var i=0;i<startWeekIndex+1;i++){
                dateArray.push({day:0,index:0});
            }
            dateArray.push({day:day,index:weekday[temDate.getDay()]});
          }else{
             dateArray.push({day:day,index:weekday[temDate.getDay()]});      
          }
          weekCount+=1;
        }
  
        var rowCount=0;
        var rowWiseDate:any=[];
        var tempDateList:any=[];
        dateArray.forEach((date,index)=>{
            if(index>0){
                if(dateArray.length-1!=index)
                    tempDateList.push(date);
                if(index!=0&&index%7==0){
                    rowCount+=1;
                    rowWiseDate[rowCount]=tempDateList;
                    tempDateList=[];
                }
                if(dateArray.length-1==index){
                    rowCount+=1;
                    rowWiseDate[rowCount]=tempDateList;
                    tempDateList=[];
                }
            }
        })
        

        console.log("currentDate",currentMonth,currentYear)

        rowWiseDate= rowWiseDate.filter((item:any)=>{
            return item!=null
        })

        setDateList(rowWiseDate);
        console.log("dateArray",dateList)
       
    }

    const yearRender=()=>{
        var mapData=[];
        for(var i=2023;i>=1900;i--){
            mapData.push(i);
        }
        return <Picker style={Style.dropDown}
            value={currentYear}
            onValueChange={(itemValue:any, itemIndex:any) =>
            setCurrentYear(itemValue)}>
            <Picker.Item label="Select Year" value={0} />
            {
                mapData.map((i)=>{
                    return <Picker.Item label={i} value={i} />
                })
            }
        </Picker>
       
    }

    const onDateSelected=(day:any)=>{
        let data = viewDefect ? defectList : eventList;
        var tempEventList = data ? data : [];
        tempEventList = tempEventList.filter((item:any)=>{
            console.log("selectedCategory",selectedCategory==item.categoryID)
            var sdTime = new Date(item.inputedAt);
            var inputedDay= sdTime.getDate(), inputedMonth = sdTime.getMonth(), inputedYear  = sdTime.getFullYear();
            return currentCategory!=0 ? 
                inputedDay == day && 
                inputedMonth == currentMonth && 
                inputedYear == currentYear && 
                currentCategory==item.categoryID
            :   inputedDay == day && 
                inputedMonth == currentMonth && 
                inputedYear == currentYear 
        })
        setFilteredDefectList([...tempEventList])
    }

    const getEventCount=(day:any,type:any)=>{
        let data = type == 1 ? defectList : eventList;
        var tempEventList = data ? data : [];
        tempEventList = tempEventList.filter((item:any)=>{
            var sdTime = new Date(item.inputedAt);
            var inputedDay= sdTime.getDate(), inputedMonth = sdTime.getMonth(), inputedYear  = sdTime.getFullYear();
            return currentCategory!=0 ? 
                inputedDay == day && 
                inputedMonth == currentMonth && 
                inputedYear == currentYear && 
                currentCategory==item.categoryID
            :   inputedDay == day && 
                inputedMonth == currentMonth && 
                inputedYear == currentYear 
        });

        return tempEventList.length;
    }

    const getCategoryDetails =()=>{
        dispatch(
          getCategoryList(
          {
            buyerID: props.buyerID,
            orderID: props.orderID,
            userMail : "parixbe@gmail.com",
          }
        ))
    }

    const saveDefectInput=()=>{
        console.log("selectedTask",selectedTask)
   
        var formData={
            categoryID:selectedCategory,
            taskID:selectedTask != 0 ? selectedTask : undefined,
            orderID:props.orderID,
            defectTypeId: selectedDefect._id,
            level:selectedLevelDefect,
            defectStatus:defectStatus,
            mappingID:mappingID,
            inputedAt:currentInputSelected?.inputedAt,
        }
        
        formData={
            ...currentDefectInput,
            ...formData
        }
        
        dispatch(
            inputDefect(formData)
        )
    
    }

    const loadCategoryTaskName=(id:any,type:any,forIn:any)=>{
       
        var tempList = type == "category"  ? categoryList : taskList;
        tempList = tempList?.filter((item:any)=>{
            return  item._id ==id
        })
        console.log("defectTypeName",tempList);
        if(tempList?.length>0)
            return type == "category" ? tempList[0]['categoryName'] :  tempList[0]['taskName']
    }

    const loadDefectTypeName=(id:any)=>{
       
        var tempList = defectTypeList  ? defectTypeList : [];
      
        tempList = tempList?.filter((item:any)=>{
            console.log("tempList", item._id,id);
            return  item._id ==id
        })

        if(tempList.length>0)
           return  tempList[0]['defectTypeName']
        else 
           return "";
    }

    const createDefect=()=>{
       dispatch(createDefectType(
            {
                "defectTypeName": defectTypeName,
                "buyerID": props.buyerID,
                "orderID": props.orderID,
                "userMail": "parixbe@gmail.com"
            }
        ))
    }
    
    const  getDefectTypeList = ()=>{
        dispatch(getDefectTypeLists(
            {
                "buyerID": props.buyerID,
                "orderID": props.orderID,
                "userMail": "parixbe@gmail.com"
            }
        ))
    }

    const  getDefectInputList = ()=>{
        dispatch(inputDefectList(
            {
                "buyerID": props.buyerID,
                "orderID": props.orderID,
                "userMail": "parixbe@gmail.com"
            }
        ))
    }

    const renderTasksWithMappingID=(mappingID:any,forDefect:any)=>{

        if(forDefect==true){
            let eventListTemp =  eventList ? eventList : []
            eventListTemp = eventListTemp.filter((data:any)=>{
                return data.mappingID==mappingID&&data.taskID!=null
            });
            console.log(
                "eventListTemp",
                eventListTemp
            )
            return <View>
                {
                    eventListTemp.map((element:any) => {
                        return <DataTable.Row onPress={()=>{}}>
                            <DataTable.Cell>{loadCategoryTaskName(element.taskID,'task',false)}</DataTable.Cell>
                            <DataTable.Cell>{element.mappingID}</DataTable.Cell>
                            <DataTable.Cell>
                            <View style={Style.defectStausHolder}>
                                <View style={[Style.pointerView,Style.noDefectPoint]}>
                                    <Text style={Style.pointerTxt}>
                                        {
                                            getDefectCount(element.taskID,element.mappingID,true,0)
                                        }
                                    </Text>
                                </View>
                                <View style={[Style.pointerView,Style.reviewDefect]}>
                                    <Text style={Style.pointerTxt}>
                                        {
                                            getDefectCount(element.taskID,element.mappingID,true,1)
                                        }
                                    </Text>
                                </View>
                                <View style={[Style.pointerView,Style.defectPointer]}>
                                    <Text style={Style.pointerTxt}>
                                        {
                                            getDefectCount(element.taskID,element.mappingID,true,2)
                                        }
                                    </Text>
                                </View>
                            </View>
                        </DataTable.Cell>
                        </DataTable.Row>
                    })
                }
            </View>
        }else{
            let eventListTemp =  defectList ? defectList : []
            eventListTemp = eventListTemp.filter((data:any)=>{
                if(selectedTask == null){
                    return data.mappingID==mappingID&&data.categoryID==selectedCategory
                }else{
                    return data.mappingID==mappingID&&data.taskID==selectedTask
                }
            });
            console.log(
                "eventListTemp",selectedCategory,selectedTask,
                eventListTemp
            )
            return <View>
                {
                    eventListTemp.map((element:any) => {
                        return <DataTable.Row onPress={()=>{
                            setCurrentInput(false)
                            seCurrentDefectInput(element)
                            setSelectedTask(null)
                            setMappingID(element.mappingID)
                            setDefectStatus(element.defectStatus)
                            setSelectedLevelDefect(element.level)
                            getFileAccessDetails(element.fileList)
                            setSelectedCategory(element.categoryID)
                            setInputType(1)
                            setSelectedDefect({defectTypeName:loadDefectTypeName(element.defectTypeId)})
                            setInputDefectView(true)}}>
                            <DataTable.Cell>{loadDefectTypeName(element.defectTypeId)}</DataTable.Cell>
                            <DataTable.Cell>{defectLevelList[element.level-1]}</DataTable.Cell>
                            <DataTable.Cell>{defectStatusList[element.defectStatus]}</DataTable.Cell>
                        </DataTable.Row>
                    })
                }
            </View>
        }
    }

    const onInputChange = (field:any,val:any) =>{

        var tempInputData:any = currentDefectInput ? currentDefectInput : {};
        tempInputData[field] = val;
        seCurrentDefectInput({
                ...currentDefectInput,
                ...tempInputData,
                ...{
                    categoryID:selectedCategory,
                    taskID:selectedTask,

                }
        });
        console.log("setCurrentInputSelected",currentDefectInput);
    }
    
    const resetStates=()=>{
        seCurrentDefectInput(null)
        setSelectedTask(null)
        setMappingID(null)
        setDefectStatus(null)
        setSelectedLevelDefect(null)
        setSelectedCategory(null)
        setInputType(1)
        setSelectedDefect(null)
        setInputDefectView(false)
    }
    
    const getFileAccessDetails=(fileList:any)=>{
        let fileId:any=[];
        fileList.forEach((element:any) => {
          fileId.push(element._id);
        });
        console.log("getFileAccessDetails",fileId);
        dispatch(
          fileAccess(
            {
              fileIds:fileId,
              id:"orderBasicInfo"
            }
          )
        )
    }
    
    const getDefectCount=(id:any,mappingID:any,task:any,statusType:any)=>{

        let defects=defectList.filter((data:any)=>{
            if(task==true){
                if(statusType==0){
                    return data.taskID == id
                    && data.mappingID == mappingID 
                    && data.defectStatus  <=2
                }else{
                    if(statusType==1){
                        return data.taskID == id
                        && data.mappingID == mappingID 
                        && data.defectStatus  == 3
                    }else{
                        return data.taskID == id
                        && data.mappingID == mappingID 
                        && data.defectStatus  == 5
                    }
                }
            }else{
                if(statusType==0){
                    return data.categoryID == id
                    && data.mappingID == mappingID 
                    && data.defectStatus  <=2
                }else{
                    if(statusType==1){
                        return data.categoryID == id
                        && data.mappingID == mappingID 
                        && data.defectStatus  == 3
                    }else{
                        return data.categoryID == id
                        && data.mappingID == mappingID 
                        && data.defectStatus  == 5
                    }
                }
            }
        });

        console.log(
            "qsxsq",
            defects?.length
        )
        return defects?.length;

    }

    console.log("selectedLevelDefect",selectedLevelDefect);

    return (
        <View style={Style.mainContent}>
            { 
                formStatus==1 ?
                <View>
                    <CustomAlert title={alertTitle} subTitle={alertSubTitle} onPress={()=>{
                        setFormStatus(0)
                        setEvent(false)
                        setInputDefectView(false)
                        setCurrentInput(false)
                    }} />
                </View>
                : <View style={{flexDirection:'row'}}>
                    {
                        addEvent == false&&viewCurrentInputed==false && inputDefectView == false ?
                            <View style={{flex:6}}>
                                <View style={{flexDirection:'row',justifyContent:'center',padding:5}}>
                                    <Text style={{flexDirection:'row',alignSelf:'center',padding:5}}>Calendar</Text>
                                    {
                                        yearRender()
                                    }
                                    <Picker style={Style.dropDown}
                                    value={currentMonth}
                                    onValueChange={(itemValue:any, itemIndex:any) =>
                                        setCurrentMonth(itemIndex-1)}>
                                        <Picker.Item label="Select Month" value={0}/>
                                        {
                                            monthList.map((month:any,index:any)=>{
                                                return <Picker.Item label={month} value={index} />
                                            })
                                        }
                                    </Picker>
                                    <Picker style={Style.dropDown}
                                        value={currentCategory}
                                        onValueChange={(itemValue:any, itemIndex:any) =>{setCurrentCategory(itemValue)}}>
                                        <Picker.Item label="Select Category" value={0}/>
                                        {
                                            categoryList?.map((data:any,index:any)=>{
                                                return <Picker.Item label={data.categoryName} value={data._id} />
                                            })
                                        }
                                    </Picker>
                                </View>
                                {
                                    currentMonth!=null&&currentYear ?
                                        <View>
                                            <View style={{borderLeftWidth:3.5,borderLeftColor:'#2f95dc',padding:5}}>
                                                <View style={Style.dateRow}>
                                                    {
                                                        weekday.map((day)=>{
                                                            return <View style={Style.weekNameHolder}>
                                                                <Text style={{ fontWeight:'500', fontSize:20}}>{day}</Text>
                                                            </View>
                                                        })
                                                    }
                                                </View>
                                                <View>
                                                    {   dateList.length!=0 ? 
                                                        dateList.map((weekList:any)=>{
                                                            return <View style={Style.dateRow}>
                                                                {
                                                                    weekList.map((weekList:any)=>{
                                                                        return <TouchableOpacity onPress={()=>{
                                                                                    onDateSelected(weekList.day);
                                                                                }}>
                                                                                <View style={Style.weekNameHolder}>

                                                                                    {
                                                                                        getEventCount(weekList.day,0)!=0&&weekList.day !=0 ?
                                                                                            <View style={{alignSelf:'flex-start',flexDirection:'row',alignContent:'space-around'}}>
                                                                                                <View style={{margin:5,marginBottom:5, height:12,width:12,borderRadius:50,backgroundColor:'#2f95dc'}}>
                                                                                                </View>
                                                                                                <Text style={{fontWeight:'500',fontSize:12,marginLeft:5,marginTop:4}}>P - {getEventCount(weekList.day,0)}</Text>
                                                                                            </View>
                                                                                        : null
                                                                                    }

                                                                                    {
                                                                                        getEventCount(weekList.day,1)!=0&&weekList.day !=0 ?
                                                                                            <View style={{alignSelf:'flex-start',flexDirection:'row',alignContent:'space-around'}}>
                                                                                                <View style={{margin:5,marginBottom:5, height:12,width:12,borderRadius:50,backgroundColor:'#e04916'}}>
                                                                                                </View>
                                                                                                <Text style={{fontWeight:'500',fontSize:12,marginLeft:5,marginTop:4}}>D - {getEventCount(weekList.day,1)}</Text>
                                                                                            </View>
                                                                                        : null
                                                                                    }                                                                        
                                                                                    <Text style={{
                                                                                        fontWeight:'800',
                                                                                        justifyContent:'center',
                                                                                        alignSelf:'baseline',
                                                                                        fontSize:15}}>{weekList.day !=0 ? weekList.day : null}</Text>
                                                                                </View>
                                                                        </TouchableOpacity>
                                                                    })
                                                                }
                                                            </View>
                                                        }) : null
                                                    }   
                                                </View> 
                                            </View>
                                        </View>
                                    : null
                                }
                            </View>
                        : null
                    }   
                    <View style={{flex:4,overflow:'scroll'}}>
                        <View style={Style.header}>
                            <View style={Style.headerLeft}>
                                <Text style={Style.headerTitle}>{ !addEvent ? "Production Defect Input" : "Input Defect"}</Text>
                            </View>
                            <View style={Style.headerRight}>
                               {  addEvent == false&&viewCurrentInputed==false&&inputDefectView == false ?
                                     <TouchableOpacity onPress={()=>{
                                        setViewDefect(!viewDefect)
                                        resetStates()
                                        setFilteredDefectList(
                                            viewDefect != true ? 
                                                [...defectList] 
                                            :     [...eventList] 
                                        )
                                        setInputDefectView(false)
                                    }}>
                                       <View>
                                            {
                                                viewDefect ?
                                                    <Text style={Style.viewLabel}>View Production</Text>
                                                :   <Text style={Style.viewLabel}>View Defect</Text>
                                            }  
                                      </View> 
                                     </TouchableOpacity>
                                    : <TouchableOpacity onPress={()=>{
                                        setInputType(2)
                                        setCurrentInputSelected({})
                                        setFormError(null)
                                        setViewDefectList(false)
                                        setInputDefectView(false)
                                        setCurrentInput(false)
                                        setEvent(false)}}>
                                        <Image style={Style.addSubLineImg}
                                            source={require("../../assets/images/close.png"
                                            )
                                        }/>
                                    </TouchableOpacity>
                                }
                            </View>
                        </View>
                        {
                            !addEvent&&!viewCurrentInputed&&!inputDefectView ?
                            <View>
                                <View style={{marginLeft:15,paddingTop:0}}>
                                        <CustomTextInput type="text" id="categoryID" value={""}
                                        placeHolder="Search category" onInputChange={(ID,val)=>{console.log("sa")}}/>
                                        <View style={Style.viewHolder}>
                                            <TouchableOpacity onPress={()=>{ setFilteredDefectList(viewDefect==true ?  [...defectList] : [...eventList]) }}>
                                                {
                                                    defectList?.length!=filteredDefectList?.length ?
                                                        <Text style={Style.showAllLabel}>{"Show all"}</Text>
                                                        : null
                                                }
                                            </TouchableOpacity>
                                        </View>
                                        <DataTable>
                                                {
                                                   viewDefect&& defectList ?
                                                        <DataTable.Header>
                                                            <DataTable.Title>Category</DataTable.Title>
                                                            <DataTable.Title>Mapping ID</DataTable.Title>
                                                            <DataTable.Title>Defect Level</DataTable.Title>
                                                            <DataTable.Title>Status</DataTable.Title>
                                                        </DataTable.Header>
                                                    : <DataTable.Header>
                                                            <DataTable.Title>Category</DataTable.Title>
                                                            <DataTable.Title>Mapping ID</DataTable.Title>
                                                            <DataTable.Title>Status</DataTable.Title>
                                                        </DataTable.Header>
                                                }
                                                {
                                                   viewDefect&& defectList&&filteredDefectList.map((data:any)=>{
                                                        console.log("filteredEventList",currentCategory,data.categoryID)
                                                        return  currentCategory!=0&&data.categoryID==currentCategory
                                                                &&!data.taskID ?
                                                                <DataTable.Row onPress={()=>{
                                                                    seCurrentDefectInput(data)
                                                                    setDefectStatus(data.defectStatus)
                                                                    setSelectedLevelDefect(data.level)
                                                                    setSelectedCategory(data.categoryID)
                                                                    setMappingID(data.mappingID)
                                                                    setSelectedTask(null)
                                                                    getFileAccessDetails(data.fileList)
                                                                    setInputType(1)
                                                                    setSelectedDefect({defectTypeName:loadDefectTypeName(data.defectTypeId)})
                                                                    setInputDefectView(true)}}>
                                                                    <DataTable.Cell>{loadCategoryTaskName(data.categoryID,"category",false)}</DataTable.Cell>
                                                                    <DataTable.Cell>{data.mappingID}</DataTable.Cell>
                                                                    <DataTable.Cell>{defectLevelList[data.level-1]}</DataTable.Cell>
                                                                    <DataTable.Cell>{defectStatusList[data.defectStatus]}</DataTable.Cell>
                                                                </DataTable.Row>
                                                            : currentCategory==0&&!data.taskID  ?
                                                                <DataTable.Row onPress={()=>{
                                                                    seCurrentDefectInput(data)
                                                                    setSelectedTask(null)
                                                                    setMappingID(data.mappingID)
                                                                    setDefectStatus(data.defectStatus)
                                                                    getFileAccessDetails(data.fileList)
                                                                    setSelectedLevelDefect(data.level)
                                                                    setSelectedCategory(data.categoryID)
                                                                    setInputType(1)
                                                                    setSelectedDefect({defectTypeName:loadDefectTypeName(data.defectTypeId)})
                                                                    setInputDefectView(true)}}>
                                                                    <DataTable.Cell>{loadCategoryTaskName(data.categoryID,"category",false)}</DataTable.Cell>
                                                                    <DataTable.Cell>{data.mappingID}</DataTable.Cell>
                                                                    <DataTable.Cell>{defectLevelList[data.level-1]}</DataTable.Cell>
                                                                    <DataTable.Cell>{defectStatusList[data.defectStatus]}</DataTable.Cell>
                                                                </DataTable.Row>
                                                            : null
                                                    })
                                                }
                                                
                                                {
                                                    viewCurrentInputed==false&&viewDefect==false&&eventList&&filteredDefectList.map((data:any)=>{
                                                        console.log("filteredEventList",currentCategory,data.categoryID)
                                                            return  currentCategory!=0&&data.categoryID==currentCategory
                                                                &&!data.taskID ?
                                                                <DataTable.Row onPress={()=>{
                                                                    console.log("asdasdloplopl",data)
                                                                    setCurrentInputSelected(data)
                                                                    setCurrentInput(true)
                                                                    setEvent(true)
                                                                    getFileAccessDetails(data.fileList)
                                                                    setMappingID(data.mappingID)
                                                                    setSelectedCategory(data.categoryID)
                                                                    setInputType(1)
                                                                    }}>
                                                                    <DataTable.Cell>{loadCategoryTaskName(data.categoryID,"category",false)}</DataTable.Cell>
                                                                    <DataTable.Cell>{data.mappingID}</DataTable.Cell>
                                                                    <DataTable.Cell>
                                                                            <View style={Style.defectStausHolder}>
                                                                                <View style={[Style.pointerView,Style.noDefectPoint]}>
                                                                                    <Text style={Style.pointerTxt}>
                                                                                        {
                                                                                            getDefectCount(data.categoryID,data.mappingID,false,0)
                                                                                        }
                                                                                    </Text>
                                                                                </View>
                                                                                <View style={[Style.pointerView,Style.reviewDefect]}>
                                                                                    <Text style={Style.pointerTxt}>
                                                                                        {
                                                                                            getDefectCount(data.categoryID,data.mappingID,false,1)
                                                                                        }
                                                                                    </Text>
                                                                                </View>
                                                                                <View style={[Style.pointerView,Style.defectPointer]}>
                                                                                    <Text style={Style.pointerTxt}>
                                                                                        {
                                                                                            getDefectCount(data.categoryID,data.mappingID,false,2)
                                                                                        }
                                                                                    </Text>
                                                                                </View>
                                                                            </View>
                                                                        </DataTable.Cell>
                                                                </DataTable.Row>
                                                            : currentCategory==0&&!data.taskID  ?
                                                                <DataTable.Row onPress={()=>{
                                                                    setSelectedCategory(data.categoryID)
                                                                    setMappingID(data.mappingID)
                                                                    setInputType(1)
                                                                    getFileAccessDetails(data.fileList)
                                                                    setCurrentInputSelected(data)
                                                                    setCurrentInput(true)
                                                                    setEvent(true)
                                                                    console.log("asdasdloplopl",data)}}>
                                                                    <DataTable.Cell>{loadCategoryTaskName(data.categoryID,"category",false)}</DataTable.Cell>
                                                                    <DataTable.Cell>{data.mappingID}</DataTable.Cell>
                                                                    <DataTable.Cell>
                                                                            <View style={Style.defectStausHolder}>
                                                                                <View style={[Style.pointerView,Style.noDefectPoint]}>
                                                                                    <Text style={Style.pointerTxt}>
                                                                                        {
                                                                                            getDefectCount(data.categoryID,data.mappingID,false,0)
                                                                                        }
                                                                                    </Text>
                                                                                </View>
                                                                                <View style={[Style.pointerView,Style.reviewDefect]}>
                                                                                    <Text style={Style.pointerTxt}>
                                                                                        {
                                                                                            getDefectCount(data.categoryID,data.mappingID,false,1)
                                                                                        }
                                                                                    </Text>
                                                                                </View>
                                                                                <View style={[Style.pointerView,Style.defectPointer]}>
                                                                                    <Text style={Style.pointerTxt}>
                                                                                        {
                                                                                            getDefectCount(data.categoryID,data.mappingID,false,2)
                                                                                        }
                                                                                    </Text>
                                                                                </View>
                                                                            </View>
                                                                        </DataTable.Cell>
                                                                </DataTable.Row>
                                                            : null
                                                    })
                                                }
                                                {
                                                  
                                                    <DataTable>
                                                        {  viewDefect&& defectList ?
                                                            <DataTable.Header>
                                                                <DataTable.Title>Task</DataTable.Title>
                                                                <DataTable.Title>Mapping ID</DataTable.Title>
                                                                <DataTable.Title>Defect Level</DataTable.Title>
                                                                <DataTable.Title>Status</DataTable.Title>
                                                            </DataTable.Header>
                                                            :<DataTable.Header>
                                                                <DataTable.Title>Task</DataTable.Title>
                                                                <DataTable.Title>Mapping ID</DataTable.Title>
                                                                <DataTable.Title>Status</DataTable.Title>
                                                            </DataTable.Header>
                                                        }
                                                        {
                                                            viewDefect&& defectList&&filteredDefectList.map((data:any)=>{
                                                                console.log("filteredEventList",currentCategory,data.categoryID)
                                                                return  currentCategory!=0&&data.categoryID==currentCategory
                                                                        &&data.taskID ?
                                                                        <DataTable.Row onPress={()=>{
                                                                            seCurrentDefectInput(data)
                                                                            setDefectStatus(data.defectStatus)
                                                                            setSelectedLevelDefect(data.level)
                                                                            setSelectedCategory(data.categoryID)
                                                                            setSelectedTask(data.taskID)
                                                                            setMappingID(data.mappingID)
                                                                            setInputType(1)
                                                                            setSelectedDefect({defectTypeName:loadDefectTypeName(data.defectTypeId)})
                                                                            setInputDefectView(true)}}>
                                                                            <DataTable.Cell>{loadCategoryTaskName(data.taskID,"task",false)}</DataTable.Cell>
                                                                            <DataTable.Cell>{data.mappingID}</DataTable.Cell>
                                                                            <DataTable.Cell>{defectLevelList[data.level-1]}</DataTable.Cell>
                                                                            <DataTable.Cell>{defectStatusList[data.defectStatus]}</DataTable.Cell>
                                                                        </DataTable.Row>
                                                                    : currentCategory==0&&data.taskID  ?
                                                                        <DataTable.Row onPress={()=>{
                                                                            seCurrentDefectInput(data)
                                                                            setDefectStatus(data.defectStatus)
                                                                            setSelectedLevelDefect(data.level)
                                                                            setMappingID(data.mappingID)
                                                                            setSelectedCategory(data.categoryID)
                                                                            setSelectedTask(data.taskID)
                                                                            setInputType(1)
                                                                            getFileAccessDetails(data.fileList)
                                                                            setSelectedDefect({defectTypeName:loadDefectTypeName(data.defectTypeId)})
                                                                            setInputDefectView(true)}}>
                                                                            <DataTable.Cell>{loadCategoryTaskName(data.taskID,"task",false)}</DataTable.Cell>
                                                                            <DataTable.Cell>{data.mappingID}</DataTable.Cell>
                                                                            <DataTable.Cell>{defectLevelList[data.level-1]}</DataTable.Cell>
                                                                            <DataTable.Cell>{defectStatusList[data.defectStatus]}</DataTable.Cell>
                                                                        </DataTable.Row>
                                                                    : null
                                                            })
                                                        }
                                                        {
                                                            viewCurrentInputed==false&&viewDefect==false&&eventList&&eventList&&filteredDefectList.map((data:any)=>{
                                                                return currentCategory!=0&&
                                                                    data.categoryID==currentCategory&&data.taskID  ? 
                                                                            <DataTable.Row onPress={()=>{
                                                                                setCurrentInputSelected(data)
                                                                                setSelectedCategory(data.categoryID)
                                                                                setSelectedTask(data.taskID)
                                                                                setInputType(1)
                                                                                setMappingID(data.mappingID)
                                                                                getFileAccessDetails(data.fileList)
                                                                                setCurrentInput(true)
                                                                                setEvent(false)}}>
                                                                                <DataTable.Cell>{loadCategoryTaskName(data.taskID,"task",false)}</DataTable.Cell>
                                                                                <DataTable.Cell>{data.mappingID}</DataTable.Cell>
                                                                                <DataTable.Cell>
                                                                                    <View style={Style.defectStausHolder}>
                                                                                        <View style={[Style.pointerView,Style.noDefectPoint]}>
                                                                                            <Text style={Style.pointerTxt}>
                                                                                                {
                                                                                                    getDefectCount(data.taskID,data.mappingID,true,0)
                                                                                                }
                                                                                            </Text>
                                                                                        </View>
                                                                                        <View style={[Style.pointerView,Style.reviewDefect]}>
                                                                                            <Text style={Style.pointerTxt}>
                                                                                                {
                                                                                                    getDefectCount(data.taskID,data.mappingID,true,1)
                                                                                                }
                                                                                            </Text>
                                                                                        </View>
                                                                                        <View style={[Style.pointerView,Style.defectPointer]}>
                                                                                            <Text style={Style.pointerTxt}>
                                                                                                {
                                                                                                    getDefectCount(data.taskID,data.mappingID,true,2)
                                                                                                }
                                                                                            </Text>
                                                                                        </View>
                                                                                    </View>
                                                                                </DataTable.Cell>
                                                                            </DataTable.Row>
                                                                        : currentCategory==0&&
                                                                            data.categoryID&&
                                                                                data.taskID ? <DataTable.Row onPress={()=>{
                                                                                    setCurrentInputSelected(data)
                                                                                    setSelectedCategory(data.categoryID)
                                                                                    setSelectedTask(data.taskID)
                                                                                    getFileAccessDetails(data.fileList)
                                                                                    setInputType(1)
                                                                                    setMappingID(data.mappingID)
                                                                                    setCurrentInput(true)
                                                                                    setEvent(false)}}>
                                                                                    <DataTable.Cell>{loadCategoryTaskName(data.taskID,"task",false)}</DataTable.Cell>
                                                                                    <DataTable.Cell>{data.mappingID}</DataTable.Cell>
                                                                                    <DataTable.Cell>
                                                                                        <View style={Style.defectStausHolder}>
                                                                                            <View style={[Style.pointerView,Style.noDefectPoint]}>
                                                                                                <Text style={Style.pointerTxt}>
                                                                                                    {
                                                                                                        getDefectCount(data.taskID,data.mappingID,true,0)
                                                                                                    }
                                                                                                </Text>
                                                                                            </View>
                                                                                            <View style={[Style.pointerView,Style.reviewDefect]}>
                                                                                                <Text style={Style.pointerTxt}>
                                                                                                    {
                                                                                                        getDefectCount(data.taskID,data.mappingID,true,1)
                                                                                                    }
                                                                                                </Text>
                                                                                            </View>
                                                                                            <View style={[Style.pointerView,Style.defectPointer]}>
                                                                                                <Text style={Style.pointerTxt}>
                                                                                                    {
                                                                                                        getDefectCount(data.taskID,data.mappingID,true,2)
                                                                                                    }
                                                                                                </Text>
                                                                                            </View>
                                                                                        </View>
                                                                                    </DataTable.Cell>
                                                                                </DataTable.Row>
                                                                            : null
                                                            })
                                                        }
                                                    </DataTable>
                                                
                                                }
                                        </DataTable>
                                </View>
                            </View>
                            : inputDefectView== true ?
                                <View>
                                    <View style={{zIndex:1}}>
                                        {
                                            inputType == 1 ?
                                                    <View>
                                                        <Ionicons name="arrow-back"  style={Style.backIcon} onPress={()=>{
                                                            setInputType(4);
                                                        }}/> 
                                                        <View style={{margin:10}}>
                                                            <Text style={{margin:5}}>
                                                            Level : {
                                                                selectedLevelDefect==1 ? 'High' 
                                                                    : selectedLevelDefect==2 ? 'Medium'
                                                                        : selectedLevelDefect==3 ? 'Low'
                                                                            : selectedLevelDefect==4 ? 'Informable'
                                                                            : null
                                                                }
                                                            </Text>
                                                            <Text style={{margin:5}}>
                                                            Defect Type :  {
                                                                selectedDefect.defectTypeName
                                                                }
                                                            </Text>
                                                            {
                                                                selectedCategory ? 
                                                                    <Text style={{margin:5}}>
                                                                        Category :  {loadCategoryTaskName(selectedCategory,'category',false)}
                                                                    </Text>
                                                                : null
                                                            }
                                                            {
                                                                selectedTask ? 
                                                                    <Text style={{margin:5}}>
                                                                        Task :  {loadCategoryTaskName(selectedTask,'task',false)}
                                                                    </Text>
                                                                : null
                                                            }
                                                           <Text style={{margin:5}}>
                                                                MappingID :  {mappingID}
                                                            </Text>
                                                        </View>
                                                        <View style={Style.productionView}>
                                                            <View style={Style.defectMarker}>
                                                                <CheckBoxButton  id={null}
                                                                isChecked={()=>{setDefectStatus(1)}}
                                                                state={defectStatus==1}/>
                                                                <Text style={Style.defectMarkerLabel}>No Defect</Text>
                                                            </View>
                                                            <View style={Style.defectMarker}>
                                                                <CheckBoxButton  id={null}
                                                                isChecked={()=>{setDefectStatus(2)}}
                                                                state={defectStatus==2}/>
                                                                <Text style={Style.defectMarkerLabel}>Resolved</Text>
                                                            </View>
                                                            <View style={Style.defectMarker}>
                                                                <CheckBoxButton  id={null}
                                                                isChecked={()=>{setDefectStatus(3)}}
                                                                state={defectStatus==3}/>
                                                                <Text style={Style.defectMarkerLabel}>User Review</Text>
                                                            </View>
                                                            
                                                            <View style={Style.defectMarker}>
                                                                <CheckBoxButton  id={null}
                                                                isChecked={()=>{setDefectStatus(4)}}
                                                                state={defectStatus==4}/>
                                                                <Text style={Style.defectMarkerLabel}>PQC Review</Text>
                                                            </View>
                                                            <View style={Style.defectMarker}>
                                                                <CheckBoxButton  id={null}
                                                                isChecked={()=>{setDefectStatus(5)}}
                                                                state={defectStatus==5}/>
                                                                <Text style={Style.defectMarkerLabel}>Defect</Text>
                                                            </View>
                                                        </View>
                                                        <CustomTextInput type="text" id="categoryQuantity" 
                                                            value={  currentDefectInput?.desc}
                                                            placeHolder="Description" 
                                                            onInputChange={(ID,val)=>{onInputChange("desc",val)}} />
                                                        <FileHandler isLargeScreen={props.isLargeScreen} 
                                                            documentList={currentDefectInput.fileList}
                                                            orderID={props.orderID}
                                                            fileUploadAccess={true}
                                                            fileAccess={fileAccessDetails}
                                                            id={currentDefectInput.uuID+"files"}
                                                            updateUserAccess = {(fileAccess)=>{onInputChange("fileAccess",fileAccess.value)}}
                                                            deletedFileList = {(fileAccess)=>{onInputChange("fileDeleted",fileAccess.value)}}
                                                            updatedFileList={(updatedList)=>{onInputChange("fileList",updatedList.value)}}/>
                                                    
                                                    </View>
                                            : inputType == 3 ? 
                                                    <View>
                                                        <View style={Style.subHeader}>
                                                            {
                                                                currentInputSelected.categoryID ?
                                                                    <Ionicons name="arrow-back"  style={Style.backIcon} onPress={()=>{
                                                                        setInputDefectView(false)
                                                                        setCurrentInput(true)
                                                                    }}/> 
                                                                : null
                                                            }
                                                            
                                                            <Text style={Style.subHeaderTitle}>Select Defect Level</Text>
                                                        </View>
                                                        <View style={{margin:2,padding:5,}}>
                                                            <TouchableOpacity onPress={()=>{
                                                                setInputType(4)
                                                                setSelectedLevelDefect(1)}}>
                                                                <View style={{margin:2,backgroundColor:'#f7f9fa',minWidth:320,height:70,justifyContent:'center'}}>
                                                                        <Text style={{alignSelf:'center',fontSize:18}}>High</Text>
                                                                </View>
                                                            </TouchableOpacity>
                                                            <TouchableOpacity onPress={()=>{
                                                                setInputType(4)
                                                                setSelectedLevelDefect(2)}}>
                                                                <View style={{margin:2,backgroundColor:'#f7f9fa',minWidth:320,height:70,justifyContent:'center'}}>
                                                                        <Text style={{alignSelf:'center',fontSize:18}}>Medium</Text>
                                                                </View>
                                                            </TouchableOpacity>
                                                            <TouchableOpacity onPress={()=>{
                                                                setInputType(4)
                                                                setSelectedLevelDefect(3)}}>
                                                                <View style={{margin:2,backgroundColor:'#f7f9fa',minWidth:320,height:70,justifyContent:'center'}}>
                                                                        <Text style={{alignSelf:'center',fontSize:18}}>Low</Text>
                                                                </View>
                                                            </TouchableOpacity>
                                                            <TouchableOpacity onPress={()=>{
                                                                setInputType(4)
                                                                setSelectedLevelDefect(4)}}>
                                                                <View style={{margin:2,backgroundColor:'#f7f9fa',minWidth:320,height:70,justifyContent:'center'}}>
                                                                        <Text style={{alignSelf:'center',fontSize:18}}>Informable</Text>
                                                                </View>
                                                            </TouchableOpacity>
                                                        </View>
                                                    </View>
                                                :  inputType == 4 ?
                                                    <View>
                                                        <View style={{flexDirection:'row'}}>
                                                            <View style={Style.subHeader}>
                                                                <Ionicons name="arrow-back"  style={Style.backIcon} onPress={()=>{
                                                                    setInputType(3);
                                                                }}/> 
                                                                <Text style={Style.subHeaderTitle}>
                                                                    { !addDefectType ? "Defect Type" : "Input Defect"}
                                                                </Text>
                                                                <View style={{flex:1}}>
                                                                    <TouchableOpacity style={{flexDirection:'row',alignSelf:'flex-end'}} onPress={()=>{
                                                                        setInputType(5)
                                                                        setAddDefectType(!addEvent)}}>
                                                                        <Image style={Style.addSubHeaderLineImg}
                                                                            source={require("../../assets/images/"+(addDefectType? "close" : "plus" )+".png")
                                                                        }/>
                                                                        {
                                                                            ! addDefectType ?
                                                                                <Text style={Style.addBtnSubHeaderTitle}>Add type</Text>
                                                                            : null
                                                                        }
                                                                    </TouchableOpacity>
                                                                </View>
                                                            </View>
                                                        </View>
                                                        <View style={{margin:2,padding:5,}}>
                                                            <CustomTextInput type="text" id="taskQuanity" 
                                                                value={  currentDefectInput?.qty }
                                                                placeHolder="Search defect type" 
                                                                onInputChange={(ID,val)=>{null}} />
                                                            <DataTable>
                                                                <DataTable.Header>
                                                                    <DataTable.Title>Defect Inputs</DataTable.Title>
                                                                </DataTable.Header>
                                                                {
                                                                    defectTypeList&&defectTypeList.map((data:any)=>{
                                                                        return  <DataTable.Row onPress={()=>{
                                                                            setInputType(1)
                                                                            setSelectedDefect(data)}}>
                                                                                <DataTable.Cell>{data.defectTypeName}</DataTable.Cell>
                                                                            </DataTable.Row>
                                                                                
                                                                    })
                                                                }
                                                            </DataTable>
                                                        </View>
                                                    </View>
                                                : inputType == 5 ? 
                                                    <View>
                                                        <View style={Style.subHeader}>
                                                                <Ionicons name="arrow-back"  style={Style.backIcon} onPress={()=>{
                                                                    setInputType(4);
                                                                }}/> 
                                                                <Text style={Style.subHeaderTitle}>
                                                                    Create defect type
                                                                </Text>
                                                            </View>
                                                        <CustomTextInput type="text" id="taskQuanity" 
                                                        value={  currentDefectInput?.qty }
                                                        placeHolder="Quantity" 
                                                        onInputChange={(ID,val)=>{
                                                        
                                                                setDefectTypeName(val)
                                                        }} />
                                                        <View style={Style.footer}>
                                                            <View style={{justifyContent:'center',flex:1}}>
                                                                <CustomLabel type="formErrorMsg" label={""} errorMsg={formError}
                                                                formRawData={null} successMsg={null}/>
                                                            </View>
                                                                <CustomButton buttonID={"Submit"} type="common"
                                                                title="Save" onSubmit={()=>{
                                                                        createDefect()
                                                                }}/>
                                                            </View>
                                                    </View>
                                                : null
                                        }
                                    </View> 
                                    {
                                        inputType==1 ?
                                            <View style={Style.footer}>
                                                <View style={{justifyContent:'center',flex:1}}>
                                                    <CustomLabel type="formErrorMsg" label={""} errorMsg={formError}
                                                    formRawData={null} successMsg={null}/>
                                                </View>
                                                <CustomButton buttonID={"Submit"} type="common"
                                                title="Save" onSubmit={()=>{
                                                    
                                                        saveDefectInput()
                                                }}/>
                                            </View>
                                        : null 
                                    }
                                </View>
                            : null
                        }
                        {
                            viewCurrentInputed == true  ?
                                <View>
                                    <View style={Style.inputedDetails}>
                                        {
                                            currentInputSelected.categoryID ?
                                                <Text>
                                                    Category : {loadCategoryTaskName(currentInputSelected?.categoryID,'category',false)}
                                                </Text>
                                            : null 
                                        }
                                        {
                                            currentInputSelected.taskID ?
                                                <Text>
                                                    Task : {loadCategoryTaskName(currentInputSelected?.taskID,'task',false)}
                                                </Text>
                                            : null 
                                        }

                                        <Text>
                                            Inputed At :{ currentInputSelected?.inputedAt}
                                        </Text>
                                        <Text>
                                            Mapping ID : {currentInputSelected.mappingID}
                                        </Text>
                                        <Text>
                                            status : {currentInputSelected.defectStatus}
                                        </Text>                                    </View>
                                        <View style={Style.defectView}>
                                            <TouchableOpacity style={{flexDirection:'row'}} onPress={()=>{
                                                setInputType(3)
                                                seCurrentDefectInput({});
                                                setCurrentInput(false)
                                                setSelectedCategory(currentInputSelected?.categoryID);
                                                setSelectedTask(currentInputSelected?.taskID);
                                                setInputDefectView(true)}}>
                                                <Image style={Style.addSubLineImg}
                                                    source={require("../../assets/images/plus.png")
                                                }/>
                                                {
                                                
                                                    <Text style={{marginTop:25}}>Input Defect</Text>
                                                
                                                }
                                            </TouchableOpacity>
                                            <TouchableOpacity style={{flexDirection:'row'}} onPress={()=>{
                                                setViewDefectList(!defectViewList)}}>
                                                <Image style={{margin:10,height:35,width:35}}
                                                source={require("../../assets/images/defect.png")}/>
                                                {
                                                    <Text style={{marginTop:25}}>View Defect</Text>
                                                }
                                            </TouchableOpacity>
                                        </View>
                                    <View>
                                    {
                                        currentInputSelected.taskID == null&& defectViewList == false ?
                                            <View>
                                                <DataTable.Row>
                                                    <DataTable.Cell>Task Name</DataTable.Cell>
                                                    <DataTable.Cell>Mapping ID</DataTable.Cell>
                                                    <DataTable.Cell>Defect Status</DataTable.Cell>
                                                </DataTable.Row>
                                                {
                                                    renderTasksWithMappingID(currentInputSelected.mappingID,true)
                                                }
                                            </View>
                                        : <View>
                                            <DataTable.Row>
                                                <DataTable.Cell>Defect Category</DataTable.Cell>
                                                <DataTable.Cell>Defect Level</DataTable.Cell>
                                                <DataTable.Cell>Status</DataTable.Cell>
                                            </DataTable.Row>
                                            {
                                                renderTasksWithMappingID(currentInputSelected.mappingID,false)
                                            }
                                        </View>
                                    }
                                    </View>
                                </View>
                            : null
                        }
                    </View>
                </View>
            }
        </View>
    )
}

export default ProductionDefectInput;