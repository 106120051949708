import React,{FC, useEffect, useState} from "react";
import {View, Text} from "react-native";
import {Style} from './style';
import { saveOrderBasicInfo, createGroup, clearRepo } from '../../Server_Repo/action';
import CustomTextInput from "../CustomTextInput";
import CustomLabel from "../CustomLabel";
import DatePicker from '../DatePicker';
import CustomButton from "../../components/CustomButton";
import FileHandler from "../FilesHandler";
import { useSelector, useDispatch } from 'react-redux';
import CustomAlert from "../CustomAlert";
import SubTitleHeader from "../SubTitleHeader";
import PagenationFooter from "../PagenationFooter";
import {getAccessToken} from "../../utility/index"
import { DataTable } from 'react-native-paper';

interface Props{
  isLargeScreen:number;
  orderID:any;
  selectedGroup:any;
  switchComponent:(component:any)=>void;
  successCallBack:(data:any)=>void;
}

const CreateGroup:FC<Props>=(props)=>{

  const dispatch:any = useDispatch();
  const [screenType,setScreenType]=useState(Style);
  const [formError,setFormError]=useState<any>(null);
  const [groupName,setGroupName]= useState<string>(props?.selectedGroup?.groupName);
  const [orderID,setOrderID]= useState<string>(props.orderID);
  const [formStatus,setFormStatus]= useState<any>(0);
  const [alertTitle,setAlertTitle]= useState<any>();
  const [alertSubTitle,setAlertSubTitle]= useState<any>();

  const {
    userDetails,orderBasicInfo,createGroupResponse
  } = useSelector((state:any) => state.userReducer);

   useEffect(()=>{
      console.log("createGroupResponse","all",createGroupResponse)
      if(createGroupResponse?.status==1){
        setFormStatus(1);
        setAlertTitle("Success");
        setAlertSubTitle("Group created successfully");
      }else{
        if(createGroupResponse?.status==0){
          setFormError(createGroupResponse.error[0]);
        }
      }
      dispatch(clearRepo());
   },[createGroupResponse!=null]);
  
    const submitBasicInfo=async ()=>{
      setFormError(null);
      var formData={
        groupName:groupName,
        type:"order"
      };
      console.log("accessToken",formData);
      if(groupName){
            dispatch(createGroup({
              ...props?.selectedGroup,
              ...formData,
            }));
      }else{
        setFormError("All fields are mandatory")
      }
    }

    const alertClickEvent=()=>{
      setFormStatus(0);
      props.switchComponent("groupList");
    }
    console.log("setFormError",formError)
    return (
      <View style={Style.mainContent}>
        <View>
          <View style={screenType.container}>
              { formStatus==1 ?
                    <View>
                      <CustomAlert title={alertTitle} subTitle={alertSubTitle} onPress={()=>alertClickEvent()} />
                    </View>
                :  <View style={Style.divView}>
                  <CustomTextInput type="text" id="groupName" value={groupName}
                    placeHolder="Group Name" onInputChange={(ID,val)=>{
                      setGroupName(val);
                      setFormError(null)}} />
                      {
                        formError ?  <CustomLabel label={"null"} type={"formErrorMsg"}
                        errorMsg={formError} formRawData={formStatus} successMsg={null}/> : null
                      }
                    <CustomButton buttonID={"Submit"} type="common"
                    title="Create" onSubmit={()=>{submitBasicInfo()}}/>
                </View>
              }
            
          </View>
        </View>
      </View>
    )
}

export default CreateGroup;