import { StyleSheet } from "react-native";
const Style =StyleSheet.create({
    container:{
            
    },
    header:{
        flex:1,
        height:100,
        flexDirection:'row',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.05,
        shadowRadius: 0,
        elevation: 1,
    },
    headerLeft:{
        flexDirection:'row',
        alignSelf:'flex-start',
    },
    headerRight:{
        alignSelf:'flex-end',
        justifyContent:'flex-end',
        alignItems:'flex-end',
        flexDirection:'row'
    },
    headerTitle:{
        fontSize:15,
        fontWeight:'500',
        margin:10
    },
    headerRightIocns:{
        height:30,
        width:30,
        margin:15,
        marginRight:25,
        alignSelf:'flex-end'
    },
    headerLeftIocns:{
        height:30,
        width:30,
        margin:20,
        marginRight:2,
        alignSelf:'flex-end'
    },
    selectCategory:{
        height:45,
        width:210,
        marginTop:10,
    },
    body:{
        flexDirection:'row',
        padding:15,
        borderBottomWidth:2,
        borderBottomStyle:'solid'
    },
    breadCrumbs:{
        flex:1,
        height:50,
        width:180,
    },
    countLabel:{
        fontSize:25,
        fontWeight:'600'
    },
    subTitle:{
        marginTop:5,
        fontSize:12,
        fontWeight:'500'
    },
    subValues:{
        fontSize:18,
        fontWeight:'600'
    },
    statusContainer:{
        flexDirection:'row'
    },
    holderEvent:{
        minWidth:250,
        marginLeft:15,
        marginTop:15,
        padding:10,
        paddingLeft:15,
        borderLeftWidth:5,
        borderLeftStyle:'soild',
        borderLeftColor:'#40a2f7',
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.2,
        shadowRadius: 2,
    },
    mainContent:{
        margin:10,
        padding:10,
        flexDirection:'row',
        justifyContent:'center'
    },
    categoryInfo:{
        minWidth:250,
        marginLeft:20,
        marginTop:10,
        paddingLeft:15,
        borderLeftWidth:5,
        borderLeftStyle:'soild',
        borderLeftColor:'#0578a6',
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.2,
        shadowRadius: 2,
        justifyContent:'center'
    },
    subTitleHeaderView:{
        flexDirection:'row',
        maxHeight:60,
        justifyContent:'flex-start',
        padding:10,
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.2,
        shadowRadius: 2,
    },
    subTitleHeader:{
        margin:15,
        fontWeight:'600',
        fontSize:15,
    },
    downloadBtn:{
        alignSelf:'flex-end',
        margin:10
    },
    dropDown:{
        height:45,
        width:250,
        borderRadius:5,
    },
});

export default Style;