import { StyleSheet } from "react-native";
import Colors from "../../constants/Colors"; 

export const Style=StyleSheet.create({
    mainContent:{
        flex:1,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 10,
        elevation: 3,
    },
    errorText:{
        display:'none',
        color:'red',
        marginTop:15,
        margin:10,
        alignSelf:'flex-start',
    },
    container:{
        flexDirection:'column',
    },
    contentHolder:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-evenly'
    },
    mobileContentHolder:{
        flexDirection:'column',
    },
    footer:{
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 1,
        marginBottom:0,
        position:'relative',
        flexDirection:'row',
        padding:2,
        zIndex:-1
    },
    divView:{
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 1,
        marginBottom:0,
        position:'relative',
        flexDirection:'column',
        padding:15,
        overflow:'scroll'
    },
    groupContainer:{
       padding:10,
    },
    header:{
        flex:1,
        height:100,
        flexDirection:'row',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.05,
        shadowRadius: 0,
        elevation: 1,
    },
    headerLeft:{
        flex:5,
        flexDirection:'row',
        alignSelf:'flex-start',
    },
    headerRight:{
        flex:5,
        alignSelf:'flex-end',
        justifyContent:'flex-end',
        flexDirection:'row'
    },
    headerTitle:{
        fontSize:15,
        fontWeight:'500',
        margin:15
    },
    headerRightIocns:{
        height:30,
        width:30,
        margin:15,
        marginRight:25,
        alignSelf:'flex-end'
    },
    headerLeftIocns:{
        height:30,
        width:30,
        margin:20,
        marginRight:2,
        alignSelf:'flex-end'
    },
    taskView:{
        flex:1,
        flexDirection:'column'
    },
    toolBar:{
        flex:1,
        padding:10,
        backgroundColor:'#f7f9fa'
    },
    toolBarImg:{
        height:30,
        width:30,
        alignSelf:'flex-end'
    },
    categoryTaskView:{
        flexDirection:'column',
    },
    itemContainer: {
        flex:1,
        flexGrow:1,
    },
    item: {
        flex: 1,
        margin: 5,
        backgroundColor: 'white',
        borderRadius:5,
        borderStyle:'solid',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.5,
        shadowRadius: 2,
        elevation: 5,
        justifyContent:'flex-end',
        maxWidth:350,
    },
    categoryName:{
        fontSize:15,
        margin:10,
        marginBottom:5
    },
    categoryLables:{
        margin:10,
        marginBottom:0,
        fontSize:10,
    },
    breadCrumbs:{
        margin:10,
        padding:15,
        height:20,
        width:250,
        borderRadius:5,
        flexDirection:'row',
        justifyContent:'space-evenly'
    },
    taskBreadCrumbs:{
        margin:10,
        padding:15,
        height:20,
        width:250,
        borderRadius:1,
        flexDirection:'row',
        justifyContent:'space-evenly'
    },
    breadCrumbsDiv:{
        justifyContent:'center',
    },breadCrumbsTitle:{
        margin:5,
        fontWeight:'600',
    },
    flexRow:{
        flexDirection:'row'
    },
    categgoryTitle:{
        fontSize:12,
        fontWeight:'400',
        margin:15
    },
    dropDown:{
        marginTop:5,
        height:47,
        width:250,
        borderRadius:5,
        alignSelf:'center'
    },
});