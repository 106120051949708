import React, { FC, useEffect, useState } from "react";
import {View, Text, Image, TouchableOpacity, FlatList, ScrollView, Picker} from "react-native";
import {Style} from './style';
import { createTask,fileAccess, clearRepo, createCategory, getOrderDetails, getCategoryList, getTaskList } from '../../Server_Repo/action';
import CustomTextInput from "../CustomTextInput";
import CustomLabel from "../CustomLabel";
import DatePicker from '../DatePicker';
import CustomButton from "../CustomButton";
import FileHandler from "../FilesHandler";
import { useSelector, useDispatch } from 'react-redux';
import CustomAlert from "../CustomAlert";
import SubTitleHeader from "../SubTitleHeader";
import PagenationFooter from "../PagenationFooter";
import {getAccessToken} from "../../utility/index"
import { DataTable } from 'react-native-paper';
import CreateGroup from "../CreateGroup";
import CreateUser from "../CreateUser";
import ResponsiblePerson from "../ResponsiblePerson";
import PickStocks from "../PickStocks";

interface Props{
  isLargeScreen:number;
  data:any;
  orderID:any;
  currentRoute:any;
  buyerID:any;
  successCallBack:(data:any)=>void;
  internalComponentChanges:()=>void;
}
const OrderTask:FC<Props>=(props)=>{

    const dispatch:any = useDispatch();
    const [screenType,setScreenType]=useState(Style);
    const [task,setTask] = useState<any>([]);
    const [reloadRender,setReloadRender]= useState<any>(true);
    const [selectedCategory,setSelectedCategory] = useState<any>();
    const [taskData,setTaskData] = useState<any>([]);
    const [categoryData,setCategoryData] = useState<any>([]);
    const [groupList,setGroupList]= useState<any>();
    const [formError,setFormError]=useState<any>(null);
    const [alertTitle,setAlertTitle]= useState<any>();
    const [alertSubTitle,setAlertSubTitle]= useState<any>();
    const [formStatus,setFormStatus]= useState<any>(0);
    const [addTask,setAddTask]=useState(false);

    const [fileAccessDetails,setFileAccessDetails] = useState<any>();
    
    const {
      createTaskResponse,
      getCategoryListResponse,
      getTaskListResponse,
      getFileAccessListResponse,
      orderDetails
    } = useSelector((state:any) => state.userReducer);

    useEffect(()=>{
      if(orderDetails?.status==1&&orderDetails?.fetchType=="basicInfo"){
        getOrderList("users");
      }
      if(orderDetails?.status==1&&orderDetails?.fetchType=="users"){
        setGroupList(orderDetails?.data)
        getCategoryDetails();
      }
    },[orderDetails]);

    useEffect(()=>{
      if(getCategoryListResponse?.status==1){
        setCategoryData(getCategoryListResponse?.data);
      }
    },[getCategoryListResponse!=undefined]);

    useEffect(()=>{
      getOrderList("basicInfo");
    },[props.orderID!=undefined]);

    useEffect(()=>{
      if(createTaskResponse?.status==1){
        setAlertTitle("Success");
        setAlertSubTitle("Task created successfully")
        setFormStatus(1)
        setAddTask(false);
        setTaskData(null)
      }else{
          setFormError(createTaskResponse?.error)
      }
    },[createTaskResponse!=undefined]);

    useEffect(()=>{
      if(getTaskListResponse?.status==1){
        setTask(getTaskListResponse?.data);
      }
    },[getTaskListResponse]);

    useEffect(()=>{
      dispatch(
        getTaskList({
          buyerID: props.buyerID,
          orderID: props.orderID,
          categoryID:selectedCategory?._id,
          userMail : "parixbe@gmail.com",
        })
      )
    },[addTask==false,selectedCategory]);

    useEffect(()=>{
      console.log("getFileAccessListResponse",getFileAccessListResponse);
     if(getFileAccessListResponse&&getFileAccessListResponse?.status==1){
       let fileAccess:any = [];
       getFileAccessListResponse.fileIds.forEach((data:any)=>{
         fileAccess.push(
           {
             id:data,
             fileAccess:getFileAccessListResponse?.data?.filter((access:any)=>{
               return access.fileID==data
             })
           }
         )
       });
       setFileAccessDetails([...fileAccess])
       console.log(
         "getFileAccessListResponse",
         fileAccess
       )
     }
  },[getFileAccessListResponse!=undefined]);

    const getOrderList=(fetchType:any)=>{
      dispatch(
        getOrderDetails(
          { buyerID: props.buyerID,
            fetchType: fetchType,
            limit: 0,
            orderID: props.orderID,
            skip: 0,
            userMail : "parixbe@gmail.com",
          }
        )
      )
    }

    const getCategoryDetails =()=>{
      dispatch(
        getCategoryList(
        {
          buyerID: props.buyerID,
          orderID: props.orderID,
          userMail : "parixbe@gmail.com",
        }
      ))
    }

    const submitTask=()=>{
      console.log(
        "selectedCategory",
        selectedCategory
      )
      if(taskData.taskName==null&&
        taskData.taskDesc==null&&
        taskData.taskQty==null&&
        taskData.noQty==null){
        setFormError(["All fields are required"])
      }else{
        dispatch(
          createTask({
            taskData:{
              ...taskData,
              ...{
                categoryID:selectedCategory?._id
              }
            },
            orderID:props.orderID,
            buyerID:props.buyerID
          })
        )
      }
    }

    const onInputChange = (field:any,val:any) =>{
      var tempTaskData:any = taskData ? taskData : {};
      tempTaskData[field] = field=="categoryQty"||field=="noOfTask" ? Number(val) : val;
      setTaskData({...taskData ,...tempTaskData});
      // console.log("taskData",taskData);
    }

    const getFileAccessDetails=(element:any)=>{
      let fileId:any=[];
      element?.fileList.forEach((element:any) => {
        fileId.push(element._id);
      });
      console.log("getFileAccessDetails",element,fileId);
      dispatch(
        fileAccess(
          {
            fileIds:fileId,
            id:"orderTask"
          }
        )
      )
    }

  return (
    <View style={Style.mainContent}>
      <View>
        { formStatus==1 ?
          <View>
            <CustomAlert title={alertTitle} subTitle={alertSubTitle} onPress={()=>{setFormStatus(0)}} />
          </View>
        : <View style={screenType.container}>
              <View style={Style.header}>
                <View style={Style.headerLeft}>
                  <Text style={Style.headerTitle}>{addTask==false ? "Taks" : "Create Task"}</Text>
                </View>
                <View style={Style.headerRight}>
                  {
                    addTask == false ?
                    <View style={{flexDirection:'row'}}>
                      <Picker style={Style.dropDown} 
                        onValueChange={(itemValue:any, itemIndex:any) =>
                        setSelectedCategory(categoryData[itemIndex])
                      }>
                      <Picker.Item label="All" value={0} />
                      {
                         categoryData?.map((element:any,index:any)=>{
                         return <Picker.Item 
                          label={element.categoryName} 
                          value={element._id} />
                        })
                      }
                      </Picker>
                      <CustomTextInput type="text" id="categoryID" value={""}
                      placeHolder="Search Category/Task"
                      onInputChange={(ID,val)=>{console.log("sa")}}/>
                    </View>
                    : null
                  }
                </View>
                
                {
                  taskData?._id ?
                      <TouchableOpacity style={{alignSelf:'flex-end'}} onPress={()=>{
                        onInputChange("isDeleted",taskData.isDeleted == 'active' ? 'inactive' : 'active')
                      }}>
                        <Image style={Style.headerRightIocns}
                        source={require('../../assets/images/'+
                         ( taskData?.isDeleted == 'active' ? 'delete' : 'undo')
                        +'.png')}/>
                    </TouchableOpacity>
                    : null
                }
                <TouchableOpacity onPress={()=>{
                      setTaskData(null)
                      setSelectedCategory(null)
                      setAddTask(!addTask)
                    }}>
                  <Image style={Style.headerRightIocns}
                    source={require("../../assets/images/"+(addTask? "close" : "plus" )+".png")
                  }/>
                </TouchableOpacity>
              </View>
              {
                reloadRender||(!reloadRender) ?
                  <View style={Style.categoryTaskView}>
                      <View>
                        {
                           addTask == false ?
                            null
                        :   null
                      }
                      </View>
                      <View style={{flex:7}}>
                        { addTask == true ?
                            <View>
                              {
                                selectedCategory!=null || taskData!=null ?
                                  <View>
                                    <View>
                                      <View>
                                      <CustomTextInput type="text" id="categoryID" 
                                        value={  taskData?.taskName }
                                        placeHolder="Task Name" 
                                        onInputChange={(ID,val)=>{onInputChange('taskName',val)}} />
                                      <CustomTextInput type="text" id="categoryDesc"
                                        value={ taskData?.taskDesc}
                                        placeHolder="Task Description"
                                        onInputChange={(ID,val)=>{onInputChange('taskDesc',val)}} />
                                      <CustomTextInput type="text" id="categoryDesc"
                                        value={ taskData?.taskQty}
                                        placeHolder="Task Category"
                                        onInputChange={(ID,val)=>{onInputChange("taskQty",Number(val))}} />
                                      </View>
                                      <View>
                                        <View>
                                          <ResponsiblePerson 
                                            groupList={groupList}
                                            id='orderCateory-res'
                                            title={'Add responsible person'}
                                            currentRoute={props.currentRoute}
                                            subRoute = {"insideOrder"}
                                            orderID={props.orderID}
                                            accessID={props.currentRoute}
                                            resPerson = {taskData?.resPersons}
                                            userListUpdated = {(userList)=>{
                                              onInputChange(
                                                "resPersons",
                                                userList)
                                            }}
                                            visible={true}/>
                                          <PickStocks groupList={groupList}
                                            id={ "stockPicker"}
                                            stockList = {taskData?.stockList}
                                            buyerId={props.buyerID}
                                            orderId={props.orderID}
                                            visible={reloadRender}
                                            stockListUpdated = {(stockList)=>{onInputChange('stockList',stockList)}}/>
                                          <FileHandler isLargeScreen={props.isLargeScreen} 
                                            documentList={taskData?.fileList}
                                            fileUploadAccess={true}
                                            fileAccess={fileAccessDetails}
                                            orderID={props.orderID}
                                            id="orderCtegory-file"
                                            updateUserAccess = {(fileAccess)=>{onInputChange("fileAccess",fileAccess.value)}}
                                            deletedFileList = {(fileAccess)=>{onInputChange("fileDeleted",fileAccess.value)}}
                                            updatedFileList={(updatedList)=>{onInputChange("fileList",updatedList.value)}}/>
                                        </View>
                                      </View>
                                    </View>
                                    <View style={Style.footer}>
                                      <View style={{justifyContent:'center',flex:1}}>
                                        <CustomLabel type="formErrorMsg" label={""} errorMsg={formError}
                                         formRawData={null} successMsg={null}/>
                                      </View>
                                      <CustomButton buttonID={"Submit"} type="common"
                                      title="Save" onSubmit={()=>{submitTask()}}/>
                                    </View>
                                  </View>
                                : <View>
                                    <View style={{flexDirection:'row',justifyContent:"center", margin:20,padding:10,height:40,width:300,alignSelf:'center',backgroundColor:'orange'}}>
                                      <Text style={{fontSize:18,fontWeight:'500'}}>Select Category</Text>
                                    </View>
                                    <View style={Style.divView}>
                                        <Text style={Style.categgoryTitle}>{"Categories"}</Text>
                                        <FlatList
                                          data={categoryData}
                                          renderItem={({item}) => (
                                            <TouchableOpacity style={Style.itemContainer} 
                                              onPress={()=>{setSelectedCategory(item)}}>
                                              <View style={Style.itemContainer}>
                                                  <View style={Style.item}>
                                                    <Text style={Style.categoryName}>{item.categoryName}</Text>
                                                    <View style={Style.flexRow}>
                                                      <View>
                                                          <Text style={Style.categoryLables}>Category Qty</Text>
                                                          <Text style={Style.categoryName}>{item.categoryQty}</Text>
                                                      </View>
                                                      <View>
                                                          <Text style={Style.categoryLables}>No of taks</Text>
                                                          <Text style={Style.categoryName}>{item.noOfTask}</Text>
                                                      </View>
                                                    </View>
                                                  </View>
                                              </View>
                                            </TouchableOpacity>
                                          )}
                                          keyExtractor={item => item.id}/>
                                      </View>
                                  </View>
                              }
                            </View>
                          : <DataTable>
                              <DataTable.Header>
                                <DataTable.Title>Task Name</DataTable.Title>
                                <DataTable.Title>Task Category</DataTable.Title>
                                <DataTable.Title>Task Incharge</DataTable.Title>
                                <DataTable.Title>Stocks</DataTable.Title>
                                <DataTable.Title>Staff involved</DataTable.Title>
                              </DataTable.Header>
                              <ScrollView style={{maxHeight:400}}>
                                {
                                    task.map((element:any) => {
                                      return  <DataTable.Row onPress={()=>{ 
                                            console.log("setTaskData",element)
                                            setTaskData(element)
                                            setFileAccessDetails(null)
                                            getFileAccessDetails(element);
                                            setAddTask(!addTask)}}>
                                          <DataTable.Cell>{element.taskName}</DataTable.Cell>
                                          <DataTable.Cell>{element.taskQty}</DataTable.Cell>
                                          <DataTable.Cell>23</DataTable.Cell>
                                          <DataTable.Cell>23</DataTable.Cell>
                                          <DataTable.Cell>Completed</DataTable.Cell>
                                        </DataTable.Row>

                                    })
                                  }
                              </ScrollView>
                            </DataTable>
                        }
                      </View> 
                  </View>
                : null
              }
          </View>
        }
      </View>
    </View>
  )
}

export default OrderTask;
