import { StyleSheet } from "react-native";
import Colors from "../../constants/Colors"; 

export const Style=StyleSheet.create({
    mainContent:{
        flex:1,
    },
    orderListTopBar:{
        flex:1,
        flexDirection:'row',
        alignItems:'center',
        padding:10,
        paddingLeft:20,
        paddingRight:20,
        shadowOffset: {width: -2, height: 4},  
        shadowColor: '#171717',  
        shadowOpacity: 0.2,  
        shadowRadius: 3,  
    },
    topBarRight:{
        flex:1,
        alignSelf:'flex-start',
        flexDirection:'row'
    },
    topBarLeft:{
        flex:1,
        alignSelf:'flex-end'
    },
    dropDown:{
        height:45,
        width:250,
        borderRadius:5,
    },
    table:{
        padding:10,
    },
    pagenation:{
        top:10,
        height:60,
        flexGrow:1,
        padding:15,
        alignSelf:'center',
        position:'relative',
        justifyContent:'center',
        flexDirection:'row'
    },
    pagenationDropdown:{
        height:35,
        width:60,
        borderRadius:4,
    },
    pagenationArrow:{
        margin:8,
        padding:5,
        height:15,
        width:15,
    }
});