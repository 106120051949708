 const signupFormQuery:any=[
     {
        id:"sinupLabel",
        type:"formTitle",
        elementType:"text",
        mandatory:true,
        placeHolder:"Name",
        value:"SignUp",
        validation:false,
        readonly:false,
        errorMsg:"User name atleast 4 character",
        options:[]
     },
     {
        id:"sinupLabel",
        type:"subFormTitle",
        mandatory:true,
        elementType:"text",
        placeHolder:"Name",
        value:"Just a few steps to create an account",
        validation:false,
        readonly:false,
        errorMsg:"User name atleast 4 character",
        options:[]
     },
    {
        id:"userName",
        type:"text",
        mandatory:true,
        elementType:"inputText",
        placeHolder:"Name",
        value:"",
        defaultVallue:"",
        validation:true,
        readonly:false,
        errorMsg:"User name atleast 4 character",
        options:[]
    },
    {
        id:"companyName",
        type:"text",
        mandatory:true,
        elementType:"inputText",
        placeHolder:"Company name",
        value:"",
        defaultVallue:"",
        validation:true,
        readonly:false,
        errorMsg:"Company name atleast 4 character",
        options:[]
    },
    {
        id:"userMail",
        type:"text",
        mandatory:true,
        elementType:"inputText",
        placeHolder:"Email",
        value:"",
        defaultVallue:"",
        validation:true,
        readonly:false,
        errorMsg:"Invalid mail",
        options:[]
    },
    {
        id:"password",
        type:"password",
        mandatory:true,
        elementType:"inputText",
        placeHolder:"Password",
        value:"",
        defaultVallue:"",
        validation:true,
        readonly:false,
        errorMsg:"Password must be atleast 10 aplhanumeric",
        options:[]
    },
    {
        id:"confirmPassword",
        type:"password",
        mandatory:true,
        elementType:"inputText",
        placeHolder:"Confirm password",
        value:"",
        defaultVallue:"",
        validation:true,
        readonly:false,
        errorMsg:"Confirm password doesn't match",
        options:[]
    },
    {
        id:"errorMsg",
        type:"formErrorMsg",
        mandatory:true,
        value:"",
        validation:false,
        readonly:false,
        errorMsg:"",
        options:[]
     },
    {
        id:"signupBtn",
        type:"submitBtn",
        title:"Sign UP",
        mandatory:true,
        placeHolder:"Confirm password",
        value:"",
        validation:true,
        readonly:false,
        errorMsg:"",
        options:[]
    }
]

export default signupFormQuery;