import React,{FC} from "react";
import {View,Text, Image,TouchableOpacity} from "react-native";
import Style from "./Style";
interface Props{
    status:boolean;
    isLargeScreen:number;
    onMenuPress:(status:boolean)=>void;
}
const ToolBarTop:FC<Props>=(props)=>{
    return(
        <View style={Style.container}>
            <Text style={Style.appTitle}>Business Requirement Suite</Text>
            {
                (props.isLargeScreen<=768)  ?
                    <TouchableOpacity onPress={()=>{props.onMenuPress(!props.status)}}>
                        <Image style={Style.menuIcon} source={require("../../assets/images/menu.png")} /> 
                    </TouchableOpacity>
                :null
            }
        </View>
    )
}

export default ToolBarTop;