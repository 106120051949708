import React, { FC,useState, useEffect } from "react";
import { View,Text, Dimensions } from "react-native";
import Style from "./style";
import { RootStackScreenProps } from '../../types';
import {logoutUser} from "../../utility";
import ToolBarTop from "../../components/ToolBarTop";
import SideMenuBar from "../../components/SideMenuBarTop";
import {useRoute} from '@react-navigation/native';
import BreadCurmbs from "../../components/BreadCrumbs";
import SubTitleHeader from "../../components/SubTitleHeader";
import OrderBasicInfo from "../../components/OrderBasicInfo";
import CustomButton from "../../components/CustomButton";
import OrderUser from "../../components/OrderUser";
import { useSelector, useDispatch } from 'react-redux';
import {clearRepo, getOrderDetails} from "../../Server_Repo/action";
import {getAccessToken} from "../../utility";
import OrderCategory from "../../components/OrderCategory";
import OrderStocksUsage from "../../components/OrderStocksUsage";
import OrderTask from "../../components/OrderTask";
import OrderProductionLine from "../../components/OrderProductionUnit";
import EventAgenda from "../../components/EventAgenda";
import CalendarEvent from "../../components/CalendarEvent";
import ProductionInput from "../../components/ProductionInput";
import ProductionDefectInput from "../../components/ProductionDefectInput";
import OredrReport from "../../components/OrderReport";

const window = Dimensions.get("window");
const noAPI =["stocks"];
interface Props{
    rouetData:any;
    routeName:any;
    navigation:(dataSets:any,natTo:any)=>void;
}
const  CreateOrder:FC<Props>=(props)=>
{

    const dispatch:any = useDispatch();
    const routeData=props.rouetData;
    const [sideMenu,setSideMenu]=useState(true);
    const [isLargeScreen,setIsLargeScreen]=useState(window.width);
    const [viewType,setViewType]=useState("basicInfo");
    const [orderID,setOrderID]=useState();
    const [orderBasicInfo,setOrderBasicInfoDetails]=useState();
    const [orderGroupInfo,setOrderGroupInfoDetails]=useState();
    const [buyerID,setBuyerId]=useState();
    
    const { 
        orderDetails ,
        apiResponse
    } = useSelector((state:any) => state.userReducer);

   
    Dimensions.addEventListener("change",({ window}) => {
        setIsLargeScreen(window.width);
    });
    
    useEffect(()=>{
        if(isLargeScreen>=730)
            setSideMenu(true)
        else
            setSideMenu(true)
    },[isLargeScreen])

    useEffect(()=>{
        if(routeData&&routeData.data&&routeData.data.orderID){
            setOrderID(routeData.data.orderID);
            getOrderInfo();
        }
    },[orderID]);

  async function getOrderInfo(){
        if(orderID&&noAPI.indexOf(viewType)==-1)
            dispatch(getOrderDetails({
                skip:0,
                limit:10,
                orderID:orderID,
                fetchType:viewType,
                buyerID:buyerID
            }));
    }

    const logout=()=>{
        logoutUser().then((status)=>{
            props.navigation("Root",{});
        })
    }

    const internalComponentChanges=()=>{
        getOrderInfo();
    }

    const navigateTo=(route:any)=>{
        props.navigation(route,{});
    }

    const switchComponent=(componentName:any)=>{
        setViewType(componentName)
    }

    const successCallBack=(data:any)=>{
        if(viewType=="basicInfo"){
            setOrderID(data.data.orderID);
            setViewType("users"); 
            setBuyerId(data.data.buyerID);
        }
    }

    useEffect(()=>{
        if(orderDetails&&orderDetails.status){
            if(orderDetails.status==1&&
                viewType=="basicInfo"&&
                orderDetails?.fetchType=="basicInfo"){
                    setBuyerId(orderDetails.data.buyerID);
                    setOrderBasicInfoDetails(orderDetails.data);
            }else{
                if(orderDetails.status==1&&viewType=="users"){
                    setOrderGroupInfoDetails(orderDetails.data);
                }
            }
        }
    },[orderDetails]);

    useEffect(()=>{
        console.log("renderStatus",viewType);
        if(viewType!='opu')
            getOrderInfo();
    },[viewType])



    return (
        <View style={Style.container}>
            <View style={Style.mainContect}>
                {
                    isLargeScreen>768||sideMenu==false ?
                    <View style={[Style.overAllContent,isLargeScreen<768 ? {width:isLargeScreen}:null]}>
                        <SubTitleHeader title={"Create Order"}/>
                        <View style={[Style.content,
                            isLargeScreen>=768 ? Style.flexDirectionRow : Style.flexDirectionColumn]}>
                            <BreadCurmbs routeName={props.routeName}
                            activeCurm={viewType}
                            orderID={routeData.data.orderID}
                            isLargeScreen={isLargeScreen}
                            switchComponent={(componentName)=>switchComponent(componentName)}
                            />
                            <View style={Style.componentHolder}>
                                {
                                    viewType=="basicInfo" ?
                                        <OrderBasicInfo isLargeScreen={isLargeScreen}
                                        data={orderBasicInfo ? orderBasicInfo : null }
                                        orderID={orderID}
                                        successCallBack={(data)=>{successCallBack(data)}}/>
                                    :   viewType=="users" ?
                                            <OrderUser isLargeScreen={isLargeScreen}
                                            currentRoute={props.routeName}
                                            internalComponentChanges={()=>internalComponentChanges()}
                                            data={orderGroupInfo ? orderGroupInfo : null}
                                            orderID={orderID}
                                            successCallBack={(data)=>{successCallBack(data)}}/>
                                        :  viewType=="category" ?
                                            <OrderCategory isLargeScreen={isLargeScreen}
                                            currentRoute={props.routeName}
                                            internalComponentChanges={()=>internalComponentChanges()}
                                            data={orderGroupInfo ? orderGroupInfo : null}
                                            orderID={orderID}
                                            buyerID={buyerID}
                                            successCallBack={(data)=>{successCallBack(data)}}/>
                                            :  viewType=="stocks" ?
                                                <OrderStocksUsage isLargeScreen={isLargeScreen}
                                                internalComponentChanges={()=>internalComponentChanges()}
                                                data={orderGroupInfo ? orderGroupInfo : null}
                                                orderID={orderID}
                                                buyerID={buyerID}
                                                successCallBack={(data)=>{successCallBack(data)}}/>
                                            : viewType=="task" ?
                                                    <OrderTask
                                                    currentRoute={props.routeName}
                                                    isLargeScreen={isLargeScreen}
                                                    internalComponentChanges={()=>internalComponentChanges()}
                                                    data={orderGroupInfo ? orderGroupInfo : null}
                                                    orderID={orderID}
                                                    buyerID={buyerID}
                                                    successCallBack={(data)=>{successCallBack(data)}}/>
                                                :  viewType=="opu" ?
                                                    <OrderProductionLine
                                                    isLargeScreen={isLargeScreen}
                                                    internalComponentChanges={()=>internalComponentChanges()}
                                                    data={orderGroupInfo ? orderGroupInfo : null}
                                                    orderID={orderID}
                                                    buyerID={buyerID}
                                                    successCallBack={(data)=>{successCallBack(data)}}/>
                                                : viewType=="eventAgenda" ?
                                                    <EventAgenda
                                                    isLargeScreen={isLargeScreen}
                                                    internalComponentChanges={()=>internalComponentChanges()}
                                                    data={orderGroupInfo ? orderGroupInfo : null}
                                                    orderID={orderID}
                                                    currentRoute={props.routeName}
                                                    buyerID={buyerID}
                                                    successCallBack={(data)=>{successCallBack(data)}}/>
                                                :  viewType=="calendarEvent" ?
                                                    <CalendarEvent
                                                    isLargeScreen={isLargeScreen}
                                                    currentRoute={props.routeName}
                                                    internalComponentChanges={()=>internalComponentChanges()}
                                                    data={orderGroupInfo ? orderGroupInfo : null}
                                                    orderID={orderID}
                                                    buyerID={buyerID}
                                                    successCallBack={(data)=>{successCallBack(data)}}/>
                                                :  viewType=="productionInput" ?
                                                    <ProductionInput
                                                    isLargeScreen={isLargeScreen}
                                                    internalComponentChanges={()=>internalComponentChanges()}
                                                    data={orderGroupInfo ? orderGroupInfo : null}
                                                    orderID={orderID}
                                                    buyerID={buyerID}
                                                    successCallBack={(data)=>{successCallBack(data)}}/>
                                                : viewType=="defectInput" ?
                                                    <ProductionDefectInput
                                                    isLargeScreen={isLargeScreen}
                                                    internalComponentChanges={()=>internalComponentChanges()}
                                                    data={orderGroupInfo ? orderGroupInfo : null}
                                                    orderID={orderID}
                                                    buyerID={buyerID}
                                                    successCallBack={(data)=>{successCallBack(data)}}/>
                                                : viewType=="orderReport" ?
                                                    <OredrReport
                                                    isLargeScreen={isLargeScreen}
                                                    orderID={orderID}
                                                    buyerID={buyerID}/>
                                                : null
                                }
                            </View>
                        </View>
                    </View>
                    : null
                }
            </View>
        </View>
    )
}

export default CreateOrder;