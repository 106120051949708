import React, { FC } from "react";
import {ImageBackground, View, Text} from "react-native";
import Style from "./style";
interface Props{
    rouetData:any;
    navigation:(dataSets:any,natTo:any)=>void;
}
const image = {uri: ''};

const DashbaordActivity:FC<Props>=()=>
{
    return (
        <View style={Style.mainContainer}>
            <ImageBackground source={require('../../assets/images/bg.jpeg')}>
                <View  style={Style.container}>
                    <View style={{justifyContent:'flex-end'}}>
                        <View style={Style.rowWise}>
                            <View  style={[Style.breadCrads,Style.totalOrderHolder]}>
                            
                                    {/* <ImageBackground source={image} resizeMode="cover" style={[Style.breadCrads,Style.totalActiveCard]}> */}
                                        <View>
                                            <Text style={Style.subCardTitle}>Total Order</Text></View>
                                            <Text style={Style.subCardVal}>-</Text>
                                        <View>
                                    </View>
                                    {/* </ImageBackground> */}
                
                            </View>
                            <View style={[Style.breadCrads,Style.completedOrderHolder]}>
                                <View><Text style={Style.subCardTitle}>Total completed Order</Text></View>
                                <Text style={Style.subCardVal}>-</Text>
                                <View></View>
                            </View>
                            <View style={[Style.breadCrads,Style.inprogressOrderHolder]}>
                                <View><Text style={Style.subCardTitle}>Total Inprogress Order</Text></View>
                                <Text style={Style.subCardVal}>-</Text>
                                <View></View>
                            </View>
                        </View>
                        <View style={Style.rowWise}>
                            <View style={[Style.breadCrads,Style.categoryOrderHolder]}>
                                <View><Text style={Style.subCardTitle}>Total Category</Text></View>
                                <Text style={Style.subCardVal}>-</Text>
                                <View></View>
                            </View>
                            <View style={[Style.breadCrads,Style.taskOrderHolder]}>
                                <View><Text style={Style.subCardTitle}>Total Task</Text></View>
                                <Text style={Style.subCardVal}>-</Text>
                                <View></View>
                            </View>
                            <View style={[Style.breadCrads,Style.defectOrderHolder]}>
                                <View><Text style={Style.subCardTitle}>Total Defect</Text></View>
                                <Text style={Style.subCardVal}>-</Text>
                                <View></View>
                            </View>
                        </View>
                        <View style={Style.rowWise}>
                            <View style={[Style.breadCrads,Style.completedOrderHolder]}>
                                <View><Text style={Style.subCardTitle}>Agenda</Text></View>
                                <Text style={Style.subCardVal}>-</Text>
                                <View></View>
                            </View>
                            <View style={[Style.breadCrads,Style.completedOrderHolder]}>
                                <View><Text style={Style.subCardTitle}>Meeting</Text></View>
                                <Text style={Style.subCardVal}>-</Text>
                                <View></View>
                            </View>
                        </View>
                    </View>
                    <View style={[Style.breadCrads,Style.notification]}>
                        <View><Text style={Style.subCardTitle}>Notification</Text></View>
                    </View>
                </View> 
            </ImageBackground>
        </View>
    )
}

export default DashbaordActivity;