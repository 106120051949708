import { StyleSheet } from "react-native";
import * as Colors from '../../constants/Colors';
const Style=StyleSheet.create({
    container:{
        flex:1,
        maxHeight:60,
        justifyContent:'flex-start',
        padding:10,
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.2,
        shadowRadius: 2,
    },
    subTitleHeader:{
        margin:15,
        fontWeight:'600',
        fontSize:15,
    },
});

export default Style;