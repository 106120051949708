import React, { FC } from "react";
import {ImageBackground, View, Text} from "react-native";
import Style from "./style";
import CustomTextInput from "../../components/CustomTextInput";
import Ionicons from '@expo/vector-icons/EvilIcons';
import CustomButton from "../../components/CustomButton";
interface Props{
    rouetData:any;
    navigation:(dataSets:any,natTo:any)=>void;
}
const image = {uri: ''};

const ProfileActivity:FC<Props>=()=>
{
    return (
        <View style={Style.mainContainer}>
            <View  style={Style.container}>
                <View style={Style.rowWise}>
                     <Ionicons name="user"  style={Style.searchIcon} 
                        onPress={()=>{}}/> 
                    <Text style={Style.pageTitleLable}>User Details</Text>
                    <View style={{flex:1,alignSelf:'center'}}>
                        <Text style={{justifyContent:'flex-end'}}>User Details</Text>
                    </View>
                </View>
                <View style={Style.rowWise}>
                    <View style={Style.notification}>
                        <Text style={Style.pageSubLabel}>User Details</Text>
                        <View style={Style.rowWise}>
                            <View style={Style.rowWise}>
                                <Text>User Name :</Text><Text>Parimal</Text>
                            </View>
                            <View style={Style.rowWise}>
                                <Text>User ID :</Text><Text>IOPPLO</Text>
                            </View>
                            <View style={Style.rowWise}>
                                <Text>User Mail :</Text><Text>parixbe@gmail.com</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={Style.rowWise}>
                    <View style={Style.notification}>
                        <Text style={Style.pageSubLabel}>Account Details</Text>
                        <View style={Style.rowWise}>
                            <View style={Style.rowWise}>
                                <Text>User Role :</Text><Text>Admin</Text>
                            </View>
                            <View style={Style.rowWise}>
                                <Text>Group : </Text><Text>IOPPLO</Text>
                            </View>
                            <View style={Style.rowWise}>
                                <Text>Last Acctive : </Text><Text>12/05/12</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={Style.rowWise}>
                    <View style={Style.notification}>
                        <Text style={Style.pageSubLabel}>Plan</Text>
                        <View style={Style.rowWise}>
                            <View style={Style.rowWise}>
                                <Text>Expired IN :</Text><Text>30 days</Text>
                            </View>
                            <View style={Style.rowWise}>
                                <Text>Current Plan </Text><Text>IOPPLO</Text>
                            </View>
                        </View>
                        <View style={Style.rowWise}>
                            <View>
                                <View style={Style.planDetails}>
                                    <Text style={Style.planCost}>50 $</Text>
                                    <Text style={Style.planFor}>3 Months</Text>
                                </View>
                                <CustomButton buttonID={"Submit"} type="common"
                                title="Buy" onSubmit={()=>{(null)}}/>
                            </View>
                            <View>
                                <View style={Style.planDetails}>
                                    <Text style={Style.planCost}>80 $</Text>
                                    <Text style={Style.planFor}>7 Months</Text>
                                </View>
                                <CustomButton buttonID={"Submit"} type="common"
                                title="Buy" onSubmit={()=>{(null)}}/>
                            </View>
                            <View>
                                <View style={Style.planDetails}>
                                    <Text style={Style.planCost}>120 $</Text>
                                    <Text style={Style.planFor}> 12 Months</Text>
                                </View>
                                <CustomButton buttonID={"Submit"} type="common"
                                title="Buy" onSubmit={()=>{(null)}}/>
                            </View>
                        </View>
                        <View style={Style.planRenewBox}>
                            <CustomTextInput type="text" id="orderName"
                                value={""}
                                placeHolder="Enter App code to renew" onInputChange={(ID,val)=>{(val)}} />
                            <CustomButton buttonID={"Submit"} type="common"
                                title="Renew Account" onSubmit={()=>{(null)}}/>
                        </View>
                    </View>
                   
                </View>
                <View style={Style.rowWise}>
                    <View style={Style.notification}>
                        <Text style={Style.pageSubLabel}>User login activity</Text>
               
                            <View style={Style.rowWise}>
                                <Text>User device : </Text><Text>Mac</Text>
                            </View>
                            <View style={Style.rowWise}>
                                <Text>Time : </Text><Text>12/12/1212</Text>
                            </View>
                            <View style={Style.rowWise}>
                                <Text>User Role :</Text><Text>Admin</Text>
                            </View>
                            <View style={Style.rowWise}>
                                <Text>User Role :</Text><Text>Admin</Text>
                            </View>
   
                    </View>
                </View>
            </View> 
        </View>
    )
}

export default ProfileActivity;