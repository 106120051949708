import { StyleSheet } from "react-native";

const Style=StyleSheet.create({
    container:{
        flex:1,
        flexDirection:'row'
    },
    floatLeft:{
        alignSelf:'flex-start',
    },
    floatRight:{
        alignSelf:'flex-end',
    },
    formTitle:{
        margin:5,
        fontSize:25,
        color:'black',
        fontWeight:'700'
    },
    subFormTitle:{
        margin:5,
        fontSize:15,
        color:'black'
    },
    errorText:{
        color:'red',
        marginTop:15,
        margin:10,
        alignSelf:'flex-start',
    },
    successText:{
        color:'green',
        marginTop:15,
        margin:10,
        alignSelf:'flex-start',
    },
    resendOtp:{
        color:'blue',
        fontWeight:'600',
        marginTop:15,
        margin:10,
        alignSelf:'center'
    },
    elementLabel:{
        margin:5,
        alignSelf:'flex-start',
        fontSize:12,
        fontWeight:'600'
    }
});

export default Style;