function formValidation(form:Array<any>,formData:any){
    var formDataIndex=formData ? Object.keys(formData) : [];
    var validationFields=form.filter((data)=>data.validation==true);
    var validationError:any=[];
    var validate=["inputText"];

    if(formData==null)
        validationError.push({id:"data",msg:"Required fields are missing"});
    else
        validationFields.forEach((data,index)=>{

                if(validate.indexOf(data.elementType)!=-1)

                    //Checking index for missing value
                    if(formDataIndex.indexOf(data.id)==-1||formData[data.id].length==0){
                        validationError.push({id:data.id,msg:data.errorMsg});
                    }
                    
                    if(formDataIndex.indexOf(data.id)!=-1){
                        //cehcking confirm password
                        if(data.id=="confirmPassword"){
                            if(formData['password']!=formData["confirmPassword"])
                                validationError.push({id:data.id,msg:data.errorMsg});
                        }
                        //cehcking  password field length
                        if(data.id=="password"){
                        if(formData[data.id].length<10){
                            validationError.push({id:data.id,msg:data.errorMsg}); 
                        }
                        }
                    }
                    //cehcking eamil field type
                    if(data.id=="userMail"){
                        if(!emaiValidation(formData[data.id]))
                            validationError.push({id:data.id,msg:data.errorMsg}); 
                    }

        });

    function emaiValidation(email:any){
        console.log(email);
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (reg.test(email) === false) {
          console.log("Email is Not Correct");
          return false;
        }
        else {
          console.log("Email is Correct");
          return true;
        }
    }

    if(validationError.length==0)
        return {
            status:0,
            errorMsg:[]
        }
    else
        return {
            status:1,
            errorMsg:validationError
        }
} 

export default formValidation;