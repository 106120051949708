import { StyleSheet } from "react-native";
import Colors from "../../constants/Colors"; 
const Style=StyleSheet.create({
    container:{
        flex:1,
        display:'flex',
        justifyContent:'flex-start',
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.0,
        shadowRadius: 2,
        flexDirection:'column',
        marginTop:2,
        overflow:'scroll',
        maxWidth:200,
        borderRightColor:'#c2cbd1',
        borderRightWidth:1
    },
    contentHolder:{
        padding:5,
        display:'flex',
        flexDirection:'row',
        height:50,
        marginRight:-2,
        zIndex:1
    },
    sidemenuTitle:{
        margin:12,
        fontWeight:'500',
        fontSize:13,
    },
    sideMenuIcon:{
        margin:10,
        height:20,
        width:20,
    },
    activeSideMenu:{
        backgroundColor:"#fafafa",
        borderRightColor:"#63afe6",
        borderRightWidth:5,
    },
    actveSidemenuTitle:{
        color:'white'
    },
    flexDirectionRow:{
        flexDirection:'row',
        overflow:'scroll',
        alignSelf:'stretch',
        maxWidth:700,
    },
    flexDirectionColumn:{
        flexDirection:'column'
    }
});

export default Style;