import React,{FC} from "react";
import {View,Text, Image,TouchableOpacity} from "react-native";
import Style from "./style";
interface Props{
    title:string;
}
const SubTitleHeader:FC<Props>=(props)=>{
    return(
        <View style={Style.container}>
            <Text style={Style.subTitleHeader}>{props.title}</Text>
        </View>
    )
}

export default SubTitleHeader;