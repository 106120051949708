 const resetPinFormQuery:any=[
     {
        id:"forgetPinLabel",
        type:"formTitle",
        elementType:"text",
        mandatory:true,
        value:"Recover Pin",
        validation:false,
        readonly:false,
        options:[]
     },
     {
        id:"forgetPinSubLabel",
        type:"subFormTitle",
        mandatory:true,
        elementType:"text",
        placeHolder:"Name",
        value:"Enter your user ID / Mail and password to reset pin",
        validation:false,
        readonly:false,
        errorMsg:"User name atleast 4 character",
        options:[]
     },
    {
        id:"userMail",
        type:"text",
        mandatory:true,
        elementType:"inputText",
        placeHolder:"Email / User ID",
        value:"",
        defaultVallue:"",
        validation:false,
        readonly:false,
        errorMsg:"User ID atleast 4 character",
        options:[]
    },
    {
        id:"password",
        type:"password",
        mandatory:true,
        elementType:"inputText",
        placeHolder:"Password",
        value:"",
        defaultVallue:"",
        validation:true,
        readonly:false,
        errorMsg:"Password must be atleast 10 character",
        options:[]
    },
    {
        id:"confirmPassword",
        type:"password",
        mandatory:true,
        elementType:"inputText",
        placeHolder:"Confirm password",
        value:"",
        defaultVallue:"",
        validation:true,
        readonly:false,
        errorMsg:"Confirm password does not match",
        options:[]
    },
    {
        id:"errorMsg",
        type:"formErrorMsg",
        mandatory:true,
        value:"",
        validation:false,
        readonly:false,
        errorMsg:"",
        options:[]
     },
    {
        id:"resetPinBtn",
        type:"submitBtn",
        title:"Reset Password",
        mandatory:true,
        placeHolder:"Reset Password",
        value:"",
        validation:true,
        readonly:false,
        errorMsg:"",
        options:[]
    }
]

export default resetPinFormQuery;