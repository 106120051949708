import { StyleSheet } from "react-native";

const Style=StyleSheet.create({
    container:{
        flexDirection:'row',
        padding:5,
        backgroundColor:"#228FF3",
        maxHeight:50,
    },
    containerLeft:{
        flexDirection:'row',
        alignSelf:'flex-start',
        padding:5,
    },
    containerRight:{
        flexDirection:'row',
        alignSelf:'flex-start',
        padding:20,
    },
    activityTitle:{
        margin:5,
        fontSize:18,
        color:'white',
        fontWeight:'700',
    },
    menuIcon:{
        marginTop:2,
        color:'white',
        fontSize:25
    },
    createProducts:{
        alignSelf:'flex-end',
        with:200,
        height:45,
        borderColor:'white',
    },
    filterOptions:{
        zIndex:5,
        flexDirection:'row',
        padding:5,
        paddingTop:5
    },
    dropDown:{
        margin:10,
        marginRight:15,
        height:48,
        width:'30%',
        borderRadius:2,
    },
    filterView:{
     marginTop:15,
     marginLeft:12,
     flexDirection:'row'
    },
    wrapView:{
        flexDirection: 'row',
        flexWrap: 'wrap',
        backgroundColor:'green'
    },
    footer:{
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 1,
        marginBottom:0,
        position:'relative',
        flexDirection:'row',
        padding:2,
        zIndex:-1
    },

    rowWise:{
        flexDirection:'row'
    },
    breadCrumbs:{
        marginTop:5,
        marginRight:15,
        marginBottom:5,
        padding:10,
        height:40,
        width:'50%',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 1,
        position:'relative',
        flexDirection:'row',
    },
    breadCrumbsLable:{
        fontWeight:'500',
    },
    tableCard:{
        marginTop:10,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 1,  
    },
    tableHeaderCard:{
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 1,  
        backgroundColor:'#ebedf0'
    },
    viewMainTitle:{
        margin:10,
        fontWeight:'500',
    },
    statusContainer:{
        flexDirection:'row'
    },
    orderInfo:{
        padding:10,
        width:'100%',
        marginLeft:20,
        marginBttom:10,
        paddingLeft:15,
        borderLeftStyle:'soild',
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.2,
        shadowRadius: 2,
        justifyContent:'center'
    },
    subTitleHeaderView:{
        flexDirection:'row',
        maxHeight:60,
        justifyContent:'flex-start',
        padding:10,
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.2,
        shadowRadius: 2,
    },
    subTitleHeader:{
        margin:15,
        fontWeight:'600',
        fontSize:15,
    },
    subTitle:{
        marginTop:5,
        fontSize:12,
        fontWeight:'500'
    },
    subValues:{
        fontSize:18,
        fontWeight:'600'
    },
    rowWiseSpliter:{
        marginRight:'5%',
    },
    fileViewTabls:{
        marginTop:10,
        paddingLeft:20,
        flexDirection:'row'
    },
    fileBtnLabel:{
        color:'black',
        alignSelf:'center'
    },
    activeFileBtnLabel:{
        color:'white',
        alignSelf:'center'
    },
    fileViewBtn:{
        height:40,
        width:180,
        justifyContent:'center',
        backgroundColor:'#dce0dd'
    },
    activeFileViewBtn:{
        justifyContent:'center',
        backgroundColor:'#248af0'
    },
    backIcon:{
        marginLeft:15,
        color:'black',
        fontSize:30
    },
});

export default Style;