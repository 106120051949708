import { StyleSheet } from "react-native";
import * as Colors from '../../constants/Colors';
const Style=StyleSheet.create({
    progressBarHolder:{
        height:10,
        width:250,
        borderRadius:20,
        backgroundColor:'gray',
        alignSelf:'center'
    },
    progressBar:{
        height:10,
        width:150,
        borderRadius:20,
        backgroundColor:Colors.default.light.progressBarColor,
        alignSelf:'flex-start'
    }
});

export default Style;