import React,{FC, useState} from "react";
import {View,Image,Text,Dimensions} from "react-native";
import CustomTextInput from "../../components/CustomTextInput";
import CustomButton from "../../components/CustomButton";
import {Style,tabletView,mobileView} from './style';
import Ionicons from '@expo/vector-icons/Ionicons';
import {ComponentSwitch} from "../../constants/ComponentSwitch";
import forgetpin from "../../constants/FormQueries/forgetpin";
import { useDispatch,useSelector } from 'react-redux';
import { forgetPassword,forgetOTP , clearRepo } from '../../Server_Repo/action';
import {storeAccessToken} from "../../utility/index";
import FormMaker from "../FormMaker";

interface Props{
  formData:any;
  switchFormType:(type:String,formData:any)=>void;
  toscreen:number;
}

const  ForgetPinForm:FC<Props>=(props)=>{

  const dispatch:any = useDispatch();
  const [formQuery,setFormQuery]=useState(forgetpin);
  const [formData,setFormData]=useState<any>(props.formData);
  const [formError,setFormError]=useState<any>(null);
  const [screenType,setScreenType]=useState(Style);

  const successCallBack=(responseFormData:any)=>{
    console.log("formDataOtp",responseFormData,formData);
    props.switchFormType(ComponentSwitch[1],formData);
  }

  const submitForm=(formData:any)=>{
      console.log("ForgetPinForm",props);
      dispatch(clearRepo());
      setFormData(formData);
      var tempObj=formData;
      tempObj["otpType"]=1;
      dispatch(forgetOTP(tempObj));
  //    props.switchFormType(ComponentSwitch[props.toscreen],tempObj)
  }

  return (
      <View style={screenType.formWidth}>
         <FormMaker formElementData={formQuery} 
           formID="forgetPin"
          erroMsg={formError} formData={formData} 
          successCallBack={(formData)=>successCallBack(formData)}
          submitForm={(formData)=>submitForm(formData)}/>
            <View style={Style.formFooter}>
                <Text style={Style.loginText} 
                    onPress={()=>{
                      dispatch(clearRepo());
                      props.switchFormType(ComponentSwitch[2],null);
                    }}>Login</Text>
                <Text style={Style.forgetPin}
                    onPress={()=>{
                      dispatch(clearRepo());
                      props.switchFormType(ComponentSwitch[0],null);
                }}>Signup</Text>
            </View>
      </View>
   )
}

export default ForgetPinForm;





