import React,{FC,useEffect, useState} from "react";
import {View,Image,Text,Dimensions} from "react-native";
import CustomTextInput from "../../components/CustomTextInput";
import CustomButton from "../../components/CustomButton";
import {Style,tabletView,mobileView} from './style';
const window = Dimensions.get("window");
import FormMaker from "../FormMaker";
import billingForm from "../../constants/FormQueries/billingForm";
import Ionicons from '@expo/vector-icons/Ionicons';
import {ComponentSwitch} from "../../constants/ComponentSwitch";
import { useDispatch,useSelector } from 'react-redux';
import { updatePlanDetails, clearRepo } from '../../Server_Repo/action';
interface Props{
  formData:any;
  switchFormType:(type:String,formData:any)=>void;
  successCallBack:(formData:any)=>void;
}
const  BillingAddressForm:FC<Props>=(props)=>{

    const dispatch:any = useDispatch();
    const {
      updatePlanDetailsResponse,
      apiResponse
  } = useSelector((state:any) => state.userReducer);
    var formTempData=billingForm;
    const [screenType,setScreenType]=useState(Style);
    const [formQuery,setFormQuery]=useState(billingForm);
    const [formData,setFormData]=useState<any>(props.formData);
    const [formError,setFormError]=useState<any>(null);


  billingForm.forEach((element:any,index:number) => {
      if(formTempData&&formTempData[element.id]!=undefined){
          element.value=formTempData[element.id];
      }else{
          if(element.elementType=="inputText")
          element.value='';
      }
  });

  useEffect(()=>{
    if(updatePlanDetailsResponse&&
      updatePlanDetailsResponse.status=="success")
     successCallBack(updatePlanDetailsResponse);
     else
      if(updatePlanDetailsResponse&&
        updatePlanDetailsResponse.status=="faill")
          setFormError(updatePlanDetailsResponse.error)
  },[updatePlanDetailsResponse])

  const successCallBack=async (reponse:any)=>{
    props.successCallBack(
      {
        ...reponse,
        ...{screenFrom:"BillingAddressForm"}
    });
  }

  const submitForm=(formData:any)=>{
    var tempObj={...formData,...props.formData};
    tempObj["type"]="mainPlan";
    console.log("BillFormData",tempObj);
    dispatch(updatePlanDetails(tempObj));
  }
  
  return (
    <View style={screenType.formWidth}>
        <Ionicons name="arrow-back"  style={Style.backIcon} onPress={()=>{
        props.switchFormType(ComponentSwitch[4],props.formData)
      }}/> 
      <FormMaker formElementData={formQuery} 
      formID="billing"
      erroMsg={formError} formData={formData} 
      successCallBack={(formData)=>successCallBack(formData)}
      submitForm={(formData)=>submitForm(formData)}/>
    </View>
  )
}

export default BillingAddressForm;