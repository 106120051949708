import React, { FC, useEffect, useState } from "react";
import { View,Text,Button,Image } from "react-native";
import Style from "./style";
import { RootStackScreenProps } from '../../types';
import ProgressBar from '../../components/ProgressBar';
import {Server_Handler} from "../../Server_Repo";
import {getAccessToken, storeUserDetails} from "../../utility";
import {validateAccessToken} from "../../constants/RouteParams";
import * as navigationParams from "../../constants/navigationParams";
import { useDispatch,useSelector } from 'react-redux';
import { getUserDetails, clearRepo } from '../../Server_Repo/action';
interface Props{
    rouetData:any;
    navigation:(dataSets:any,natTo:any)=>void;
}
const  SplashActivity:FC<Props> = (props)=>{

    const dispatch:any = useDispatch();
    const {
        userDetails,
        apiResponse
    } = useSelector((state:any) => state.userReducer);

    const [redirectionUser,setRedirectionUser]=useState<boolean>(false);
    const [progress,setProgress]=useState<number>(0);
    const [navTo,setNavTo]=useState<any>("");
    const [serverRes,setserverRes]=useState<boolean>(false);

    
    useEffect(()=>{
        getAccessToken().then(async(data:any)=>{
            if(data.status=="1"){
                console.log(
                    "accessToken",
                    data.accessToken
                )
                if(data.accessToken=='init'||data.accessToken=='logout'){
                    props.navigation("signup",{data:{initScreen:"login"}});
                }else{
                    await dispatch(getUserDetails(
                        {
                          userMail:data.accessToken
                        }
                    ));
                }
            }else{
                setserverRes(true);
                setNavTo(navigationParams.toSignUpActivity);
            }
        });
    },[]);

    useEffect(()=>{
  
       if(userDetails&&userDetails.userRole=="Admin"){
            if(userDetails!=null&&
                userDetails!=undefined&&
                userDetails.userStatus=="activePlan"){
                    console.log("userDetails",userDetails); 
                    storeUserDetails(userDetails).then(()=>{
                        setserverRes(true);
                        setProgress(0);
                        setNavTo(navigationParams.toDashboardActivity);
                    })
           }else{
                setserverRes(true);
                setNavTo(navigationParams.toSignUpActivity);
           }
       }else{
            if(userDetails!=null&&
                userDetails!=undefined&&
                userDetails.userStatus!="blocked"){
                    storeUserDetails(userDetails).then(()=>{
                        setserverRes(true);
                        setProgress(0);
                        setNavTo(navigationParams.toDashboardActivity);
                    })
                   
            }else{
                setNavTo(navigationParams.toLoginActivity);
            }
        }
    },[userDetails!=undefined]);

    useEffect(()=>{
        if(serverRes==false)
            setRedirectionUser(true);
        
        if(redirectionUser==true
            &&progress==250
            &&serverRes==true){
            props.navigation(navTo,{data:userDetails});
        }
    },[redirectionUser,progress])

    return (
        <View style={Style.container}>
            <Image style={Style.splashLogo}
            source={require('../../assets/images/icon.png')}/>
            <Text style={Style.appTitle}>Business Requirement suite</Text>
             {
                redirectionUser==true&&progress!=250 ? 
                    <ProgressBar progressStatus={(progress)=>{
                        setProgress(progress)}
                    }/>
                : null
             }
        </View>
    )
}

export default SplashActivity;