import React,{useEffect, useState} from 'react';
import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Store } from './Server_Repo/store';
import {Provider} from "react-redux";
import SideMenuBar from "./components/SideMenuBarTop";
import ToolBarTop from './components/ToolBarTop';
import { View } from './components/Themed';
import {Dimensions} from "react-native";
import Style from "./style"
import { RootStackScreenProps } from './types';
import SplashActivity from './screens/splashActivity';
import DashbaordActivity from './screens/dashboardActivity';
import CreateOrder from './screens/createOrderActivity';
import OrderList from './screens/orderList';
import UserManagementActivity from './screens/userManagementActivity';
import SignupActivity from './screens/signupActivity';
import StockActivity from './screens/stockActivity';
import AddOnActivity from './screens/addOnActivity';
import ProfileActivity from './screens/profileActivity';
import SupportActivity from './screens/supportActivity'
const window = Dimensions.get("window");

export default function App() {

  const [sideMenu,setSideMenu]=useState(true);
  const [isLargeScreen,setIsLargeScreen]=useState(window.width);
  const [initScreen,setScreen] = useState("splashActivity");
  const [navData,setNavData] = useState("splashActivity");
  useEffect(()=>{
      console.log(
        "initScreen",
        initScreen
      )
  },[initScreen])
  return (
    <View style={{flex:1,backgroundColor:'white'}}>
       <SafeAreaProvider>
          <Provider store={Store}>
            {
              initScreen=="splashActivity" ? 
                <SplashActivity rouetData={navData} navigation={(natTo:any,data:any)=>{
                  setNavData(data);
                  setScreen(natTo);
                }}/>
              :
               initScreen=="signup" ? 
                <SignupActivity routeName={initScreen} rouetData={navData} 
                  navigation={(natTo:any,data:any)=>{
                  setNavData(data);
                  setScreen(natTo);
                }}/>
              : <View style={Style.container}>
                  <ToolBarTop status={sideMenu}
                  isLargeScreen={isLargeScreen}
                  onMenuPress={(status)=>{setSideMenu(status)}}/>
                  <View style={Style.mainContect}>
                      {
                          sideMenu==true ?
                                <SideMenuBar currentRoute={initScreen}
                                navigateTo={(route)=>{setScreen(route)}}/>
                          : null 
                      }
                      {
                        initScreen=="dashboard" ? 
                          <DashbaordActivity rouetData={navData} 
                            navigation={(natTo:any,data:any)=>{
                            setNavData(data);
                            setScreen(natTo);
                          }}/>
                        : initScreen=="createOrder" ? 
                            <CreateOrder routeName={initScreen} rouetData={navData} 
                              navigation={(natTo:any,data:any)=>{
                              setNavData(data);
                              setScreen(natTo);
                            }}/>
                        : initScreen=="orderList" ? 
                            <OrderList routeName={initScreen} rouetData={navData} 
                              navigation={(natTo:any,data:any)=>{
                              setNavData(data);
                              setScreen(natTo);
                            }}/>
                        : initScreen=="userManagement" ? 
                          <UserManagementActivity routeName={initScreen} rouetData={navData} 
                            navigation={(natTo:any,data:any)=>{
                            setNavData(data);
                            setScreen(natTo);
                          }}/>
                        : initScreen=="stockManagement" ? 
                            <StockActivity routeName={initScreen} rouetData={navData} 
                              navigation={(natTo:any,data:any)=>{
                              setNavData(data);
                              setScreen(natTo);
                            }}/>
                        : initScreen=="assetStore" ? 
                          <AddOnActivity rouetData={navData} 
                            navigation={(natTo:any,data:any)=>{
                            setNavData(data);
                            setScreen(natTo);
                          }}/>
                        :  initScreen=="setting" ? 
                          <ProfileActivity rouetData={navData} 
                            navigation={(natTo:any,data:any)=>{
                            setNavData(data);
                            setScreen(natTo);
                          }}/>
                        : initScreen=="support" ? 
                          <SupportActivity rouetData={navData} 
                            navigation={(natTo:any,data:any)=>{
                            setNavData(data);
                            setScreen(natTo);
                          }}/>
                      : null
                        
                      }
                  </View>
              </View>
            }
        </Provider>
      </SafeAreaProvider>
    </View>
  );
}