import React,{FC,useState,useEffect} from "react";
import {View,Text, TouchableOpacity} from "react-native";
import Style from "./style";
import { useSelector, useDispatch } from 'react-redux';
interface Props{
    label:string;
    type:string;
    errorMsg:any;
    formRawData:any;
    successMsg:any;
}
import { resendOTP } from '../../Server_Repo/action';
const CustomLabel:FC<Props>=(props)=>{
   
  const [resendTop,setResendOtp] = useState<Boolean>(false);
  const dispatch:any = useDispatch();

  useEffect(()=>{
    resetSendOtp();
   },[resendTop])
   
   const resetSendOtp=()=>{
    setTimeout(() => {
        setResendOtp(true);
    }, 1000);
   }
    if(props.type=="resendOtp")
        resetSendOtp();

    const sendOtp=()=>{
        setResendOtp(false);
        dispatch(resendOTP(
            {
              userMail:props.formRawData.options[0],
              otpType:props.formRawData.value
            }
        ));
    }

    return(
        <View style={Style.container}>
            {
                ( props.type=="formTitle" ? <Text style={Style.formTitle}>{props.label}</Text>
                : props.type=="subFormTitle" ? <Text style={Style.subFormTitle}>{props.label}</Text>  
                : props.type=="formSuccessMsg"&&props.successMsg!=null ? <Text style={[Style.successText]}>{props.successMsg}</Text>
                : props.type=="formErrorMsg"&&props.errorMsg!=null ? <Text style={[Style.errorText]}>{props.errorMsg}</Text>
                : props.type=="resendOtp"&&resendTop==true? <Text style={[Style.resendOtp]} onPress={()=>{sendOtp()}}>Resend OTP</Text>
                : props.type=="elementLabel"? <Text style={[Style.elementLabel]}>{props.label}</Text>
                : null )
            }
        </View>
    )
}

export default CustomLabel;