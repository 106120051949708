import React,{FC, useEffect, useState} from "react";
import {View,Text} from "react-native";
import {Style} from './style';
import {ComponentSwitch} from "../../constants/ComponentSwitch";
import FormMaker from "../FormMaker";
import signupFormQuery from "../../constants/FormQueries/signupForm";
import { useDispatch } from 'react-redux';
import { setUserMail, userSignup, clearRepo } from '../../Server_Repo/action';
interface Props{
    formData:any;
    switchFormType:(type:String,formData:any)=>void;
}
const signupForm:FC<Props>=(props)=>{

    const dispatch:any = useDispatch();
    const [screenType,setScreenType]=useState(Style);
    const [formQuery,setFormQuery]=useState(signupFormQuery);
    const [formData,setFormData]=useState<any>(props.formData);
    const [formError,setFormError]=useState<any>(null);
   
    signupFormQuery.forEach((element:any,index:number) => {
        console.log(formData);
        if(formData&&formData[element.id]!=undefined){
            element.value=formData[element.id];
            signupFormQuery[index]= element;
        }else{
            if(element.elementType=="inputText")
            element.value='';
        }
    });

    const submitForm=(playload:any)=>{
        setFormData(playload);
        dispatch(setUserMail(playload));
        dispatch(userSignup(playload));
    }

    const successCallBack=(reponse:any)=>{
        dispatch(clearRepo());
        if(reponse.route=="signUp")
            props.switchFormType(ComponentSwitch[1],{...formData,otpType:0})
    }

      return (
        <View style={screenType.formWidth}>
            <FormMaker formElementData={formQuery} 
            formID="signUp"
            erroMsg={formError} formData={formData} 
            successCallBack={(formData)=>successCallBack(formData)}
            submitForm={(formData)=>submitForm(formData)}/>
            <View style={Style.formFooter}>
                <Text style={Style.loginText} 
                    onPress={()=>{
                        dispatch(clearRepo());
                        props.switchFormType(ComponentSwitch[2],null);
                }}>Login</Text>
                <Text style={Style.forgetPin}
                    onPress={()=>{
                        dispatch(clearRepo());
                        props.switchFormType(ComponentSwitch[3],null);        
                }}>Forgeting password</Text>
            </View>
        </View>
     )
}

export default signupForm;