import React, { FC } from "react";
import {ImageBackground, View, Text} from "react-native";
import Style from "./style";
import CustomTextInput from "../../components/CustomTextInput";
import Ionicons from '@expo/vector-icons/Ionicons';
import CustomButton from "../../components/CustomButton";
interface Props{
    rouetData:any;
    navigation:(dataSets:any,natTo:any)=>void;
}
const image = {uri: ''};

const AddOnActivity:FC<Props>=()=>
{
    return (
        <View style={Style.mainContainer}>
            <View  style={Style.container}>
                <View style={Style.rowWise}>
                    <CustomTextInput type="text" id="orderName"
                                value={""}
                    placeHolder="Search your suitable addon" onInputChange={(ID,val)=>{(val)}} />
                     <Ionicons name="search"  style={Style.searchIcon} 
                        onPress={()=>{}}/> 
                </View>
                <View style={{justifyContent:'center'}}>
                    <View style={Style.rowWise}>
                        <View  style={[Style.breadCrads,Style.totalOrderHolder]}>
                        
                                {/* <ImageBackground source={image} resizeMode="cover" style={[Style.breadCrads,Style.totalActiveCard]}> */}
                                    <View>
                                        <Text style={Style.subCardTitle}>Download Report</Text></View>
                                        <Text style={Style.subCardVal}>10 $ / month</Text>
                                    <View>
                                    <View style={Style.soonLable}>
                                        <Text>Opening soon for users</Text>
                                    </View>
                                </View>
                                {/* </ImageBackground> */}
            
                        </View>
                        <View style={[Style.breadCrads,Style.completedOrderHolder]}>
                            <View><Text style={Style.subCardTitle}>Location Monitor</Text></View>
                            <Text style={Style.subCardVal}>20 $ / month</Text>
                            <View style={Style.soonLable}>
                                <Text>Opening soon for users</Text>
                            </View>
                        </View>
                        <View style={[Style.breadCrads,Style.inprogressOrderHolder]}>
                            <View><Text style={Style.subCardTitle}>Machine Health Monitor</Text></View>
                            <Text style={Style.subCardVal}>250 $ / month</Text>
                            <View style={Style.soonLable}>
                                <Text>Opening soon for users</Text>
                            </View>
                        </View>
                        <View style={[Style.breadCrads,Style.inprogressOrderHolder]}>
                            <View><Text style={Style.subCardTitle}>Request Letter Box</Text></View>
                            <Text style={Style.subCardVal}>10 $ / month</Text>
                            <View style={Style.soonLable}>
                                <Text>Opening soon for users</Text>
                            </View>
                        </View>
                    </View>
                    <View style={Style.rowWise}>
                        <View style={[Style.breadCrads,Style.inprogressOrderHolder]}>
                            <View><Text style={Style.subCardTitle}>Individual notes</Text></View>
                            <Text style={Style.subCardVal}>5 $ / month</Text>
                            <View style={Style.soonLable}>
                                <Text>Opening soon for users</Text>
                            </View>
                        </View>
                        <View style={[Style.breadCrads,Style.inprogressOrderHolder]}>
                            <View><Text style={Style.subCardTitle}>Notifiation Remainder</Text></View>
                            <Text style={Style.subCardVal}>5 $ / month</Text>
                            <View style={Style.soonLable}>
                                <Text>Opening soon for users</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View> 
        </View>
    )
}

export default AddOnActivity;