import { StyleSheet } from "react-native";

const style = StyleSheet.create({
    container: {
      flex:1,
      alignSelf:"flex-start"
    },
    checkboxContainer: {
      flexDirection: "row",
      borderColor:'#119009',
      borderStyle:"solid",
      borderWidth:2,
      height:20,
      width:20,
      justifyContent:'center',
      borderRadius:5,
    },
    checkbox: {
      alignSelf: "flex-start",
      height:15,
      width:15
    },
    label: {
      alignSelf:'center'
    },
    checkImage:{
      height:20,
      width:20,
      alignSelf:'center'
    }
});

export default style;