const planBillFormQuery:any=[
    {
       id:"billFormLabel",
       type:"formTitle",
       elementType:"text",
       mandatory:true,
       value:"Billing Address",
       validation:false,
       readonly:false,
       options:[]
    },
    {
       id:"billSubLabel",
       type:"subFormTitle",
       mandatory:true,
       elementType:"text",
       value:"Enter your billing address to complete purchase",
       validation:false,
       readonly:false,
       options:[]
    },
   {
       id:"userName",
       type:"text",
       mandatory:true,
       elementType:"inputText",
       placeHolder:"Name",
       value:"",
       defaultVallue:"",
       validation:true,
       readonly:false,
       errorMsg:"User name atleast 4 character",
       options:[]
   },
   {
       id:"userAddress",
       type:"text",
       mandatory:true,
       elementType:"inputText",
       placeHolder:"Address",
       value:"",
       defaultVallue:"",
       validation:true,
       readonly:false,
       errorMsg:"Address must be at least 50 character",
       options:[]
   },
   {
       id:"userPincode",
       type:"number",
       mandatory:true,
       elementType:"inputText",
       placeHolder:"Pincode",
       value:"",
       defaultVallue:"",
       validation:true,
       readonly:false,
       errorMsg:"Invalid mail",
       options:[]
   },
   {
       id:"userCity",
       type:"text",
       mandatory:true,
       elementType:"inputText",
       placeHolder:"City",
       value:"",
       defaultVallue:"",
       validation:true,
       readonly:false,
       errorMsg:"City name must be atleast 3 character",
       options:[]
   },
   {
    id:"userCountry",
    type:"text",
    mandatory:true,
    elementType:"inputText",
    placeHolder:"Country",
    value:"",
    defaultVallue:"",
    validation:true,
    readonly:false,
    errorMsg:"Country name must be atleast 3 character",
    options:[]
    },
   {
       id:"errorMsg",
       type:"formErrorMsg",
       mandatory:true,
       value:"",
       validation:false,
       readonly:false,
       errorMsg:"",
       options:[]
    },
   {
       id:"submitBtn",
       type:"submitBtn",
       title:"Complete purchase",
       mandatory:true,
       placeHolder:"Complete purchase",
       value:"",
       validation:true,
       readonly:false,
       errorMsg:"",
       options:[]
   }
]

export default planBillFormQuery;