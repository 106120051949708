import React, { FC, useEffect, useState } from "react";
import {View,Text, Image, TouchableOpacity} from "react-native";
import Style from "./Style";
import sideMenuQuery from "../../constants/FormQueries/sidemenu"; 
import { getUserDetails } from "../../utility";

interface Props{
    currentRoute:string;
    navigateTo:(route:any)=>void;
}
const SideMenuBar:FC<Props>=(props)=>{

    const commonScreen = ["support","setting"]
    const [localUserDetails,setUserDetails] = useState<any>({});
   
    useEffect(()=>{
        getUserDetails().then((userDetails:any)=>{
            console.log(
                "SideMenuBar",
                userDetails.userDetails
            )
            setUserDetails(JSON.parse(userDetails.userDetails))
        })
    },[]);
    
    const checkUser=(element:any)=>{
        if(element.id=="fileHandler")
            return -1;

        if(localUserDetails?.userRole=="Admin"){
            return 1;
        }else{
            if(localUserDetails?.userRole == "staff"){
                if(commonScreen.indexOf(element.id)==-1){
                    return localUserDetails?.userAccess.findIndex((items:any)=>{
                        return items.id == element.id
                    });
                }else{
                    return 1
                }
            }else{
                return 1
            }
        }
    }

    return(
        <View style={Style.container}>
        {
            sideMenuQuery.map((element:any,index:any )=> {
                return <View key={index+"key-sidemenu"}>
                {
                    checkUser(element) != -1 ?
                        <TouchableOpacity onPress={()=>{props.navigateTo(element.route)}}>
                            <View style={[
                                    Style.contentHolder,
                                    props.currentRoute==element.route? Style.activeSideMenu : null]}>
                                <Image style={Style.sideMenuIcon} source={
                                    require("../../assets/images/"+element.icon)}/> 
                                <Text style={[
                                    Style.sidemenuTitle,
                                    props.currentRoute==element.route? Style.actveSidemenuTitle:null]}>
                                        {element.title}
                                </Text>
                            </View>
                        </TouchableOpacity>
                    : null
                }
                </View>
            })
        }
        </View>
    )

}

export default SideMenuBar;