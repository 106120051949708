import { StyleSheet } from "react-native";

const Style=StyleSheet.create({
    container:{
      
    },
    calendarView:{
        marginLeft:9.5,
        marginTop:55,
        height:250,
        backgroundColor:'white',
        position:'absolute',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 10,
        elevation: 3,
        alignSelf:'center'
    },
    calendarHeader:{
        height:40,
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'space-evenly'
    },
    headerRow:{
        width:45,
        height:10,
        justifyContent:'center',
        alignItems:'center'
    },
    calendarBody:{
        flex:1,
    },
    dateRow:{
        flex:1,
        flexDirection:'row',
        flexGrow:1,
        flexShrink:1
    },
    weekNameHolder:{
        height:30,
        width:45,
        justifyContent:'center',
        alignItems:'center',
    },
    monthYearViewHolder:{
        justifyContent:'center',
        alignItems:'center',
    },
    monthYearHolder:{
        height:40,
        width:60,
        justifyContent:'center',
        alignItems:'center',
    }
});

export default Style;