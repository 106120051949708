import { StyleSheet } from "react-native";
import Colors from "../../constants/Colors"; 

export const Style=StyleSheet.create({
    container:{
        flex:2,
        flexDirection:'row',
        justifyContent:'center',
        backgroundColor:  'white'
    },
    columLeft:{
        flex:4,
        justifyContent:'center',
        backgroundColor:"#228FF3",
    },
    columRight:{
        flex:5,
        maxHeight:450,
        justifyContent:'center',
        alignSelf:'center',
    },
    formWidth:{
        maxWidth:400,
        alignSelf:'center'
    },
    splashLogo:{
        height:100,
        width:100,
        margin:20,
        alignSelf:'center',
       
    },
    sigupLabel:{
        fontSize:30,
        margin:10,
        alignSelf:'baseline',
        fontWeight:'600',
    },
    sigupSubLabel:{
        fontSize:15,
        margin:10,
        alignSelf:'baseline',
        fontWeight:'600',
    },
    formFooter:{
        maxWidth:320,
        justifyContent:'space-between',
        flexDirection:'row',
        margin:10,
    },
    errorText:{
        display:'none',
        color:'red',
        marginTop:15,
        margin:10,
        alignSelf:'flex-start',
    },
    loginText:{
        alignSelf:'flex-start',
     
        color:Colors.light.progressBarColor
    },
    forgetPin:{
        alignSelf:'flex-end',
        color:Colors.light.progressBarColor
    },
    backIcon:{
        color:'black',
        fontSize:40
    },
});

export const tabletView=StyleSheet.create({
    container:{
        flex:2,
        flexDirection:'row',
        justifyContent:'center',
        backgroundColor:  'white'
    },
    columLeft:{
        display:'none',
        flex:0,
        justifyContent:'center',
        backgroundColor:"#228FF3",
    },
});

export const mobileView=StyleSheet.create({
    container:{
        flex:1,
        padding:10,
        flexDirection:'row',
        justifyContent:'center',
        backgroundColor:  'white'
    },
    columLeft:{
        display:'none',
        flex:0,
        justifyContent:'center',
        backgroundColor:"#228FF3",
    },
});

