import React,{FC, useEffect, useState} from "react";
import { View, Text, Picker, Dimensions, TouchableOpacity } from "react-native";
import Style from "./style";
import DatePicker from '../DatePicker';
import CustomButton from "../CustomButton";
import OrderProductionReport from "../OrderProductionReport";
import OrderStockReport from "../OrderStockReport";
import { useSelector, useDispatch } from 'react-redux';
import {
    LineChart,
  } from 'react-native-chart-kit';

interface Props{
    orderID:any;
    buyerID:any;
    isLargeScreen:any
}
import { getOverAllReport  } from '../../Server_Repo/action';
import OrderDefectReport from "../OrderDefectReport";
import OrderAgendaReport from "../OrderAgendaReport";
import OrderCalendarReport from "../OrderCalendarReport";
import OrderUserReport from "../OrderUserReport";
import OrderFileReport from "../OrderFileReport";
import OrderAdvanceReport from "../OrderAdvanceReport";
const OredrReport:FC<Props>=(props)=>{

    // possibility [inti,actualReport,stockReport]
    const [reportView,setReportView] = useState<any>("init");
    const [overAllReport,setOverAllReport] = useState<any>();
    const dispatch:any = useDispatch();
    const {
        getOverAllReportReponse
    } = useSelector((state:any) => state.userReducer);

    useEffect(()=>{
        dispatch( 
            getOverAllReport({
            orderID: props.orderID,
        }))
    },[])

    useEffect(()=>{
        console.log(
            "getOverAllReportReponse",
            getOverAllReportReponse
        )
        if(getOverAllReportReponse?.status==1){
            setOverAllReport(getOverAllReportReponse.data);
        }
    },[getOverAllReportReponse!=undefined])

    return(
        <View style={Style.container}>
            {
                reportView == "init" ?
                    <View>
                        <View style={Style.subTitleHeaderView}>
                            <View style={Style.headerLeft}>
                                <Text style={Style.headerTitle}>{"Order Overall Report"}</Text>
                            </View>
                            <View style={Style.headerRight}>
                                <Text style={Style.downloadBtn}></Text>
                            </View>
                        </View>
                        {
                            overAllReport ?
                                <View style={Style.mainContent}>
                            <View style={Style.statusContainer}>
                                <View style={Style.categoryInfo}>
                                    <View>
                                        <Text style={Style.subTitle}>Order ID</Text>
                                        <Text style={Style.subValues}>{overAllReport.orderID}</Text>
                                        
                                        <Text  style={Style.subTitle}>Order Name</Text>
                                        <Text style={Style.subValues}>{overAllReport.orderName}</Text>
                               

                                        <Text  style={Style.subTitle}>Total Production Qty</Text>
                                        <Text style={Style.subValues}>{overAllReport.proQty}</Text>
                                       
                                        
                                        <Text style={Style.subTitle}>No of category</Text>
                                        <Text style={Style.subValues}>{overAllReport.noOfCategory}</Text>

                                       
                                        <Text style={Style.subTitle}>No of Delivery</Text>
                                        <Text style={Style.subValues}>{overAllReport.noOfDelivery}</Text>

                                        <Text style={Style.subTitle}>Production Planned</Text>
                                        <View>
                                            <Text style={Style.subValues}>{overAllReport.proStartDate}</Text>
                                            <Text style={Style.subValues}>{overAllReport.proEndDate}</Text>
                                        </View>

                                        <Text style={Style.subTitle}>Order Status</Text>
                                        <Text style={Style.subValues}>In Progress</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={Style.statusContainer}>
                                <View>
                                    <TouchableOpacity onPress={()=>{setReportView("actualReport")}}>
                                        <View style={Style.holderEvent}>
                                            <Text style={Style.subValues}>Plan expectation</Text>
                                            <Text>
                                                Expectation : 90
                                            </Text>
                                            <Text>
                                                 {"Actual : "+ overAllReport?.orderInputedCategory[0].categoryID}
                                            </Text>
                                        </View>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={()=>{setReportView("stockReport")}}>
                                        <View style={Style.holderEvent}>
                                            <Text style={Style.subValues}>Stocks Status</Text>
                                            <Text>
                                               { "Out of stock: " +overAllReport?.stockStatus?.outOfStock}
                                            </Text>
                                            <Text>
                                                {"Stock alert :"+ overAllReport?.stockStatus?.stockLimit}
                                            </Text>
                                            <Text>
                                               { "Stock in :" + overAllReport?.stockStatus?.activeStock}
                                            </Text>
                                        </View>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={()=>{setReportView("defectReport")}}>
                                        <View style={Style.holderEvent}>
                                            <Text style={Style.subValues}>Production Defect</Text>
                                            <Text>
                                               { "High : "+overAllReport?.defectStatus[0]?.high}
                                            </Text>
                                            <Text>
                                                { "Medium : "+overAllReport?.defectStatus[0]?.medium}
                                            </Text>
                                            <Text>
                                                { "Low : "+overAllReport?.defectStatus[0]?.low}
                                            </Text>
                                            <Text>
                                                { "Info : "+overAllReport?.defectStatus[0]?.info}
                                            </Text>
                                        </View>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={()=>{setReportView("agendaReport")}}>
                                        <View style={Style.holderEvent}>
                                            <Text style={Style.subValues}>Agenda</Text>
                                            <Text>
                                                {"Total : "+overAllReport?.eveAgenda[0]?.total}
                                            </Text>
                                            <Text>
                                                {"Today : "+overAllReport?.eveAgenda[0]?.today}
                                            </Text>
                                            <Text>
                                                {"Completed : "+overAllReport?.eveAgenda[0]?.completed}
                                            </Text>
                                            <Text>
                                                {"Incompleted: "+overAllReport?.eveAgenda[0]?.delay}
                                            </Text>
                                        </View>
                                    </TouchableOpacity>
                                </View>
                                <View>
                                    <TouchableOpacity onPress={()=>{setReportView("calendarReport")}}>
                                        <View style={Style.holderEvent}>
                                            <Text style={Style.subValues}>Calendar Events</Text>
                                            <Text>
                                                {"Upcoming : "+overAllReport?.calEve[0]?.upcomming}
                                            </Text>
                                            <Text>
                                                {"Completed : "+overAllReport?.calEve[0]?.completed}
                                            </Text>
                                          
                                        </View>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={()=>{setReportView("orderUserReport")}}>
                                        <View style={Style.holderEvent}>
                                            <Text style={Style.subValues}>Staff</Text>
                                            <Text>
                                               {"Total : "+overAllReport?.userStatus[0]?.total}
                                            </Text>
                                            <Text>
                                                {"Active : "+overAllReport?.userStatus[0]?.active}
                                            </Text>
                                            <Text>
                                                {"Inactive : "+overAllReport?.userStatus[0]?.inactive}
                                            </Text>
                                        </View>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={()=>{setReportView("orderFileReport")}}>
                                        <View style={[Style.holderEvent,{borderLeftColor:'#43993f'}]}>
                                            <Text style={Style.subValues}>Files</Text>
                                            <Text>
                                               
                                            </Text>
                                        </View>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={()=>{setReportView('orderAdvanceReport')}}>
                                        <View style={[Style.holderEvent,{borderLeftColor:'#43993f'}]}>
                                            <Text style={Style.subValues}>Advance Filter</Text>
                                            <Text>
                                               
                                            </Text>
                                        </View>
                                    </TouchableOpacity>
                                </View>
                            </View>
                                </View>
                            :null
                        }
                        
                    </View>
                :   reportView == "actualReport" ?
                    <OrderProductionReport goBack={()=>{setReportView("init")}} orderID={props.orderID} isLargeScreen={props.isLargeScreen}/>
                :   reportView == "stockReport" ?
                    <OrderStockReport goBack={()=>{setReportView("init")}}  orderID={props.orderID} isLargeScreen={props.isLargeScreen}/>
                :    reportView == "defectReport" ?
                    <OrderDefectReport goBack={()=>{setReportView("init")}} orderID={props.orderID} isLargeScreen={props.isLargeScreen}/>
                :    reportView == "agendaReport" ?
                    <OrderAgendaReport goBack={()=>{setReportView("init")}}/>
                :   reportView == "calendarReport" ?
                    <OrderCalendarReport goBack={()=>{setReportView("init")}}/>
                :    reportView == "orderUserReport" ?
                    <OrderUserReport goBack={()=>{setReportView("init")}}/>
                :    reportView == "orderFileReport" ?
                    <OrderFileReport goBack={()=>{setReportView("init")}}/>
                :    reportView == "orderAdvanceReport" ?
                     <OrderAdvanceReport goBack={()=>{setReportView("init")}}/>
                :   null

            }
        </View>
    )
}

export default OredrReport;