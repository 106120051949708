import { StyleSheet, Dimensions } from "react-native";
import * as Colors from '../../constants/Colors';
const Style=StyleSheet.create({
    container:{
        flex:1,
        backgroundColor:'white',
        shadowColor: '#3199de',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.5,
        shadowRadius: 2,
        elevation: 1,
    },
    mainContect:{
      flex:1,
      flexDirection:'row',
    },
    overAllContent:{
        flexGrow:1,
        margin:10,
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.2,
        shadowRadius: 2,
    },
    content:{
        flex:8,
        flexGrow:1,
        flexDirection:'row',
    },
    componentHolder:{
        flex:1,
        display:'flex',
        padding:20,
        overflow:'scroll',
        maxHeight:800
    },
    flexDirectionRow:{
        flexDirection:'row',
        overflow:'scroll'
    },
    flexDirectionColumn:{
        flexDirection:'column',
        maxHeight:120,
    },
    backIcon:{
        color:'black',
        fontSize:40
    }
});

export default Style;