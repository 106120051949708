import React,{FC, useEffect, useState} from "react";
import {View, Text} from "react-native";
import {Style} from './style';
import { saveOrderBasicInfo,fileAccess,  clearRepo } from '../../Server_Repo/action';
import CustomTextInput from "../CustomTextInput";
import CustomLabel from "../CustomLabel";
import DatePicker from '../DatePicker';
import CustomButton from "../../components/CustomButton";
import FileHandler from "../FilesHandler";
import { useSelector, useDispatch } from 'react-redux';
import CustomAlert from "../CustomAlert";
import SubTitleHeader from "../SubTitleHeader";
import {getAccessToken} from "../../utility/index"

interface Props{
  isLargeScreen:number;
  orderID:any;
  data:any;
  successCallBack:(data:any)=>void;
}
const OrderBasicInfo:FC<Props>=(props)=>{

    console.log("OrderBasicInfo",props)
    const dispatch:any = useDispatch();
    const [screenType,setScreenType]=useState(Style);
    const [formError,setFormError]=useState<any>(null);
    const [orderBasicInformation,setOrderBasicInfo]=useState(props?.data);
    const [alertTitle,setAlertTitle]= useState<any>();
    const [alertSubTitle,setAlertSubTitle]= useState<any>();
    const [formStatus,setFormStatus]= useState<any>(0);
    const [fileAccessDetails,setFileAccessDetails] = useState<any>();
    const [reloadRender,setReloadRender]= useState<any>(props.orderID ? false : true); 

    const {
      orderBasicInfo,
      getFileAccessListResponse
    } = useSelector((state:any) => state.userReducer);

   useEffect(()=>{
    if(orderBasicInfo){
      console.log("orderBasicInfo",orderBasicInfo);
      if(orderBasicInfo.status==0){
        setFormError(orderBasicInfo.error);
      }else{
        if(orderBasicInfo.route=="createOrder"){
          setAlertTitle("SUCCESS");
          setAlertSubTitle("Order created successfully");
          props.successCallBack(
            {
              ...orderBasicInfo,
              ...{
                operation:"orderBasic"
              }
          });
        }
        setFormStatus(1)
      }
    }
   
   },[orderBasicInfo]);

   useEffect(()=>{
      setReloadRender(false);
      if(props.data&&props.data!=null&&props.data!=undefined){
        setOrderBasicInfo(props.data)
        getFileAccessDetails(props.data?.fileList);
        setReloadRender(true);
      }
   },[props.data]);

   useEffect(()=>{
       console.log("getFileAccessListResponse",getFileAccessListResponse);
      if(getFileAccessListResponse?.status==1){
        let fileAccess:any = [];
        getFileAccessListResponse.fileIds.forEach((data:any)=>{
          fileAccess.push(
            {
              id:data,
              fileAccess:getFileAccessListResponse?.data?.filter((access:any)=>{
                return access.fileID==data
              })
            }
          )
        });
        setFileAccessDetails([...fileAccess])
        console.log(
          "getFileAccessListResponse",
          fileAccess
        )
      }
   },[getFileAccessListResponse!=undefined]);

    const getFileAccessDetails=(fileList:any)=>{
        let fileId:any=[];
        fileList.forEach((element:any) => {
          fileId.push(element._id);
        });
        console.log("getFileAccessDetails",fileId);
        dispatch(
          fileAccess(
            {
              fileIds:fileId,
              id:"orderBasicInfo"
            }
          )
        )
    }

    const submitBasicInfo=()=>{
      setFormError("");
      var formData:any = orderBasicInformation;
      formData = {
        ...props.data,
        ...formData,
      }

      console.log("submitBasicInfo",formData );

      if(formData.orderName&&
          formData.orderDescription&&
            formData.orderID
            &&formData.noOfCategory 
            &&formData.proStartDate
            &&formData.proEndDate){
            if(formData.proStartDate<formData.proEndDate&&
              formData.proEndDate>formData.proStartDate)
            {
              if(formData.proQty>0)
              {
                if(formData.noOfDelivery>=0)
                {
                  if(formData.proCost>=0)
                  {
                    if(formData.expPerDay>=0)
                    {
                      if(formData.expPerDay<=formData.proQty){
                        if(formData.noOfCategory<formData.proQty){
                          dispatch(saveOrderBasicInfo(formData));
                        }else{
                          setFormError("No of category is less than production quanity")
                        }
                      }else{
                        setFormError("Expectation per day is more than your total production quanity")
                      }
                    }else{
                      setFormError("Invalid expectation per day")
                    }
                  }else{
                    setFormError("Invalid production cost")
                  }
                }else{
                  setFormError("Invalid delivery")
                }
              }else{
                setFormError("Invalid production quantity")
              }
            }else{
              setFormError("Invalid production date")
            }
      }else{
        setFormError("All fields are mandatory")
      }
    }

    const alertClickEvent=()=>{
      setFormStatus(0);
      if(formStatus==1){
        props.successCallBack(
          {
            ...orderBasicInfo,
            ...{
              operation:"orderBasic"
            }
          });
      }
    }

    const onInputChange = (fieldName:any,value:any)=>{
      let basicInfo:any = orderBasicInformation ? orderBasicInformation : {};
      basicInfo[fieldName] = value ;
      setOrderBasicInfo({...basicInfo});
    }

    return (
      <View style={Style.mainContent}>
        {
          formStatus==1 ?
              <View>
                <CustomAlert title={alertTitle} subTitle={alertSubTitle} onPress={()=>alertClickEvent()} />
              </View>
          :   
            reloadRender||props.orderID==undefined ? <View>
              <View style={screenType.container}>
                <View style={[props.isLargeScreen>=730? Style.contentHolder : Style.mobileContentHolder ]}>
                  <View style={Style.divView}>
                  <SubTitleHeader title={"Order details"}/>
                  <CustomTextInput type="text" id="orderName"
                    value={orderBasicInformation?.orderName}
                    placeHolder="Order name" onInputChange={(ID,val)=>{onInputChange(ID,val)}} />
                  <CustomTextInput type="text" id="orderID" 
                    value={orderBasicInformation?.orderID}
                    placeHolder="Order ID" onInputChange={(ID,val)=>{onInputChange(ID,val)}} />
                  <CustomTextInput type="text" id="orderDescription" 
                    value={orderBasicInformation?.orderDescription}
                    placeHolder="Order Description" onInputChange={(ID,val)=>{onInputChange(ID,val)}} />
                  </View>
                  <View style={Style.divView}>
                    <SubTitleHeader title={"Production details"}/>
                    <CustomTextInput type="number" id="noOfCategory" 
                      value={orderBasicInformation?.noOfCategory}
                      placeHolder="No of category" onInputChange={(ID,val)=>{onInputChange(ID,Number(val))}} />
                    <CustomTextInput type="number" id="proQty" 
                      value={orderBasicInformation?.proQty}
                      placeHolder="Total production qty" onInputChange={(ID,val)=>{onInputChange(ID,Number(val))}} />
                    <CustomTextInput type="number" id="expPerDay"
                      value={orderBasicInformation?.expPerDay}
                      placeHolder="Expectation per day" onInputChange={(ID,val)=>{onInputChange(ID,Number(val))}} />
                  </View>
                  <View style={Style.divView}>
                    <SubTitleHeader title={"Production plan details"}/>
                    <CustomTextInput type="number" id="proCost" 
                      value={orderBasicInformation?.proCost}
                      placeHolder="Production cost (optional)" onInputChange={(ID,val)=>{onInputChange(ID,val)}} />
                    <CustomTextInput type="number" id="noOfDelivery" 
                     value={orderBasicInformation?.noOfCategory}
                      placeHolder="No of delivery" onInputChange={(ID,val)=>{onInputChange(ID,val)}} />
                    <DatePicker label={"Production Start Date"} placeholder={"Production Start Date"}
                      date={orderBasicInformation?.proStartDate}
                      layerIndex={3} onDateSelected={(date)=>{onInputChange("proStartDate",date)}}/>
                    <DatePicker label={"Production End Date"} placeholder={"Production End Date"}
                     date={ orderBasicInformation?.proEndDate}
                     layerIndex={2} onDateSelected={(date)=>{onInputChange("proEndDate",date)}}/>
                  </View>
                </View>
                <View style={[Style.divView,{zIndex:-1}]}>
                  <SubTitleHeader title={"Files"}/>
                  <View style={{flex:1}}>
                    <FileHandler isLargeScreen={props.isLargeScreen} 
                      documentList={
                        orderBasicInformation?.fileList
                      }
                      fileUploadAccess={true}
                      orderID={props.orderID}
                      fileAccess={fileAccessDetails}
                      id="orderBasicInfo"
                      updateUserAccess = {(fileAccess)=>{onInputChange("fileAccess",fileAccess.value)}}
                      deletedFileList = {(fileAccess)=>{onInputChange("fileDeleted",fileAccess.value)}}
                      updatedFileList={(updatedList)=>{onInputChange("fileList",updatedList.value)}}/>
                  </View>
                </View>
              </View>
              <View style={Style.footer}>
                  <View style={{justifyContent:'center',flex:1}}>
                    <CustomLabel type="formErrorMsg" label={""} errorMsg={formError}
                    formRawData={null} successMsg={null}/>
                  </View>
                  <CustomButton buttonID={"Submit"} type="common"
                  title="Save" onSubmit={()=>{submitBasicInfo()}}/>
              </View>
            </View> :null
        }
      </View>
    )
}

export default OrderBasicInfo;