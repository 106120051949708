import React,{FC, useState} from "react";
import {View,Text} from "react-native";
import CustomButton from "../../components/CustomButton";
import {Style} from './style';
interface Props{
    formData:any;
    selectedPlan:(plan:Number)=>void;
}
const PlanForm:FC<Props>=(props)=>{
    console.log("SWDW",props.formData);
    const [screenType,setScreenType]=useState(Style);

      return (
        <View style={screenType.formWidth}>

            {
                props.formData.status=="selectPlan" ?
                   <View style={screenType.planForm}>
                        <Text style={screenType.sigupLabel}>3 months trail</Text>
                        <View style={screenType.planDetailsHolder}>
                            <Text>Full Access / 24X7 support</Text>
                        </View>
                        <CustomButton title={"Trail"} buttonID={"planBtnTrail"} type={""}
                        onSubmit={()=>{props.selectedPlan(0)}}/>
                    </View>
                :  <View style={screenType.planForm}>
                    <Text style={screenType.sigupLabel}>3 months</Text>
                    <View style={screenType.planDetailsHolder}>
                        <Text>Full Access / 24X7 support</Text>
                        <Text>Get extra 15 days free</Text>
                    </View>
                    <CustomButton title={"60,000"} buttonID={"planBtnOne"} type={""}
                    onSubmit={()=>{props.selectedPlan(1)}}/>
                </View>
            }
            
            <View style={screenType.planForm}>
                <Text style={screenType.sigupLabel}>6 months</Text>
                <View style={screenType.planDetailsHolder}>
                    <Text>Full Access / 24X7 support</Text>
                    <Text>Get extra 2 months free</Text>
                </View>                
                <CustomButton title={"80,000"} buttonID={"planBtnTwo"} type={""}
                 onSubmit={()=>{props.selectedPlan(2)}}/>
            </View>
            <View style={screenType.planForm}>
                <Text style={screenType.sigupLabel}>1 years</Text>
                <View style={screenType.planDetailsHolder}>
                    <Text>Full Access / 24X7 support</Text>
                    <Text>Get extra 4 months free</Text>
                </View>               
                <CustomButton title={"10,0000"} buttonID={"planBtnThree"} type={""}
                 onSubmit={()=>{props.selectedPlan(3)}}/>
            </View>
        </View>
     )
}

export default PlanForm;