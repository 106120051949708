import React,{FC,useState} from "react";
import {View} from "react-native"; 
import { TextInput } from 'react-native-paper';
import Style from "./style";

interface Props{
    id:string;
    placeHolder:string,
    value:any;
    type:string,
    onInputChange:(id:string,value:string)=>void;
}

const theme=   {   
     colors: { 
        primary: "#228FF3",
    },
    backgorundColor:"white"
};


const CustomTextInput:FC<Props> =(props)=>{

    const [passwordVisible,setPasswordVisible]=useState(true);
    const [val,setValue]=useState<string>(props.value);

    const onInputChange=(id:any,val:any)=>{
        setValue(val);
        props.onInputChange(id,val);
    }

    console.log("CustomTextInput",props.value)

    return (
        <View style={Style.container}>
             {props.type=="text" ?
                <TextInput
                theme={theme}
                defaultValue={val}
                focusable={true}
                style={Style.inputField}
                onChangeText={(value)=>onInputChange(props.id,value)}
                placeholder={props.placeHolder}
                label={props.placeHolder}
                mode={"outlined"} />
                : props.type=="number" ? <TextInput
                    theme={theme}
                    defaultValue={val}
                    style={Style.inputField}
                    placeholder={props.placeHolder}
                    mode={"outlined"}
                    label={props.placeHolder}
                    keyboardType={"numeric"}
                    onChangeText={(value)=>onInputChange(props.id,value.replace(/[^0-9]/g, ''))}/> 
                    : props.type=="date" ? <TextInput
                        theme={theme}
                        label={props.placeHolder}
                        value={props.value}
                        style={Style.inputField}
                        placeholder={props.placeHolder}
                        right={<TextInput.Icon style={{marginTop:15,}} name={"calendar"} />}
                        mode={"outlined"}/> 
                        :   props.type=="password" ? <TextInput
                            theme={theme}
                            label={props.placeHolder}
                            value={val}
                            style={Style.inputField}
                            placeholder={props.placeHolder}
                            secureTextEntry={passwordVisible}
                            onChangeText={(value)=>onInputChange(props.id,value)}
                            right={<TextInput.Icon onPress={()=>{setPasswordVisible(!passwordVisible)}} style={{marginTop:15,}} name={"eye"} />}
                            mode={"outlined"}/> 
                            : <TextInput
                                theme={theme}
                                style={[Style.inputField,{maxWidth:'50%'}]}
                                defaultValue={val}
                                placeholder={props.placeHolder}
                                mode={"outlined"}
                                keyboardType={"numeric"}
                                onChangeText={(value)=>onInputChange(props.id,value)}
                                />
            }
        </View>
    )
};

export default CustomTextInput;