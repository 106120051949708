import React, { FC,useState, useEffect } from "react";
import { View,Text, Dimensions, TouchableOpacity, Image, FlatList} from "react-native";
import Style from "./style";
import ToolBarTop from "../../components/ToolBarTop";
import SideMenuBar from "../../components/SideMenuBarTop";
import { RootStackScreenProps } from '../../types';
const window = Dimensions.get("window");
import { useSelector, useDispatch } from 'react-redux';
import CreateStocks from "../../components/CreateStocks";
import sideMenuQuery from "../../constants/FormQueries/sidemenu";
import  { getStockList } from '../../Server_Repo/action';
import CustomTextInput from "../../components/CustomTextInput";
interface Props{
    rouetData:any;
    routeName:any
    navigation:(dataSets:any,natTo:any)=>void;
}
const  StockActivity:FC<Props> =(props)=>
{
    const dispatch:any = useDispatch();
    const [sideMenu,setSideMenu]=useState(true);
    const [isLargeScreen,setIsLargeScreen]=useState(window.width);
    const [addStocks,setAddStocks] = useState<any>();
    const [stockList,setStockList] = useState<any>([]);
    const [internalViewType,setInternalViewType]= useState<any>("");
    const [stockSelected,setStockSelected] = useState<any>();

    const {
        stockListResponse
    } = useSelector((state:any) => state.userReducer);

    useEffect(()=>{
        dispatch(
          getStockList(
            {
              
            }
          )
        )
      },[addStocks==false]);

        useEffect(()=>{
            if(stockListResponse?.status == 1){
                setStockList(stockListResponse?.data)
            }
        },[stockListResponse!=undefined])

    useEffect(()=>{
        userAccess()
    },[]);

    Dimensions.addEventListener("change",({ window}) => {
        setIsLargeScreen(window.width);
    });
    
    useEffect(()=>{
        if(isLargeScreen>=730)
            setSideMenu(true)
        else
            setSideMenu(true)
    },[isLargeScreen])

    const navigateTo=(route:any)=>{
        props.navigation(route,{});
    }

    const switchComponent=(component:any)=>{
        setInternalViewType(component);
        if(component=="userList"){
          // getStaffListAPI();
        }
        if(component=="groupList"){
         // getGroupList();
        }
    }

    const successCallBack =()=>{
        setAddStocks(!addStocks)
    };

    const userAccess=()=>{
        let tempList:any = sideMenuQuery;
        tempList = tempList.filter((data:any)=>{
        return data.route == props.routeName
        });
        console.log("tempList",tempList[0].options);
    }

    const setStockDetails=(stockItem:any)=>{
        setAddStocks(true);
        setStockSelected(stockItem);
      }
    
    return (
        <View style={Style.container}>
            <View style={Style.mainContect}>
                <View style={{flex:1}}>
                    <View style={Style.header}>
                        <View style={Style.headerLeft}>
                        {
                            internalViewType=="userList" ?
                                <TouchableOpacity onPress={()=>{setAddStocks(!addStocks)}}>
                                <Image style={Style.headerLeftIocns}
                                source={
                                require("../../assets/images/back.png")
                                }/>
                            </TouchableOpacity>
                            : null
                        }
                        <Text style={Style.headerTitle}>{
                            addStocks==true ? "Create Stock" : "Stock List" 
                        }</Text>
                        </View>
                        <View style={Style.headerRight}>
                            <TouchableOpacity onPress={()=>{setAddStocks(!addStocks); setStockSelected(null)}}>
                            <Image style={Style.headerRightIocns}
                                source={ 
                                addStocks==true ? 
                                require("../../assets/images/close.png")
                                : require("../../assets/images/plus.png")
                            }/>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View>
                        {
                            addStocks==true ?  
                                <CreateStocks
                                    currentRoute={props.routeName}
                                    isLargeScreen={isLargeScreen}
                                    successCallBack= {()=>{ successCallBack()}}
                                    stockDetails={stockSelected}/> 
                            : <View style={{padding:10,}}>
                                <CustomTextInput type="text" id="orderName"
                                 value={""}
                                 placeHolder="Search Stocks/Racks/Qty" onInputChange={(ID,val)=>{(val)}} />
                                <View style={{padding:10,}}>
                                    <FlatList
                                    data={stockList}
                                    renderItem={({item}) => (
                                        <TouchableOpacity style={Style.itemContainer} onPress={()=>{setStockDetails(item)}}>
                                        <View style={Style.itemContainer}>
                                            <View style={Style.item}>
                                                <Text style={Style.stockQty}>{"Qty : "+item.stockQty}</Text>
                                                <Text style={Style.stockQty}>{"Rack ID  : "+item.rackID}</Text>
                                                <Text  style={Style.stockName}>{item.stockName}</Text>
                                            </View>
                                        </View>
                                        </TouchableOpacity>
                                    )}
                                    keyExtractor={item => item.id}
                                    numColumns={5} />
                                </View>
                            </View>
                        }
                    </View>
                </View>
            </View>
        </View>
    )
}

export default StockActivity;