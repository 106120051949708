import React,{FC,useState} from "react";
import {View,Text,Picker,Dimensions, TouchableOpacity, Image} from "react-native";
import Style from "./style";
import Ionicons from '@expo/vector-icons/Ionicons';
import CustomButton from "../CustomButton";
import CustomTextInput from "../CustomTextInput";
import { Button } from "react-native-paper";
import {DataTable} from "react-native-paper";
import CustomLabel from "../CustomLabel";
import CheckBoxButton from "../CheckBox";
import {
    LineChart,
    BarChart,
    PieChart,
    ProgressChart,
    ContributionGraph,
    StackedBarChart,
  } from 'react-native-chart-kit';

  interface Props{
    goBack:()=>void;
  }

  const MyLineChart = () => {
    return (
      <>
        <LineChart
          data={{
            labels: ['January', 'February', 'March', 'April', 'May', 'June',1,,2,3,4,5,6,7,],
            datasets: [
              {
                data: [20, 45, 28, 80, 99, 43,1,5,10,10],
                strokeWidth: 2,
              },
            ],
          }}
          width={Dimensions.get('window').width - 370}
          height={400}
          chartConfig={{
            backgroundColor: '#1cc910',
            backgroundGradientFrom: '#eff3ff',
            backgroundGradientTo: '#efefef',
            decimalPlaces: 2,
            color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
            style: {
              borderRadius: 16,
            },
          }}
          style={{
            marginVertical: 8,
            borderRadius: 16,
          }}
        />
      </>
    );
  };

const OrderUserReport:FC<Props>=(props)=>{

    const [userDetailsiew,setUserdetailsView] = useState<any>(false);

    return (
      <View>
          <View style={Style.container}>
              <View style={Style.containerLeft}>
              <TouchableOpacity onPress={()=>{props.goBack()}}>
                <Ionicons name="arrow-back"  style={Style.menuIcon}/>
              </TouchableOpacity>
                  <Text style={Style.activityTitle}>Order calendar</Text>
              </View>
          </View>
          <View style={Style.orderAgendaList}>
              {
                userDetailsiew == false ?
                  <View style={Style.equalSapce}>
                    <View>
                        <View>
                            <View style={Style.agendaBreadCrums}>
                                <Text style={Style.defectMarkerLabel}>User List</Text>
                            </View>
                        </View>
                        <CustomTextInput type="text" id="orderName"
                        value={""}
                        placeHolder="Order name" onInputChange={(ID,val)=>{(null)}} />
                    </View>
                    <DataTable>
                          <DataTable.Header>
                            <DataTable.Title>Task Name</DataTable.Title>
                            <DataTable.Title>Task Category</DataTable.Title>
                            <DataTable.Title>Task Incharge</DataTable.Title>
                            <DataTable.Title>Stocks</DataTable.Title>
                            <DataTable.Title>Staff involved</DataTable.Title>
                          </DataTable.Header>
                          <DataTable.Row onPress={()=>{setUserdetailsView(true)}}>
                              <DataTable.Cell>23</DataTable.Cell>
                              <DataTable.Cell>23</DataTable.Cell>
                              <DataTable.Cell>23</DataTable.Cell>
                              <DataTable.Cell>23</DataTable.Cell>
                              <DataTable.Cell>Completed</DataTable.Cell>
                          </DataTable.Row>
                    </DataTable>
                  </View>
                : null
              }
              {
                userDetailsiew == true ?
                  <View style={Style.equalSapce}>
                      <View  style={Style.filterView}>
                        <TouchableOpacity onPress={()=>{setUserdetailsView(false)}}>
                          <Image style={{height:25,width:25,marginTop:5,marginLeft:10,}}
                                source={require("../../assets/images/back.png")
                            }/>
                        </TouchableOpacity>
                        <Text style={Style.viewMainTitle}>User Details</Text>
                      </View>
                  </View>
                : null
              }
          </View>
      </View>
    )
}

export default OrderUserReport;