import React, { FC, useEffect, useState } from "react";
import {StyleSheet, View, Text, Picker, TouchableOpacity, Image } from "react-native";
import Style from "./style";
import { DataTable } from 'react-native-paper';
import CustomTextInput from "../CustomTextInput";
import CheckBoxButton from "../CheckBox";
import { useSelector, useDispatch,  } from 'react-redux';
import {getStaffList, getStaffDetailsById, getOrderUserList, clearRepo} from '../../Server_Repo/action';
import sideMenuQuery from "../../constants/FormQueries/sidemenu";
import CustomLabel from "../CustomLabel";
import DatePicker from "../DatePicker";

interface Props{
    currentRoute:any;
    subRoute:any;
    groupList:any;
    resPerson:any;
    id:any;
    title:any;
    accessID:any;
    visible:any;
    orderID:any;
    userListUpdated:(userList:any)=>void;
}
const ResponsiblePerson:FC<Props> = (props)=>{

    console.log("ResponsiblePerson",props?.resPerson)
    const dispatch:any = useDispatch();
    const [addUser,setAddUser] = useState<any>(false);
    const [editAccess,setEditAccess] = useState<any>(false);
    const [addCheckedAll,setAddCheckedAll] = useState<any>(false);
    const [userList,setUserList] = useState<any>([]);
    const [sideMenuOptions,setSideMenuOptions]= useState<any>([]);
    const [userEdtitAccess,setUserEditAccess] = useState<any>([]);
    const [selectedUsers,setSelectedUser] = useState<any>([]);
    const [staffResPersopn,setStaffResPerson] = useState<any>(props.resPerson);


    const { 
        getStaffListResponse, 
        getStaffDetailsByIdResponse,
        getOrderUserListResponse 
    }  = useSelector((state:any) => state.userReducer);

    useEffect(()=>{
        dispatch(clearRepo())
        getStaffById(staffResPersopn);
    },[staffResPersopn,props.id]);

    const getStaffById=(staffResList:any)=>{
        let staffIds:any=[];
        dispatch(clearRepo())
        staffResList?.forEach((element:any) => {
            if(element?.resPerson){
                staffIds.push(element.resPerson)
            }
        });
        
        if(staffIds.length>0){
            dispatch(
                getStaffDetailsById({
                    userList:staffIds,
                    id:props.id
                })
            )
        }
        
    }

    useEffect(()=>{
       
       
        if(getStaffDetailsByIdResponse != undefined&&
            props.id==getStaffDetailsByIdResponse.id){
                
            if(props.subRoute=="insideOrder"&&addUser==true||
                props.subRoute=="insideFileHandler"&&addUser==true){
                setUserList(
                    getStaffDetailsByIdResponse.data
                )
            }else{
               
                let composeUserList:any = [];
                let tempUserDetails:any = getStaffDetailsByIdResponse.data;
                staffResPersopn?.forEach((element:any)=>{
                    let userDetails  = tempUserDetails.filter((details:any)=>{
                        return details._id == element.resPerson
                    });
                    if(userDetails.length>0){
                        userDetails = userDetails[0];
                        composeUserList.push({
                            resPerson:element.resPerson,
                            taskAccess:element.taskAccess,
                            scheduleFrom:element?.scheduleFrom,
                            scheduleTo:element?.scheduleTo,
                            userDetails:{
                                userName: userDetails.userName,
                                userRole: userDetails.userRole,
                                userID: userDetails._id,
                                groupID: userDetails.groupID,
                            }
                        })
                    }

                });
                setSelectedUser([...composeUserList]);
            }
        }
    },[getStaffDetailsByIdResponse!=undefined])

    useEffect(()=>{
        let tempList:any = sideMenuQuery;
         tempList = tempList.filter((data:any)=>{
            return data.route == props.accessID
         });
        setSideMenuOptions(tempList[0]?.options);
    },[]);

    useEffect(()=>{
        if(getStaffListResponse?.status==1&&
            props.id==getStaffListResponse.id){
            setUserList(getStaffListResponse?.data)
    }
    },[getStaffListResponse]);

    useEffect(()=>{
    if(getOrderUserListResponse?.status==1){
        if(props.id==getOrderUserListResponse.id){
            setStaffResPerson(getOrderUserListResponse?.data)
        }
      }
    },
    [getOrderUserListResponse!=undefined]);

    const getOrderList=(groupId:any)=>{
        setUserList([]);
        if(props.subRoute!="insideOrder"&&
            props.subRoute!="insideFileHandler"){
            dispatch(
                getStaffList(
                {
                    groupID:groupId,
                    fieldID:props.currentRoute,
                    id:props.id
                }
            ))
        }else{
            dispatch(
                getOrderUserList(
                {
                    orderID: props.orderID,
                    groupID:groupId,
                    id:props.id
                    
                })
              )
        }
   }

   const updateUserList=(staffData:any,fromNew:any)=>{

    var tempSelectedUser = selectedUsers ? selectedUsers : [];
    var removeUserFromTask:any = [];
    var arrayIndex = tempSelectedUser.findIndex((item:any,index:any)=>{
       // console.log("adsasdasds",index,item)
        return item.removeResPerson
    });

    if(arrayIndex != -1){
        removeUserFromTask = tempSelectedUser[arrayIndex].removeResPerson;
    }else{
        tempSelectedUser.push(
            {
                removeResPerson:[]
            }
        );
    }

    if(staffData!=null){
        var indexID = tempSelectedUser.findIndex((users:any)=>{
            return users.resPerson == (
                fromNew == true ? staffData._id : staffData.resPerson
            )
        });
        if(indexID==-1){
            removeUserFromTask = userRemoveFromTask(
                fromNew == true ? staffData._id : staffData.resPerson,
                removeUserFromTask,
                true
            );
            tempSelectedUser.push({
                resPerson: fromNew == true ? staffData._id : staffData.resPerson,
                userDetails:{
                    userName:staffData.userName,
                    userRole:staffData.userRole,
                    userID:staffData.userID,
                    groupID:staffData.groupID
                }
            })
        }else{
            tempSelectedUser.splice(indexID, 1);
            removeUserFromTask = userRemoveFromTask(
                fromNew == true ? staffData._id : staffData.resPerson,
                removeUserFromTask,
                false
            );
        }
    }else{
        if(addCheckedAll==true){
            userList?.forEach((element:any) => {
                tempSelectedUser =  tempSelectedUser.filter((data:any)=>{
                    if(data.resPerson != element._id){
                        removeUserFromTask = userRemoveFromTask(
                            data.resPerson,
                            removeUserFromTask,
                            false
                        );
                    }
                    return data.resPerson != element._id
                });
            });
        }else{
            userList?.forEach((element:any) => {
                var alreadyExists =  tempSelectedUser.findIndex((data:any)=>{
                    return data.resPerson == element._id
                });
                if(alreadyExists==-1){
                    removeUserFromTask = userRemoveFromTask(
                        element._id,
                        removeUserFromTask,
                        true
                    );
                    tempSelectedUser.push({
                        resPerson:element._id,
                        userDetails:{
                            userName:element.userName,
                            userRole:element.userRole,
                            userID:element.userID,
                            groupID:element.groupID
                        }
                    });
                }
            });
            setSelectedUser([...tempSelectedUser]);
        }
        setAddCheckedAll(!addCheckedAll);
    }

   
    let removeResPersonIndex = tempSelectedUser.findIndex((res:any)=>{
        return res.removeResPerson
    });
    tempSelectedUser[removeResPersonIndex].removeResPerson = removeUserFromTask;
    setSelectedUser([...tempSelectedUser]);
    props.userListUpdated([...tempSelectedUser]);
   }

   const checkStaffSelected=(userID:any)=>{
    var indexOf =  selectedUsers?.findIndex((staffDetails:any)=>{
        return staffDetails.resPerson == userID;
    });
    console.log(
        "checkStaffSelected",indexOf>=0,selectedUsers
    )
    return indexOf>=0;
   };

   const getGroupName=(groupID:any)=>{
        if(typeof props.groupList  !== "boolean" ){
            var tempList = props.groupList?.filter(
                (item:any)=>{ 
                    return (item._id == groupID ?  item.groupName : null )
            });
            if(tempList.length>0){
                return tempList[0].groupName;
            }
        }
   }

   const setUserSechduleDate=(userID:any,date:any,field:string)=>{
        let userDetails = selectedUsers ? selectedUsers : [];
        let index = userDetails.findIndex((item:any)=>{
            return item.resPerson == userID
        });
        userDetails[index][field] = date;
        setSelectedUser([...userDetails]);
        props.userListUpdated([...userDetails]);
   }

   const accessSelected = (userID:any,accessDetails:any,keyVal:any) =>{

    let userDetails = selectedUsers ? selectedUsers : [];
    let index = userDetails.findIndex((item:any)=>{
        return item.resPerson == userID
    });

    if(userDetails[index].taskAccess){
        let accessIndex =  userDetails[index]["taskAccess"].findIndex((item:any)=>{
            return item.key == accessDetails.id
        })
        console.log("accessIndex",accessIndex);
        if(keyVal==null&&accessIndex!= -1){
            console.log("qqqqq")
            userDetails[index]["taskAccess"].splice(accessIndex,1);
        }else{
            if(accessIndex != -1){
                userDetails[index]["taskAccess"][accessIndex] = {
                    key : accessDetails.id,
                    val : keyVal!=null ? keyVal : 0
                }
            }else{
                userDetails[index]["taskAccess"].push({
                    key : accessDetails.id,
                    val : keyVal!=null ? keyVal : 0
                });
            }
        }
    }else{
        userDetails[index]["taskAccess"] = [];
        userDetails[index]["taskAccess"].push( {
            key : accessDetails.id,
            val : keyVal!=null ? keyVal : 0
        })
    }
    setSelectedUser([...userDetails]);
    props.userListUpdated([...userDetails]);
    console.log("accessSelected",userDetails);
   }

   const checkAccessExists = (userID:any,accessDetails:any) =>{

        let userDetails = selectedUsers ? selectedUsers : [];
        let index = userDetails.findIndex((item:any)=>{
            return item.resPerson == userID
        });

        if(userDetails[index].taskAccess){
            let accessIndex =  userDetails[index]["taskAccess"].findIndex((item:any)=>{
                return item.key == accessDetails.id
            })
            if(accessIndex != -1){
                return userDetails[index]["taskAccess"][accessIndex]["val"];
            }else{
                return -1; 
            }
        }else{
            return -1;
        }
   }

   const userRemoveFromTask=(removeUserID:any,removalary:any,addUser:boolean)=>{

    let removalAryTemp = removalary;
    let index = props.resPerson?.findIndex((element:any)=>{
       return element.resPerson == removeUserID
    });

    if(addUser != true){
        if(index != -1){
            console.log("userRemoveFromTask","IF")
            removalAryTemp.push(removeUserID)
        }
    }else{
        if(index != -1){
            console.log("userRemoveFromTask","ELSE")
            removalAryTemp = removalAryTemp.slice(index,1)
        }
    }
   
    console.log("userRemoveFromTask",removalAryTemp)
    return removalAryTemp;
   }
   
   console.log("selectedUsers",selectedUsers)
    return(
        <View style={Style.main}>
            <View style={Style.container}>
                <View style={Style.containerLeft}>
                    { editAccess != true ?
                        <View style={{flex:1,flexDirection:'row'}}>
                        <Text>{props.title}</Text>
                        <TouchableOpacity onPress={()=>{setAddUser(!addUser)}}>
                            <Image style={{height:20,width:20,marginLeft:10,}}
                             source={require("../../assets/images/"+(addUser? "close" : "plus" )+".png")
                            }/>
                        </TouchableOpacity>
                    </View>
                    : <View style={{flex:1,flexDirection:'row'}}>
                        <TouchableOpacity onPress={()=>{setEditAccess(!editAccess)}}>
                            <Image style={{height:25,width:30,marginRight:10,marginTop:2}}
                            source={require("../../assets/images/back.png")}/>
                        </TouchableOpacity>
                        <Text style={Style.editAccessLabel}>{"Edit user access"}</Text>
                    </View>
                    }
                </View>
            </View>
            {
                editAccess == false && addUser ? 
                    <View  style={Style.divView}>
                        <View style={Style.userSelectView}>
                            <Picker style={Style.dropDown}
                                onValueChange={(groupData:any) =>{
                                    setAddCheckedAll(false);
                                    getOrderList(groupData);
                                }}>
                                <Picker.Item label="Select Group" value={0} />
                                {
                                    props.groupList?.map((groupData:any)=>{
                                        return  <Picker.Item label={groupData?.groupName} 
                                                value={groupData._id} />
                                    })
                                }
                            </Picker>
                            { 
                                userList.length>10 ?
                                    <CustomTextInput type="text" id="orderName"
                                    value={""}
                                    placeHolder="Search User Name/ID" onInputChange={(ID,val)=>{(val)}} />
                                : null
                            }
                        </View>
                        <View style={Style.userSelectView}>
                            { userList.length>0 ?
                                <DataTable>
                                    <DataTable.Header>
                                        <DataTable.Title>
                                            <CheckBoxButton 
                                                id={null}
                                                isChecked={()=>{updateUserList(null,true)}}
                                                state={addCheckedAll}/>
                                        </DataTable.Title>
                                        <DataTable.Title>User Name</DataTable.Title>
                                        <DataTable.Title>Access</DataTable.Title>
                                    </DataTable.Header>
                                    {
                                        userList?.map((userData:any)=>{
                                            return <DataTable.Row> <DataTable.Cell>
                                                    <View style={{paddingTop:15,}}>
                                                        <CheckBoxButton  id={null}
                                                            isChecked={()=>{updateUserList(userData,true)}}
                                                            state={checkStaffSelected(userData._id )}/>
                                                    </View>
                                                </DataTable.Cell>
                                                <DataTable.Cell>{userData?.userName}</DataTable.Cell>
                                                <DataTable.Cell>
                                                    
                                                </DataTable.Cell>
                                            </DataTable.Row>
                                        })
                                    }
                                </DataTable>
                                : null 
                            }
                        </View>
                    </View>
                : editAccess == false && selectedUsers && selectedUsers.length>0? 
                    <View  style={Style.divView}>
                        <View  style={Style.divView}>
                            {   selectedUsers.length>10 ?
                                    <CustomTextInput type="text" id="orderName"
                                    value={""}
                                    placeHolder="Search User name/ID" 
                                    onInputChange={(ID,val)=>{(val)}} />
                                : null
                            }
                            <View style={Style.userSelectView}>
                                <DataTable>
                                    <DataTable.Header>
                                        <DataTable.Title>
                                            <View style={{padding:1,}}>
                                                <CheckBoxButton 
                                                    id={null}
                                                    isChecked={()=>{updateUserList(null,false)}}
                                                    state={addCheckedAll}/>
                                            </View>
                                        </DataTable.Title>
                                        <DataTable.Title>User Names</DataTable.Title>
                                        <DataTable.Title>Group Name</DataTable.Title>
                                        <DataTable.Title>User ID</DataTable.Title>
                                        <DataTable.Title>User Role</DataTable.Title>
                                        {
                                            props.subRoute!="insideOrder"||
                                            props.subRoute=="insideFileHandler" ?
                                                <DataTable.Title>Access</DataTable.Title>
                                            : null

                                        }
                                    </DataTable.Header>
                                    {
                                        selectedUsers?.map((userData:any)=>{
                                            return  userData.resPerson ?<DataTable.Row> <DataTable.Cell>
                                                        <View style={{paddingTop:15,}}>
                                                            <CheckBoxButton  id={null}
                                                                isChecked={()=>{updateUserList(userData,false)}}
                                                                state={checkStaffSelected(userData.resPerson )}/>
                                                        </View>
                                                    </DataTable.Cell>
                                                    <DataTable.Cell>{userData.userDetails.userName}</DataTable.Cell>
                                                    <DataTable.Cell>{getGroupName(userData.userDetails.groupID)}</DataTable.Cell>
                                                    <DataTable.Cell>{userData.userDetails.userID}</DataTable.Cell>
                                                    <DataTable.Cell>{userData.userDetails.userRole}</DataTable.Cell>
                                                    {
                                                        props.subRoute!="insideOrder"||
                                                        props.subRoute=="insideFileHandler" ?
                                                            <DataTable.Cell>
                                                                <TouchableOpacity onPress={()=>{
                                                                    setUserEditAccess(userData)
                                                                    setEditAccess(!editAccess)}
                                                                }>
                                                                    {"Edit Access"}
                                                                </TouchableOpacity>
                                                            </DataTable.Cell>
                                                        : null
                                                    }
                                                </DataTable.Row>
                                            : null
                                        })
                                    }
                                </DataTable>
                            </View>
                        </View>
                    </View>
                :  editAccess == true ?
                    <View>
                        <View style={Style.editAccessView}>
                        <View style={Style.rowWise}>
                            <View>
                                <Text style={Style.editAccessLabels}>Name</Text>
                                <Text style={Style.editAccessValue}>
                                    {userEdtitAccess.userDetails.userName}
                                </Text>
                            </View>
                            <View>
                                <Text style={Style.editAccessLabels}>User ID</Text>
                                <Text style={Style.editAccessValue}>
                                    {userEdtitAccess.userDetails.userID}
                                </Text>
                            </View>
                            <View>
                                <Text style={Style.editAccessLabels}>Role</Text>
                                <Text style={Style.editAccessValue}>
                                    {userEdtitAccess.userDetails.userRole}
                                </Text>
                            </View>
                        </View>
                        <View style={Style.rowWise}>
                            <View>
                                <Text style={Style.contentLabel}>Schedule From/To</Text>
                                <DatePicker label={"Production Start Date"} placeholder={"Production Start Date"}
                                    date={userEdtitAccess?.scheduleFrom}
                                    layerIndex={4} onDateSelected={(date)=>{setUserSechduleDate(
                                        userEdtitAccess.resPerson,
                                        date,
                                        "scheduleFrom"
                                    )}}/>
                                <DatePicker label={"Production Start Date"} placeholder={"Production Start Date"}
                                    date={userEdtitAccess?.scheduleTo}
                                    layerIndex={5} onDateSelected={(date)=>{setUserSechduleDate(
                                        userEdtitAccess.resPerson,
                                        date,
                                        "scheduleTo"
                                    )}}/>
                            </View>
                            <View>
                                <View style={Style.accessControlView}>
                                    <Text style={Style.contentLabel}>Access</Text>
                                    <View style={{maxHeight:500,maxWidth:500,flexDirection:'row',flex:1,overflow:'scroll'}}>
                                        {sideMenuOptions.map((element:any)=>{
                                            return  <View style={[styleBox.box]}>
                                                <View>
                                                    <CheckBoxButton  state={
                                                         checkAccessExists(userEdtitAccess.resPerson,element) != -1 ? true : false
                                                        } id={null}
                                                        isChecked={()=>{accessSelected(userEdtitAccess.resPerson,element,null)}}/>
                                                    <Text style={Style.accessListText}>{element.title}</Text>
                                                </View>
                                                <Picker  onValueChange={(itemValue:any) =>{accessSelected(userEdtitAccess.resPerson,element,itemValue)}}
                                                    value = {checkAccessExists(userEdtitAccess.resPerson,element)}>
                                                    { element?.options.map((optionVal:any)=>{
                                                        return   <Picker.Item 
                                                            label={optionVal.title} 
                                                            value={optionVal.value} />
                                                        })
                                                    }
                                                </Picker>
                                            </View>
                                        })}
                                    </View> 
                                </View>
                            </View>
                        </View>
                    </View>
                    </View>
                : null
            }
        </View>
    )
}

export default ResponsiblePerson;


const styleBox = StyleSheet.create({
    box: {
      padding:15,
    },
    row: {
      flexDirection: "row",
      flexWrap: "wrap",
    },
  });