import { StyleSheet, Dimensions } from "react-native";
const Style=StyleSheet.create({
    container:{
        flex:1,
        backgroundColor:'white',
    },
    mainContect:{
      flex:1,
      flexDirection:'row',
    },
 
});

export default Style;