import { StyleSheet } from "react-native";

const Style=StyleSheet.create({
    container:{
        flexDirection:'row',
        padding:5,
        backgroundColor:"#228FF3",
        maxHeight:50,
    },
    containerLeft:{
        flexDirection:'row',
        alignSelf:'flex-start',
        padding:5,
    },
    containerRight:{
        flexDirection:'row',
        alignSelf:'flex-start',
        padding:20,
    },
    activityTitle:{
        margin:5,
        fontSize:18,
        color:'white',
        fontWeight:'700',
    },
    menuIcon:{
        marginTop:2,
        color:'white',
        fontSize:25
    },
    createProducts:{
        alignSelf:'flex-end',
        with:200,
        height:45,
        borderColor:'white',
    },
    filterOptions:{
        flexDirection:'row',
        padding:5,
        paddingTop:5
    },
    dropDown:{
        margin:10,
        marginRight:15,
        height:48,
        width:'30%',
        borderRadius:2,
    },
    filterView:{
     marginTop:15,
     marginLeft:12,
     flexDirection: 'row',
    },
    wrapView:{
        flexDirection: 'row',
        flexWrap: 'wrap',
        backgroundColor:'green'
    },
    footer:{
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 1,
        marginBottom:0,
        position:'relative',
        flexDirection:'row',
        padding:2,
        zIndex:-1
    },
});

export default Style;