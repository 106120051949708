import React,{FC, useEffect, useState} from "react";
import {View, Text, Image, TouchableOpacity} from "react-native";
import {Style} from './style';
import {getGroupList,getOrderUserList,updateOrderUserList} from '../../Server_Repo/action';
import { useSelector, useDispatch } from 'react-redux';
import ResponsiblePerson from "../ResponsiblePerson";

interface Props{
  isLargeScreen:number;
  data:any;
  orderID:any;
  currentRoute:any;
  successCallBack:(data:any)=>void;
  internalComponentChanges:()=>void;
}
const OrderCategory:FC<Props>=(props)=>{

    const dispatch:any = useDispatch();
    const [screenType,setScreenType]=useState(Style);
    const [userList,setUserList]= useState<any>();
    const [groupDetails,setGroupDetails] = useState<any>(props.data);
    const [formError,setFormError] = useState<any>();
    const [loader,setLoader] = useState<any>(true);
    
    const { 
      getGroupListResponse,
      updateUserListResponse,
      getOrderUserListResponse
    } = useSelector((state:any) => state.userReducer);

    
    useEffect(()=>{
      
        if(getGroupListResponse?.status==1){
          setGroupDetails(getGroupListResponse?.data);
          dispatch(
            getOrderUserList(
            {
                orderID: props.orderID,
                id:"createOrder-userList"
            })
          )
        }
        if(updateUserListResponse?.status!=1){
          setFormError(getGroupListResponse?.error);
        }

        console.log("yyyyyyy",getOrderUserListResponse)
        if(getOrderUserListResponse?.status==1&&
          getOrderUserListResponse.id=="createOrder-userList"){
          console.log(
            "getOrderUserListResponse",
            getOrderUserListResponse?.data
          )
          setUserList([...getOrderUserListResponse?.data])
          setLoader(false)
          console.log(
            "userList",
            userList
          )
        //  setFormError(getGroupListResponse?.error);
        }
    },[getGroupListResponse!=undefined,
      updateUserListResponse!=undefined,
      getOrderUserListResponse!=undefined
    ]);

    useEffect(()=>{
      dispatch(
          getGroupList(
          {
              fetchType: "users",
              limit:0,
              skip:0
          })
      )
  },[])

  const updateUserDetails=(userDetails:any)=>{

    dispatch(
      updateOrderUserList(
        {
            orderID:props.orderID,
            userList:userDetails
        })
    )
  }
  console.log("userList",userList)
    return (
      <View style={Style.mainContent}>
        <View>
          <View style={screenType.container}>
              <View style={Style.header}>
                <Text style={Style.headerTitle}>Order Staff</Text>
              </View>
            <View style={Style.divView}>
              {
                loader == false ?
                    <ResponsiblePerson groupList={groupDetails}
                    title={"Add Staff to this Order"}
                    id='orderUser'
                    subRoute={"outSideOrder"}
                    orderID={props.orderID}
                    currentRoute={props.currentRoute}
                    accessID={props.currentRoute}
                    resPerson = {userList ? userList : null}
                    userListUpdated = {(userList)=>{updateUserDetails(userList)}}
                    visible={true}/>
                :  null
              }
            </View>
          </View>
        </View>
      </View>
    )
}

export default OrderCategory;