import {call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {Server_Handler} from "./index";

 async function signupCall(action) {
  console.log("sagaResponse",action);
  return await Server_Handler("userActivity/signUp",action.payload,"POST").
   then((response)=>{
      console.log("sagaResponse",response);
     return response;
  })
 }
 
// worker Saga: will be fired on USER_FETCH_REQUESTED actions
 function* signup(action) {
   try {
      console.log("signup call running");
      const response = yield call(signupCall,action);
      yield put({type: "USER_SIGNUP_REQUEST_SUCCESS", response: response});
   } catch (e) {
      console.log("errorMsg",e);
      yield put({type: "API_CALL_FAIL", message: e.message});
   }
}

async function verifyOtpCall(action) {
   return await Server_Handler("userActivity/verifyOTP",action.payload,"POST").
    then((response)=>{
       console.log("sagaResponse",response);
      return response;
   })
  }
  
 // worker Saga: will be fired on USER_FETCH_REQUESTED actions
  function* otpverify(action) {
    try {
       console.log("otp call running");
       const response = yield call(verifyOtpCall,action);
       yield put({type: "VERIFY_OTP_SUCCESS", response: response});
    } catch (e) {
       console.log("errorMsg",e);
       yield put({type: "API_CALL_FAIL", message: e.message});
    }
 }
 async function resendOtpCall(action) {
   console.log("resendotpCall","apicall",action);
   return await Server_Handler("userActivity/resendOtp",action.payload,"POST").
    then((response)=>{
       console.log("sagaResponse",response);
      return response;
   })
  }
  
 // worker Saga: will be fired on USER_FETCH_REQUESTED actions
  function* resendOtp(action) {
   console.log("resendotpCall","saga");
    try {
       console.log("otp call running");
       const response = yield call(resendOtpCall,action);
       yield put({type: "RESEND_OTP_SUCCESS", response: response});
    } catch (e) {
       console.log("errorMsg",e);
       yield put({type: "API_CALL_FAIL", message: e.message});
    }
 }
 
 async function getUserDetailsCall(action) {
   console.log("resendotpCall","apicall",action.payload.userMail);
   return await Server_Handler("userActivity/getUserDetails",action.payload,"POST").
    then((response)=>{
       console.log("sagaResponse",response);
      return response;
   })
  }
  
 // worker Saga: will be fired on USER_FETCH_REQUESTED actions
  function* getUserDetails(action) {
   console.log("resendotpCall","saga");
    try {
       console.log("otp call running");
       const response = yield call(getUserDetailsCall,action);
       yield put({type: "GET_USER_DETAILS_SUCCESS", response: response});
    } catch (e) {
       console.log("errorMsg",e);
       yield put({type: "API_CALL_FAIL", message: e.message});
    }
 }

 async function updatePlanDetailsCall(action) {
   console.log("resendotpCall","apicall",action.payload.userMail);
   return await Server_Handler("userActivity/updatePlan",action.payload,"POST").
    then((response)=>{
       console.log("sagaResponse",response);
      return response;
   })
  }
  
 // worker Saga: will be fired on USER_FETCH_REQUESTED actions
  function* updatePlanDetails(action) {
   console.log("resendotpCall","saga");
    try {
       console.log("otp call running");
       const response = yield call(updatePlanDetailsCall,action);
       yield put({type: "UPDATE_PLAN_DETAILS_SUCCESS", response: response});
    } catch (e) {
       console.log("errorMsg",e);
       yield put({type: "API_CALL_FAIL", message: e.message});
    }
 }


 async function loginUserCall(action) {
   console.log("resendotpCall","apicall",action.payload.userMail);
   return await Server_Handler("userActivity/userLogin",action.payload,"POST").
    then((response)=>{
       console.log("sagaResponse",response);
      return response;
   })
  }
  
 // worker Saga: will be fired on USER_FETCH_REQUESTED actions
  function* userLogin(action) {
   console.log("resendotpCall","saga");
    try {
       console.log("otp call running");
       const response = yield call(loginUserCall,action);
       yield put({type: "LOGIN_USER_SUCCESS", response: response});
    } catch (e) {
       console.log("errorMsg",e);
       yield put({type: "API_CALL_FAIL", message: e.message});
    }
 }

 async function forgetOtpCall(action) {
   console.log("resendotpCall","apicall",action);
   return await Server_Handler("userActivity/resendOtp",action.payload,"POST").
    then((response)=>{
       console.log("sagaResponse",response);
      return response;
   })
  }
  
 // worker Saga: will be fired on USER_FETCH_REQUESTED actions
  function* forgetPinOtp(action) {
   console.log("resendotpCall","saga");
    try {
       console.log("otp call running");
       const response = yield call(forgetOtpCall,action);
       yield put({type: "FORGET_PASSWORD_OTP_SUCCESS", response: response});
    } catch (e) {
       console.log("errorMsg",e);
       yield put({type: "API_CALL_FAIL", message: e.message});
    }
 }

 async function forgetPasswordCall(action) {
   console.log("resendotpCall","apicall",action.payload.userMail);
   return await Server_Handler("userActivity/forgetPin",action.payload,"POST").
    then((response)=>{
       console.log("sagaResponse",response);
      return response;
   })
  }
  
 // worker Saga: will be fired on USER_FETCH_REQUESTED actions
  function* forgetPassword(action) {
   console.log("resendotpCall","saga");
    try {
       console.log("foorget pin call running");
       const response = yield call(forgetPasswordCall,action);
       yield put({type: "FORGET_PASSWORD_SUCCESS", response: response});
    } catch (e) {
       console.log("errorMsg",e);
       yield put({type: "API_CALL_FAIL", message: e.message});
    }
 }

 async function saveCoumentCall(action) {
   return await Server_Handler("Files/fileHandler",action.payload,"POST").
    then((response)=>{
       console.log("sagaResponse",response);
      return response;
   })
  }
  
 // worker Saga: will be fired on USER_FETCH_REQUESTED actions
  function* saveDocument(action) {
   console.log("saveDocument","saga",action.payload,);
    try {
       console.log("foorget pin call running");
       const response = yield call(saveCoumentCall,action);
       yield put(
         {  type: "SAVE_FILE_REQUEST_SUCCESS",
            response: {...response,...{fieldID:action.payload.fieldID}}
         }
      );
    } catch (e) {
       console.log("errorMsg",e);
       yield put({type: "API_CALL_FAIL", message: e.message});
    }
 }

 async function saveOrderBasicInfoCall(action) {
   console.log("resendotpCall","apicall",action.payload.userMail);
   return await Server_Handler("OrderProduction/createOrder",action.payload,"POST").
    then((response)=>{
       console.log("sagaResponse",response);
      return response;
   })
  }
  
 // worker Saga: will be fired on USER_FETCH_REQUESTED actions
  function* saveOrderBasicInfo(action) {
   console.log("resendotpCall","saga");
    try {
       console.log("CREATE_ORDER_RESPONSE pin call running");
       const response = yield call(saveOrderBasicInfoCall,action);
       yield put({type: "CREATE_ORDER_RESPONSE", response: response});
    } catch (e) {
       console.log("errorMsg",e);
       yield put({type: "API_CALL_FAIL", message: e.message});
    }
 }

 async function getOrderListCall(action) {
   console.log("resendotpCall","apicall",action.payload.userMail);
   return await Server_Handler("OrderProduction/getOrderList",action.payload,"POST").
    then((response)=>{
       console.log("sagaResponse",response);
      return response;
   })
  }
  
 // worker Saga: will be fired on USER_FETCH_REQUESTED actions
  function* getOrderList(action) {
   console.log("resendotpCall","saga");
    try {
       console.log("CREATE_ORDER_RESPONSE pin call running");
       const response = yield call(getOrderListCall,action);
       yield put({type: "GET_ORDER_LIST_RESPONSE", response: response});
    } catch (e) {
       console.log("errorMsg",e);
       yield put({type: "API_CALL_FAIL", message: e.message});
    }
 }

 async function getOrderDetailsCall(action) {
   console.log("resendotpCall","apicall",action.payload.userMail);
   return await Server_Handler("OrderProduction/getOrderDetails",action.payload,"POST").
    then((response)=>{
       console.log("sagaResponse",action.payload, response);
      return response;
   })
  }
  
 // worker Saga: will be fired on USER_FETCH_REQUESTED actions
  function* getOrderDetails(action) {
   console.log("resendotpCall","saga");
    try {
       console.log("CREATE_ORDER_RESPONSE pin call running");
       const response = yield call(getOrderDetailsCall,action);
       console.log("response",{ ...response,...{fetchType:action.payload.fetchType}});
       yield put({type: "GET_ORDER_DETAIL_RESPONSE", 
         response: { ...response,...{fetchType:action.payload.fetchType}}
       });
    } catch (e) {
       console.log("errorMsg",e);
       yield put({type: "API_CALL_FAIL", message: e.message});
    }
 }


 async function createGroupCall(action) {
   console.log("resendotpCall","apicall",action.payload.userMail);
   return await Server_Handler("Group/createGroup",action.payload,"POST").
    then((response)=>{
       console.log("sagaResponse",response);
      return response;
   })
  }

   
 // worker Saga: will be fired on USER_FETCH_REQUESTED actions
 function* createGroup(action) {
   console.log("resendotpCall","saga");
    try {
       console.log("CREATE_GROUP_RESPONSE pin call running");
       const response = yield call(createGroupCall,action);
       yield put({type: "CREATE_GROUP_RESPONSE", response: response});
    } catch (e) {
       console.log("errorMsg",e);
       yield put({type: "API_CALL_FAIL", message: e.message});
    }
 }


 async function createStaffCall(action) {
   console.log("resendotpCall","apicall",action.payload.userMail);
   return await Server_Handler("Staff/createStaff",action.payload,"POST").
    then((response)=>{
       console.log("sagaResponse",response);
      return response;
   })
  }

  // worker Saga: will be fired on USER_FETCH_REQUESTED actions
  function* createStaff(action) {
   console.log("resendotpCall","saga");
    try {
       console.log("CREATE_STAFF_RESPONSE pin call running");
       const response = yield call(createStaffCall,action);
       yield put({type: "CREATE_STAFF_RESPONSE", response: response});
    } catch (e) {
       console.log("errorMsg",e);
       yield put({type: "API_CALL_FAIL", message: e.message});
    }
 }


 async function getStaffListCall(action) {
   console.log("resendotpCall","apicall",action.payload.userMail);
   return await Server_Handler("Staff/staffList",action.payload,"POST").
    then((response)=>{
       console.log("getStaffListCall",response);
      return response;
   })
  }

  // worker Saga: will be fired on USER_FETCH_REQUESTED actions
  function* getStaffList(action) {
   console.log("resendotpCall","saga");
    try {
       console.log("GET_STAFF_LIST_RESPONSE pin call running");
       const response = yield call(getStaffListCall,action);

       console.log("getStaffList",action.payload.fieldID);
       yield put({type: "GET_STAFF_LIST_RESPONSE", 
       response: {
       ...response,
       ...{fieldID:action.payload.fieldID,id:action.payload.id}
      }});
    } catch (e) {
       console.log("errorMsg",e);
       yield put({type: "API_CALL_FAIL", message: e.message});
    }
 }

 async function getStaffDetailsCall(action) {
   console.log("resendotpCall","apicall",action.payload.userMail);
   return await Server_Handler("Staff/staffDetails",action.payload,"POST").
    then((response)=>{
       console.log("sagaResponse",response);
      return response;
   })
  }

  // worker Saga: will be fired on USER_FETCH_REQUESTED actions
  function* getStaffDetails(action) {
   console.log("resendotpCall","saga");
    try {
       console.log("GET_STAFF_LIST_RESPONSE pin call running");
       const response = yield call(getStaffDetailsCall,action);
       yield put({type: "GET_STAFF_LIST_RESPONSE", 
       response: {
         ...response,
         ...{fieldID:action.payload.fieldID}
       }
      });
    } catch (e) {
       console.log("errorMsg",e);
       yield put({type: "API_CALL_FAIL", message: e.message});
    }
 }

   async function getStaffDetailsByIdCall(action) {
      console.log("resendotpCall","apicall",action.payload);
      return await Server_Handler("Staff/staffListByID",action.payload,"POST").
         then((response)=>{
            console.log("sagaResponse",response);
         return response;
      })
   }

  // worker Saga: will be fired on USER_FETCH_REQUESTED actions
  function* getStaffDetailsById(action) {
      console.log("resendotpCall","saga");
      try {
         console.log("GET_STAFF_LIST_RESPONSE pin call running");
         const response = yield call(getStaffDetailsByIdCall,action);
         yield put({type: "GET_STAFF_LIST_BY_ID_RESPONSE", response: 
            {  
               ...response,
               ...{fieldID:action.payload.fieldID,id:action.payload.id}
         }});
      } catch (e) {
         console.log("errorMsg",e);
         yield put({type: "API_CALL_FAIL", message: e.message});
      }
   }

   async function createStockCall(action) {
      console.log("resendotpCall","apicall",action.payload);
      return await Server_Handler("Stock/createStock",action.payload,"POST").
         then((response)=>{
            console.log("sagaResponse",response);
         return response;
      })
   }

  // worker Saga: will be fired on USER_FETCH_REQUESTED actions
  function* createStock(action) {
      console.log("resendotpCall","saga");
      try {
         console.log("GET_STAFF_LIST_RESPONSE pin call running");
         const response = yield call(createStockCall,action);
         yield put({type: "CREATE_STOCKS_RESPONSE", response: response});
      } catch (e) {
         console.log("errorMsg",e);
         yield put({type: "API_CALL_FAIL", message: e.message});
      }
   }

   async function getStockListCall(action) {
      console.log("resendotpCall","apicall",action.payload);
      return await Server_Handler("Stock/stockList",action.payload,"POST").
         then((response)=>{
            console.log("sagaResponse",response);
         return response;
      })
   }

  // worker Saga: will be fired on USER_FETCH_REQUESTED actions
  function* getStockList(action) {
      console.log("resendotpCall","saga");
      try {
         console.log("GET_STAFF_LIST_RESPONSE pin call running");
         const response = yield call(getStockListCall,action);
         yield put({type: "GET_STOCKS_LIST_RESPONSE", response: response});
      } catch (e) {
         console.log("errorMsg",e);
         yield put({type: "API_CALL_FAIL", message: e.message});
      }
   }

   async function createCategoryCall(action) {
      console.log("resendotpCall","apicall",action.payload);
      return await Server_Handler("Category/createCategory",action.payload,"POST").
         then((response)=>{
            console.log("sagaResponse",response);
         return response;
      })
   }

  // worker Saga: will be fired on USER_FETCH_REQUESTED actions
  function* createCategory(action) {
      console.log("resendotpCall","saga");
      try {
         console.log("CREATE_CATEGORY_RESPONSE pin call running");
         const response = yield call(createCategoryCall,action);
         yield put({type: "CREATE_CATEGORY_RESPONSE", response: response});
      } catch (e) {
         console.log("errorMsg",e);
         yield put({type: "API_CALL_FAIL", message: e.message});
      }
  }


   async function getCategoryListCall(action) {
      console.log("resendotpCall","apicall",action.payload);
      return await Server_Handler("Category/getCategory",action.payload,"POST").
         then((response)=>{
            console.log("sagaResponse",response);
         return response;
      })
   }

  // worker Saga: will be fired on USER_FETCH_REQUESTED actions
  function* getCategoryList(action) {
      console.log("resendotpCall","saga");
      try {
         console.log("CREATE_CATEGORY_RESPONSE pin call running");
         const response = yield call(getCategoryListCall,action);
         yield put({type: "GET_CATEGORY_LIST_RESPONSE", response: response});
      } catch (e) {
         console.log("errorMsg",e);
         yield put({type: "API_CALL_FAIL", message: e.message});
      }
   }
/*
  Starts fetchUser on each dispatched `USER_FETCH_REQUESTED` action.
  Allows concurrent fetches of user.
*/

async function createTaskCall(action) {
   console.log("resendotpCall","apicall",action.payload);
   return await Server_Handler("Task/createTask",action.payload,"POST").
      then((response)=>{
         console.log("sagaResponse",response);
      return response;
   })
}

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* createTask(action) {
   console.log("resendotpCall","saga");
   try {
      console.log("CREATE_CATEGORY_RESPONSE pin call running");
      const response = yield call(createTaskCall,action);
      yield put({type: "CREATE_TASK_RESPONSE", response: response});
   } catch (e) {
      console.log("errorMsg",e);
      yield put({type: "API_CALL_FAIL", message: e.message});
   }
}


async function getTaskListCall(action) {
   console.log("resendotpCall","apicall",action.payload);
   return await Server_Handler("Task/getTask",action.payload,"POST").
      then((response)=>{
         console.log("sagaResponse",response);
      return response;
   })
}

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* getTaskList(action) {
   console.log("resendotpCall","saga");
   try {
      console.log("GET_TASK_LIST_RESPONSE pin call running");
      const response = yield call(getTaskListCall,action);
      yield put({type: "GET_TASK_LIST_RESPONSE", response: response});
   } catch (e) {
      console.log("errorMsg",e);
      yield put({type: "API_CALL_FAIL", message: e.message});
   }
}


async function createOPUCall(action) {
   console.log("resendotpCall","apicall",action.payload);
   return await Server_Handler("OPU/createOrderProductionLine",action.payload,"POST").
      then((response)=>{
         console.log("sagaResponse",response);
      return response;
   })
}

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* createOPU(action) {
   console.log("resendotpCall","saga");
   try {
      console.log("CREATE_CATEGORY_RESPONSE pin call running");
      const response = yield call(createOPUCall,action);
      yield put({type: "CREATE_OPU_RESPONSE", response: response});
   } catch (e) {
      console.log("errorMsg",e);
      yield put({type: "API_CALL_FAIL", message: e.message});
   }
}


async function getOPUListCall(action) {
   console.log("resendotpCall","apicall",action.payload);
   return await Server_Handler("OPU/getOrderProductionLine",action.payload,"POST").
      then((response)=>{
         console.log("sagaResponse",response);
      return response;
   })
}

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* getOPUList(action) {
   console.log("resendotpCall","saga");
   try {
      console.log("GET_OPU_LIST_RESPONSE pin call running");
      const response = yield call(getOPUListCall,action);
      yield put({type: "GET_OPU_LIST_RESPONSE", response: response});
   } catch (e) {
      console.log("errorMsg",e);
      yield put({type: "API_CALL_FAIL", message: e.message});
   }
}

async function createAgendaCall(action) {
   console.log("resendotpCall","apicall",action.payload);
   return await Server_Handler("Agenda/createAgenda",action.payload,"POST").
      then((response)=>{
         console.log("sagaResponse",response);
      return response;
   })
}

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* createAgenda(action) {
   console.log("resendotpCall","saga");
   try {
      console.log("CREATE_CATEGORY_RESPONSE pin call running");
      const response = yield call(createAgendaCall,action);
      yield put({type: "CREATE_AGENDA_RESPONSE", response: response});
   } catch (e) {
      console.log("errorMsg",e);
      yield put({type: "API_CALL_FAIL", message: e.message});
   }
}

async function getAgendaListCall(action) {
   console.log("resendotpCall","apicall",action.payload);
   return await Server_Handler("Agenda/getAgenda",action.payload,"POST").
      then((response)=>{
         console.log("sagaResponse",response);
      return response;
   })
}

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* getAgendaList(action) {
   console.log("resendotpCall","saga");
   try {
      console.log("GET_OPU_LIST_RESPONSE pin call running");
      const response = yield call(getAgendaListCall,action);
      yield put({type: "GET_AGENDA_LIST_RESPONSE", response: response});
   } catch (e) {
      console.log("errorMsg",e);
      yield put({type: "API_CALL_FAIL", message: e.message});
   }
}

async function createCalendarEventCall(action) {
   console.log("resendotpCall","apicall",action.payload);
   return await Server_Handler("Calendar/createCalendarEvent",action.payload,"POST").
      then((response)=>{
         console.log("sagaResponse",response);
      return response;
   })
}

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* createCalendar(action) {
   console.log("resendotpCall","saga");
   try {
      console.log("CREATE_CATEGORY_RESPONSE pin call running");
      const response = yield call(createCalendarEventCall,action);
      yield put({type: "CREATE_CALENDAR_EVENT_RESPONSE", response: response});
   } catch (e) {
      console.log("errorMsg",e);
      yield put({type: "API_CALL_FAIL", message: e.message});
   }
}

async function getCalendarEventListCall(action) {
   console.log("resendotpCall","apicall",action.payload);
   return await Server_Handler("Calendar/getCalendarEvent",action.payload,"POST").
      then((response)=>{
         console.log("sagaResponse",response);
      return response;
   })
}

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* getCalendarEventList(action) {
   console.log("resendotpCall","saga");
   try {
      console.log("GET_OPU_LIST_RESPONSE pin call running");
      const response = yield call(getCalendarEventListCall,action);
      yield put({type: "GET_CALENDAR_EVENT_LIST_RESPONSE", response: response});
   } catch (e) {
      console.log("errorMsg",e);
      yield put({type: "API_CALL_FAIL", message: e.message});
   }
}

async function productionInputCall(action) {
   console.log("resendotpCall","apicall",action.payload);
   return await Server_Handler("ProductionInput/inputProduction",action.payload,"POST").
      then((response)=>{
         console.log("sagaResponse",response);
      return response;
   })
}

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* productionInput(action) {
   console.log("resendotpCall","saga");
   try {
      console.log("CREATE_CATEGORY_RESPONSE pin call running");
      const response = yield call(productionInputCall,action);
      yield put({type: "PRODUCTION_INPUT_RESPONSE", response: response});
   } catch (e) {
      console.log("errorMsg",e);
      yield put({type: "API_CALL_FAIL", message: e.message});
   }
}

async function getInputListCall(action) {
   console.log("resendotpCall","apicall",action.payload);
   return await Server_Handler("ProductionInput/getProductionInput",action.payload,"POST").
      then((response)=>{
         console.log("sagaResponse",response);
      return response;
   })
}

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* getInputList(action) {
   console.log("resendotpCall","saga");
   try {
      console.log("GET_OPU_LIST_RESPONSE pin call running");
      const response = yield call(getInputListCall,action);
      yield put({type: "GET_INPUT_LIST_RESPONSE", response: response});
   } catch (e) {
      console.log("errorMsg",e);
      yield put({type: "API_CALL_FAIL", message: e.message});
   }
}

async function createDefectTypeCall(action) {
   console.log("resendotpCall","apicall",action.payload);
   return await Server_Handler("DefectFormat/createDefectType",action.payload,"POST").
      then((response)=>{
         console.log("sagaResponse",response);
      return response;
   })
}

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* createDefectType(action) {
   console.log("resendotpCall","saga");
   try {
      console.log("CREATE_CATEGORY_RESPONSE pin call running");
      const response = yield call(createDefectTypeCall,action);
      yield put({type: "CREATE_DEFECT_TYPE_RESPONSE", response: response});
   } catch (e) {
      console.log("errorMsg",e);
      yield put({type: "API_CALL_FAIL", message: e.message});
   }
}

async function getDefectTypeListCall(action) {
   console.log("resendotpCall","apicall",action.payload);
   return await Server_Handler("DefectFormat/getDefectTypeList",action.payload,"POST").
      then((response)=>{
         console.log("sagaResponse",response);
      return response;
   })
}

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* getDefectTypeList(action) {
   console.log("resendotpCall","saga");
   try {
      console.log("CREATE_CATEGORY_RESPONSE pin call running");
      const response = yield call(getDefectTypeListCall,action);
      yield put({type: "GET_DEFECT_TYPE_LIST_RESPONSE", response: response});
   } catch (e) {
      console.log("errorMsg",e);
      yield put({type: "API_CALL_FAIL", message: e.message});
   }
}

async function inputDefectCall(action) {
   console.log("resendotpCall","apicall",action.payload);
   return await Server_Handler("DefectInput/defectProductionInput",action.payload,"POST").
      then((response)=>{
         console.log("sagaResponse",response);
      return response;
   })
}

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* inputDefect(action) {
   console.log("resendotpCall","saga");
   try {
      console.log("INPUT_DEFECT_RESPONSE pin call running");
      const response = yield call(inputDefectCall,action);
      yield put({type: "INPUT_DEFECT_RESPONSE", response: response});
   } catch (e) {
      console.log("errorMsg",e);
      yield put({type: "API_CALL_FAIL", message: e.message});
   }
}

async function inputDefectListCall(action) {
   console.log("resendotpCall","apicall",action.payload);
   return await Server_Handler("DefectInput/getDefectProductionInput",action.payload,"POST").
      then((response)=>{
         console.log("sagaResponse",response);
      return response;
   })
}

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* inputDefectList(action) {
   console.log("resendotpCall","saga");
   try {
      console.log("INPUT_DEFECT_LIST_RESPONSE pin call running");
      const response = yield call(inputDefectListCall,action);
      yield put({type: "INPUT_DEFECT_LIST_RESPONSE", response: response});
   } catch (e) {
      console.log("errorMsg",e);
      yield put({type: "API_CALL_FAIL", message: e.message});
   }
}


async function getGroupListCall(action) {
   console.log("resendotpCall","apicall",action.payload);
   return await Server_Handler("Group/getGroupList",action.payload,"POST").
      then((response)=>{
         console.log("sagaResponse",response);
      return response;
   })
}

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* getGroupList(action) {
   console.log("resendotpCall","saga");
   try {
      console.log("GET_GROUP_LIST_RESPONSE pin call running");
      const response = yield call(getGroupListCall,action);
      yield put({type: "GET_GROUP_LIST_RESPONSE", response: response});
   } catch (e) {
      console.log("errorMsg",e);
      yield put({type: "API_CALL_FAIL", message: e.message});
   }
}

async function fileAccessCall(action) {
   console.log("resendotpCall","apicall",action.payload);
   return await Server_Handler("Files/fileAccess",action.payload,"POST").
      then((response)=>{
         console.log("sagaResponse",response);
      return {...response,...action.payload};
   })
}

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* getFileAccessList(action) {
   console.log("resendotpCall","saga");
   try {
      console.log("GET_FILE_ACCESS_LIST_RESPONSE pin call running");
      const response = yield call(fileAccessCall,action);
      yield put({type: "GET_FILE_ACCESS_LIST_RESPONSE", response: response});
   } catch (e) {
      console.log("errorMsg",e);
      yield put({type: "API_CALL_FAIL", message: e.message});
   }
}


async function updateOrderUserCall(action) {
   console.log("resendotpCall","apicall",action.payload);
   return await Server_Handler("OrderProduction/updateUserList",action.payload,"POST").
      then((response)=>{
         console.log("sagaResponse",response);
      return {...response,...action.payload};
   })
}

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* updateOrderUser(action) {
   console.log("resendotpCall","saga");
   try {
      console.log("UPDATE_ORDER_USER_LIST_RESPONSE pin call running");
      const response = yield call(updateOrderUserCall,action);
      yield put({type: "UPDATE_ORDER_USER_LIST_RESPONSE", response: response});
   } catch (e) {
      console.log("errorMsg",e);
      yield put({type: "API_CALL_FAIL", message: e.message});
   }
}

async function getOrderUserCall(action) {
   console.log("resendotpCall","apicall",action.payload);
   return await Server_Handler("OrderProduction/getOrderUserList",action.payload,"POST").
      then((response)=>{
         console.log("sagaResponse",response);
      return {...response,...action.payload};
   })
}

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* getOrderUser(action) {
   console.log("resendotpCall","saga");
   try {
      console.log("GET_ORDER_USER_LIST_RESPONSE pin call running");
      const response = yield call(getOrderUserCall,action);
      yield put({type: "GET_ORDER_USER_LIST_RESPONSE", response: {...response,...action.payload}});
   } catch (e) {
      console.log("errorMsg",e);
      yield put({type: "API_CALL_FAIL", message: e.message});
   }
}

async function getOrderStockUsageCall(action) {
   console.log("resendotpCall","apicall",action.payload);
   return await Server_Handler("Stock/orderStockUsage",action.payload,"POST").
      then((response)=>{
         console.log("sagaResponse",response);
      return {...response,...action.payload};
   })
}

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* getOrderStockUsage(action) {
   console.log("resendotpCall","saga");
   try {
      console.log("GET_ORDER_STOCK_USAGE_LIST_RESPONSE pin call running");
      const response = yield call(getOrderStockUsageCall,action);
      yield put({type: "GET_ORDER_STOCK_USAGE_LIST_RESPONSE", response: {...response,...action.payload}});
   } catch (e) {
      console.log("errorMsg",e);
      yield put({type: "API_CALL_FAIL", message: e.message});
   }
}

async function getOverAllReportCall(action) {
   console.log("resendotpCall","apicall",action.payload);
   return await Server_Handler("orderReport/orderReport",action.payload,"POST").
      then((response)=>{
         console.log("sagaResponse",response);
      return {...response,...action.payload};
   })
}

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* getOverAllOrderReport(action) {
   console.log("resendotpCall","saga");
   try {
      console.log("GET_OVERALL_REPORT_LIST_RESPONSE pin call running");
      const response = yield call(getOverAllReportCall,action);
      yield put({type: "GET_OVERALL_REPORT_LIST_RESPONSE", response: {...response,...action.payload}});
   } catch (e) {
      console.log("errorMsg",e);
      yield put({type: "API_CALL_FAIL", message: e.message});
   }
}

async function getReportDetailsCall(action) {
   console.log("resendotpCall","apicall",action.payload);
   return await Server_Handler("orderReport/getFilterReqinfo",action.payload,"POST").
      then((response)=>{
         console.log("sagaResponse",response);
      return {...response,...action.payload};
   })
}

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* getReportFilterDetails(action) {
   console.log("resendotpCall","saga");
   try {
      console.log("GET_FILTER_DETAILS_RESPONSE pin call running");
      const response = yield call(getReportDetailsCall,action);
      yield put({type: "GET_FILTER_DETAILS_RESPONSE", response: {...response,...action.payload}});
   } catch (e) {
      console.log("errorMsg",e);
      yield put({type: "API_CALL_FAIL", message: e.message});
   }
}

async function getActualReportDetailsCall(action) {
   console.log("resendotpCall","apicall",action.payload);
   return await Server_Handler("orderReport/getActualReport",action.payload,"POST").
      then((response)=>{
         console.log("sagaResponse",response);
      return {...response,...action.payload};
   })
}

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* getActualReportDetails(action) {
   console.log("resendotpCall","saga");
   try {
      console.log("GET_FILTER_DETAILS_RESPONSE pin call running");
      const response = yield call(getActualReportDetailsCall,action);
      yield put({type: "GET_ACTUAL_REPORT_DETAILS_RESPONSE", response: {...response,...action.payload}});
   } catch (e) {
      console.log("errorMsg",e);
      yield put({type: "API_CALL_FAIL", message: e.message});
   }
}

function* apiSaga() {
  yield  takeEvery("USER_SIGNUP_REQUEST",signup);
  yield  takeEvery("VERIFY_OTP",otpverify);
  yield  takeEvery("RESEND_OTP",resendOtp);
  yield  takeEvery("GET_USER_DETAILS",getUserDetails);
  yield  takeEvery("UPDATE_PLAN_DETAILS",updatePlanDetails);
  yield  takeEvery("LOGIN_USER",userLogin);
  yield  takeEvery("FORGET_PASSWORD_OTP",forgetPinOtp);
  yield  takeEvery("FORGET_PASSWORD",forgetPassword);
  yield  takeEvery("SAVE_FILE_REQUEST",saveDocument);
  yield  takeEvery("CREATE_ORDER_REQUEST",saveOrderBasicInfo);
  yield  takeEvery("GET_ORDER_LIST_REQUEST",getOrderList);
  yield  takeEvery("GET_ORDER_DETAILS_REQUEST",getOrderDetails);
  yield  takeEvery("CREATE_GROUP",createGroup);
  yield  takeEvery("CREATE_STAFF",createStaff);
  yield  takeEvery("GET_STAFF_LIST",getStaffList);
  yield  takeEvery("GET_STAFF_DETAILS",getStaffDetails);
  yield  takeEvery("GET_STAFF_LIST_BY_ID",getStaffDetailsById);
  yield  takeEvery("CREATE_STOCKS",createStock);
  yield  takeEvery("GET_STOCKS_LIST",getStockList);
  yield  takeEvery("CREATE_CATEGORY",createCategory);
  yield  takeEvery("GET_CATEGORY_LIST",getCategoryList);
  yield  takeEvery("CREATE_TASK",createTask);
  yield  takeEvery("GET_TASK_LIST",getTaskList);
  yield  takeEvery("CREATE_OPU",createOPU);
  yield  takeEvery("GET_OPU_LIST",getOPUList);
  yield  takeEvery("CREATE_AGENDA",createAgenda);
  yield  takeEvery("GET_AGENDA_LIST",getAgendaList);
  yield  takeEvery("CREATE_CALENDAR_EVENT",createCalendar);
  yield  takeEvery("GET_CALENDAR_EVENT_LIST",getCalendarEventList);
  yield  takeEvery("PRODUCTION_INPUT",productionInput);
  yield  takeEvery("GET_INPUT_LIST",getInputList);
  yield  takeEvery("CREATE_DEFECT_TYPE",createDefectType);
  yield  takeEvery("GET_DEFECT_TYPE_LIST",getDefectTypeList);
  yield  takeEvery("INPUT_DEFECT",inputDefect);
  yield  takeEvery("INPUT_DEFECT_LIST",inputDefectList);
  yield  takeEvery("GET_GROUP_LIST",getGroupList);
  yield  takeEvery("GET_FILE_ACCESS_LIST",getFileAccessList);
  yield  takeEvery("UPDATE_ORDER_USER_LIST",updateOrderUser);
  yield  takeEvery("GET_ORDER_USER_LIST",getOrderUser);
  yield  takeEvery("GET_ORDER_STOCK_USAGE_LIST",getOrderStockUsage);
  yield  takeEvery("GET_OVERALL_REPORT",getOverAllOrderReport);
  yield  takeEvery("GET_FILTER_DETAILS",getReportFilterDetails);
  yield  takeEvery("GET_ACTUAL_REPORT_DETAILS",getActualReportDetails);
}

export default apiSaga;