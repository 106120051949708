import React, { FC } from "react";
import {ImageBackground, View, Text} from "react-native";
import Style from "./style";
import CustomTextInput from "../../components/CustomTextInput";
import Ionicons from '@expo/vector-icons/EvilIcons';
import CustomButton from "../../components/CustomButton";
interface Props{
    rouetData:any;
    navigation:(dataSets:any,natTo:any)=>void;
}
const image = {uri: ''};

const SupportActivity:FC<Props>=()=>
{
    return (
        <View style={Style.mainContainer}>
            <View  style={Style.container}>
                <View style={Style.rowWise}>
                     <Ionicons name="user"  style={Style.searchIcon} 
                        onPress={()=>{}}/> 
                    <Text style={Style.pageTitleLable}>Support 24x7</Text>
                </View>
            
                <View style={Style.rowWise}>
                    <View style={Style.notification}>
                        <Text style={Style.countryLabel}>Contact India</Text>
                        <View>
                            <Text>
                                Mail : support.in@alphaskynet.com
                            </Text>
                            <Text>
                                Contact : 99948452778
                            </Text>
                        </View>
                    </View>
                    <View style={Style.notification}>
                        <Text style={Style.countryLabel}>Contact US</Text>
                        <View>
                            <Text>
                                Mail : suppor.us@alphaskynet.com
                            </Text>
                            <Text>
                                Contact : 99948452778
                            </Text>
                        </View>
                    </View>
                    <View style={Style.notification}>
                        <Text style={Style.countryLabel}>Contact Japan</Text>
                        <View>
                            <Text>
                                Mail : support.jp@alphaskynet.com
                            </Text>
                            <Text>
                                Contact : 99948452778
                            </Text>
                        </View>
                    </View>
                </View>
               
                <View style={Style.notification}>
                    <Text style={Style.pageSubLabel}>Feedback</Text>
                        <CustomTextInput type="text" id="orderName"
                            value={""}
                            placeHolder="Your valuable input" onInputChange={(ID,val)=>{(val)}} />
                        <CustomButton buttonID={"Submit"} type="common"
                        title="Submit" onSubmit={()=>{(null)}}/>
                </View>

            </View> 
        </View>
    )
}

export default SupportActivity;