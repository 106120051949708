import { StyleSheet } from "react-native";
import Colors from "../../constants/Colors"; 

const Style=StyleSheet.create({
    container:{
        flex:1,
    },
    customButton:{
        height:45,
        width:340,
        margin:10,
        padding:5,
        backgroundColor:Colors.light.progressBarColor
    }
})

export default Style;