import { StyleSheet } from "react-native";
import * as Colors from '../../constants/Colors';
const Style=StyleSheet.create({
    container:{
        flex:1,
        maxHeight:80,
        width:'100%',
        justifyContent:'flex-start',
        padding:10,
       // backgroundColor:Colors.default.light.progressBarColor,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 1,
        elevation: 3,
        flexDirection:'row'
    },
    appTitle:{
        margin:15,
        fontWeight:'600',
        fontSize:20,
     //   color:'white'
    },
    menuIcon:{
        margin:15,
        marginLeft:10,
        height:25,
        width:25
    }
});

export default Style;