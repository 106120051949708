import { StyleSheet } from "react-native";
import Colors from "../../constants/Colors"; 

export const Style=StyleSheet.create({
    mainContent:{
        flex:1,
    },
    errorText:{
        display:'none',
        color:'red',
        marginTop:15,
        margin:10,
        alignSelf:'flex-start',
    },
    container:{
        flex:1,
        flexDirection:'column',
    },
    divView:{
        flex:1,
        marginBottom:0,
        position:'relative',
        flexDirection:'column',
        padding:15
    },
    groupContainer:{
       padding:10,
    },
    accessListText:{
        fontSize:15,
        fontWeight:'500',
        color:'#6b6f70',
        alignSelf:'center'
    },
    fieldsView:{
        flex:1,
        flexWrap:'wrap',
        justifyContent:'space-around',
        overflow:'scroll'
    },
    userStatus:{
        margin:10,
        flexDirection:'row',
    },
    userStatusLabel:{
        marginLeft:10,
        alignSelf:'flex-start'
    },
    accessDropdown:{
        margin:15,
        marginTop:0,
    }
});